<template>
  <v-hover
    v-if="showIcon"
    v-slot:default="{ hover }"
    :open-delay="0"
    :close-delay="0"
    :value="val"
  >
    <span :data-cy="dataCy">
      <v-btn class="popover-status-icon" text @click="val = !val">
        <v-icon v-if="icon" :color="iconColor">
          {{ icon }}
        </v-icon>
        <img v-else :src="imgSrc" :alt="imgAlt" />
      </v-btn>
      <v-tooltip
        :content-class="tooltipContentClass"
        v-bind:value="hover"
        bottom
      >
        <template v-slot:activator="{ on }">
          <span v-on="on"></span>
        </template>
        <span>
          {{ iconText }}
        </span>
      </v-tooltip>
    </span>
  </v-hover>
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    showIcon: Boolean,
    icon: String,
    iconColor: { type: String, default: 'grey' },
    imgSrc: String,
    imgAlt: String,
    iconText: String,
    tooltipContentClass: String,
    dataCy: String,
  },
  data() {
    return {
      val: false,
    }
  },
}
</script>

import {
  LOG_IN_AS_HEADER_NAMES,
  LOG_IN_AS_HEADER_USER_VALUES,
  PATH_PARAMS,
} from './utils'
import { getLoggedInAsUser } from '../../helpers/loginas/logInAsHelper'

export const getLogInAsHeaders = (headersArray) => {
  const headers = {}
  const loggedInAsUser = getLoggedInAsUser()

  if (loggedInAsUser?.accountId && headersArray?.length) {
    headers[LOG_IN_AS_HEADER_NAMES.LOG_IN_AS] = true
    headersArray.forEach((key) => {
      if (loggedInAsUser.hasOwnProperty(LOG_IN_AS_HEADER_USER_VALUES[key])) {
        headers[key] = loggedInAsUser[LOG_IN_AS_HEADER_USER_VALUES[key]]
      }
    })
  }

  return headers
}

export const getLogInAsUrl = (url, loggedInUserAccountId) => {
  const loggedInAsUser = getLoggedInAsUser()
  const accountId = loggedInAsUser?.accountId
    ? loggedInAsUser.accountId
    : loggedInUserAccountId
  return url.replace(PATH_PARAMS.ACCOUNT_ID, accountId)
}

<template>
  <v-card class="ma-4">
    <v-card-title>{{ $t('developerPanel.myEms')}}</v-card-title>
    <v-card-text>
      <v-data-table
      :headers="headers"
      :items="emulatorData"
      :items-per-page="10">
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { HTTPStatus } from '../../api'

export default {
  data: () => ({
      headers: [
        { text: 'Serial', value: 'serial' },
        { text: 'Device Type', value: 'model' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Last contact', value: 'last_contact' },
        { text: 'Status', value: 'status' }
      ],
  }),
  computed: {

    ...mapGetters('developerpanel', [
      'getEmulators'
      ]),

    emulatorData() {

      const emulators = this.getEmulators

      if (emulators.length > 0) {
          return emulators.map((emulator) => {
            let lastLog = "N/A"
             if (emulator.logs?.length > 0) { // checks if emulator has log data
                lastLog = emulator.logs[emulator.logs.length - 1][0].Value // the last array is the most recent data
             }
            return {
              ...emulator,
              last_contact: lastLog
            }
          })
        } else {
          return []
        }
    }
  },
  async mounted() {

      const response = await this.$api.getAllEmulators()

      if (response.status === HTTPStatus.OK) {
        const data = await response.json()
        this.setEmulators(data)
      } else {
        const message = {
            text: 'API call failed',
            type: 'warning',
            timeout: -1,
            buttonText: 'Close',
            status: response.status
        }
        this.$store.dispatch('notifications/addMessage', message)
      }
  },
  methods: {
    ...mapActions({
      setEmulators: 'developerpanel/setEmulators',
    })
  }
}
</script>

<style lang="scss" scoped>
  .v-card__title, .v-card__text {
    padding-bottom: 0;
  }

</style>

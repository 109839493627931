<template>
  <v-card class="pa-4 ma-4">
    <v-card-title>{{ $t('developerPanel.createEmTitle') }}</v-card-title>
    <v-card-text class="pa-2">
      <v-form ref="createForm">
        <v-select
        v-model="device"
        label="Device Type"
        :items="deviceTypes"
        item-text="name"
        item-value="id"
        return-object
        outlined
        />
        <v-text-field v-model="lastSerial" label="Serial Number" outlined :rules="[validationRules.serialNumber]" @input="validateSerial"/>
        <v-checkbox v-model="bulk" label="Create Multiple Emulators" />
        <v-text-field v-if="bulk" type="number" label="Device Quntity" v-model="quantity" outlined :rules="[validationRules.quantity]" @input="validateBulk"/>
      </v-form>
      <v-btn :disabled="!isSerialValid || (bulk && !isBulkValid)" @click="createEmClick">{{ $t('buttons.create') }}</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>

import { DEVICES } from '../../types/constants'
import { mapActions } from 'vuex'
import { HTTPStatus } from '../../api'

export default Vue.extend({
  data() {
    return {
      device: [...DEVICES.values()][0],
      devices: [],
      deviceTypes: [...DEVICES.values()],
      lastSerial: '',
      quantity: 2,
      bulk: false,
      validationRules: {
        serialNumber: value => /^(?!1[5-9]|[2-4]\d|50)\d{2}\d{5}$/.test(value), // YY excludes 15-50, accepts 7 numbers
        quantity: value => value >= 2 && value <= 100
      },
      isSerialValid: false,
      isBulkValid: true
    }
  },
  methods: {

    validateSerial() {
      if (this.validationRules.serialNumber(this.lastSerial)) {
        this.isSerialValid = true
      } else {
        this.isSerialValid = false
      }
    },

    validateBulk() {
      if (this.validationRules.quantity(this.quantity)) {
        this.isBulkValid = true
      } else {
        this.isBulkValid = false
      }
    },

    ...mapActions({
      setEmulators: 'developerpanel/setEmulators',
    }),

    async createEmClick() {
      this.setDevices()

      const response = await this.$api.createEmulator(this.devices)
      if (response.status === HTTPStatus.Created) {
        const res = await this.$api.getAllEmulators()
        if (res.status === HTTPStatus.OK) {
          const data = await res.json()
          this.setEmulators(data)
        } else {
          const message = {
              text: 'API call failed',
              type: 'warning',
              timeout: -1,
              buttonText: 'Close',
              status: res.status
          }
          this.$store.dispatch('notifications/addMessage', message)
        }
      }
    },
    createCurrentDate(curDate) {
      const formatDate = curDate.toISOString()
      return formatDate.slice(0, 23)
    },
    setDevices() {
      const device = {
        model: this.device.model_number,
        serial: this.device.model_number + this.lastSerial
      }
      const devices = [device]
      if (this.device.sub_model) device.sub_model = this.device.sub_model // airassure test case

      if (this.bulk) {
        for (let i = 1; i < this.quantity; i++) {
          const device = {
            model: this.device.model_number,
            serial: this.device.model_number + String(+this.lastSerial + i).padStart(7, "0") // would it be possible for the last 3 to be 999, is it always going be 7 number long last serial numbers
          }
          if (this.device.sub_model) device.sub_model = this.device.sub_model
          devices.push(device)
        }
      }
      this.devices = devices
    }
  }
})

</script>

import UserModule from './user'
import MapModule from './map'
import DevicesModule from './devices'
import DevicesModelsModule from './devicemodels'
import AlertModule from './alerts'
import SubscriptionsModule from './subscriptions'
import AdminPanelModule from './adminpanel'
import LoginAsModule from './loginas'
import DeveloperPanelModule from './developerpanel'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    releaseVersion: process.env.VUE_APP_VERSION,
  },
  modules: {
    user: UserModule,
    map: MapModule,
    devices: DevicesModule,
    devicemodels: DevicesModelsModule,
    alerts: AlertModule,
    subscriptions: SubscriptionsModule,
    adminpanel: AdminPanelModule,
    loginas: LoginAsModule,
    developerpanel: DeveloperPanelModule,
  },
})

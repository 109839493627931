<template>
  <div>
    <v-list>
      <measurement-options
        :deviceIds="deviceIds"
        :passedinParameter="passedinParameter"
        :widgetId="widgetId"
        @parameterChange="parameterChange"
      />
      <v-divider></v-divider>
      <!-- Export Data -->
      <v-list-item
        @click.stop="openExportData"
        data-cy="exportData"
        :disabled="disableExportButton"
      >
        <v-icon>mdi-file-download</v-icon>
        <v-list-item-title>{{
          $t('map.historyDialog.dropdown.exportCsv')
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <export-data-dialog
      :open="exportData"
      :devices="selectedDevices"
      :permissions="permissions"
      @close="closeExportData"
    />
  </div>
</template>

<script>
import ExportDataDialog from '../dialogs/ExportDataDialog.vue'
import MeasurementOptions from '@/components/devicePopover/content/dropdown/MeasurementOptions.vue'

export default {
  name: 'history-menu',
  components: {
    'export-data-dialog': ExportDataDialog,
    'measurement-options': MeasurementOptions,
  },
  props: {
    selectedDevices: Array,
    passedinParameter: Number,
    widgetId: String,
    permissions: Object,
  },
  data() {
    return {
      exportData: false,
    }
  },
  computed: {
    deviceId() {
      return this.selectedDevices?.[0]?.id || ''
    },
    isDeviceConnectedToSmartBridge() {
      return !!this.$store.getters[
        'devices/getConnectedSmartBridgeSerialByDeviceId'
      ](this.deviceId)
    },
    // TODO: This can be removed after enabling CSV Export for connected devices.
    disableExportButton() {
      return this.isDeviceConnectedToSmartBridge
    },
    deviceIds() {
      return this.selectedDevices.map((device) => {
        return device.id
      })
    },
  },
  methods: {
    openExportData() {
      this.exportData = true
    },
    closeExportData() {
      this.exportData = false
    },
    parameterChange(newValue) {
      this.$emit('parameterChange', newValue)
    },
  },
}
</script>
<style lang="scss" scoped>
.v-list {
  padding: 0px;
}
</style>

export default {
  getIsLogInAs(state) {
    return state.isLogInAs
  },
  getImpersonatedUser(state) {
    return state.impersonatedUser
  },
  getImpersonatedUserPermissions(state) {
    return state.impersonatedUserPermissions
  }
}

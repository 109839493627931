export default {
  async setAccountsTableData({ commit }, data) {
    const { accountsData, simpleSubs, loginDates } = data
    //Format data to be sent to accounts table
    const dataToBeCommitted = []
    for (let index = 0; index < accountsData.length; index++) {
      const singleAcct = {
        email: accountsData[index].email,
        username: `${accountsData[index].lastName}, ${accountsData[index].firstName}`,
        firstName: accountsData[index].firstName,
        lastName: accountsData[index].lastName,
        accountId: accountsData[index].accountID,
        userId: accountsData[index].id,
        subscription: simpleSubs[index],
        sapAcctId: accountsData[index].sapAccountId,
        lastLogIn: loginDates[index],
      }
      dataToBeCommitted.push(singleAcct)
    }
    commit('updateAccountsTableData', dataToBeCommitted)
  },
}

<template>
  <base-dialog
    @close="close"
    :open="open"
    :error="error"
    :secondaryError="secondaryError"
    :shaded="isShaded"
    :title="$t('map.deviceSettings.title')"
    icon="mdi-settings-box"
    data-cy="deviceSettingsDialog"
    :fullscreen="$vuetify.breakpoint.xs"
    :persistentOverlay="true"
    customCss="device-settings"
  >
    <!-- default view -->
    <template v-if="showDefaultView">
      <v-progress-circular
        v-show="isLoading"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <v-tabs centered fixed-tabs v-model="tab" :inert="isLoading">
        <v-tabs-slider :color="tabSliderColor"></v-tabs-slider>
        <v-tab
          data-cy="generalTab"
          href="#generalTab"
          :class="{ 'error--text': !generalTab.valid }"
        >
          <span class="tab-title">{{ $t('map.deviceSettings.general') }}</span>
        </v-tab>
        <v-tab
          data-cy="locationTab"
          href="#locationTab"
          :class="{ 'error--text': !locationTab.valid }"
        >
          <span class="tab-title">{{ $t('map.deviceSettings.location') }}</span>
        </v-tab>
        <v-tab
          v-if="calibrationTab.show"
          data-cy="calibrationTab"
          href="#calibrationTab"
          :class="{ 'error--text': !calibrationTab.valid }"
        >
          <span class="tab-title">{{
            $t('map.deviceSettings.calibration')
          }}</span>
        </v-tab>

        <v-tab-item :eager="true" value="generalTab">
          <v-form v-model="generalTab.valid" ref="generalTab">
            <v-card flat>
              <device-name
                v-model="generalTab.deviceName"
                :rules="[rules.required]"
                :cyTag="'settingsDeviceName'"
              ></device-name>
              <v-card :disabled="!generalTab.enablePublicToggle" elevation="0" class="pa-0 ma-0" data-cy="settingsIsPublicContainer">
                <v-switch
                  v-model="generalTab.isPublic"
                  v-bind:label="$t('activateDevice.isPublic')"
                  color="primary"
                  v-bind:hint="$t('activateDevice.isPublicDescription')"
                  persistent-hint
                  data-cy="settingsIsPublic"
                ></v-switch>
              </v-card>
              <div v-if="generalTab.showIndoorSensor" class="secondary-indicator">
                <v-switch
                  v-model="generalTab.indoorSensor"
                  :disabled="generalTab.isIndoorOnly"
                  color="primary"
                  :hint="indoorSensorHint"
                  :persistent-hint="generalTab.isIndoorOnly"
                  data-cy="settingsIsIndoor"
                >
                  <template v-slot:label>
                    {{ $t('activateDevice.indoorSensor') }}
                    <div class="device-type-indicator">
                      <img
                        v-if="generalTab.indoorSensor"
                        :src="indoorPinSrc"
                        alt="Indoor Device Pin"
                      />
                      <img
                        v-if="!generalTab.indoorSensor"
                        :src="outdoorPinSrc"
                        alt="Outdoor Device Pin"
                      />
                    </div>
                  </template>
                </v-switch>
              </div>
              <!-- Logging and Cleaning Intervals -->
              <v-row v-if="generalTab.showLoggingCleaningIntervals">
                <v-col cols="6">
                  <template>
                    <v-select
                      v-model="loggingInterval"
                      :items="loggingIntervalItems"
                      :label="$t('map.deviceSettings.loggingInterval')"
                      data-cy="loggingIntervalDropDown"
                    >
                      <template v-slot:item="{ item, on, attrs }">
                        <v-list-item v-on="on" v-bind="attrs">
                          <v-tooltip top :disabled="!item.disabled">
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-content v-on="on" v-bind="attrs">
                                <v-list-item-title>
                                  <span :data-cy="`${item.text}`">{{item.text}}</span>
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                            <span>{{ $t('map.deviceSettings.upgrade') }}</span>
                          </v-tooltip>
                        </v-list-item>
                      </template>
                    </v-select>
                  </template>
                </v-col>
                <v-col cols="6">
                  <template>
                    <v-select
                      v-model="cleaningInterval"
                      :items="cleaningIntervalItems"
                      data-cy="cleaningIntervalDropDown"
                    >
                     <template v-slot:label>
                      <div class="cleaning-interval-label-wrapper">
                        <label>{{$t('map.deviceSettings.cleaningInterval')}}</label>
                        <v-tooltip bottom max-width="400">
                       <template v-slot:activator="{ on, attrs }">
                        <span icon v-bind="attrs" v-on="on">
                         <v-icon data-cy="cleaningIntervalTooltip">mdi-information-outline</v-icon>
                           </span>
                           </template>
                          <span>
                            {{ $t('map.deviceSettings.cleaningIntervalToolTip') }}
                          </span>
                        </v-tooltip>
                      </div>
                     </template>
                      <template v-slot:item="{ item, on, attrs }">
                        <v-list-item v-on="on" v-bind="attrs">
                          <v-tooltip top :disabled="!item.disabled">
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-content v-on="on" v-bind="attrs">
                                <v-list-item-title>
                                  {{ item.text }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                            <span>{{ $t('map.deviceSettings.upgrade') }}</span>
                          </v-tooltip>
                        </v-list-item>
                      </template>
                    </v-select>
                  </template>
                </v-col>
              </v-row>
              <!-- Unregister -->
              <v-btn
                @click="unregisterWarningOpen = true"
                text
                class="error--text"
                data-cy="unregisterButton"
                >{{ $t('deviceUnregister.unregisterButton') }}</v-btn
              >
              <div class="hint-text hint-text-button">
                {{ $t('deviceUnregister.unregisterHelp') }}
              </div>
            </v-card>
          </v-form>
        </v-tab-item>

        <v-tab-item :eager="true" value="locationTab">
          <v-form v-model="locationTab.valid" ref="locationTab">
            <v-card flat>
              <v-card-title class="tab-content__title">
                {{ $t('activateDevice.location') }}
              </v-card-title>
              <device-location-name
                v-model="locationTab.locationName"
                :cyTag="'deviceSettingsLocationName'"
              ></device-location-name>
              <v-card :disabled="!this.locationTab.allowSetLocation" elevation="0" class="ma-0 pa-0" data-cy="settingsSetLocationContainer">
                <v-btn
                  :loading="gettingLocation"
                  @click="useBrowserLocation"
                  text
                  class="primary--text"
                  v-bind:disabled="locationError"
                  data-cy="useBrowserLocation"
                  >{{ $t('activateDevice.useMyLocation') }}</v-btn
                >
                <div class="hint-text hint-text-button">
                  {{ $t('activateDevice.useMyLocationHint') }}
                </div>
                <br />
                <div class="errorMessage">{{ this.locationErrorMessage }}</div>
                <v-text-field
                  v-model="locationTab.coords.latitude"
                  v-bind:label="$t('activateDevice.latitude')"
                  min="-90"
                  max="90"
                  maxlength="12"
                  :rules="this.latitudeRules"
                  @keypress="preventNonCoords($event)"
                  data-cy="deviceSettingsLatitude"
                  :class="{ 'text-field-required' : this.locationTab.allowSetLocation }"
                  :disabled="!this.locationTab.allowSetLocation"
                ></v-text-field>
                <v-text-field
                  v-bind:label="$t('activateDevice.longitude')"
                  persistent-hint
                  v-model="locationTab.coords.longitude"
                  min="-180"
                  max="180"
                  maxlength="13"
                  :rules="this.longitudeRules"
                  @keypress="preventNonCoords($event)"
                  data-cy="deviceSettingsLongitude"
                  :class="{ 'text-field-required' : this.locationTab.allowSetLocation }"
                  :disabled="!this.locationTab.allowSetLocation"
                ></v-text-field>
                <div class="hint-text">
                  {{ $t('activateDevice.coordinateHint') }}
                  <a
                    v-bind:href="$t('activateDevice.coordinateHintLink')"
                    class="hint-text-link"
                    target="_blank"
                    >{{ $t('activateDevice.coordinateHintLink') }}</a
                  >
                </div>
              </v-card>
            </v-card>
          </v-form>
        </v-tab-item>

        <v-tab-item :eager="true" value="calibrationTab">
          <v-form v-model="calibrationTab.valid" ref="calibrationTab">
            <device-calibration
              :deviceModel="generalTab.deviceModel"
              :userCals.sync="calibrationTab.userCals"
              :userZeros.sync="calibrationTab.userZeros"
              :permissions="permissions"
              @validated="onValidation"
              @requestZero="requestZero"
            ></device-calibration>
          </v-form>
        </v-tab-item>
      </v-tabs>
    </template>
    <template v-if="showDefaultView" v-slot:actions>
      <v-btn
        @click="close"
        outlined
        class="black--text"
        text
        data-cy="deviceSettingsClose"
        >{{ $t('buttons.cancel') }}</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        @click="save"
        class="primary white--text"
        :disabled="disableSave"
        text
        data-cy="deviceSettingsSave"
        >{{ $t('buttons.save') }}</v-btn
      >
    </template>
    <!-- unregister device view actions -->
    <template v-else-if="unregisterWarningOpen" v-slot:actions>
      <device-unregister-actions
        :deviceId="deviceId"
        @unregister="handleUnregister"
        @close="unregisterWarningOpen = false"
      />
    </template>
    <!-- zeroing confirmation view actions -->
    <template v-else-if="zeroConfirmOpen" v-slot:actions>
      <device-zero-confirmation-actions
        @confirm="confirmAddZero"
        @cancel="zeroConfirmOpen = false"
      />
    </template>
    <!-- unregister device view body -->
    <span v-if="unregisterWarningOpen">
      <device-unregister-card :deviceId="deviceId" />
    </span>
    <!-- zeroing confirmation view body -->
    <span v-if="zeroConfirmOpen">
      <device-zero-confirmation
        :zeroValue="calibrationTab.queuedZeroRequest.zeroValue"
      />
    </span>
  </base-dialog>
</template>

<script>
import { locationGetter, locationCoordsRegex } from '../../services/location'
import {
  defaultLoggingInterval,
  defaultCleaningInterval,
  writeDeviceConfig,
  getConfigByKey,
} from '../../services/device-config'
import { getUserCals } from '../../services/device-calibration'
import { getUserZeros } from '../../services/device-zeroing'
import { DEVICE_UPDATE_INTERVAL_MS } from '../../services/device-metadata'
import { checkStatus } from '../../api/services/utils'
import Dialog from './Dialog.vue'
import DeviceUnregisterCard from '../deviceUnregister/DeviceUnregisterCard.vue'
import DeviceUnregisterActions from '../deviceUnregister/DeviceUnregisterActions.vue'
import DeviceCalibration from '../deviceSettings/DeviceCalibration'
import { SlugsEnum } from '../../permissions/SlugsEnum'
import DeviceZeroConfirmation from '../deviceSettings/DeviceZeroConfirmation.vue'
import DeviceZeroConfirmationActions from '../deviceSettings/DeviceZeroConfirmationActions.vue'
import DeviceName from '../deviceSettings/DeviceNameTextarea.vue'
import DeviceLocationName from '../deviceSettings/DeviceLocationNameTextarea.vue'

const loggingIntervalTypes = {
  minute1: 60,
  minute5: 300,
  minute10: 600,
  minute15: 900,
  minute30: 1800,
  minute60: 3600,
}

const cleaningIntervalTypes = {
  day1: 86400,
  day7: 604800,
}

export default {
  name: 'DeviceSettingsDialog',
  props: ['open', 'deviceId', 'permissions'],
  mixins: [locationGetter],
  components: {
    'base-dialog': Dialog,
    'device-name': DeviceName,
    'device-location-name': DeviceLocationName,
    'device-calibration': DeviceCalibration,
    'device-unregister-card': DeviceUnregisterCard,
    'device-unregister-actions': DeviceUnregisterActions,
    DeviceZeroConfirmation,
    DeviceZeroConfirmationActions,
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      tab: null,
      unregisterWarningOpen: false,
      zeroConfirmOpen: false,
      gettingLocation: false,
      locationError: false,
      locationErrorMessage: '',
      updateError: false,
      requiredError: false,
      calibrationError: false,
      loadStateInterval: null,

      generalTab: {
        valid: false,
        deviceName: '',
        deviceModel: '',
        deviceModelName: '',
        enablePublicToggle: false,
        isPublic: false,
        indoorSensor: false,
        isIndoorOnly: false,
        showLocation: false,
        showIndoorSensor: false,
        showLoggingCleaningIntervals: false,
        privacyZone: false,
        calibrationFactors: {
          pm25: 1,
          pm10: 1,
        },
      },

      locationTab: {
        locationName: '',
        valid: false,
        allowSetLocation: false,
        coords: {
          latitude: '',
          longitude: '',
        },
      },

      calibrationTab: {
        show: false,
        valid: false,
        userCals: [],
        userZeros: {},
        queuedZeroRequest: {
          zeroValue: null,
          sensorName: null,
        },
      },

      loggingInterval: null, // this is the default selection
      cleaningInterval: null, //set from api
      rules: {
        empty: (value) => value === null || value.length === 0,
        required: (value) => !!value || this.$t('validation.required'),
        latitude: (value) => {
          return (
            locationCoordsRegex.latitude.test(value) ||
            this.$t('validation.invalidEntry')
          )
        },
        longitude: (value) => {
          return (
            locationCoordsRegex.longitude.test(value) ||
            this.$t('validation.invalidEntry')
          )
        },
      },
    }
  },
  mounted() {
    this.completeDialogData()
  },
  watch: {
    open() {
      this.completeDialogData()
    },
  },
  computed: {
    latitudeRules() {
      if (this.generalTab.showLocation) {
        return [this.rules.required, this.rules.latitude]
      }
      return []
    },
    longitudeRules() {
      if (this.generalTab.showLocation) {
        return [this.rules.required, this.rules.longitude]
      }
      return []
    },
    showDefaultView() {
      return !this.unregisterWarningOpen && !this.zeroConfirmOpen
    },
    hasCleaningIntervalPermission() {
      return this.permissions.includes(SlugsEnum.CleaningIntervalWriteAll)
    },
    hasLoggingIntervalGreaterThan1Permission() {
      return this.permissions.includes(
        SlugsEnum.LoggingIntervalWriteGreaterThan1
      )
    },
    hasLoggingIntervalGreaterThanOrEqualTo15Permission() {
      return this.permissions.includes(
        SlugsEnum.LoggingIntervalWriteGreaterThanOrEqualTo15
      )
    },
    hasLoggingIntervalAllPermission() {
      return this.permissions.includes(SlugsEnum.LoggingIntervalWriteAll)
    },
    cleaningIntervalItems() {
      return [
        {
          text: this.$t('map.deviceSettings.cleaningIntervals.day1'),
          value: cleaningIntervalTypes.day1,
          disabled: !this.hasCleaningIntervalPermission,
        },
        {
          text: this.$t('map.deviceSettings.cleaningIntervals.day7'),
          value: cleaningIntervalTypes.day7,
          disabled: false,
        },
      ]
    },
    loggingIntervalItems() {
      return [
        {
          text: this.$t('map.deviceSettings.loggingIntervals.minute1'),
          value: loggingIntervalTypes.minute1,
          disabled: !this.hasLoggingIntervalAllPermission,
        },
        {
          text: this.$t('map.deviceSettings.loggingIntervals.minute5'),
          value: loggingIntervalTypes.minute5,
          disabled:
            !this.hasLoggingIntervalGreaterThan1Permission &&
            !this.hasLoggingIntervalAllPermission,
        },
        {
          text: this.$t('map.deviceSettings.loggingIntervals.minute10'),
          value: loggingIntervalTypes.minute10,
          disabled:
            !this.hasLoggingIntervalGreaterThan1Permission &&
            !this.hasLoggingIntervalAllPermission,
        },
        {
          text: this.$t('map.deviceSettings.loggingIntervals.minute15'),
          value: loggingIntervalTypes.minute15,
          disabled:
            !this.hasLoggingIntervalGreaterThan1Permission &&
            !this.hasLoggingIntervalGreaterThanOrEqualTo15Permission &&
            !this.hasLoggingIntervalAllPermission,
        },
        {
          text: this.$t('map.deviceSettings.loggingIntervals.minute30'),
          value: loggingIntervalTypes.minute30,
          disabled:
            !this.hasLoggingIntervalGreaterThan1Permission &&
            !this.hasLoggingIntervalGreaterThanOrEqualTo15Permission &&
            !this.hasLoggingIntervalAllPermission,
        },
        {
          text: this.$t('map.deviceSettings.loggingIntervals.minute60'),
          value: loggingIntervalTypes.minute60,
          disabled:
            !this.hasLoggingIntervalGreaterThan1Permission &&
            !this.hasLoggingIntervalGreaterThanOrEqualTo15Permission &&
            !this.hasLoggingIntervalAllPermission,
        },
      ]
    },
    fieldsValid() {
      const lng = parseFloat(this.locationTab.coords.longitude)
      const lat = parseFloat(this.locationTab.coords.latitude)

      const hasName = this.rules.required(this.generalTab.deviceName) === true
      const latRulesSatisfied = this.latitudeRules.every(rule => rule(lat) === true)
      const lngRulesSatisfied = this.longitudeRules.every(rule => rule(lng) === true)

      return latRulesSatisfied && lngRulesSatisfied && hasName && !this.calibrationError
    },
    isCalibrationTab() {
      return this.tab === 'calibrationTab'
    },
    hasInvalidTabs() {
      if (
        !this.generalTab.valid ||
        !this.locationTab.valid ||
        !this.calibrationTab.valid
      ) {
        return true
      }
      return false
    },
    error() {
      if (this.isLoading) {
        return ''
      }
      if (this.updateError) {
        return this.$t('map.deviceSettings.failedToUpdate')
      }
      if (this.requiredError) {
        return this.$t('activateDevice.validationRequiredError')
      }
      if (this.unregisterWarningOpen || this.zeroConfirmOpen) {
        // There are no required fields during unregistration or zeroing confirmation
        // suppress the error message
        return ''
      }
      if (this.isCalibrationTab) {
        return ''
      }
      return this.$t('validation.requiredHelp')
    },
    secondaryError() {
      if (this.isLoading) {
        return ''
      }
      if (this.unregisterWarningOpen || this.zeroConfirmOpen) {
        // There are no required fields during unregistration or zeroing confirmation
        // suppress the error message
        return ''
      }
      if (this.hasInvalidTabs) {
        return this.$t('map.deviceSettings.completeAllTabs')
      }
      return ''
    },
    isShaded() {
      if (this.unregisterWarningOpen || this.zeroConfirmOpen) {
        return false
      }
      return true
    },
    deviceModelKey() {
      const model = this.$store.getters['devices/getModelByDeviceId'](this.deviceId)
      return this.$store.getters['devicemodels/getModelKey'](model)
    },
    deviceImgPackageName() {
      const dustTrakImgPackage = 'dusttrak'
      const modelKey = this.deviceModelKey
      if (modelKey?.includes(dustTrakImgPackage)) {
        return dustTrakImgPackage
      }

      return modelKey
    },
    indoorSensorHint() {
      if (this.generalTab.isIndoorOnly) {
        return this.$t('activateDevice.isIndoorOnlyHint', {
          modelName: this.generalTab.deviceModelName,
        })
      }
      return ''
    },
    indoorPinSrc() {
      return this.$store.getters['devicemodels/getPinPath'](this.generalTab.deviceModel, 'indoor')
    },
    outdoorPinSrc() {
      return this.$store.getters['devicemodels/getPinPath'](this.generalTab.deviceModel, 'outdoor')
    },
    tabSliderColor() {
      return !this[this.tab]?.valid ? 'error' : 'currentColor'
    },
    disableSave() {
      return !this.fieldsValid || this.isLoading || this.isSaving
    },
  },
  methods: {
    async init() {
      this.gettingLocation = false
      this.locationError = false
      this.locationErrorMessage = ''
      this.updateError = false
      this.requiredError = false
      this.calibrationError = false

      this.generalTab.deviceName = ''
      this.generalTab.deviceModel = ''
      this.generalTab.deviceModelName = ''
      this.generalTab.isPublic = false
      this.generalTab.indoorSensor = false
      this.generalTab.isIndoorOnly = false
      this.generalTab.showLocation = false
      this.generalTab.privacyZone = false

      this.locationTab.locationName = ''
      this.locationTab.coords.latitude = ''
      this.locationTab.coords.longitude = ''

      this.calibrationTab.userCals = []
      this.calibrationTab.userZeros = {}
      this.calibrationTab.queuedZeroRequest.zeroValue = null
      this.calibrationTab.queuedZeroRequest.sensorName = null

      await this.loadState()
      await this.getDeviceConfig()
      this.startLoadStateInterval()

      this.validateTabs()
    },
    async completeDialogData() {
      if (this.open) {
        this.init()
        this.completeDeviceData()
        this.completeDeviceMetadata()
      }
    },
    completeDeviceData() {
      if (this.deviceId) {
        this.generalTab.deviceName = this.$store.getters[
          'devices/getNameByDeviceId'
        ](this.deviceId)
        this.generalTab.deviceModel = this.$store.getters[
          'devices/getModelByDeviceId'
        ](this.deviceId)
        this.generalTab.isPublic = this.$store.getters[
          'devices/getIsPublicByDeviceId'
        ](this.deviceId)
        this.generalTab.indoorSensor = this.$store.getters[
          'devices/getIsIndoorByDeviceId'
        ](this.deviceId)
        this.locationTab.locationName = this.$store.getters[
          'devices/getLocationNameByDeviceId'
        ](this.deviceId)

        const coords = this.$store.getters['devices/getCoordsByDeviceId'](
          this.deviceId
        )
        if (coords) {
          this.locationTab.coords.latitude = coords.lat
          this.locationTab.coords.longitude = coords.lng
        }

        if (this.generalTab.deviceModel) {
          this.generalTab.deviceModelName = this.$store.getters[
            'devicemodels/getModelName'
          ](this.generalTab.deviceModel)
        }

        const indoorOnly = this.$store.getters['devicemodels/getIndoorOnly'](
          this.generalTab.deviceModel
        )
        if (indoorOnly) {
          this.generalTab.indoorSensor = true
          this.generalTab.isIndoorOnly = true
        }
      }
    },
    completeDeviceMetadata() {
      if (this.deviceId) {
        const showLocation = this.$store.getters[
          'devicemodels/getShowLocation'
        ](this.generalTab.deviceModel)
        if (showLocation) {
          this.generalTab.showLocation = true
          this.locationTab.allowSetLocation = true
        }
        const showCalibration = this.$store.getters[
          'devicemodels/getShowCalibration'
        ](this.generalTab.deviceModel)
        if (showCalibration) {
          this.calibrationTab.show = true
        }
        const showIndoorSensor = this.$store.getters[
          'devicemodels/getShowIndoorSensor'
        ](this.generalTab.deviceModel)
        if (showIndoorSensor) {
          this.generalTab.showIndoorSensor = true
        }
        const showLoggingCleaningIntervalSettings = this.$store.getters[
          'devicemodels/getShowIntervalSettings'
        ](this.generalTab.deviceModel)
        if (showLoggingCleaningIntervalSettings) {
          this.generalTab.showLoggingCleaningIntervals = true
        }
        const enablePublicToggle = this.$store.getters[
          'devicemodels/getAllowPublic'
        ](this.generalTab.deviceModel)
        if (enablePublicToggle) {
          this.generalTab.enablePublicToggle = true
        }
      }
    },
    close() {
      this.stopLoadStateInterval()
      this.$emit('close', {
        deviceId: this.deviceId,
        coords: {
          lng: parseFloat(this.locationTab.coords.longitude),
          lat: parseFloat(this.locationTab.coords.latitude),
        },
      })

      this.closeUnregisterWarning()
    },
    startLoadStateInterval() {
      if (this.loadStateInterval === null) {
        this.loadStateInterval = setInterval(this.loadState, DEVICE_UPDATE_INTERVAL_MS)
      }
    },
    stopLoadStateInterval() {
      if (this.loadStateInterval !== null) {
        clearInterval(this.loadStateInterval)
        this.loadStateInterval = null
      }
    },
    closeUnregisterWarning() {
      // delay to give time for the closing animation
      setTimeout(() => {
        this.unregisterWarningOpen = false
      }, 100)
    },
    handleUnregister: function () {
      this.$emit('unregister')
      this.close()
    },
    setCoords(coords) {
      if (coords) {
        if (coords.lat && coords.lng) {
          this.locationTab.coords.latitude = `${coords.lat.toFixed(8)}`
          this.locationTab.coords.longitude = `${coords.lng.toFixed(8)}`
        } else {
          this.locationError = true
          this.locationErrorMessage = this.$t(
            'activateDevice.locationNotAvailable'
          )
        }
      } else {
        this.locationError = true
        this.locationErrorMessage = this.$t('activateDevice.locationDenied')
      }
      this.gettingLocation = false
    },
    useBrowserLocation() {
      this.gettingLocation = true

      this.getGeolocation((coords) => {
        this.setCoords(coords)
      })
    },
    preventNonCoords(event) {
      const keypress = event.keyCode || event.which || event.charCode
      if (keypress > 57) {
        // NOTE: may need to modify this to prevent input like "--4.323" or "0100..43" etc
        event.preventDefault()
      }
    },
    async loadState() {
      this.$store.dispatch('devices/getDeviceDetailsAndUpdate', this.deviceId)
    },
    async save() {
      this.isSaving = true
      this.requiredError = false
      this.updateError = false

      const deviceData = {
        cloud_state_data: {
          name: this.generalTab.deviceName,
          locationName: this.locationTab.locationName,
          isPublic: this.generalTab.isPublic,
          isIndoor: this.generalTab.indoorSensor,
          longitude: parseFloat(this.locationTab.coords.longitude),
          latitude: parseFloat(this.locationTab.coords.latitude),
        },
      }

      const config = writeDeviceConfig(
        this.loggingInterval,
        this.cleaningInterval,
        this.calibrationTab.userCals,
        this.calibrationTab.userZeros
      )

      if (!this.hasAllRequiredData(deviceData)) {
        this.requiredError = true
        return
      }

      try {
        const updateResp = await this.$api.patchUpdateDevice(
          deviceData,
          this.deviceId
        )
        if (!updateResp.ok) {
          this.updateError = true
          return
        }
        this.$store.dispatch('devices/updateDeviceCoords', {
          deviceId: this.deviceId,
          coords: {
            lng: parseFloat(deviceData.cloud_state_data.longitude),
            lat: parseFloat(deviceData.cloud_state_data.latitude),
          },
        })

        this.$store.dispatch('devices/updateMetadata', {
          deviceId: this.deviceId,
          isPublic: this.generalTab.isPublic,
          indoor: this.generalTab.indoorSensor,
          name: this.generalTab.deviceName,
          locationName: this.locationTab.locationName,
        })

        /* Save config for devices that show logging intervals, cleaning intervals, or calibration tab.
        Since DustTrak devices are auto-registered and the config is not created during this process,
        don't try to update config data for SB connected devices.
        It returns an error and prevents the device settings from be saved. */
        if (this.generalTab.showLoggingCleaningIntervals || this.calibrationTab.show) {
          const configResp = await this.$api.postDeviceConfig(
            config,
            this.deviceId
          )
          if (!configResp.ok) {
            this.updateError = true
            return
          }
        }

        this.$store.dispatch('devices/updateUserCals', {
          deviceId: this.deviceId,
          userCals: this.calibrationTab.userCals,
        })

        this.close()
      } catch (error) {
        this.updateError = true
      }

      this.isSaving = false
    },
    hasAllRequiredData(deviceData) {
      return (
        this.rules.required(deviceData.cloud_state_data.name) === true &&
        this.latitudeRules.every(rule => rule(deviceData.cloud_state_data.latitude)) &&
        this.longitudeRules.every(rule => rule(deviceData.cloud_state_data.longitude))
      )
    },
    onValidation(v) {
      if (v.type === 'calibration') {
        this.calibrationError = !v.valid
      }
    },
    storeDevicePin(dev) {
      const coords = { lat: dev.latitude, lng: dev.longitude }
      this.$store.dispatch('devices/updateDeviceCoords', {
        deviceId: this.deviceId,
        coords: coords,
      })

      this.$store.dispatch('devices/updateMetadata', {
        deviceId: this.deviceId,
        name: dev.friendlyName,
        indoor: dev.indoorSensor,
      })

      this.$store.dispatch('devices/updateIsPublic', {
        deviceId: this.deviceId,
        isPublic: dev.isPublic,
      })
    },
    async getDeviceConfig() {
      this.isLoading = true

      const resp = await this.$api.getDeviceConfig(this.deviceId)
      if (checkStatus(resp)) {
        const body = await resp.json()
        const sensorConfig = body.sensor_config
        const cloudConfig = body.cloud_config
        this.loggingInterval = getConfigByKey(sensorConfig, 'send_rate') || defaultLoggingInterval
        this.cleaningInterval =
          getConfigByKey(sensorConfig, 'pm_clean_interval') || defaultCleaningInterval

        const model = this.$store.getters['devices/getModelByDeviceId'](
          this.deviceId
        )
        const subModel = this.$store.getters['devices/getSubmodelByDeviceId'](
          this.deviceId
        )
        const modelCalibrationMeta = this.$store.getters[
          'devicemodels/getCalibrationLimits'
        ](model, subModel)

        this.calibrationTab.userCals = getUserCals(
          sensorConfig,
          modelCalibrationMeta
        )

        const zeroableSensors = this.$store.getters[
          'devicemodels/getZeroableMeasurements'
        ](model, subModel)
        const zeroAppliedAtMap = this.$store.getters['devices/getZeroAppliedAtByDeviceId'](this.deviceId)
        const zeroStateMap = this.$store.getters['devices/getZeroStateByDeviceId'](this.deviceId)

        this.calibrationTab.userZeros = getUserZeros(
          zeroableSensors,
          cloudConfig,
          zeroAppliedAtMap,
          zeroStateMap
        )
      }

      this.isLoading = false
    },
    requestZero(sensorName, zeroValue) {
      this.calibrationTab.queuedZeroRequest.sensorName = sensorName
      this.calibrationTab.queuedZeroRequest.zeroValue = zeroValue
      this.zeroConfirmOpen = true
    },
    confirmAddZero() {
      if (
        this.calibrationTab.queuedZeroRequest.sensorName !== null &&
        this.calibrationTab.queuedZeroRequest.zeroValue !== null
      ) {
        this.calibrationTab.userZeros[
          this.calibrationTab.queuedZeroRequest.sensorName
        ].userzero = this.calibrationTab.queuedZeroRequest.zeroValue

        this.calibrationTab.queuedZeroRequest.sensorName = null
        this.calibrationTab.queuedZeroRequest.zeroValue = null
      }

      this.zeroConfirmOpen = false
    },
    validateTabs() {
      this.$refs.generalTab?.validate()
      this.$refs.locationTab?.validate()
      this.$refs.calibrationTab?.validate()
    },
  },
  beforeDestroy() {
    this.close()
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/global-variables.scss';

.v-dialog {
  .v-card {
    padding: 24px;
    .v-input {
      padding-top: 24px;
    }
    .secondary-indicator {
      display: flex;
      margin-bottom: 24px;
      .device-type-indicator {
        align-self: center;
        margin-left: 16px;
        img {
          height: 36px;
        }
      }
    }

    .v-card__text:not(.mobile) {
      .v-card--flat {
        padding: 16px !important;
      }
    }

    .v-card__text.mobile {
      .v-card--flat {
        padding: 12px !important;
      }
    }

    .v-progress-circular {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 100;
      transform: translate3d(-50%, -50%, 0);
    }
  }
  .v-card__title.tab-content__title {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0 0 0 0;
    opacity: 0.56;
  }
  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }
  .hint-text {
    font-size: 12px;
  }
  .hint-text.red-font {
    color: red;
  }
  .hint-text-button {
    margin-left: 16px;
  }
  .errorMessage {
    font-size: 12px;
    color: red;
    text-align: right;
    padding-right: 16px;
  }
}
.v-list-item--disabled {
  pointer-events: auto;
  cursor: not-allowed;
  padding-left: 0;
  padding-right: 0;
  .v-list-item__content {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.v-tabs {
  transition: all 0.2s ease;
  &[inert] {
    opacity: 0.5;
    pointer-events: none;
  }
  ::v-deep .v-tabs-bar {
    .dialog-card--shaded & {
      background-color: transparent;
    }
  }
  &--fixed-tabs ::v-deep .v-tabs-bar {
    padding: 0 16px;
    .dialog-card--shaded & {
      padding: 0;
      background-color: transparent;
    }
  }
  &--fixed-tabs ::v-deep .v-tabs-bar--is-mobile .v-tab:not(.v-tab--active) {
    background-color: $white-smoke-color;
  }
  ::v-deep
    .v-slide-group--is-overflowing:not(.v-slide-group--has-affixes)
    > .v-slide-group__prev {
    display: none !important;
  }
  ::v-deep .v-tabs-slider-wrapper {
    .dialog-card--shaded & {
      top: 0;
      bottom: auto;
    }
  }
  .v-tabs-slider {
    transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  .v-tab--active {
    .dialog-card--shaded & {
      background-color: #fff;
    }
  }
  .v-tab.error--text {
    position: relative;
    &:not(.v-tab--active):before {
      .dialog-card--shaded & {
        opacity: 0.08;
      }
    }
    &:not(.v-tab--active):hover {
      &:before {
        .dialog-card--shaded & {
          opacity: 0.16;
        }
      }
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-color: currentColor;
      opacity: 0.4;
      .dialog-card--shaded & {
        display: none;
      }
    }
    .tab-title {
      position: relative;
      &:after {
        content: '*';
        position: absolute;
        left: 100%;
        margin-left: 4px;
        vertical-align: super;
        font-size: smaller;
        line-height: 0.75;
      }
    }
  }
  .cleaning-interval-label-wrapper{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 0.5rem;
    margin-top: -2px;
  }
}
</style>

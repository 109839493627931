export default {
  startUpdatingLocation(state) {
    state.loading = true
  },
  stopUpdatingLocation(state) {
    state.loading = false
  },
  updateLocation(state, coords) {
    state.lat = coords.lat
    state.lng = coords.lng
    state.allowed = true
  },
  disallowLocation(state) {
    state.allowed = false
  },
}

export const productFamilies = Object.freeze({
  BLUESKY: 'bluesky',
  AIRASSURE: 'airassure',
})

const AQICodes = [
  { min: 0, max: 50, name: 'good', background: '#00e400', color: '#0c0c0cc2' },
  {
    min: 51,
    max: 100,
    name: 'moderate',
    background: '#ffff00',
    color: '#0c0c0cc2',
  },
  {
    min: 101,
    max: 150,
    name: 'unhealthy_sensitive',
    background: '#ff7e00',
    color: '#0c0c0cc2',
  },
  {
    min: 151,
    max: 200,
    name: 'unhealthy',
    background: '#ff0000',
    color: 'white',
  },
  {
    min: 201,
    max: 300,
    name: 'very_unhealthy',
    background: '#8f3f97',
    color: 'white',
  },
  {
    min: 301,
    max: 500,
    name: 'hazardous',
    background: '#7e0023',
    color: 'white',
  },
]

export const getAQIStyles = (aqi) => {
  let styles = {
    background: '#eeeeee',
    color: 'white',
  }

  if (!aqiValid(aqi)) {
    return styles
  }

  const aqiCodeArr = AQICodes.filter((code) => {
    return code.min <= aqi && code.max >= aqi
  })

  if (aqiCodeArr.length != 1) {
    return styles
  }

  styles.background = aqiCodeArr[0].background
  styles.color = aqiCodeArr[0].color
  return styles
}

export const aqiValid = (aqi) => {
  if (aqi || aqi === 0) {
    if (typeof aqi !== 'number') {
      return false
    }
    if (aqi == -1) return false
    return true
  } else {
    return false
  }
}
export const getAQIName = (aqi) => {
  if (aqi == -1) {
    return 'error'
  }
  if (!aqiValid(aqi)) {
    return 'none'
  }

  const aqiCodeArr = AQICodes.filter((code) => {
    return code.min <= aqi && code.max >= aqi
  })

  if (aqiCodeArr.length != 1) {
    return 'none'
  }

  return aqiCodeArr[0].name
}

export const deviceModelDisplay = (modelKey, translateFn) => {
  if (modelKey) {
    return translateFn(`map.mapSettings.deviceModelNames.${modelKey}`)
  }
  return ''
}

export const deviceModelDisplayLong = (modelKey, translateFn) => {
  if (modelKey) {
    return translateFn(`map.mapSettings.deviceModelLongNames.${modelKey}`)
  }
  return ''
}

export const deviceSubmodelDisplay = (submodelKey, translateFn) => {
  if (submodelKey) {
    return translateFn(`map.mapSettings.deviceSubmodelNames.${submodelKey}`)
  }
  return ''
}

export const getRelativeTimeSince = (date, translateFn) => {
  const msPerMinute = 60 * 1000
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24
  const msPerMonth = msPerDay * 30
  const msPerYear = msPerDay * 365

  if (!date || isNaN(date)) {
    return translateFn('map.deviceInfo.connection.never')
  }

  const elapsed = new Date() - date
  if (elapsed < msPerMinute) {
    return translateFn(
      'map.deviceInfo.connection.secondsAgo',
      Math.floor(elapsed / 1000)
    )
  } else if (elapsed < msPerHour) {
    return translateFn(
      'map.deviceInfo.connection.minutesAgo',
      Math.floor(elapsed / msPerMinute)
    )
  } else if (elapsed < msPerDay) {
    return translateFn(
      'map.deviceInfo.connection.hoursAgo',
      Math.floor(elapsed / msPerHour)
    )
  } else if (elapsed < msPerMonth) {
    return translateFn(
      'map.deviceInfo.connection.daysAgo',
      Math.floor(elapsed / msPerDay)
    )
  } else if (elapsed < msPerYear) {
    return translateFn(
      'map.deviceInfo.connection.monthsAgo',
      Math.floor(elapsed / msPerMonth)
    )
  } else {
    return translateFn(
      'map.deviceInfo.connection.yearsAgo',
      Math.floor(elapsed / msPerYear)
    )
  }
}

const getBackground = (aqi, moving) => {
  // Set indicator background based on aqi value
  let background = ''
  const notReportingBackground = '#eaeaea'
  const defaultBackground = '#0fadef'
  if (aqi || aqi === 0) {
    const aqiCodeArr = AQICodes.filter((code) => {
      return code.min <= aqi && code.max >= aqi
    })

    if (aqiCodeArr.length !== 1) {
      background = notReportingBackground
    } else {
      background = aqiCodeArr[0].background
    }
  } else if (aqi === null) {
    background = notReportingBackground
  } else {
    // if the current reading is undefined, then the pin will be in the blank "loading" state
    background = defaultBackground
  }

  // Set indicator background based on moving value
  if (moving) {
    background = defaultBackground
  }
  return background
}

const getBadgeCount = (badge, moving) => {
  // Build SVG code for alert badge count based on badge value
  let badgeCount = ''
  if (badge && !moving) {
    let xPos = 5
    if (badge > 9) {
      badge = '9+'
      xPos = 3
    }
    badgeCount = `
      <circle cx="8" cy="8" r="8" />
      <text id="badge-count" x="${xPos}" y="11" fill="#fff" font-size="10" font-family="Roboto-Regular, Roboto, sans-serif">${badge}</text>`
  }
  return badgeCount
}

export const buildIconSVG = (
  productFamily,
  aqi,
  indoor,
  moving = false,
  badge = 0
) => {
  const icon = {
    url: '',
    labelOrigin: { x: null, y: null },
  }

  const background = getBackground(aqi, moving)

  // Set pin fill color based on indoor value
  const fillColor = indoor ? '#fff' : '#000'

  const badgeCount = getBadgeCount(badge, moving)

  // Build final SVG markup and assign label coordinates
  let svg = ''
  let badgeGroup = ''
  let labelOrigin = { x: 0, y: 0 }
  let anchor = { x: 16, y: 52 }

  switch (productFamily) {
    case productFamilies.BLUESKY:
      badgeGroup = badgeCount
        ? `<g id="badge" transform="translate(121.5 82)">${badgeCount}</g>`
        : ''
      svg = `
        <svg xmlns="http://www.w3.org/2000/svg" width="38.5" height="51.922">
          <g id="bluesky-icon" transform="translate(-99 -82)">
            <g>
              <path id="icon-fill" d="M114.5 132.46l-.729-.775c-.14-.15-3.464-3.704-6.842-8.568-1.994-2.87-3.585-5.596-4.73-8.102-1.459-3.196-2.199-6.061-2.199-8.515 0-3.877 1.507-7.52 4.244-10.256A14.407 14.407 0 01114.5 92c3.877 0 7.52 1.507 10.256 4.244A14.407 14.407 0 01129 106.5c0 2.454-.74 5.319-2.2 8.515-1.144 2.506-2.735 5.232-4.729 8.102-3.378 4.864-6.702 8.419-6.842 8.568l-.729.776z" fill="${fillColor}" />
              <path id="icon-outline" d="M114.5 93c-7.464 0-13.5 6.036-13.5 13.5 0 10.125 13.5 24.5 13.5 24.5s13.5-14.375 13.5-24.5c0-7.464-6.036-13.5-13.5-13.5m0-2a15.4 15.4 0 0110.963 4.537A15.4 15.4 0 01130 106.5c0 4.615-2.391 10.397-7.107 17.188-3.42 4.923-6.793 8.53-6.935 8.681l-1.458 1.553-1.458-1.553c-.142-.151-3.515-3.758-6.935-8.681C101.391 116.898 99 111.115 99 106.5a15.4 15.4 0 014.537-10.963A15.4 15.4 0 01114.5 91z" fill="#000" />
              <circle id="aqi-indicator" cx="0" cy="0" r="13" stroke="#fff" fill="${background}" transform="translate(114.5 106.5)" />
            </g>
            ${badgeGroup}
          </g>
        </svg>`
      labelOrigin = { x: 16, y: 24 }
      break

    case productFamilies.AIRASSURE:
      badgeGroup = badgeCount
        ? `<g id="badge" transform="translate(349.5 146.75)">${badgeCount}</g>`
        : ''
      svg = `
        <svg xmlns="http://www.w3.org/2000/svg" width="53.5" height="52.992">
          <g id="airassure-icon" transform="translate(-318.5 -146.5)">
            <g>
              <path id="icon-fill" d="M340.977 196.8l-2.66-6.65-.252-.628h-10.791a1 1 0 01-1-1v-17.255h-5.77c-.665 0-.893-.519-.947-.678-.054-.159-.19-.709.337-1.114l20.496-15.76a.99.99 0 01.61-.207c.222 0 .433.072.61.207l20.495 15.76c.527.405.391.955.337 1.114-.054.16-.282.678-.946.678h-5.771v17.255a1 1 0 01-1 1h-10.837l-.251.628-2.66 6.65z" fill="${fillColor}" />
              <path id="icon-outline" d="M340.977 194.108l1.731-4.329.503-1.257h11.514v-18.255h6.77L341 154.507l-20.495 15.76h6.77v18.255h11.468l.502 1.257 1.732 4.329m0 5.385l-3.589-8.971h-10.114a2 2 0 01-2-2v-16.255h-4.77c-1.91 0-2.734-2.421-1.219-3.585l20.496-15.76a1.995 1.995 0 012.438 0l20.495 15.76c1.514 1.164.692 3.585-1.218 3.585h-4.771v16.255a2 2 0 01-2 2h-10.16l-3.588 8.97z" fill="#000" />
              <rect id="aqi-indicator" x="-13" y="0" width="26" height="22" rx="2" stroke="#fff" fill="${background}" transform="translate(341 166)" />
            </g>
            ${badgeGroup}
          </g>
        </svg>`
      labelOrigin = { x: 22.5, y: 31 }
      anchor = { x: 23, y: 52 }
      break

    case 'TBD': // Future DustTrak device(s)
      badgeGroup = badgeCount
        ? `<g id="badge" transform="translate(23 0)">${badgeCount}</g>`
        : ''
      svg = `
        <svg xmlns="http://www.w3.org/2000/svg" width="39.25" height="51.905">
          <g id="dusttrak-icon">
            <g>
              <path id="icon-fill" d="M15.5 49.212l-.928-2.321-3.413-8.534a14.607 14.607 0 01-7.139-4.98A14.363 14.363 0 011 24.52c0-3.873 1.509-7.514 4.247-10.253A14.405 14.405 0 0115.5 10.019c3.873 0 7.515 1.508 10.253 4.247A14.405 14.405 0 0130 24.519c0 3.239-1.044 6.302-3.02 8.859a14.609 14.609 0 01-7.138 4.98l-3.413 8.533-.929 2.321z" fill="${fillColor}" />
              <path id="icon-outline" d="M15.5 46.52l3.594-8.985C24.805 35.962 29 30.73 29 24.52c0-7.456-6.044-13.5-13.5-13.5S2 17.063 2 24.519c0 6.211 4.195 11.443 9.907 13.016L15.5 46.52m0 5.386l-1.857-4.643-3.24-8.102a15.625 15.625 0 01-7.174-5.17A15.44 15.44 0 010 24.518c0-4.14 1.613-8.033 4.54-10.96a15.398 15.398 0 0110.96-4.54c4.14 0 8.033 1.612 10.96 4.54A15.398 15.398 0 0131 24.519a15.44 15.44 0 01-3.23 9.47 15.626 15.626 0 01-7.172 5.17l-3.24 8.103-1.858 4.643z" fill="#000" />
              <circle id="aqi-indicator" cx="13" cy="13" r="13" stroke="#fff" fill="${background}" transform="translate(2.5 11.5)" />
            </g>
            ${badgeGroup}
          </g>
        </svg>`
      labelOrigin = { x: 16, y: 24.5 }
      break
  }

  icon.url = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`
  icon.labelOrigin = labelOrigin
  icon.anchor = anchor

  return icon
}

export const aqiValidator = {
  methods: {
    validateAQI: function (aqi) {
      return aqiValid(aqi) ? aqi : '!'
    },
  },
}

export default {
  setMovingPin(state, deviceToMove) {
    state.deviceToMove = deviceToMove
  },
  setLastZoom(state, zoom) {
    state.location.lastZoom = zoom
  },
  setZoom(state, zoom) {
    state.location.zoom = zoom
  },
  setCenter(state, center) {
    state.location.center = center
  },
  setMovingMode(state, movingMode) {
    state.movingMode = movingMode
  },
  setDeviceViewMode(state, deviceViewMode) {
    state.deviceViewMode = deviceViewMode
  },
  setDeviceToView(state, deviceToView) {
    state.deviceToView = deviceToView
  },
  updateSettings(state, data) {
    state.shownSettings = { ...state.shownSettings, ...data }
    localStorage.setItem('tsi.mapSettings', JSON.stringify(state.shownSettings))
  },
  updateLocation(state) {
    localStorage.setItem('tsi.mapLocation', JSON.stringify(state.location))
  },
}

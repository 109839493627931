<template>
  <base-dialog
    :open="open"
    @close="close"
    :title="$t('subscriptionBillingInfoDialog.title')"
    :useXCloseButton="true"
    data-cy="subscriptionBillingInfoDialog"
    icon="mdi-credit-card"
    :fullscreen="$vuetify.breakpoint.xs"
    :persistentOverlay="true"
  >
    <v-card-subtitle>
      {{ $t('subscriptionBillingInfoDialog.subtitle') }}
    </v-card-subtitle>
    <v-card-text>
      <div>
        <v-radio-group
          v-model="paymentType"
          row
          :disabled="shouldDisableSwitchingPaymentTypes"
        >
          <v-radio
            :label="$t('subscriptionBillingInfoDialog.cardBilling')"
            :value="paymentTypes.CC"
            color="primary"
            :data-cy="'paymentTypeParameterCC'"
          ></v-radio>
          <v-radio
            :label="$t('subscriptionBillingInfoDialog.purchaseOrderBilling')"
            :value="paymentTypes.PO"
            color="primary"
            :data-cy="'paymentTypeParameterPO'"
          ></v-radio>
        </v-radio-group>
        <div
          id="billing-info-monthly-purchase-order-issue"
          class="hint-text"
          v-if="shouldDisplayMonthlyPurchaseOrderIssue"
        >
          {{ $t('subscriptionBillingInfoDialog.purchaseOrderMonthlyInfo') }}
        </div>
        <v-text-field
          v-if="isBillingPurchaseOrder"
          v-model="purchaseOrderReference"
          v-bind:label="$t('subscriptionBillingInfoDialog.enterPurchaseOrder')"
          maxlength="35"
          counter="35"
          :rules="[rules.required]"
          :disabled="shouldDisablePurchaseOrderEntry"
          data-cy="billingInfoPurchaseOrderEntry"
          id="billing-info-purchase-order-entry"
          class="text-field billing-info-purchase-order-entry"
        ></v-text-field>
        <div id="delegoIFrameHost" v-show="shouldDisplayCreditCardIFrame"></div>
        <div
          v-if="shouldDisplayCreditCardIFrameLoading"
          id="progressSpinner"
          class="text-center emptyMessage"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </div>
      <span
        v-if="shouldDisplayTemporaryChargeInfo"
        id="billing-info-cc-temp-charge-info"
        >{{ $t('subscriptionBillingInfoDialog.temporaryChargeInfo') }}</span
      >
    </v-card-text>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <div
        v-if="shouldDisplayBillingUpdateInProgress"
        id="progressSpinner"
        class="text-center emptyMessage"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        v-if="shouldDisplayUpdateBillingButton"
        @click="updateBilling"
        id="billing-info-update-btn"
        class="primary white--text"
        :disabled="shouldDisableUpdateBillingButton"
        text
        data-cy="subscriptionBillingInfoUpdateBilling"
        >{{ $t('buttons.save') }}</v-btn
      >
    </template>
  </base-dialog>
</template>
<script>
import dialog from './Dialog'
import { HTTPStatus } from '../../api'
import {
  messageTypes,
  SNACKBAR_STATIC_DURATION_MS,
} from '../../services/notifications'
import {
  monthlySubscriptions,
  paymentTypes,
} from '../../store/subscriptions/utils'

export default {
  name: 'SubscriptionBillingInfoDialog',
  props: ['open', 'activationId', 'subscriptionId'],
  components: { 'base-dialog': dialog },
  data() {
    return {
      paymentType: paymentTypes.CC,
      creditCardInfo: null,
      isDelegoLoaded: false,
      purchaseOrderReference: '',
      isUpdating: false,
      rules: {
        empty: (value) => value === null || value.length === 0,
        required: (value) => !!value || this.$t('validation.required'),
      },
    }
  },
  computed: {
    isMonthly() {
      return monthlySubscriptions.includes(this.subscriptionId)
    },
    isBillingPurchaseOrder() {
      return this.paymentType === paymentTypes.PO
    },
    isBillingCreditCard() {
      return this.paymentType === paymentTypes.CC
    },
    fieldsValid() {
      if (this.isBillingPurchaseOrder) {
        return (
          !this.isMonthly &&
          this.rules.required(this.purchaseOrderReference) === true
        )
      } else if (this.isBillingCreditCard) {
        return this.creditCardInfo != null
      }
      return false
    },
    shouldDisableSwitchingPaymentTypes() {
      return this.isUpdating
    },
    shouldDisplayMonthlyPurchaseOrderIssue() {
      return this.isMonthly && this.isBillingPurchaseOrder
    },
    shouldDisablePurchaseOrderEntry() {
      return this.isMonthly || this.isUpdating
    },
    shouldDisplayCreditCardIFrame() {
      return this.isBillingCreditCard && this.isDelegoLoaded
    },
    shouldDisplayCreditCardIFrameLoading() {
      return !this.isDelegoLoaded
    },
    shouldDisplayBillingUpdateInProgress() {
      return this.isUpdating
    },
    shouldDisplayUpdateBillingButton() {
      return (
        !this.isUpdating &&
        (this.isBillingPurchaseOrder || this.creditCardInfo != null)
      )
    },
    shouldDisableUpdateBillingButton() {
      return !this.fieldsValid
    },
    shouldDisplayTemporaryChargeInfo() {
      return this.isBillingCreditCard && this.creditCardInfo != null
    },
  },
  watch: {
    open() {
      if (this.open) {
        this.delegoInit().catch(() => {
          // nothing to do
        })
      }
    },
  },
  created() {
    //technique for how to use constants in the template
    this.paymentTypes = paymentTypes
  },
  destroyed() {
    this.clearDelegoIFrame()
  },
  methods: {
    close() {
      if (this.isUpdating) {
        return
      }
      this.clearFields()
      this.clearDelegoIFrame()
      this.$emit('close')
    },
    clearDelegoIFrame() {
      this.isDelegoLoaded = false
      let element = document.getElementById('delegoIFrameHost')
      if (element && element.firstChild) {
        element.removeChild(element.firstChild)
      }
    },
    clearFields() {
      this.creditCardInfo = null
      this.purchaseOrderReference = ''
      this.paymentType = paymentTypes.CC
    },
    async updateBilling() {
      this.isUpdating = true
      const message = await (this.isBillingPurchaseOrder
        ? this.updateBillingPurchaseOrder()
        : this.updateBillingCreditCard())
      this.$store.dispatch('notifications/addMessage', message)
      this.isUpdating = false
      this.close()
    },
    async updateBillingPurchaseOrder() {
      const eMsg = {
        text: this.$t(
          'subscriptionBillingInfoDialog.toasts.purchaseOrderFailure'
        ),
        type: messageTypes.ERROR,
        timeout: SNACKBAR_STATIC_DURATION_MS,
      }

      try {
        const resp = await this.$api.postBillingInfoPurchaseOrder(
          this.activationId,
          this.purchaseOrderReference
        )

        //not success
        if (resp.status !== HTTPStatus.OK) {
          return eMsg
        }

        //subscriptions need to be updated
        await this.updateSubscriptions()

        //success
        return {
          text: this.$t(
            'subscriptionBillingInfoDialog.toasts.purchaseOrderConfirmation'
          ),
          type: messageTypes.SUCCESS,
          timeout: SNACKBAR_STATIC_DURATION_MS,
        }
      } catch (e) {
        //not success
        return eMsg
      }
    },
    async updateBillingCreditCard() {
      const eMsg = {
        text: this.$t('subscriptionBillingInfoDialog.toasts.cardFailure'),
        type: messageTypes.ERROR,
        timeout: SNACKBAR_STATIC_DURATION_MS,
      }

      try {
        const resp = await this.$api.postBillingInfoCreditCard(
          this.activationId,
          this.creditCardInfo.cardType,
          this.creditCardInfo.cardToken,
          this.creditCardInfo.cardExpiry,
          this.creditCardInfo.cardHolderName
        )

        //not success
        if (resp.status !== HTTPStatus.OK) {
          return eMsg
        }

        //subscriptions need to be updated
        await this.updateSubscriptions()

        //success
        return {
          text: this.$t(
            'subscriptionBillingInfoDialog.toasts.cardConfirmation'
          ),
          type: messageTypes.SUCCESS,
          timeout: SNACKBAR_STATIC_DURATION_MS,
        }
      } catch (e) {
        //not success
        return eMsg
      }
    },
    updateSubscriptions() {
      return this.$store.dispatch('subscriptions/updateSubscriptions', {
        auth: this.$auth,
        api: this.$api,
        devices: this.$auth.isAuthenticated
          ? this.$store.state.devices.allDevices
          : [],
      })
    },
    //START: DELEGO FUNCTIONS
    async delegoInit() {
      this.isDelegoInit = true
      this.creditCardInfo = null
      const response = await this.$api.getDelegoToken()
      const { token } = await response.json()
      this.delegoOpenRapidPay(token)
    },
    delegoHasInit() {
      this.isDelegoLoaded = true
    },
    delegoSuccess(tokenizeResponse) {
      const payment = tokenizeResponse.payment
      const cardExpiry = payment.cardExpiration
      this.creditCardInfo = {
        cardType: payment.cardType,
        cardToken: payment.token,
        cardExpiry: `${cardExpiry.month}/${cardExpiry.year}`,
        cardHolderName: payment.cardholderName,
      }
    },
    delegoFailure() {
      this.creditCardInfo = null
    },
    delegoChangePayment() {
      this.creditCardInfo = null
    },
    delegoOpenRapidPay(authorizationToken) {
      const self = this
      DelegoRapidPay.open({
        url: process.env.VUE_APP_DELEGO_URL,
        token: authorizationToken,
        paymentRequest: {
          currencyCode: 'USD',
          total: 0.01,
          /*
           * Payment request object
           */
        },
        element: '#delegoIFrameHost',
        appclass: 'tsilink',
        events: {
          onApplicationInitialized: function () {
            /*
             * Actions to take once RapidPay is displayed
             */
            self.delegoHasInit()
          },
          onTokenizeSuccess: function (
            tokenizeResponse,
            signedTokenizeResponse
          ) {
            /*
             * Actions to take once RapidPay has tokenized a payment card
             */
            self.delegoSuccess(tokenizeResponse, signedTokenizeResponse)
          },
          onChangePaymentMethodClicked: function () {
            self.delegoChangePayment()
          },
          onFailure: function (error) {
            /*
             * Actions to take if RapidPay fails to tokenize a payment card (typically a system error)
             */
            self.delegoFailure(error)
          },
        },
        /*
         * Additional optional parameters, as desired.
         */
      })
      //END: DELEGO FUNCTIONS
    },
  },
}
</script>

<style lang="scss" scoped>
#progressSpinner {
  width: 100%;
}
.v-card__subtitle {
  padding-top: 5px;
  padding-bottom: 5px;
}
#billing-info-update-btn {
  left: -15px;
}
</style>
<style lang="scss">
#delegoIFrameHost {
  .delego-rapidpay {
    width: 100%;
    height: 100%;
    border-width: 0;
  }
}
.billing-info-purchase-order-entry {
  padding-top: 6px;
}
#billing-info-purchase-order-entry,
.billing-info-purchase-order-entry .v-label {
  font-size: 14px;
  font-weight: 400;
}
.billing-info-purchase-order-entry .v-messages__message {
  font-size: 10px;
  font-weight: 400;
}
#billing-info-update-btn.v-btn--disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}
</style>

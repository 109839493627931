export const access = {
  NONE: 0, // Cannot access data of private devices
  SHARED: 1,
  FULL: 2,
}

export function canAccess(desiredAccess, currentAccess) {
  if (currentAccess >= desiredAccess) {
    return true
  }

  return false
}

export function isAccess(desiredAccess, currentAccess) {
  if (currentAccess === desiredAccess) {
    return true
  }
  return false
}

export const accessUtil = {
  data() {
    return {
      access,
    }
  },
  methods: {
    canAccess,
    isAccess,
  },
}

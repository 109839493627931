<template>
  <base-dialog
    :open="open"
    :title="$t('map.deviceAQI.title')"
    data-cy="deviceAQIDialog"
    :fullscreen="$vuetify.breakpoint.xs"
    :persistentOverlay="true"
  >
    <template v-slot:header>
      <v-card-title>
        <v-icon class="header-icon">mdi-information</v-icon>
        {{ $t('map.deviceAQI.title') }}
      </v-card-title>
    </template>
    <div class="aqi-description">
      {{
        $t('map.deviceAQI.aqiDescription', {
          measurementType: this.measurementTypeText,
        })
      }}
    </div>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="close"
        class="primary white--text"
        text
        data-cy="deviceAQIDoneButton"
      >
        {{ $t('buttons.done') }}
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import dialog from './Dialog'
import { measurementTypeNames } from '../../store/map/utils'

export default {
  name: 'DeviceAQIDialog',
  props: ['open'],
  components: { 'base-dialog': dialog },
  computed: {
    measurementTypeText() {
      const measurementType = this.$store.getters['map/getMeasurementType']
      return measurementTypeNames[measurementType]
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    .v-card__title {
      font-weight: 300;
    }
    .v-card__actions {
      .v-btn {
        min-width: 120px;
        @media (min-width: 496px) {
          min-width: 160px;
        }
      }
    }
  }
  .aqi-description {
    padding: 22px 16px 24px 24px;
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>

<template>
  <div>
    <register-multiple-devices-dialog
      :open="open"
      @close="onCloseHandler"
      :step="step"
      :title="$t(currentStepConfiguration.overrideTitle)"
      :subTitle="$t(currentStepConfiguration.overrideSubtitle)"
      :downloadCsv="currentStepConfiguration.downloadCsv"
      :disableActionButton="currentStepConfiguration.disableActionButton"
      :onClickActionButton="actionHandler"
      :actionButtonText="$t(currentStepConfiguration.overrideActionButtonText)"
      :cssStyle="currentStepConfiguration.cssStyle"
      :removeCancelButton="currentStepConfiguration.removeCancelButton"
    >
      <template v-slot:content>
        <v-form v-if="currentStepConfiguration.process === stepProcesses.UPLOAD">
          <file-csv-input @onFileChanged="setCsvFile" :value="csvFile" />
        </v-form>
        <in-progress-message
          v-if="currentStepConfiguration.process === stepProcesses.PROGRESS"
          :messages="message"
        />
        <success-message
          v-if="currentStepConfiguration.process === stepProcesses.BEFORE_REGISTER"
          :message="$t('registerMultipleDevices.uploadSucceeded')"
          :fileName="csvFile.name"
        />
        <error-message
          v-if="currentStepConfiguration.process === stepProcesses.FAILED"
          :errors="csvErrors"
        />
        <summary-message v-if="currentStepConfiguration.process === stepProcesses.AFTER_REGISTER"
          :errors="registrationErrors"
          :registeredDevices="registeredDevices"
          :failedDevices="failedDevices"
          :notProcessedDevices="notProcessedDevices" />
      </template>
    </register-multiple-devices-dialog>
  </div>
</template>

<script>
import registerMultipleDevicesDialog from './dialog/registerMultipleDevicesDialog.vue'
import fileInput from './messages/content/fileInput.vue'
import errorMessage from './messages/errorMessage.vue'
import successMessage from './messages/successMessage.vue'
import inProgressMessage from './messages/inProgressMessage.vue'
import summaryMessage from './messages/summaryMessage.vue'
import {
  getCsvData,
  validateCsvRow,
  validateCsvFile,
  readCsvContent,
} from './validation/csv-utils'
import { DEVICE_DEFAULT_CONFIG } from '../../../services/device-config'
import { REGISTER_MULTIPLE_DEVICES_STEPS, STEP_NAMES, STEP_PROCESSES, CLOSE_ACTIONS } from './utils'
import { subscriptionGeneralTiers, subscriptionTypes } from '../../../store/subscriptions/utils'
import { formatDeviceData, formatRegisterError, sanitizeRows } from './helper'

export default {
  name: 'RegisterMultipleDevices',
  props: {
    open: Boolean,
  },
  components: {
    'register-multiple-devices-dialog': registerMultipleDevicesDialog,
    'file-csv-input': fileInput,
    'error-message': errorMessage,
    'success-message': successMessage,
    'in-progress-message': inProgressMessage,
    'summary-message': summaryMessage
  },
  data() {
    return {
      step: STEP_NAMES.UPLOAD_FILE,
      csvFile: null,
      csvErrors: [],
      stepProcesses: STEP_PROCESSES,
      message: '',
      registrationErrors: [],
      registeredDevices: 0,
      failedDevices: 0,
      notProcessedDevices: 0,
      cancelRegistration: false,
    }
  },
  computed: {
    currentStepConfiguration() {
      return REGISTER_MULTIPLE_DEVICES_STEPS[this.step] || {}
    },
  },
  methods: {
    onCloseHandler() {
      if (this.currentStepConfiguration.closeAction === CLOSE_ACTIONS.STOP_REGISTRATION) {
        this.stopRegistration()
      } else {
        this.close()
      }
    },
    close() {
      this.$emit('close')
      setTimeout(() => {
        this.init()
      }, 500)
    },
    init() {
      this.step = STEP_NAMES.UPLOAD_FILE
      this.csvFile = null
      this.csvErrors = []
      this.message = ''
      this.registrationErrors = []
      this.registeredDevices = 0
      this.failedDevices = 0
      this.notProcessedDevices = 0
      this.cancelRegistration = false
    },
    async actionHandler() {
      switch (this.step) {
        case STEP_NAMES.UPLOAD_FAILED:
        case STEP_NAMES.SHOW_CSV_ERRORS:
          this.retry()
          break
        case STEP_NAMES.UPLOAD_SUCCEEDED:
          await this.startRegistration()
          break
        case STEP_NAMES.SHOW_REGISTRATION_RESULT:
          this.onCloseHandler()
          break
      }
    },
    setCsvFile(file) {
      this.csvFile = file
      this.validateCsv()
    },
    retry() {
      this.init()
    },
    async validateCsv() {
      if (this.csvFile && this.csvFile.type === 'text/csv') {
        this.message = [this.$t('registerMultipleDevices.checkingForErrors')]
        this.step = STEP_NAMES.VALIDATE_CSV
        var errors = []
        var csvData = []
        const csvContent = await readCsvContent(this.csvFile)
        var fileError = await validateCsvFile(csvContent)

        if (fileError === '') {
          csvData = await getCsvData(csvContent)
          csvData.forEach((dataRow) => {
            const dataErrors = validateCsvRow(dataRow)
            errors = errors.concat(dataErrors)
          })
        } else {
          errors = fileError
        }
        if (errors.length > 0) {
          this.csvErrors = errors
          this.step = STEP_NAMES.SHOW_CSV_ERRORS
        } else {
          this.step = STEP_NAMES.UPLOAD_SUCCEEDED
        }
      } else {
        this.csvErrors = this.$t('registerMultipleDevices.incorrectFileType')
        this.step = STEP_NAMES.UPLOAD_FAILED
      }
    },
    async startRegistration() {
      const devices = await this.prepareRegistration()
      const registerData = await this.registerDevices(devices)
      this.prepareRegistrationSummary(registerData)
      this.showRegistrationSummary()
    },
    async prepareRegistration() {
      this.step = STEP_NAMES.REGISTER_DEVICES
      const csvContent = await readCsvContent(this.csvFile)
      return sanitizeRows(await getCsvData(csvContent))
    },
    async registerDevice(deviceData) {
      let success = false

      const resp = await this.$api.postRegisterDevice(deviceData)
      if (resp.ok) {
        success = true
        const body = await resp.json()
        const setData = {
          accountId: body.cloud_account_id,
          deviceId: body.cloud_device_id,
          model: body.model,
          submodel: undefined,
          serial: body.serial,
          name: body.cloud_state_data?.name?.value,
          locationName: body.cloud_state_data?.location_name?.value,
          indoor: body.cloud_state_data?.is_indoor?.value,
          isPublic: body.cloud_state_data?.is_public?.value,
          coords: {
            lng: body.cloud_state_data?.location?.value?.coordinates[0],
            lat: body.cloud_state_data?.location?.value?.coordinates[1],
          },
        }
        this.$store.dispatch('devices/setDevice', setData)
        await this.updateDeviceConfig(body.cloud_device_id)
      } else {
        const message = await resp.text()
        throw new Error(message)
      }

      return success
    },
    async updateDevice(deviceData, deviceId) {
      let success = false

      const patchSubPayload = {
        model: deviceData.model,
        serial: deviceData.serial,
        subscription: this.getPatchDeviceSubscription(deviceData.model),
        isExternal: false,
      }

      const patchSub = await this.$api.patchDeviceSubscription(patchSubPayload)

      if (patchSub.ok) {
        // patch successful, update device meta
        const updateDevice = await this.$api.patchUpdateDevice(
          deviceData,
          deviceId
        )
        if (updateDevice.ok) {
          // update successful, registration successful
          success = true
        } else {
          // update unsuccessful, delete previously patched sub
          await this.$api.patchDeviceSubscription({
            ...patchSubPayload,
            delete: true
          })
        }
      }else{
        const message = await patchSub.text()
        throw new Error(message)
      }
      if (success) {
        await this.updateDeviceConfig(deviceId)
      }

      return success
    },
    async updateDeviceConfig(deviceId) {
      await this.$api.postDeviceConfig(DEVICE_DEFAULT_CONFIG, deviceId)
    },
    async registerDevices(devices) {
      const registerData = {
        registeredDevices: 0,
        failedDevices: 0,
        notProcessedDevices: 0,
        errors: [],
      }

      this.message = this.getInProgressMessages(0, devices.length)

      for (let [index, device] of devices.entries()) {
        if (this.cancelRegistration) {
          break
        }

        this.message = this.getInProgressMessages(index + 1, devices.length)
        const deviceData = formatDeviceData(device)
        deviceData.subscription = this.getDeviceSubscription(deviceData.model)

        try {
          let success = false
          if (this.hasDataServices(deviceData.serial)) {
            const deviceId = this.getDeviceId(deviceData.serial)
            success = await this.updateDevice(deviceData, deviceId)
          } else {
            success = await this.registerDevice(deviceData)
          }
          if (success) {
            registerData.registeredDevices++
          }
        } catch (e) {
          registerData.failedDevices++
          const message = e.toString()
          registerData.errors.push(formatRegisterError(message, deviceData.serial))
        }
      }
      registerData.notProcessedDevices = devices.length - (registerData.registeredDevices + registerData.failedDevices)

      try {
        await this.$store.dispatch('devices/setAllUserDevices')
        await this.$store.dispatch('subscriptions/updateSubscriptions', {
          auth: this.$auth,
          api: this.$api,
        })
        this.$store.dispatch('devices/setAllDevices')
      } catch (e) {
        // ignore, stop the spinner animation.
      }

      return registerData
    },
    stopRegistration() {
      this.cancelRegistration = true
    },
    prepareRegistrationSummary(registerData) {
      this.registeredDevices = registerData.registeredDevices
      this.failedDevices = registerData.failedDevices
      this.notProcessedDevices = registerData.notProcessedDevices
      this.registrationErrors = registerData.errors
    },
    showRegistrationSummary() {
      this.step = STEP_NAMES.SHOW_REGISTRATION_RESULT
    },
    getInProgressMessages(processedDevices, numberOfDevices) {
      return [
        this.$t('registerMultipleDevices.registeringDevices'),
        this.$t('registerMultipleDevices.registerProgress')
          .replace('/registeredDevices/', processedDevices)
          .replace('/numberOfDevices/', numberOfDevices),
      ]
    },
    canAddToBasicAndPremiumSubscriptions(modelNumber) {
      return (
        this.$store.getters['subscriptions/canAddDeviceToBasicByModel'](
          modelNumber
        ) &&
        this.$store.getters['subscriptions/canAddDeviceToPremiumByModel'](
          modelNumber
        )
      )
    },
    hasDataServices(serialNumber) {
      return this.$store.getters[
        'subscriptions/getDeviceDataServicesByDeviceSerial'
      ](serialNumber)
    },
    getDeviceId(serialNumber) {
      return this.$store.getters['devices/getDeviceIdByDeviceSerial'](
        serialNumber
      )
    },
    getDeviceSubscription(modelNumber) {
      return this.canAddToBasicAndPremiumSubscriptions(modelNumber)
      ? subscriptionGeneralTiers.PREMIUM
      : subscriptionTypes.WEBSITE
    },
    getPatchDeviceSubscription(modelNumber) {
      return this.canAddToBasicAndPremiumSubscriptions(modelNumber)
      ? subscriptionGeneralTiers.PREMIUM
      : null
    }
  },
}
</script>

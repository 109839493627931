export const measurementIcon = Object.freeze({
  pm25_raw: '/img/measurements/pm.svg',
  pm10_raw: '/img/measurements/pm.svg',
  temp: 'img/measurements/temp.svg',
  rh: 'img/measurements/rh.svg',
  baro_inhg: '/img/measurements/bp.svg',
  co2: '/img/measurements/co2.svg',
  tvoc: 'img/measurements/voc.svg',
  co: '/img/measurements/co.svg',
  ch2o: '/img/measurements/ch2o.svg',
  so2: '/img/measurements/so2.svg',
  o3: '/img/measurements/o3.svg',
  no2: '/img/measurements/no2.svg',
})

const locationStore = 'user/location'

export const errors = {
  unknown: 1,
  disallowed: 2,
}

export const locationCoordsRegex = Object.freeze({
  latitude: /^[-+]?([1-8]?\d(\.\d{1,8})?|90(\.0{1,8})?)$/,
  longitude: /^[+-]?(((1[0-7]\d)|([1-9]?\d))(\.\d{1,8})?|180(\.0{1,8})?)$/,
})

export const locationGetter = {
  methods: {
    getGeolocation(callback) {
      const currentLocation =
        this.$store.getters[`${locationStore}/currentLocation`]

      if (currentLocation.error) {
        if (currentLocation.error == errors.disallowed) {
          callback(false)
          return
        }

        if (currentLocation.error == errors.unknown) {
          this.$store.watch(
            (state) => {
              return state.user.location.loading
            },
            (loading) => {
              if (!loading) {
                callback(
                  scrapeErrors(
                    this.$store.getters[`${locationStore}/currentLocation`]
                  )
                )
                return
              }
            }
          )

          this.$store.dispatch(`${locationStore}/updateLocation`)
        }
      } else {
        callback(currentLocation)
      }
    },
    getLocation(callback) {
      const savedLoc = localStorage.getItem('tsi.mapLocation')
      const coords = this.$store.state.map.location.center
      return savedLoc ? callback(coords) : this.getGeolocation(callback)
    },
    setLocation(coords) {
      this.$store.dispatch('map/setCenter', coords)
    },
  },
}

function scrapeErrors(coords) {
  if (coords && !coords.error) {
    return coords
  }

  return false
}

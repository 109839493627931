const mut = {
  startUpdatingLocation: 'startUpdatingLocation',
  stopUpdatingLocation: 'stopUpdatingLocation',
  updateLocation: 'updateLocation',
  disallowLocation: 'disallowLocation',
}

// TODO: Update this action, so that it becomes testable
export default {
  updateLocation({ commit }) {
    commit(mut.startUpdatingLocation)
    if (!navigator.geolocation) {
      commit(mut.disallowLocation)
      commit(mut.stopUpdatingLocation)
      return
    }

    navigator.geolocation.getCurrentPosition(
      (location) => {
        commit(mut.updateLocation, {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        })
        commit(mut.stopUpdatingLocation)
      },
      () => {
        // (err) => {}
        commit(mut.disallowLocation)
        commit(mut.stopUpdatingLocation)
      }
    )
  },
}

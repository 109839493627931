<template>
  <tr :key="item.id" :data-cy="`device${item.deviceSerial}Row`">
    <td class="mobile-list" :class="{'ml-6': isChild}">
      <div class="item-title">
        <action-menu :deviceId="item.id" :accessLevel="item.accessCode" @openDialog="openDialog" />
        <v-tooltip
          bottom
          :max-width="600"
          :disabled="!isNameOverflowing[item.id]"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on" class="device-name" :ref="item.id">
              <span v-if="item.deviceName">{{ item.deviceName }}</span>
              <span v-else>{{ $t('manageDevices.unnamedDevice') }}</span>
            </div>
          </template>
          <span>{{ item.deviceName }}</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <table-condition
          v-if="canAccess(access.FULL, item.accessCode) && item.showCondition"
          :deviceId="item.id"
          :accessLevel="item.accessCode"
        />
      </div>
      <ul class="flex-content" :class="{'expandable': itemCanBeExpanded}">
        <li class="flex-item">
          <span>
            {{ $t('manageDevices.tableHeaders.deviceLocationName') }}:&nbsp;
          </span>
          <span>{{ item.deviceLocationName }}</span>
        </li>
        <li class="flex-item">
          <span>
            {{ $t('manageDevices.tableHeaders.deviceType') }}:&nbsp;
          </span>
          <span>{{ item.deviceType }}</span>
        </li>
        <li class="flex-item">
          <span>
            {{ $t('manageDevices.tableHeaders.deviceModel') }}:&nbsp;
          </span>
          <span>{{ item.deviceModel }}</span>
        </li>
        <li class="flex-item">
          <span>
            {{ $t('manageDevices.tableHeaders.deviceSerial') }}:&nbsp;
          </span>
          <span>{{ item.deviceSerial }}</span>
        </li>
        <li class="flex-item">
          <span>
            {{ $t('manageDevices.tableHeaders.deviceSubscription') }}:&nbsp;
          </span>
          <span>{{ item.deviceSubscription }}</span>
        </li>
        <li class="flex-item">
          <span>{{ $t('manageDevices.tableHeaders.access') }}:&nbsp;</span>
          <span>{{ item.access }}</span>
        </li>
        <li class="flex-item">
          <span>
            {{ $t('manageDevices.tableHeaders.lastCommunication') }}:&nbsp;
          </span>
          <span v-if="item.lastCommunication">{{ item.lastCommunication }}</span>
          <span v-else>{{ $t('manageDevices.lastCommunicationNever') }}</span>
        </li>
        <li class="flex-item">
          <span> {{ measurementLabel }}:&nbsp; </span>
          <span><styled-reading v-if="item.showAQI" :reading="item.reading" /></span>
        </li>
      </ul>
      <div class="text-end my-2" v-if="itemCanBeExpanded" >
        <v-btn text class="primary--text flex-row-reverse" @click="expand(!isExpanded)">
          {{ isExpanded ? $t('manageDevices.mobileExpansionOptions.hide') : $t('manageDevices.mobileExpansionOptions.view') }}
        </v-btn>
      </div>
    </td>
  </tr>
</template>

<script>
import { deviceModels } from '../../store/subscriptions/utils'

import { accessUtil } from '../../services/device-access'
import { measurementTypes } from '../../store/map/utils'
import { isChildOverflowing } from '../../services/html-utilities'
import debounce from 'lodash/debounce'

import actionMenu from './actionMenu.vue'
import styledReading from './styledReading.vue'
import tableCondition from './tableCondition.vue'

export default {
  name: 'DeviceTableBodyMobile',
  props: {
    item: Object,
    expand: Function,
    isExpanded: Boolean,
    isChild: Boolean
  },
  mixins: [accessUtil],
  components: {
    'action-menu': actionMenu,
    'styled-reading': styledReading,
    'table-condition': tableCondition,
  },
  computed: {
    measurementLabel() {
      const type = this.$store.getters['map/getMeasurementType']
      if (type === measurementTypes.pm10) {
        return this.$t('manageDevices.tableHeaders.aqi10')
      } else {
        return this.$t('manageDevices.tableHeaders.aqi25')
      }
    },
    itemCanBeExpanded() {
      const res = this.item.deviceModel === deviceModels.SMART_BRIDGE.toUpperCase() && this.item.associatedDevices?.length > 0
      return res
    },
  },
  data() {
    return {
      isNameOverflowing: false,
    }
  },
  methods: {
    handleResize () {
      this.resizeHandler()
    },
    openDialog(dialogData) {
      this.$emit('openDialog', dialogData)
    },
  },
  created(){
    this.resizeHandler = debounce(() => {
      this.isNameOverflowing = isChildOverflowing(this.$refs[this.item.id + 'Name'])
      this.isLocationNameOverflowing = isChildOverflowing(this.$refs[this.item.id + 'LocationName'])
    }, 200)
  },
}
</script>

<style lang="scss" scoped>
.v-data-table td.mobile-list {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 48px;
  max-width: 100vw;
  .ma-6 & {
    max-width: calc(100vw - 48px);
  }

  .item-title {
    display: flex;
    line-height: 36px;
    padding-top: 8px;
    .device-name {
      margin-left: 8px;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  ul {
    padding-top: 8px;
    padding-bottom: 16px;
    padding-left: 12px;
    list-style-type: none;
    .item-title {
      font-weight: 500;
      font-size: 15px;
    }
  }
  ul.expandable {
    padding-bottom: 0px;
  }
  .mobile-action-menu-container {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .flex-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;
    > span:first-child {
      display: inline-block;
      margin-right: 4px;
      white-space: nowrap;
      color: rgba(0, 0, 0, 0.6);
    }
    > span:last-child {
      display: inline-block;
    }
  }
}
</style>

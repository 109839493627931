import { Measurement_Names } from './utils'
import { convertObjectToArray } from '../../helpers/common/helper'

export const getMeasurementsV1Format = (measurements) => {
  return convertObjectToArray(measurements, (measurementKey) => {
    const measurement = measurements[measurementKey]
    return {
      type: measurementKey,
      data: {
        value: measurement.value,
        timestamp: measurement.timestamp,
      },
      unit: measurement.unit,
      calibration: measurement.calibration,
      precision: measurement.precision,
    }
  })
}

export const getSensorsV1Format = (sensors) => {
  return convertObjectToArray(sensors, (sensorKey) => {
    const sensor = sensors[sensorKey]
    return {
      type: sensorKey,
      status: sensor.status,
      status_messages: sensor.status_messages,
      measurements: getMeasurementsV1Format(sensor.measurements),
    }
  })
}

export const getMeasurementsFormattedResponse = (measurements) => {
  const formattedMeasurements = []

  if (measurements?.length) {
    return measurements.map((measurement) => {
      return {
        name: Measurement_Names[measurement.type] || measurement.type,
        timestamp: measurement.data ? measurement.data.timestamp : null,
        value: measurement.data ? measurement.data.value : null,
        precision: measurement.precision,
      }
    })
  }

  return formattedMeasurements
}

export const getSensorStatusFormattedResponse = (sensor) => {
  return sensor.status_messages || []
}

export const getDeviceStatusFormattedResponse = (readings) => {
  return readings.system_status_messages || []
}

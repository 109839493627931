<template>
  <div>
    <v-tooltip left v-model="showRegisterToolTip">
      <template v-slot:activator="{ on }">
        <v-btn
          id="registerDeviceButton"
          fab
          v-on="on"
          @click="open"
          :class="[
            { restrictedContent: showRestricted },
            { accent: !showRestricted },
          ]"
          v-show="!$store.state.map.movingMode"
          data-cy="openRegisterDevice"
        >
          <v-icon>mdi-map-marker-plus</v-icon>
        </v-btn>
      </template>
      <span>{{ registerButtonTooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showRegisterToolTip: false,
      showRestricted: false,
      unsubscribe: null,
    }
  },
  async mounted() {
    this.getHasQtyRemaining()
    this.unsubscribe = this.$store.subscribeAction({
      after: (action) => {
        if (action.type == 'subscriptions/updateSubscriptions') {
          this.getHasQtyRemaining()
        }
      },
    })
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      try {
        this.unsubscribe()
      } catch (e) {
        //unsubscribe error
      }
      this.unsubscribe = null
    }
  },
  methods: {
    open() {
      if (!this.showRestricted) {
        this.showRegisterToolTip = false
        this.$emit('open')
      }
    },
    getHasQtyRemaining() {
      const websiteTier = this.$store.getters['subscriptions/getWebsite']
      // User does not have any subscriptions making them free tier
      if (websiteTier.length === 0) {
        const hasQtyRemaining =
          this.$store.getters['subscriptions/hasQtyRemaining']
        this.showRestricted = !hasQtyRemaining
      } else {
        // Only grey out map button on free tier
        this.showRestricted = false
      }
    },
  },
  computed: {
    registerButtonTooltip() {
      return this.showRestricted
        ? this.$t('activateDevice.upgradeTooltip')
        : this.$t('activateDevice.tooltip')
    },
  },
}
</script>

<style lang="scss" scoped>
.restrictedContent {
  background-color: gray !important;
  cursor: not-allowed;
  opacity: 0.36;
}
</style>

export default {
  getNotifications(state) {
    return state.notifications
  },
  getNotificationsNum(state) {
    return state.notifications.length
  },
  getNotificationsByDeviceId: (state) => (deviceId) => {
    return state.notifications.reduce((acc, note) => {
      if (note.device.id == deviceId && !note.isRead) {
        acc.push(note)
      }
      return acc
    }, [])
  },
  getNotificationsNumByDeviceId: (state) => (deviceId) => {
    let m = state.notifications.reduce((acc, note) => {
      if (note.device.id == deviceId && !note.isRead) {
        acc.push(note)
      }
      return acc
    }, [])

    return m.length
  },
}

import { Colors } from '@fusion/saas-components'

export default {
  customVariables: ['~/assets/vuetify-variables.scss'],
  theme: {
    themes: {
      light: {
        primary: Colors.mainPrimary,
        accent: Colors.mainQuaternary,
        secondary: Colors.dark100,
        info: Colors.mainPrimary,
        warning: Colors.accentPrimary,
        error: Colors.accentError,
        success: Colors.accentSuccess,
        textPrimary: Colors.textPrimary,
        textAlternate: Colors.textAlternate,
      },
    },
  },
}

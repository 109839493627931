<template>
  <div class="api-container">
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <v-card>
          <span v-if="hasUserPermission('ExternalApiV3ApigeeButton')">
            <v-card-title data-cy="api-secret-subtitle">{{
              $t('apiServices.titleDeveloperPortal')
            }}</v-card-title>
            <v-card-text>
              <v-btn class="ma-2" @click="openPortalLink()" data-cy="open-portal-button" >
                {{ $t('apiServices.buttonOpenPortal') }}
                <v-icon right>mdi-open-in-new</v-icon>
              </v-btn>
              <v-spacer />
              <label class="ma-2" data-cy="api-portal-description">{{
                $t('apiServices.portalDescription')
              }}</label>
            </v-card-text>
          </span>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>{{
            $t('accountSubscriptions.dataServicesSubscriptions')
          }}</v-card-title>
          <div class="text-center" v-if="removeLock || subLock">
            <v-progress-circular indeterminate color="primary" class="ma-4" />
          </div>
          <v-list v-else subheader dense class="inline">
            <v-list-item v-for="(sub, i) in dataSubscriptions" :key="i">
              <v-list-item-content>
                <v-list-item-title>{{ sub.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('accountSubscriptions.deviceAllowance') }}
                  {{
                    $t(
                      'accountSubscriptions.deviceSubscriptionsUsedRemaining',
                      {
                        qty: sub.totalUsed,
                        remaining: sub.totalQtyRemaining,
                      }
                    )
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        <div>{{ $t('apiServices.registeredDevices') }}</div>
        <v-spacer />
        <v-btn
          text
          class="black--text register-btn"
          @click="dialogs.registerDevicesDialog.open = true"
          data-cy="registerDevicesDialogButton"
          :disabled="removeLock || subLock"
        >
          <v-icon class="header-icon">mdi-map-marker-plus</v-icon>
          {{ $t('apiServices.registerDevices') }}
        </v-btn>
      </v-card-title>
      <v-card-text class="registered-devices-card">
        <v-data-table
          v-if="!removeLock && !subLock"
          :headers="registeredDevices.headers"
          :hide-default-header="isMobile"
          :items="devices"
          :disable-sort="true"
          :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
          :class="registeredDevicesClass"
          :footer-props="registeredDevicesFooterProps"
        >
          <!-- body slot -->
          <template v-slot:body="{ items }">
            <!-- desktop view -->
            <tbody
              v-if="!isMobile"
              class="registered-device-table"
              :data-updated="updateForced"
            >
              <tr v-for="item in items" :key="item.id">
                <td class="black--text">{{ item.serial }}</td>
                <td style="max-width: 300px">
                  <v-tooltip
                    bottom
                    :max-width="600"
                    :disabled="!isNameOverflowing[item.id]"
                  >
                    <template v-slot:activator="{ on }">
                      <div v-on="on" class="device-name" :ref="item.id">
                        <span>{{ item.deviceName }}</span>
                      </div>
                    </template>
                    <span>{{ item.deviceName }}</span>
                  </v-tooltip>
                </td>
                <td>
                  <span>{{ item.dataServices }}</span>
                </td>
                <td>
                  <template v-if="deviceHasWebsiteSubscription(item)">
                    <span v-if="item.websiteSubscription">{{
                      item.websiteSubscription
                    }}</span>
                    <span v-if="item.freeTier">{{
                      $t('apiServices.table.freeTierName')
                    }}</span>
                  </template>
                  <span v-else>
                    {{ $t('apiServices.table.notRegistered') }}
                  </span>
                </td>
                <td class="text-xs-left right-aligned">
                  <action-menu
                    :deviceId="item.id"
                    :websiteSubscription="item.websiteSubscription"
                    :hasFreeTier="item.freeTier"
                    :removeLock="removeLock"
                  />
                </td>
              </tr>
            </tbody>
            <!-- mobile view -->
            <tbody
              v-else
              class="registered-device-mobile-table"
              :data-updated="updateForced"
            >
              <tr v-for="item in items" :key="item.id">
                <td class="mobile-list">
                  <div class="item-title">
                    <action-menu
                      :deviceId="item.id"
                      :websiteSubscription="item.websiteSubscription"
                      :hasFreeTier="item.freeTier"
                      :removeLock="removeLock"
                    />
                    <div class="title-text">
                      <span>
                        {{ item.serial }}
                      </span>
                    </div>
                  </div>
                  <v-row no-gutters>
                    <v-col>
                      <ul class>
                        <li v-if="item.deviceName" class="list-item">
                          <span class="mobile-header">
                            {{ $t('apiServices.table.deviceName') }}:
                          </span>
                          <v-tooltip
                            bottom
                            :max-width="600"
                            :disabled="!isNameOverflowing[item.id]"
                          >
                            <template v-slot:activator="{ on }">
                              <span
                                v-on="on"
                                class="mobile-field device-name"
                                :ref="item.id"
                              >
                                <span>{{ item.deviceName }}</span>
                              </span>
                            </template>
                            <span>{{ item.deviceName }}</span>
                          </v-tooltip>
                        </li>
                        <li class="list-item">
                          <span class="mobile-header">
                            {{ $t('apiServices.table.dataServices') }}:
                          </span>
                          <span class="mobile-field">
                            {{ item.dataServices }}
                          </span>
                        </li>
                        <li class="list-item">
                          <span class="mobile-header">
                            {{ $t('apiServices.table.websiteSubs') }}:
                          </span>
                          <template v-if="deviceHasWebsiteSubscription(item)">
                            <span
                              v-if="item.websiteSubscription"
                              class="mobile-field"
                            >
                              {{ item.websiteSubscription }}
                            </span>
                            <span v-else class="mobile-field">
                              {{ $t('apiServices.table.freeTierName') }}
                            </span>
                          </template>
                          <span v-else class="mobile-field">
                            {{ $t('apiServices.table.notRegistered') }}
                          </span>
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <!-- progress spinner -->
        <div v-else class="text-center emptyMessage">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-card-text>
    </v-card>
    <register-devices-dialog
      :open="dialogs.registerDevicesDialog.open"
      :removeLock="removeLock"
      :subLock="subLock"
      @close="registerDevicesDialogClose"
      @getUserDevices="getUserDevices"
    />
  </div>
</template>

<script>
import {
  accountExists,
  emailVerified,
  isAuthorized,
  routeHasPermission,
} from '@fusion/auth'
import { SlugsEnum } from '../permissions/SlugsEnum'
import { isChildOverflowing } from '../services/html-utilities'
import debounce from 'lodash/debounce'
import RegisterDevicesDialog from '../components/api/RegisterDevicesDialog.vue'
import ActionMenu from '../components/api/ActionMenu.vue'

const PORTAL_LINK = 'https://developers.tsilink.com'

export default {
  mixins: [
    isAuthorized,
    routeHasPermission(SlugsEnum.SideBarDataServicesExecute),
    accountExists,
    emailVerified,
  ],
  components: {
    'register-devices-dialog': RegisterDevicesDialog,
    'action-menu': ActionMenu,
  },
  data() {
    return {
      keys: [],
      results: [],
      removeLock: false,
      subLock: false,
      addToBothWebsiteAndDataServices: false,
      dialogs: {
        registerDevicesDialog: {
          open: false,
        },
      },
      registeredDevices: {
        headers: [
          {
            text: this.$t('apiServices.table.serialNumber'),
            align: 'left',
            value: 'serialNumber',
          },
          {
            text: this.$t('apiServices.table.deviceName'),
            align: 'left',
            value: 'deviceName',
          },
          {
            text: this.$t('apiServices.table.dataServices'),
            value: 'dataServicesSubs',
          },
          {
            text: this.$t('apiServices.table.websiteSubs'),
            value: 'websiteSubscription',
          },
          {
            text: this.$t('apiServices.table.actions'),
            value: 'actions',
            align: 'right',
          },
        ],
      },
      isNameOverflowing: {},
      updateForced: false,
    }
  },
  mounted() {
    this.observe(this.$el)
    this.getUserDevices()
  },
  async updated() {
    if (!this.removeLock && !this.subLock) {
      // The DOM needs to be patched before a name overflow can be detected
      // Then a second update is forced to patch the DOM again
      // and enable tooltips where necessary
      await this.setIsNameOverflowing()
      if (!this.updateForced) {
        this.$forceUpdate()
        this.updateForced = true
      } else {
        this.updateForced = false
      }
    }
  },
  destroyed() {
    if (this.observer) this.observer.disconnect()
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    dataSubscriptions() {
      return this.$store.getters['subscriptions/getDataServices']
    },
    devices() {
      const keys = Object.keys(this.$store.state['devices'].serial)
      let devs = []
      for (const key of keys) {
        const isShared = this.$store.getters['devices/getIsSharedByDeviceId'](key)
        const serial = this.$store.getters['devices/getSerialByDeviceId'](key)
        const deviceName = this.$store.getters['devices/getNameByDeviceId'](key)
        const isDataServices = this.deviceInDataServices(serial)
        if (serial && isDataServices && !isShared) {
          const isFreeTier = this.isDeviceFreeTier(key)
          const dev = {
            id: key,
            serial: serial,
            deviceName: deviceName,
            dataServices: this.getDataServiceSubName(serial),
            websiteSubscription: this.getWebsiteSubName(serial),
            freeTier: isFreeTier,
          }
          devs.push(dev)
        }
      }
      return devs
    },
    registeredDevicesClass() {
      return { mobile: this.isMobile }
    },
    registeredDevicesFooterProps() {
      return {
        'items-per-page-text': this.isMobile
          ? this.$t('tables.rows')
          : this.$t('tables.rowsPerPage'),
        itemsPerPageOptions: [10, 25, 50],
      }
    },
  },
  methods: {
    getDataServiceSubName(serial) {
      if (this.$store.getters['subscriptions/getDeviceDataServices']) {
        const dataServiceTier =
          this.$store.getters[
            'subscriptions/getDeviceDataServicesByDeviceSerial'
          ](serial)
        if (dataServiceTier) {
          return this.$store.getters['subscriptions/getSubscriptionNameById'](
            dataServiceTier
          )
        }
      }
      return undefined
    },
    getWebsiteSubName(serial) {
      const premiumTier =
        this.$store.getters[
          'subscriptions/getDevicePremiumSubscriptionByDeviceSerial'
        ](serial)
      if (premiumTier) {
        return this.$store.getters['subscriptions/getSubscriptionNameById'](
          premiumTier
        )
      }
      const basicTier =
        this.$store.getters[
          'subscriptions/getDeviceBasicSubscriptionByDeviceSerial'
        ](serial)
      if (basicTier) {
        return this.$store.getters['subscriptions/getSubscriptionNameById'](
          basicTier
        )
      }
      return undefined
    },
    deviceHasWebsiteSubscription(device) {
      return device.websiteSubscription || device.freeTier
    },
    deviceInDataServices(serial) {
      return this.$store.getters['subscriptions/hasDataServicesSubscription'](serial)
    },
    deviceInWebsite(serial) {
      return this.$store.getters['subscriptions/hasWebsiteSubscription'](serial)
    },
    deviceInFreeTier(serial) {
      return this.$store.getters['subscriptions/hasFreeTierSubscription'](serial)
    },
    registerDevicesDialogClose() {
      this.dialogs.registerDevicesDialog.open = false
    },
    async getUserDevices() {
      this.removeLock = true
      await this.$store.dispatch('devices/setAllDevices')
      const devices = this.$store.state.devices.allDevices
      this.subLock = true
      await this.$store.dispatch('subscriptions/updateSubscriptions', {
        auth: this.$auth,
        api: this.$api,
        devices,
      })
      this.subLock = false
      this.removeLock = false
    },
    isDeviceFreeTier(deviceId) {
      const serial = this.$store.state['devices'].serial[deviceId]
      return (
        !this.deviceInWebsite(serial) &&
        this.deviceInFreeTier(serial)
      )
    },
    openPortalLink() {
      window.open(PORTAL_LINK)
    },
    hasUserPermission(slugName) {
      return this.$permissions.isAllowed(SlugsEnum[slugName])
    },
    async setIsNameOverflowing() {
      await Vue.nextTick()
      this.devices.forEach((item) => {
        this.isNameOverflowing[item.id] = isChildOverflowing(
          this.$refs[item.id]
        )
      })
    },
    observe(el) {
      const forceUpdate = debounce(() => {
        this.$forceUpdate()
      }, 200)

      this.observer = new ResizeObserver(forceUpdate)
      if (el instanceof Element) {
        this.observer.observe(el)
      }
    },
  },
}
</script>

<style lang="scss">
// requires non-scoped scss to override the vuetify styles
.registered-devices-card {
  .v-data-table {
    .v-data-table-header-mobile__select {
      min-width: unset !important;
    }
    .v-data-footer {
      padding-right: 16px !important;
    }
    .v-data-table-header-mobile__wrapper {
      .v-select {
        max-width: 335px !important;
      }
    }
  }
  .v-data-table--mobile {
    .v-data-footer__pagination {
      margin: 0 0 0 12px !important;
    }
    .v-data-footer__icons-before {
      width: 24px !important;
    }
    .v-data-footer__icons-after {
      width: 24px !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.api-container {
  padding: 0px;
  @media (min-width: 496px) {
    padding: 12px;
  }
}

.col > .v-card,
[class*='col-'] > .v-card {
  height: calc(100% - 24px);
  margin: 12px;
}

.v-card {
  margin: 12px;

  .registered-devices-card {
    padding: 0;
  }
}

.v-card__title {
  font-weight: 300;
  .header-icon {
    color: rgba(0, 0, 0, 0.76);
    margin-right: 8px;
  }
}

@media (min-width: 600px) {
  .v-list.inline {
    display: flex;
    flex-wrap: wrap;
    > .v-list-item {
      flex-basis: 33.3333%;
    }
  }
}

.registered-devices-card {
  tbody.registered-device-table {
    tr {
      td {
        .row {
          margin: 4px 0;
        }
      }
    }
  }

  tbody.registered-device-mobile-table {
    tr {
      border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
      td {
        border-bottom: unset !important;
        .row {
          margin: 4px 0;
        }
      }
    }
  }

  td.mobile-list {
    ul {
      padding-bottom: 16px;
      padding-left: 12px;
      list-style-type: none;
    }
    .item-title {
      display: flex;
      line-height: 36px;
      padding-top: 8px;
      .title-text {
        margin-left: 8px;
        font-weight: 700;
      }
    }
    .list-item {
      margin-bottom: 4px;
    }
    .mobile-header {
      display: block;
      margin-right: 4px;
      white-space: nowrap;
      color: rgba(0, 0, 0, 0.6);
    }
    .mobile-field {
      display: block;
      &.device-name {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .device-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }

  .v-btn.icon-button {
    min-width: 120px !important;
    width: 120px !important;
  }
}

.register-btn {
  margin-left: auto;
}

.emptyMessage {
  height: 240px;
  line-height: 240px;
}

.right-aligned {
  direction: rtl;
}
</style>

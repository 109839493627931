/**
 * Checks whether a child element of the provided element is overflowing
 * @param {HTMLElement} element
 * @returns Boolean
 */
export function isChildOverflowing(element) {
  const parent = Array.isArray(element) ? element[0] : element
  const child = parent?.firstChild
  if (child) {
    return (
      child.offsetWidth > parent.offsetWidth ||
      child.offsetHeight > parent.offsetHeight
    )
  }
  return false
}

/**
 * Checks for element in DOM, and if not found, waits for element to be added
 * @param {String} selector
 * @returns {Promise} that resolves when element is available in DOM
 */
export function waitForElement(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector))
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector))
        observer.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })
  })
}

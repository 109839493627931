<template>
  <base-dialog
    :open="open"
    data-cy="removeWarningDialog"
    :persistentOverlay="true"
    :fullscreen="$vuetify.breakpoint.xs"
    content-class="removeWarning"
    :maxWidth="450"
  >
    <v-card-title slot="header">
      <v-icon class="header-icon">mdi-alert</v-icon>
      {{ $t('dashboard.removeWarningDialog.title') }}
    </v-card-title>
    <v-card-text>
      {{ $t('dashboard.removeWarningDialog.message') }}
    </v-card-text>
    <template v-slot:actions>
      <v-btn
        :v-if="$vuetify.breakpoint.xs"
        @click="cancel"
        text
        outlined
        class="black--text dialog-btn"
        data-cy="removeWidgetCancel"
      >
        {{ $t('buttons.cancel') }}
      </v-btn>
      <v-spacer />
      <v-btn
        @click="remove"
        text
        class="primary white--text dialog-btn"
        data-cy="removeWidgetOK"
      >
        {{ $t('dashboard.removeWarningDialog.removeButton') }}
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import Dialog from './Dialog.vue'

export default {
  props: ['open'],
  components: {
    'base-dialog': Dialog,
  },
  methods: {
    remove() {
      this.$emit('ok')
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss">
.removeWarning {
  .v-card {
    .v-card__title {
      padding: 0px 0px 16px 16px;
    }
  }
}
.dialog-btn {
  width: 160px;
}
</style>

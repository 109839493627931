export default {
  setIsLogInAs({ commit }, isLogInAs) {
    commit('setIsLogInAs', isLogInAs)
  },
  setImpersonatedUser({ commit }, impersonatedUser) {
    commit('setImpersonatedUser', impersonatedUser)
  },
  async updateImpersonatedUserPermissions({ commit }, data) {
    const { api, auth } = data
    if (!auth.isAuthenticated) {
      commit('setImpersonatedUserPermissions', [])
      return
    }
    const permissionsRes = await api.getUserPermissions()
    const permissions = await permissionsRes.json()
    commit('setImpersonatedUserPermissions', permissions)
  }
}


import { subscriptionTierLength, renewalDateStatuses } from '../../store/subscriptions/utils'
import { convertUTCToTSITimezoneDate, convertRFC3339ToMonthDayYearDateString } from '../common/helper'

export const getDeviceSubscriptionIndex = (subscription, deviceModelAndSerial) => {
  let deviceIndex = -1
  if (subscription) {
    const deviceIds = subscription.deviceIds
    if (deviceIds && deviceIds.length) {
      deviceIds.forEach((ids, index) => {
        if (ids && ids.length) {
          ids.forEach((key) => {
            if (key === deviceModelAndSerial) {
              deviceIndex = index
            }
          })
        }
      })
    }
  }

  return deviceIndex
}

export const getDeviceSubscriptionTier = (subscription) => {
  return subscription && subscription.id ? subscriptionTierLength[subscription.id] : ''
}

export const getDeviceSubscriptionActivationDate = (subscription, deviceSubscriptionIndex) => {
  if (subscription && deviceSubscriptionIndex !== -1) {
    const activationDate = subscription.activationDate ? subscription.activationDate[deviceSubscriptionIndex] : ''
    if (activationDate) {
      return convertUTCToTSITimezoneDate(activationDate)
    }
  }

  return ''
}

export const getDeviceSubscriptionRenewalDate = (subscription, deviceSubscriptionIndex) => {
  const renewalDate = {
    status: renewalDateStatuses.NotApplicable,
    date: ''
  }
  if (subscription && deviceSubscriptionIndex !== -1) {
    if (subscription.expires && subscription.expires[deviceSubscriptionIndex]
      && subscription.canceled && subscription.canceled[deviceSubscriptionIndex]) {
        renewalDate.status = renewalDateStatuses.Canceled
    } else if (subscription.canceled && subscription.canceled[deviceSubscriptionIndex]) {
      renewalDate.status = renewalDateStatuses.CancellationPending
    } else if (subscription.billingInfo && subscription.billingInfo[deviceSubscriptionIndex]
      && subscription.billingInfo[deviceSubscriptionIndex].autoRenewalDate) {
        renewalDate.status = renewalDateStatuses.AutoRenewal
        renewalDate.date = convertRFC3339ToMonthDayYearDateString(subscription.billingInfo[deviceSubscriptionIndex].autoRenewalDate)
    }
  }

  return renewalDate
}

export const getDeviceSubscriptionEndDate = (subscription, deviceSubscriptionIndex) => {
  if (subscription && deviceSubscriptionIndex !== -1) {
    if (subscription.expires && subscription.expires[deviceSubscriptionIndex]) {
      return convertRFC3339ToMonthDayYearDateString(subscription.expires[deviceSubscriptionIndex])
    }
  }

  return ''
}

export const getDeviceSubscriptionFormattedDetails = (subscription, deviceModelAndSerial) => {
  const deviceSubscriptionIndex = getDeviceSubscriptionIndex(subscription, deviceModelAndSerial)
  return {
    activationDate: getDeviceSubscriptionActivationDate(subscription, deviceSubscriptionIndex),
    subscriptionTier: getDeviceSubscriptionTier(subscription),
    renewalDate: getDeviceSubscriptionRenewalDate(subscription, deviceSubscriptionIndex),
    endDate: getDeviceSubscriptionEndDate(subscription, deviceSubscriptionIndex)
  }
}

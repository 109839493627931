<template>
  <div>
    <v-card
      v-if="$store.state.map.movingMode"
      style="right: 0rem; top: 0rem; width: 290px"
      data-cy="moveDeviceCard" :coords="JSON.stringify(currentCoords)"
    >
      <v-card-title class="move-title">{{
        $t('map.moveDevice.title')
      }}</v-card-title>
      <div class="move-content">{{ $t('map.moveDevice.content') }}</div>
      <v-card-actions class="move-actions" pa="0">
        <v-btn
          @click="cancelActivation"
          outlined
          class="left black white--text"
          text
          data-cy="cancelMoveButton"
          >{{ $t('buttons.cancel') }}</v-btn
        >
        <v-btn
          @click="completeActivation"
          class="right primary white--text"
          text
          data-cy="moveDoneButton"
          >{{ $t('buttons.done') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    pinId() {
      return this.$store.state.map.deviceToMove?.deviceId
    },
    currentCoords() {
    const { lng, lat } = this.$store.getters['devices/getCoordsByDeviceId'](this.pinId)
      return {
        longitude: parseFloat(lng),
        latitude: parseFloat(lat),
      }
    }
  },
  methods: {
    async completeActivation() {
      if (this.currentCoords) {
        try {
          await this.$api.patchUpdateDevice({ cloud_state_data: this.currentCoords }, this.pinId)
        } catch (e) {
          // TODO: catch http error
          this.$store.dispatch('map/stopMoving')
        } finally {
          this.$store.dispatch('map/stopMoving')
        }
      }
    },
    cancelActivation() {
      const device = this.$store.state.map.deviceToMove
      this.$store.dispatch('devices/updateDeviceCoords', device)
      this.$store.dispatch('map/cancelMove')
    },
  },
}
</script>

<style lang="scss" scoped>
.isSticky {
  position: sticky;
  background: #ffffff;
  bottom: 0;
}

.padding-16 {
  padding: 16px;
}
.form-section-header {
  opacity: 0.56;
}
.hint-text {
  font-size: 12px;
  opacity: 0.76;
}
.hint-text-button {
  margin-left: 16px;
}
.hint-text.red-font {
  color: red;
}
.v-dialog {
  .v-card {
    padding: 16px;
  }
  .v-card__title {
    font-weight: 300;
  }
  .v-card__subtitle {
    padding: 16px 24px;
  }
  .secondary-indicator {
    display: flex;
    .device-type-indicator {
      align-self: center;
      margin: 0 0 0 16px;
      img {
        height: 36px;
      }
    }
  }
  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
    .trailing-icon-text {
      width: 100%;
      text-indent: 30px;
    }

    &.disabled {
      border-color: grey;
      background-color: #a2a2a2 !important;
      cursor: not-allowed;
    }
  }
}

.rtl {
  direction: rtl;
}

.dialog {
  margin: 0px !important;
}

.errorMessage {
  font-size: 12px;
  color: red;
  text-align: left;
  padding-left: 16px;
}

.move-title {
  font-size: 18px;
  padding: 0.8rem;
  padding-bottom: 0.2rem;
  font-weight: 400;
}
.move-content {
  padding: 0 0.8rem 1.2rem 0.8rem;
  opacity: 0.7;
}
.move-actions {
  padding: 0;
  .v-btn {
    margin: 0 !important;
    padding: 0;
    width: 50%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: 3.2rem;
    font-size: 1rem;
  }
  .v-btn.left {
    border-bottom-right-radius: 0;
  }
  .v-btn.right {
    border-bottom-left-radius: 0;
  }
}
</style>

export const Measurement_Names = Object.freeze({
  mcpm1x0: 'pm1.0',
  mcpm2x5: 'pm2.5',
  mcpm4x0: 'pm4.0',
  mcpm10: 'pm10',
  mcpm2x5_aqi : 'pm2.5 aqi',
  mcpm10_aqi: 'pm10 aqi',
  ncpm0x5: 'pm0.5 NC',
  ncpm1x0: 'pm1.0 NC',
  ncpm2x5: 'pm2.5 NC',
  ncpm4x0: 'pm4.0 NC',
  ncpm10: 'pm10 NC',
  tpsize: 'typical particle size',
  temp_c: 'Temperature',
  rh_percent: 'Relative Humidity',
  voc_mgm3: 'tvoc',
  voc_ppb: 'EtOH',
  ch2o_ppb: 'ch2o',
  co2_ppm: 'co2',
  co_ppm: 'co',
  baro_inhg: 'pressure',
  o3_ppb: 'o3',
  no2_ppb: 'no2',
  so2_ppb: 'so2'
})

export const connectionTypes = Object.freeze({
  WIFI: 'wifi',
  ETHERNET: 'ethernet',
})

export const searchableDeviceAttributes = [
  "deviceName",
  "deviceLocationName",
  "deviceType",
  "deviceModel",
  "deviceSerial",
  "deviceSubscription",
  "access",
  "lastUpdate"
]

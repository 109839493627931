var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{
    'ma-6': !this.$vuetify.breakpoint.xs,
    'manage-subscriptions-card': true,
    'is-mobile': this.isMobile,
  }},[_c('v-card-subtitle',[_c('v-btn',{staticClass:"returnText",attrs:{"id":"returnButton","plain":"","data-cy":"manageSubscriptionsBackButton"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left-circle-outline")])],1),_c('span',{staticClass:"returnText"},[_vm._v(_vm._s(_vm.$t('app.routes.manageSubscriptions')))]),(
        !_vm.$auth.loading &&
        _vm.userSubscriptions !== null &&
        _vm.userSubscriptions.length !== 0
      )?_c('p',{staticClass:"timezoneDetails"},[_vm._v(" "+_vm._s(_vm.$t('manageSubscriptions.timezoneDetails'))+" ")]):_vm._e()],1),(
      !_vm.$auth.loading &&
      _vm.userSubscriptions !== null &&
      _vm.userSubscriptions.length !== 0
    )?_c('v-data-table',{class:{
      mobile: _vm.isMobile,
      'last-row-expanded': this.isLastRowExpanded,
    },attrs:{"headers":_vm.headers,"items":_vm.userSubscriptions,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":_vm.enableExpandableRows,"item-key":"activationId","mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"custom-filter":_vm.filterByColumnValueAndDeviceSerial,"header-props":_vm.headerProps,"search":_vm.customTableFilter,"options":_vm.tableOptions,"sort-by":_vm.sortedBy,"sort-desc":_vm.sortDesc,"itemsPerPage":_vm.itemsPerPage,"footer-props":{
      'items-per-page-text': _vm.isMobile
        ? _vm.$t('tables.rows')
        : _vm.$t('tables.rowsPerPage'),
      itemsPerPageOptions: [10, 25, 50],
    },"data-cy":"manageSubscriptionsTable"},on:{"update:expanded":function($event){_vm.expanded=$event},"update:sortBy":function($event){_vm.sortedBy=$event},"update:sort-by":function($event){_vm.sortedBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"current-items":_vm.updateTableComputedItems},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"table-bar"},[_c('v-text-field',{staticClass:"search-field",style:(_vm.isMobile ? '' : 'width: 375px'),attrs:{"prepend-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","data-cy":"searchField"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},(!_vm.isMobile)?{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-left"},[(!_vm.isAddOnSub(item.subscriptionId))?_c('action-menu',{attrs:{"item":item,"isLogInAs":_vm.isLogInAs},on:{"cancelSubscriptionOpen":_vm.cancelSubscriptionOpen,"subscriptionBillingInfoOpen":_vm.subscriptionBillingInfoOpen}}):_vm._e()],1)]}}:{key:"body",fn:function(ref){
    var items = ref.items;
    var headers = ref.headers;
    var expand = ref.expand;
    var isExpanded = ref.isExpanded;
return [(_vm.enableExpandableRows)?_c('expand-collapse-buttons',{on:{"expandAll":_vm.expandAllDevices,"collapseAll":_vm.collapseAllDevices}}):_vm._e(),_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"mobile-list"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ul',{staticClass:"flex-content"},[_vm._v(" "+_vm._s(item.subscription)+" "),(!_vm.isAddOnSub(item.subscriptionId))?_c('action-menu',{attrs:{"item":item,"isLogInAs":_vm.isLogInAs},on:{"cancelSubscriptionOpen":_vm.cancelSubscriptionOpen,"subscriptionBillingInfoOpen":_vm.subscriptionBillingInfoOpen}}):_vm._e(),_vm._l((_vm.getMobileHeaders(headers, item)),function(header){return _c('li',{key:header.value,staticClass:"flex-item"},[_c('span',[_vm._v(" "+_vm._s(header.text)+":  ")]),_c('span',[_vm._v(_vm._s(item[header.value]))])])}),(_vm.enableExpandableRows && _vm.itemCanBeExpanded(item))?_c('li',{staticClass:"flex-item expandable-option-mobile"},[_c('v-btn',{staticClass:"v-data-table__expand-icon expandable-icon-mobile",class:{'v-data-table__expand-icon--active' : isExpanded(item)},attrs:{"icon":""},on:{"click":function($event){expand(item,!isExpanded(item))}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1),_c('span',[_vm._v(" "+_vm._s(_vm.$t('manageSubscriptions.devices')))])],1):_vm._e(),(isExpanded(item))?_c('li',{staticClass:"flex-item expandable-content-column mobile"},[_c('expandable-content',{attrs:{"item":item}})],1):_vm._e()],2)])],1)],1)])}),0)]}},{key:"item.data-table-expand",fn:function(ref){
    var item = ref.item;
    var expand = ref.expand;
    var isExpanded = ref.isExpanded;
return [(_vm.itemCanBeExpanded(item))?_c('td',{staticClass:"text-start"},[_c('v-btn',{staticClass:"v-data-table__expand-icon",class:{'v-data-table__expand-icon--active' : isExpanded},attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"expandable-content-column",attrs:{"colspan":11}},[_c('expandable-content',{attrs:{"item":item}})],1)]}},(_vm.enableExpandableRows)?{key:"footer.prepend",fn:function(ref){return [_c('expand-collapse-buttons',{on:{"expandAll":_vm.expandAllDevices,"collapseAll":_vm.collapseAllDevices}})]}}:null],null,true)}):(
      !_vm.$auth.loading &&
      _vm.userSubscriptions !== null &&
      _vm.userSubscriptions.length === 0
    )?_c('div',{staticClass:"text-center emptyMessage"},[_vm._v(" "+_vm._s(_vm.$t('manageSubscriptions.emptyMessage'))+" ")]):_c('div',{staticClass:"text-center emptyMessage"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),_c('subscription-cancel-dialog',{attrs:{"open":_vm.dialog.subscriptionCancel.open,"activationId":_vm.dialog.subscriptionCancel.activationId},on:{"close":_vm.cancelSubscriptionClosed}}),_c('subscription-billing-info-dialog',{attrs:{"open":_vm.dialog.subscriptionBillingInfo.open,"activationId":_vm.dialog.subscriptionBillingInfo.activationId,"subscriptionId":_vm.dialog.subscriptionBillingInfo.subscriptionId},on:{"close":_vm.subscriptionBillingInfoClosed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
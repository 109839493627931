import { connectionTypes } from '../store/devices/utils'

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
// function compare(a, b) {
//   if (a is less than b by some ordering criterion) {
//     return -1;
//   }
//   if (a is greater than b by the ordering criterion) {
//     return 1;
//   }
// }
export const sortDeviceCondition = (deviceA, deviceB) => {
  // sort based on isOwned if either are shared
  if (!deviceA.isOwned) {
    return 1
  } else if (!deviceB.isOwned) {
    return -1
  }

  // sort on eol warning if either device has one
  if (deviceA.spsEol) {
    return -1
  } else if (deviceB.spsEol) {
    return 1
  }

  // sort on sps30/tiber status
  const numberErrors = {
    a: countNumberOfErrors(deviceA),
    b: countNumberOfErrors(deviceB),
  }

  // sort on # of errors, unless they have the same #
  if (numberErrors.a !== numberErrors.b) {
    return numberErrors.a > numberErrors.b ? 1 : -1
  }

  // sort based on connection
  if (!deviceA.isConnected) {
    return 1
  } else if (!deviceB.isConnected) {
    return -1
  }

  // sort on device connection type
  if (deviceA.connectionType === connectionTypes.ETHERNET) {
    return 1
  } else if (deviceB.connectionType === connectionTypes.ETHERNET) {
    return -1
  }

  // otherwise sort on rssi
  const res = deviceA.rssi > deviceB.rssi ? -1 : 1
  return res
}

/**
 * Each sensor has a status array with list of strings for the errors if there re any
 * @param readings the sensorReadings object
 * @return the number of errors
 **/
export const countNumberOfErrors = (device) => {
  return device.deviceErrors.length + device.sensorErrors.length
}

import store from '../../store'

export const getLoggedInAsUser = () => {
  return store?.getters ? store.getters['loginas/getImpersonatedUser'] : {}

}

export const getUserAccountId = async (auth) => {
  if (auth?.getAccountId) {
    const loggedInAsUser = getLoggedInAsUser()
    return loggedInAsUser?.accountId ? loggedInAsUser.accountId : await auth.getAccountId()
  }

  return ''
}

<template>
  <div>
    <v-form ref="form" v-if="!isEnableNotificationSetting">
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <v-card
            class="padding pa-4"
            style="padding-top: 0px !important; padding-bottom: 0px !important"
            flat
          >
            <div>
              <v-text-field
                v-model="currentAlert.title"
                v-bind:label="$t('map.deviceAlerts.fields.title')"
                maxlength="20"
                counter="20"
                :disabled="editing"
                :rules="[rules.required]"
                data-cy="addTitle"
                class="text-field-required"
              ></v-text-field>
              <v-select
                v-model="currentAlert.condition.field"
                :items="measurements"
                item-text="text"
                item-value="value"
                :disabled="editing"
                :label="$t('map.deviceAlerts.fields.sensor')"
                data-cy="addSensor"
                class="text-field-required"
                :rules="[rules.required]"
                @change="validateThreshold"
              ></v-select>
              <v-select
                v-model="currentAlert.condition.operator"
                :items="operators"
                item-text="text"
                item-value="value"
                :disabled="editing"
                :label="$t('map.deviceAlerts.fields.condition')"
                @change="validateThreshold"
                data-cy="addCondition"
              ></v-select>
              <v-select
                v-model="currentAlert.frequencyType"
                :items="frequencyType"
                item-text="text"
                item-value="value"
                :label="$t('map.deviceAlerts.fields.frequency')"
                data-cy="frequency"
              ></v-select>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card
            class="pa-4"
            style="padding-top: 0px !important; padding-bottom: 0px !important"
            flat
          >
            <div>
              <v-switch
                v-model="currentAlert.enabled"
                v-bind:label="$t('map.deviceAlerts.fields.enabled')"
                color="primary"
                data-cy="addEnabled"
              ></v-switch>
              <v-select
                v-model="currentAlert.severity"
                :items="levels"
                item-text="text"
                item-value="value"
                :label="$t('map.deviceAlerts.fields.level')"
                data-cy="addLevel"
              ></v-select>
              <v-text-field
                ref="threshold"
                v-model="currentAlert.condition.value"
                v-bind:label="$t('map.deviceAlerts.fields.value')"
                maxlength="10"
                :disabled="measurementSet"
                :rules="[
                  rules.required,
                  rules.validNum,
                  rules.threshold,
                  rules.precision,
                ]"
                @keypress="preventNonNumbers($event)"
                data-cy="addThreshold"
                class="text-field-required"
              ></v-text-field>
              <transition>
                <div
                  v-if="helpText"
                  class="help-text"
                  data-cy="thresholdHelpText"
                >
                  <p>{{ helpText }}</p>
                </div>
              </transition>
            </div>
          </v-card>
        </v-col>
        <v-container class="pa-4 pt-0">
          <div v-if="isNotificationEnabled">
            {{ $t('map.deviceAlerts.enableNotificationsHelp') }}
          </div>
          <v-row align="center">
            <v-col cols="12" sm="6" class="pa-0 pl-4 send-notification">
              <v-checkbox
                color="primary"
                v-model="isSendNotification"
                @change="removeSelection"
                :label="$t('map.deviceAlerts.fields.sendNotifications')"
                :disabled="
                  (isNotificationEnabled &&
                    !currentAlert.sendEmail &&
                    !currentAlert.sendSms) ||
                  enableNotificationBtn
                "
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6" class="enable-notification">
              <v-btn
                v-if="enableNotificationBtn"
                text
                class="primary--text mobile-menu"
                @click="toggleNotification"
              >
                {{ $t('map.deviceAlerts.fields.enableNotifications') }}
              </v-btn>
            </v-col>
          </v-row>
          <div v-if="isSendNotification">
            <v-label>{{ $t('map.deviceAlerts.fields.deliverTo') }}</v-label>
            <v-checkbox
              class="zero-height-v-messages"
              color="primary"
              v-model="currentAlert.sendSms"
              :label="getSmsLabel"
              :disabled="disableSms"
            ></v-checkbox>
            <div v-if="!enableNotificationBtn && disableSms">
              <v-btn
                text
                class="primary--text mobile-menu"
                @click="toggleNotification"
              >
                {{ $t('map.deviceAlerts.fields.enableNotifications') }}
              </v-btn>
            </div>
            <v-checkbox
              class="zero-height-v-messages"
              color="primary"
              v-model="currentAlert.sendEmail"
              :label="getEmailLabel"
              :disabled="disableEmail"
            ></v-checkbox>
            <div v-if="!enableNotificationBtn && disableEmail">
              <v-btn
                text
                class="primary--text mobile-menu"
                @click="toggleNotification"
              >
                {{ $t('map.deviceAlerts.fields.enableNotifications') }}
              </v-btn>
            </div>
          </div>
        </v-container>
      </v-row>
      <v-card class="pa-4" flat>
        <div>
          <v-text-field
            v-model="currentAlert.userMessage"
            v-bind:label="$t('map.deviceAlerts.fields.message')"
            maxlength="25"
            counter="25"
            data-cy="addCustomMessage"
          ></v-text-field>
        </div>
      </v-card>
      <v-card-actions text>
        <div class="hint-text">
          {{ $t('validation.requiredHelp') }}
        </div>
      </v-card-actions>
    </v-form>
    <fusion-notification-settings
      v-if="isEnableNotificationSetting"
      :isSmallDisclaimerTitle="true"
      :showMobileDoneButton="true"
      @onSave="onNotificationSelect"
      @close="toggleNotification"
      @onDisclaimerAgree="onDisclaimerAgree"
    />
  </div>
</template>

<script>
export default {
  props: {
    currentAlert: Object,
    rules: Object,
    phone: Object,
    isEditingAlert: Boolean,
    isSmsDisabled: Boolean,
    isEmailDisabled: Boolean,
    isNotificationEnabled: Boolean,
    deviceId: String,
    measurements: Array,
    helpText: String,
  },
  data() {
    return {
      operators: [
        {
          text: this.$t('map.deviceAlerts.fields.operators.lt'),
          value: 'lt',
        },
        {
          text: this.$t('map.deviceAlerts.fields.operators.lte'),
          value: 'lte',
        },
        {
          text: this.$t('map.deviceAlerts.fields.operators.eq'),
          value: 'eq',
        },
        {
          text: this.$t('map.deviceAlerts.fields.operators.gte'),
          value: 'gte',
        },
        {
          text: this.$t('map.deviceAlerts.fields.operators.gt'),
          value: 'gt',
        },
      ],
      levels: [
        {
          text: this.$t('map.deviceAlerts.fields.levels.standard'),
          value: 'standard',
        },
        {
          text: this.$t('map.deviceAlerts.fields.levels.warning'),
          value: 'warning',
        },
        {
          text: this.$t('map.deviceAlerts.fields.levels.critical'),
          value: 'critical',
        },
      ],
      frequencyType: [
        {
          text: this.$t('map.deviceAlerts.fields.frequencyTypes.onceCrossed'),
          value: 'onceCrossed',
        },
        {
          text: this.$t('map.deviceAlerts.fields.frequencyTypes.everyCrossed'),
          value: 'everyCrossed',
        },
      ],
      isSendNotification: false,
      mobileNumber: 'not added',
      primaryEmail: 'not added',
      isEnableNotificationSetting: false,
      isNotificationOptionSelected: false,
      disableSms: this.isSmsDisabled,
      disableEmail: this.isEmailDisabled,
      enableNotificationBtn: this.isNotificationEnabled,
    }
  },
  watch: {
    isSmsDisabled(newVal) {
      this.disableSms = newVal
    },
    isEmailDisabled(newVal) {
      this.disableEmail = newVal
    },
    isNotificationEnabled(newVal) {
      this.enableNotificationBtn = newVal
    },
  },
  computed: {
    editing: function () {
      return !!this.currentAlert.id
    },
    getSmsLabel() {
      const mobilePhoneText = this.$t('map.deviceAlerts.fields.mobilePhone')
      const pendingText = this.$t('userProfile.notificationSettings.pending')
      const requiresOptinText = this.$t('map.deviceAlerts.requiresOptIn')

      let label = `${mobilePhoneText}`

      if (this.phone?.isPrimary && this.phone?.smsNotificationsPending) {
        // add (Pending) to label
        label += ` (${this.mobileNumber}) (${pendingText})`
      } else if (
        // add (Requires Opt-in) to label
        this.phone?.isPrimary &&
        !this.phone?.smsNotificationsPending &&
        !this.phone?.allowSmsNotifications
      ) {
        label += ` (${requiresOptinText})`
      } else {
        label += ` (${this.mobileNumber})`
      }
      return label
    },
    getEmailLabel() {
      let label = `${this.$t('map.deviceAlerts.fields.primaryEmail')}`

      if (!this.$store.state.userProfile?.user?.allowEmailNotifications) {
        label += ` (${this.$t('map.deviceAlerts.requiresOptIn')})`
      } else {
        label += ` (${this.primaryEmail})`
      }

      return label
    },
    measurementSet() {
      return this.currentAlert.condition.field == ''
    },
  },
  async mounted() {
    this.primaryEmail = this.$store.state.userProfile?.user?.email
    if (this.phone?.number) {
      this.mobileNumber = this.phone.number
    }
    if (this.isEditingAlert) {
      this.isSendNotification =
        this.currentAlert.sendEmail || this.currentAlert.sendSms
    }
  },
  methods: {
    validateThreshold() {
      this.$refs.threshold.validate()
    },
    preventNonNumbers(event) {
      const allowedChars = [0, 8, 45, 46] // undefined, backspace, minus, delete
      const allowedNumbers = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57] // 0–9
      const keypress = event.keyCode || event.which || event.charCode
      if (![...allowedChars, ...allowedNumbers].includes(keypress)) {
        event.preventDefault()
      }
    },
    toggleNotification() {
      this.isEnableNotificationSetting = !this.isEnableNotificationSetting
      this.isNotificationOptionSelected = !this.isNotificationOptionSelected
      this.enableNotificationBtn = this.disableSms && this.disableEmail
      this.$emit('toggleNotification')
    },
    onNotificationSelect(e) {
      this.disableSms = !e.smsEnabled
      this.disableEmail = !e.emailEnabled
    },
    onDisclaimerAgree() {
      this.$emit('onDisclaimerAgree')
    },
    removeSelection() {
      if (!this.isSendNotification) {
        this.currentAlert.sendSms = false
        this.currentAlert.sendEmail = false
      }
    },
  },
}
</script>

<style lang="scss">
.zero-height-v-messages {
  .v-input__slot {
    margin-bottom: 0;
  }
  .v-messages {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.hint-text {
  font-size: 12px;
  opacity: 0.76;
  margin-left: 16px;
  color: red;
}
.help-text {
  display: flex;
  align-items: flex-start;
  font-size: 0.8125rem;
  line-height: 1.375;
  padding: 8px 12px;
  border: 1px solid #00aeef;
  border-top-width: 4px;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 8px;
  .v-icon {
    margin-right: 8px;
  }
  p {
    margin: 0;
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>

<template>
  <div>
    <aqi-measurement
      :deviceId="id"
      ref="aqiMeasurement"
    />
    <popover-measurements
      :deviceId="id"
      :permissions="permissions"
      :sensorReadings="sensorReadings"
    />
  </div>
</template>

<script>
// Content components
import aqiMeasurement from './measurements/aqiMeasurement/AQIMeasurement.vue'
import popoverMeasurements from './measurements/popoverMeasurements.vue'

export default {
  components: {
    'aqi-measurement': aqiMeasurement,
    'popover-measurements': popoverMeasurements,
  },
  props: {
    deviceId: String,
    permissions: Array,
  },
  computed: {
    id: function () {
      return this.deviceId || ''
    },
    sensorReadings: function () {
      return this.$store.getters['devices/getSensorReadingsByDeviceId'](this.id)
    },
  },
  methods: {
    // This is used by the parent component refresh
    refresh: function () {
      this.$refs.aqiMeasurement.refresh()
    },
  },
}
</script>

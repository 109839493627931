<template>
  <div class="reading" :style="aqiStyles(reading)">
    {{ validateAQI(reading) }}
  </div>
</template>

<script>
import { getAQIStyles, aqiValidator } from '../../services/map'

export default {
  name: 'StyledReading',
  props: ['reading'],
  mixins: [aqiValidator],
  methods: {
    aqiStyles: function (aqi) {
      const styles = getAQIStyles(aqi)

      if (styles.background === '#eeeeee') {
        return `background-color:${styles.background};color:#0c0c0cc2;`
      }
      return `background-color:${styles.background};color:${styles.color}`
    },
  },
}
</script>

<style lang="scss" scoped>
.reading {
  background-color: #eeeeee;
  color: white;
  text-align: center;
  font-weight: 500;
  border-radius: 2px;
  width: 40px;
  padding: 2px 0 2px 0;
  display: inline-block;
}
</style>

const localStorage = window.localStorage

export const measurementTypes = Object.freeze({
  pm25: 0,
  pm10: 1,
})

export const measurementTypeNames = Object.freeze({
  [measurementTypes.pm25]: 'PM 2.5',
  [measurementTypes.pm10]: 'PM 10',
})

export const defaultMapSettings = Object.freeze({
  showMine: true,
  showPublic: true,
  showShared: true,
  showIndoor: true,
  showOutdoor: true,
  pinMeasurementType: measurementTypes.pm25,
  deviceModels: null,
})

export const defaultMapLocation = Object.freeze({
  center: { lat: 30, lng: -87 },
  zoom: 5,
  lastZoom: 5,
})

export function getMapSettingsObject() {
  const settings = JSON.parse(localStorage.getItem('tsi.mapSettings'))
  if (settings && settings.pinMeasurementType === undefined) {
    settings.pinMeasurementType = defaultMapSettings.pinMeasurementType
  }
  if (settings && settings.deviceModels === undefined) {
    settings.deviceModels = defaultMapSettings.deviceModels
  }
  return settings !== null ? settings : defaultMapSettings
}

export function getMapLocationObject() {
  const oldKey = 'lastLocation'
  const key = 'tsi.mapLocation'
  const oldSettings = JSON.parse(localStorage.getItem(oldKey))
  if (oldSettings !== null) {
    const mapLocation = { ...defaultMapLocation }
    mapLocation.center = oldSettings
    localStorage.setItem(key, JSON.stringify(mapLocation))
    localStorage.removeItem(oldKey)
    return mapLocation
  } else {
    const settings = JSON.parse(localStorage.getItem(key))
    return settings !== null ? settings : defaultMapLocation
  }
}

<template>
  <v-hover
    v-if="showConnectivity"
    v-slot:default="{ hover }"
    :open-delay="0"
    :close-delay="0"
    :value="val"
  >
    <span :data-cy="`${connectionType}ConnectionStatus`">
      <v-btn class="popover-status-icon" text @click="val = !val">
        <v-icon
          v-if="isDeviceConnected && connectionType === connectionTypes.WIFI"
          color="grey"
        >
          {{ connection.icon }}
        </v-icon>
        <img
          v-else-if="
            isDeviceConnected && connectionType === connectionTypes.ETHERNET
          "
          :src="ethernetIcon"
          alt="Ethernet Icon"
        />
        <v-icon v-else color="grey">
          {{ connectionStates.disconnected.icon }}
        </v-icon>
      </v-btn>
      <v-tooltip content-class="connection-status-tooltip" v-bind:value="hover" bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on"></span>
        </template>
        <div v-if="isDeviceConnected">
          {{ connectionText }}
        </div>
        <div v-else>
          {{ connectionStates.disconnected.label }}
        </div>
      </v-tooltip>
    </span>
  </v-hover>
</template>

<script>
import { connectionTypes } from '@/store/devices/utils'

export default {
  props: {
    deviceId: String,
  },
  data() {
    return {
      val: false,
      connectionStates: {
        disconnected: {
          label: this.$t('map.popover.timestamp.disconnected'),
          icon: 'mdi-wifi-strength-alert-outline',
        },
      },
      connectionTypes: connectionTypes,
      ethernetConnections: {
        wired: {
          label: this.$t('map.popover.connectionStrength.ethernet.wired'),
        },
      },
      wifiConnections: {
        veryGood: {
          label: this.$t('map.popover.connectionStrength.wifi.veryGood'),
          icon: 'mdi-wifi-strength-4',
        },
        good: {
          label: this.$t('map.popover.connectionStrength.wifi.good'),
          icon: 'mdi-wifi-strength-3',
        },
        okay: {
          label: this.$t('map.popover.connectionStrength.wifi.okay'),
          icon: 'mdi-wifi-strength-2',
        },
        bad: {
          label: this.$t('map.popover.connectionStrength.wifi.bad'),
          icon: 'mdi-wifi-strength-2',
        },
        unusable: {
          label: this.$t('map.popover.connectionStrength.wifi.unusable'),
          icon: 'mdi-wifi-strength-1',
        },
        na: {
          label: this.$t('map.popover.valueBlank'),
          icon: 'mdi-wifi-strength-alert-outline',
        },
      },
      ethernetIcon: '/img/icons/ethernet_icon_grey.svg',
    }
  },
  computed: {
    id() {
      return this.deviceId || ''
    },
    rssi() {
      return this.$store.getters['devices/getRSSIByDeviceId'](this.id)
    },
    model() {
      return this.$store.getters['devices/getModelByDeviceId'](this.id)
    },
    connection() {
      return this.connectionType === connectionTypes.WIFI
        ? this.wifiConnection
        : this.ethernetConnections.wired
    },
    connectionType() {
      return (
        this.$store.getters['devices/getConnectionTypeByDeviceId'](this.id) ||
        connectionTypes.WIFI
      )
    },
    connectionText() {
      return this.connectionType === connectionTypes.WIFI
        ? `${this.$t('map.popover.timestamp.wifiStrength')}: ${
            this.connection.label
          }`
        : this.connection.label
    },
    wifiConnection() {
      if (this.rssi && typeof this.rssi === 'number') {
        if (this.rssi > -67) return this.wifiConnections.veryGood
        if (this.rssi > -70) return this.wifiConnections.good
        if (this.rssi > -80) return this.wifiConnections.okay
        if (this.rssi > -90) return this.wifiConnections.bad
        if (this.rssi <= -90) return this.wifiConnections.unusable
      }

      return this.wifiConnections.na
    },
    isDeviceConnected() {
      return this.$store.getters['devices/getIsConnectedByDeviceId'](this.id)
    },
    showConnectivity() {
      return this.$store.getters['devicemodels/getShowConnectivity'](this.model)
    },
  },
}
</script>

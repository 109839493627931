<template>
  <v-dialog
    @input="close"
    v-model="open"
    :hideOverlay="false"
    :persistent="true"
    data-cy="currentReadingsDialog"
    max-width="500"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind="on"></slot>
    </template>
    <v-card>
      <current-readings-widget
        :deviceIds="deviceIds"
        :isDialog="true"
        @close="close"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import CurrentReadingsWidget from '../widgets/currentReadings/CurrentReadingsWidget.vue'

export default {
  name: 'CurrentReadingsDialog',
  props: {
    open: Boolean,
    currentDeviceId: String,
  },
  components: {
    'current-readings-widget': CurrentReadingsWidget,
  },
  computed: {
    deviceIds() {
      return [this.currentDeviceId]
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
div[data-cy='currentReadingsWidget'] {
  ::v-deep .widget-header-container {
    padding: 0 0 0 14px;
  }
}

::v-deep .widget-content-container {
  min-height: 360px;
}

@media (min-width: 960px) {
  ::v-deep .measurement-list-wrapper {
    padding: 0 0 0 12%;
  }
}

@media (max-width: 959px) {
  ::v-deep .v-dialog {
    min-width: 350px;

    .widget-content-container,
    .widget-footer-container {
      padding: 8px;
    }
  }
}
</style>

import { getAllDevices, getModelsMetaData, getUserDevices } from './devices'

import {
  getRSSI,
  getEoL,
  getFirmwareVersion,
  getConnectedSmartBridgeSerial,
  getLastState,
  getConnectionType,
  getTelemTxType,
  getIsConnected,
  getBatteryCharging,
  getBatteryInstalled,
  getBatteryLow,
  getBatteryRemaining,
} from './state'

export {
  getRSSI,
  getEoL,
  getFirmwareVersion,
  getConnectedSmartBridgeSerial,
  getLastState,
  getAllDevices,
  getModelsMetaData,
  getUserDevices,
  getConnectionType,
  getTelemTxType,
  getIsConnected,
  getBatteryCharging,
  getBatteryInstalled,
  getBatteryLow,
  getBatteryRemaining,
}

export default {
  getRSSI,
  getEoL,
  getFirmwareVersion,
  getConnectedSmartBridgeSerial,
  getLastState,
  getAllDevices,
  getModelsMetaData,
  getUserDevices,
  getConnectionType,
  getTelemTxType,
  getBatteryCharging,
  getBatteryInstalled,
  getBatteryLow,
  getBatteryRemaining,
}

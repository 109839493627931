<template>
  <v-card class="devPanel">
    <v-row class="headerRow">
      <v-col class="headerCol" cols="12" md="3">
        <v-card-title class="devHeader"> {{ $t('app.routes.developer') }} </v-card-title>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="3" align="center">
        <emulatorPanel />
      </v-col>
      <v-col cols="12" md="9" align="center">
        <emulatorTable />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {
  isAuthorized,
  emailVerified,
  hasTSIEmail,
  accountExists,
  routeHasPermission,
} from '@fusion/auth'
import { SlugsEnum } from '../permissions/SlugsEnum'
import emulatorTable from '../components/developerPanel/emulatorTable.vue'
import emulatorPanel from '../components/developerPanel/emulatorPanel.vue'

export default {
  name: 'DeveloperPanel',
  mixins: [
    isAuthorized,
    emailVerified,
    hasTSIEmail,
    accountExists,
    routeHasPermission(SlugsEnum.DeveloperPanel),
  ],
  data() {
    return {}
  },
  components: { emulatorTable, emulatorPanel }
}
</script>

<style lang="scss" scoped>
.devPanel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  border-radius: 0;
  box-shadow: none;

  .devHeader {
    font-weight: 300;
    padding-bottom: 8px;
    padding-top: 8px;
    font-size: 24px;
    @media (max-width: 900px) {
      margin-left: 8px;
    }
    margin-left: 24px;
  }
  .headerRow {
    margin-top: 32px;
    margin-bottom: 4px;
  }
  .headerCol {
    padding-bottom: 0;
  }
}
</style>

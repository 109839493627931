<template>
  <base-dialog
    :open="open"
    :title="$t('map.historyDialog.exportDialog.title')"
    :persistentOverlay="true"
    icon="mdi-file-download"
    @close="close"
  >
    <v-card v-if="downloading" class="padding-top-16" flat>
      <span class="message">{{
        $t('map.historyDialog.exportDialog.message')
      }}</span>
    </v-card>
    <v-card-subtitle>
      <div v-if="!downloading">
        <span data-cy="maxExportDaysText">
          {{
            $t('map.historyDialog.exportDialog.subtitle', {
              numberOfDays: maxNumberOfDays,
            })
          }}
        </span>
        <span v-if="subscriptionMixedWarning">
          {{ $t('map.historyDialog.exportDialog.subscriptionMixedWarning') }}
        </span>
      </div>
    </v-card-subtitle>
    <v-card v-if="!downloading" class="padding-24" flat>
      <div class="form-section-header">
        {{ $t('map.historyDialog.exportDialog.dateRange') }}
      </div>
      <v-row justify="center">
        <v-menu
          ref="fromDate"
          v-model="fromDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateStart"
              label="From"
              placeholder="MM/DD/YYYY"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateStart"
            no-title
            @input="fromDate = false"
            :min="minDate"
            :max="maxDate"
            :show-current="false"
          ></v-date-picker>
        </v-menu>
      </v-row>
      <v-row justify="center">
        <v-menu
          ref="toDate"
          v-model="toDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateEnd"
              label="To"
              placeholder="MM/DD/YYYY"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateEnd"
            no-title
            @input="toDate = false"
            :min="minDate"
            :max="maxDate"
            :show-current="false"
          ></v-date-picker>
        </v-menu>
      </v-row>
      <v-card-subtitle v-show="!hasThreeMonthsPermission">
        <span v-if="!downloading" data-cy="upgradeAccountText">
          {{ $t('map.historyDialog.upgradeTooltip') }}
        </span>
      </v-card-subtitle>
    </v-card>
    <v-card-actions class="warning-container" text>
      <v-spacer></v-spacer>
      <div v-if="publicOnlyWarning && !downloading" class="hint-text red-font">
        {{ $t('map.historyDialog.exportDialog.publicOnlyWarning') }}
      </div>
      <div v-if="publicMixedWarning && !downloading" class="hint-text red-font">
        {{ $t('map.historyDialog.exportDialog.publicMixedWarning') }}
      </div>
    </v-card-actions>
    <template v-if="!downloading" v-slot:actions>
      <v-btn
        @click="close"
        outlined
        class="black--text"
        text
        data-cy="closeExportData"
        >{{ $t('buttons.close') }}</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        @click="exportCsv"
        class="primary white--text"
        text
        data-cy="exportDataButton"
        :disabled="publicOnlyWarning"
        >{{ $t('buttons.export') }}</v-btn
      >
    </template>
    <template v-else v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="downloading"
        data-cy="closeExportDataDialogButton"
        @click="close"
        class="primary white--text export-data-done-button"
        text
        >{{ $t('buttons.done') }}</v-btn
      >
    </template>
  </base-dialog>
</template>

<script>
import dialog from './Dialog.vue'
import { getTodayDateObject } from '../../services/data-export'
import { SlugsEnum } from '../../permissions/SlugsEnum'
import { getUserAccountId } from '../../helpers/loginas/logInAsHelper'

let DATE_TODAY = getTodayDateObject()
const ONE_DAY_MS = 1000 * 60 * 60 * 24
const SEVEN_DAYS_MS = ONE_DAY_MS * 7
const THIRTY_DAYS_MS = ONE_DAY_MS * 30
const NINETY_DAYS_MS = ONE_DAY_MS * 90

export default {
  name: 'ExportDataDialog',
  props: ['open', 'devices', 'permissions'],
  components: {
    'base-dialog': dialog,
  },
  data() {
    return {
      dateStart: this.formatDate(DATE_TODAY.getTime() - ONE_DAY_MS),
      dateEnd: this.formatDate(DATE_TODAY.getTime()),
      fromDate: false,
      toDate: false,
      downloading: false,
      accountId: null,
    }
  },
  async mounted() {
    this.accountId = await getUserAccountId(this.$auth)
  },
  computed: {
    permissionSet: function () {
      const perms = [
        ...new Set(
          this.devices.map((device) => this.permissions[device.id]).flat(1)
        ),
      ]
      return perms
    },
    maxNumberOfDays: function () {
      return this.hasThreeMonthsPermission
        ? 90
        : this.hasMonthPermission
        ? 30
        : 7
    },
    hasMonthPermission: function () {
      return this.permissionSet.includes(
        SlugsEnum.HistoryChartExecuteExportMonth
      )
    },
    hasThreeMonthsPermission: function () {
      return this.permissionSet.includes(
        SlugsEnum.HistoryChartExecuteExportThreeMonths
      )
    },
    minDate() {
      const minTime = this.hasThreeMonthsPermission
        ? NINETY_DAYS_MS + ONE_DAY_MS
        : this.hasMonthPermission
        ? THIRTY_DAYS_MS + ONE_DAY_MS
        : SEVEN_DAYS_MS + ONE_DAY_MS
      return this.formatDate(DATE_TODAY.getTime() - minTime)
    },
    maxDate() {
      return this.formatDate(DATE_TODAY)
    },
    subscriptionMixedWarning() {
      let found90Days = false
      let found30Days = false
      let foundFreeTier = false
      for (let device of this.devices) {
        const devId = device.id
        const permissions = this.permissions[devId]
        if (permissions) {
          if (
            permissions.includes(SlugsEnum.HistoryChartExecuteExportThreeMonths)
          ) {
            found90Days = true
          } else if (
            permissions.includes(SlugsEnum.HistoryChartExecuteExportMonth)
          ) {
            found30Days = true
          } else {
            const isMine = this.getIsMineByDeviceId(devId)
            const isShared = this.getIsSharedByDeviceId(devId)
            if (isMine || isShared) {
              foundFreeTier = true
            }
          }
        }
      }
      return found90Days + found30Days + foundFreeTier > 1
    },
    publicMixedWarning() {
      let foundMine = false
      let foundPublic = false

      for (let device of this.devices) {
        const isMine = this.getIsMineByDeviceId(device.id)
        const isShared = this.getIsSharedByDeviceId(device.id)

        if (isMine || isShared) {
          foundMine = true
        } else {
          foundPublic = true
        }
      }

      return foundMine && foundPublic
    },
    publicOnlyWarning() {
      let foundMine = false
      let foundPublic = false

      for (let device of this.devices) {
        const isMine = this.getIsMineByDeviceId(device.id)
        const isShared = this.getIsSharedByDeviceId(device.id)
        if (isMine || isShared) {
          foundMine = true
        } else {
          foundPublic = true
        }
      }

      return !foundMine && foundPublic
    },
  },
  watch: {
    open() {
      this.init()
    },
    dateStart() {
      if (new Date(this.dateStart) > new Date(this.dateEnd)) {
        this.dateEnd = this.dateStart
      }
    },
    dateEnd() {
      if (new Date(this.dateStart) > new Date(this.dateEnd)) {
        this.dateStart = this.dateEnd
      }
    },
  },
  methods: {
    init() {
      this.dateStart = this.formatDate(DATE_TODAY.getTime() - ONE_DAY_MS)
      this.dateEnd = this.formatDate(DATE_TODAY.getTime())
    },
    close() {
      this.$emit('close')

      // delay setting download to false to give time for the dialog to close
      setTimeout(() => {
        this.downloading = false
      }, 50)
    },
    async exportCsv() {
      this.downloading = true

      const sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms))
      }

      for (let device of this.devices) {
        const devId = device.id
        const isMine = this.getIsMineByDeviceId(devId)
        const isShared = this.getIsSharedByDeviceId(devId)
        if (isMine || isShared) {
          try {
            const measurements = this.$store.getters[
              'devicemodels/getSupportedMeasurements'
            ](device.model, device.submodel)
            await this.$api.getCSV(
              devId,
              this.getStartDate(this.permissions[devId]),
              this.dateEnd.concat('T23:59:59Z'),
              this.$store.state.userProfile.settings.tempUnit,
              this.$store.state.userProfile.settings.pressureUnit,
              this.$store.state.userProfile.settings.pmUnit,
              this.permissions[devId],
              measurements,
              isShared
            )
          } catch (error) {
            // eslint-disable-next-line
            console.error(error)
          }

          await sleep(1000)
        }
      }
    },
    formatDate(date) {
      return new Date(date).toISOString().substr(0, 10)
    },
    getIsMineByDeviceId(id) {
      return (
        this.accountId ===
        this.$store.getters['devices/getAccountByDeviceId'](id)
      )
    },
    getIsSharedByDeviceId(id) {
      return this.$store.getters['devices/getIsSharedByDeviceId'](id)
    },
    getStartDate(permission) {
      let minTime = SEVEN_DAYS_MS + ONE_DAY_MS
      if (permission) {
        if (
          permission.includes(SlugsEnum.HistoryChartExecuteExportThreeMonths)
        ) {
          minTime = NINETY_DAYS_MS + ONE_DAY_MS
        } else if (
          permission.includes(SlugsEnum.HistoryChartExecuteExportMonth)
        ) {
          minTime = THIRTY_DAYS_MS + ONE_DAY_MS
        }
      }
      const minDate = DATE_TODAY.getTime() - minTime
      if (new Date(this.dateStart).getTime() < minDate) {
        return this.formatDate(minDate)
      } else {
        return this.dateStart
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    // padding: 24px;
    min-height: 350px;
    .v-input {
      padding-top: 24px;
    }
    .form-section-header {
      font-weight: 300;
      font-size: 18px;
      margin-left: -12px;
      opacity: 0.56;
    }
    .message {
      color: rgba(0, 0, 0, 0.56);
    }
  }
  .v-card__subtitle {
    min-height: 30px;
    padding: 16px 12px 8px 12px;
  }
  .v-card__title {
    font-weight: 300;
    padding: 0 0 16px 0;
  }
  .warning-container {
    height: 34px;
  }
  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }
  .hint-text {
    font-size: 12px;
    opacity: 0.76;
  }
  .hint-text.red-font {
    color: red;
  }
}
</style>

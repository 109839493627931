<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
          class="black--text"
          text
          v-on="on"
          data-cy="historyDialogDropdown"
          v-on:focus.native="$event.target.blur()"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <history-menu-items
        :passedinParameter="passedinParameter"
        :selectedDevices="selectedDevices"
        :permissions="permissions"
        @parameterChange="parameterChange"
      />
    </v-menu>
  </div>
</template>

<script>
import HistoryMenuItems from './HistoryMenuItems.vue'

export default {
  name: 'history-menu',
  props: {
    selectedDevices: Array,
    passedinParameter: Number,
    permissions: Object,
  },
  components: { HistoryMenuItems },
  methods: {
    parameterChange(selected) {
      this.$emit('parameterChange', selected)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card__title {
  .v-btn {
    padding: 0;
    min-width: 10px;
    padding-left: 0.4rem;
    padding-right: 0.4rem;

    .v-icon {
      font-size: 22px;
    }
  }
}
</style>

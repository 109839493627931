<template>
  <div class="unregister-actions-row">
    <v-btn
      @click="close"
      outlined
      class="black--text"
      text
      data-cy="unregisterCancelButton"
      :disabled="isUnregisteringDevice"
      >{{ $t('buttons.cancel') }}</v-btn
    >
    <v-spacer></v-spacer>
    <v-btn
      @click="unregisterConfirm"
      class="warning white--text"
      text
      data-cy="unregisterConfirmButton"
      :loading="isUnregisteringDevice"
      :disabled="isUnregisteringDevice"
      >{{ $t('deviceUnregister.unregisterCard.actionButton') }}</v-btn
    >
  </div>
</template>

<script>
import { HTTPStatus } from '../../api'

export default {
  name: 'DeviceUnregisterActions',
  props: {
    deviceId: String,
    useWebsite: { type: Boolean, default: true },
  },
  data() {
    return {
      isUnregisteringDevice: false,
    }
  },
  computed: {
    model() {
      return this.$store.getters['devices/getModelByDeviceId'](this.deviceId)
    },
    serial() {
      return this.$store.getters['devices/getSerialByDeviceId'](this.deviceId)
    },
    hasDataServices() {
      return (
        this.$store.getters['subscriptions/hasDataServicesSubscription'](this.serial)
      )
    },
    hasWebsite() {
      return (
        this.$store.getters['subscriptions/hasWebsiteSubscription'](this.serial) ||
        this.$store.getters['subscriptions/hasFreeTierSubscription'](this.serial)
      )
    },
    hasWebsiteAndDataServices() {
      return this.hasWebsite && this.hasDataServices
    },
    onAPIPage() {
      return this.$route.name === 'api-services'
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async unregisterDevice() {
      let resp
      let didUnregister = false
      let deleteFromStore = false

      if (this.hasWebsiteAndDataServices) {
        //if you have both types of subscriptions
        //then unsubscribe from the one you want
        resp = await this.$api.patchDeviceSubscription({
          model: this.model,
          serial: this.serial,
          delete: true,
          isExternal: !this.useWebsite,
        })
        didUnregister = resp.status == HTTPStatus.NoContent
      } else {
        //unregister the device and flag to delete from Vuex store
        resp = await this.$api.postUnregisterDevice(this.deviceId)
        didUnregister = resp.status == HTTPStatus.NoContent
        if (didUnregister) {
          deleteFromStore = true
        }
      }

      return {
        didUnregister,
        deleteFromStore
      }
    },
    async updateStore(didUnregister, deleteFromStore) {
      //if an update is required
      if (didUnregister) {
        if (this.$router?.currentRoute?.name === 'manage-devices') {
          await this.$store.dispatch('devices/setAllUserDevices')
          await this.$store.dispatch('devices/setAllDevices')
        } else {
          await this.$store.dispatch('devices/setAllDevices')
        }

        const allDevices = this.$auth.isAuthenticated
          ? this.$store.state.devices.allDevices
          : []
        // if device is to be deleted from store,
        // delete from allDevices to prevent 404 response
        // from subscriptions API
        if (deleteFromStore) {
          delete allDevices[this.deviceId]
        }
        await this.$store.dispatch('subscriptions/updateSubscriptions', {
          auth: this.$auth,
          api: this.$api,
          devices: allDevices,
        })

        // deletion from store is deferred until now
        // to prevent parent component
        // from being removed prematurely
        if (deleteFromStore) {
          this.$store.dispatch('devices/deleteDeviceById', this.deviceId)
        }
      }
    },
    async unregisterConfirm() {
      this.isUnregisteringDevice = true
      this.$root.$emit('device-unregistering', true)
      const { didUnregister, deleteFromStore } = await this.unregisterDevice()

      await this.updateStore(didUnregister, deleteFromStore)

      this.isUnregisteringDevice = false
      this.$root.$emit('device-unregistering', false)
      if (didUnregister) {
        this.$emit('unregister')
      }

      this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.unregister-actions-row {
  display: flex;
  width: 100%;

  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }
}
</style>

<template>
  <base-dialog
    :open="open"
    :title="$t('map.deviceAlerts.title')"
    @close="close"
    data-cy="deviceAlertsDialog"
    :fullscreen="$vuetify.breakpoint.xs"
    :persistentOverlay="true"
  >
    <!-- Delete header -->
    <template v-slot:header v-if="deleteViewOpen">
      <v-card-title data-cy="deleteAlertHeader">
        <v-icon class="header-icon">mdi-delete</v-icon>
        {{ $t('map.deviceAlerts.delete.title') }}
      </v-card-title>
    </template>
    <!-- List of alerts view header -->
    <template
      v-slot:header
      v-else-if="!updateViewOpen && !isEnableNotification"
    >
      <v-card-title data-cy="alertSetupHeader">
        <v-icon class="header-icon">mdi-bell</v-icon>
        {{ $t('map.deviceAlerts.title') }}
      </v-card-title>
    </template>
    <template
      v-slot:header
      v-else-if="updateViewOpen && editingAlert && !isEnableNotification"
    >
      <v-card-title>
        <v-icon class="header-icon">mdi-pencil</v-icon>
        {{ $t('map.deviceAlerts.editTitle') }}
      </v-card-title>
    </template>
    <template
      v-slot:header
      v-else-if="updateViewOpen && addingAlert && !isEnableNotification"
    >
      <v-card-title>
        <v-icon class="header-icon">mdi-bell-plus</v-icon>
        {{ $t('map.deviceAlerts.addTitle') }}
      </v-card-title>
    </template>
    <template
      v-slot:header
      v-else-if="isEnableNotification && !isNotificationsTitle"
    >
      <v-card-title>
        <v-icon class="header-icon">mdi-bell-plus</v-icon>
        {{ $t('map.deviceAlerts.enableNotificationTitle') }}
      </v-card-title>
    </template>
    <template v-slot:header v-else-if="isNotificationsTitle">
      <v-card-title>
        <v-icon class="header-icon">mdi-bell-plus</v-icon>
        {{ $t('map.deviceAlerts.notificationTitle') }}
      </v-card-title>
    </template>
    <!-- Add alert view header -->
    <template v-slot:header v-else-if="addViewOpen">
      <v-card-title data-cy="addTitle">
        <v-icon class="header-icon">mdi-bell-plus</v-icon>
        {{ $t('map.deviceAlerts.addTitle') }}
      </v-card-title>
    </template>
    <!-- delete message -->
    <div v-if="deleteViewOpen">
      <v-card flat>{{ $t('map.deviceAlerts.delete.message') }}</v-card>
    </div>
    <!-- progress spinner -->
    <div
      v-else-if="!updateViewOpen && alerts === null && !isEnableNotification"
      class="text-center emptyMessage"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        data-cy="alertLoadingSpinner"
      ></v-progress-circular>
    </div>
    <!-- empty message -->
    <div
      v-else-if="
        !updateViewOpen && alerts.length === 0 && !isEnableNotification
      "
      class="text-center emptyMessage"
      data-cy="emptyAlertMessage"
    >
      {{ $t('map.deviceAlerts.empty') }}
    </div>
    <v-form
      v-else-if="!updateViewOpen && alerts.length > 0 && !isEnableNotification"
    >
      <v-list-item
        v-for="(alert, index) in alerts"
        :key="index"
        :style="
          index === alerts.length - 1
            ? 'padding-bottom: 8px; padding-right: 0;'
            : 'border-bottom: 1px solid rgba(0,0,0,0.26); padding-bottom: 8px; margin-bottom: 16px; padding-right: 0;'
        "
      >
        <v-list-item-content style="padding-top: 0">
          <v-card-actions
            style="padding-left: 0; margin-top: 0; padding-right: 0px"
          >
            <v-list-item-title style="font-size: 17px" data-cy="listTitle">
              {{ alert.title }}
            </v-list-item-title>
            <v-spacer />
            <template v-if="!$vuetify.breakpoint.xs">
              <v-btn
                @click="startAlertEdit(alert)"
                class="icon-button"
                text
                data-cy="editIcon"
              >
                <v-icon left light>mdi-pencil</v-icon>
                <div>
                  {{ $t('buttons.edit') }}
                </div>
              </v-btn>
              <v-btn
                @click="confirmDeleteAlert(alert.id)"
                class="icon-button"
                text
                data-cy="deleteIcon"
              >
                <v-icon left light>mdi-delete</v-icon>
                <div data-cy="">
                  {{ $t('buttons.delete') }}
                </div>
              </v-btn>
            </template>
          </v-card-actions>
          <br />
          <br />
          <v-list-item-subtitle>
            <span data-cy="listCreationDate"
              >{{ $t('map.deviceAlerts.fields.creationDate') }}:
            </span>
            <span data-cy="listCreationDateData">
              {{ new Date(alert.createdAt).toLocaleString() }}</span
            >
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <span data-cy="listAlertEnabled"
              >{{ $t('map.deviceAlerts.fields.enabled') }}:
            </span>
            <span data-cy="listAlertEnabledData">
              {{ alert.enabled ? $t('boolean.yes') : $t('boolean.no') }}
            </span>
          </v-list-item-subtitle>
          <v-list-item-subtitle data-cy="listAlertLevel">
            <span>{{ $t('map.deviceAlerts.fields.level') }}: </span>
            <span v-if="alert.severity === 'standard'">
              {{ $t('map.deviceAlerts.fields.levels.standard') }}
            </span>
            <span v-else-if="alert.severity === 'warning'">{{
              $t('map.deviceAlerts.fields.levels.warning')
            }}</span>
            <span v-else-if="alert.severity === 'critical'">{{
              $t('map.deviceAlerts.fields.levels.critical')
            }}</span>
            <span v-else>{{ $t('map.deviceAlerts.fields.levels.none') }}</span>
          </v-list-item-subtitle>
          <span>
            <v-list-item-subtitle>
              <span data-cy="listAlertSensor"
                >{{ $t('map.deviceAlerts.fields.sensor') }}:
              </span>
              <span v-if="alert.condition.field">
                {{
                  $t(`map.deviceAlerts.fields.sensors.${alert.condition.field}`)
                }}
              </span>
              <span v-else>
                {{ $t('map.deviceAlerts.fields.sensors.none') }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle data-cy="listAlertCondition">
              <span v-if="alert.condition.operator === 'lt'">
                <span>{{ $t('map.deviceAlerts.fields.condition') }}: </span>
                <i18n path="map.deviceAlerts.fields.conditions.lt" tag="span">
                  <template v-slot:value>
                    <span>{{ alert.condition.value }}</span>
                  </template>
                </i18n>
              </span>
              <span v-else-if="alert.condition.operator === 'lte'">
                <span>{{ $t('map.deviceAlerts.fields.condition') }}: </span>
                <i18n path="map.deviceAlerts.fields.conditions.lte" tag="span">
                  <template v-slot:value>
                    <span>{{ alert.condition.value }}</span>
                  </template>
                </i18n>
              </span>
              <span v-else-if="alert.condition.operator === 'eq'">
                <span>{{ $t('map.deviceAlerts.fields.condition') }}: </span>
                <i18n path="map.deviceAlerts.fields.conditions.eq" tag="span">
                  <template v-slot:value>
                    <span>{{ alert.condition.value }}</span>
                  </template>
                </i18n>
              </span>
              <span v-else-if="alert.condition.operator === 'gte'">
                <span>{{ $t('map.deviceAlerts.fields.condition') }}: </span>
                <i18n path="map.deviceAlerts.fields.conditions.gte" tag="span">
                  <template v-slot:value>
                    <span>{{ alert.condition.value }}</span>
                  </template>
                </i18n>
              </span>
              <span v-else-if="alert.condition.operator === 'gt'">
                <span>{{ $t('map.deviceAlerts.fields.condition') }}: </span>
                <i18n path="map.deviceAlerts.fields.conditions.gt" tag="span">
                  <template v-slot:value>
                    <span>{{ alert.condition.value }}</span>
                  </template>
                </i18n>
              </span>
              <span v-else>
                <span>{{ $t('map.deviceAlerts.fields.condition') }}: </span>
                <span>{{ $t('map.deviceAlerts.fields.conditions.none') }}</span>
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle data-cy="listFieldDeliverTo">
              <span>{{ $t('map.deviceAlerts.fields.deliverTo') }}: </span>
              <span v-if="alert.sendSms && phone && phone.number">
                <span>{{ $t('map.deviceAlerts.fields.mobile') }}</span>
                <span v-if="$vuetify.breakpoint.smAndUp">
                  ({{ phone.number }})
                </span>
              </span>
              <span v-if="alert.sendSms && alert.sendEmail">, </span>
              <span v-if="alert.sendEmail">
                <span>{{ $t('map.deviceAlerts.fields.email') }}</span>
                <span v-if="$vuetify.breakpoint.smAndUp">
                  ({{ $store.state.userProfile.user.email }})
                </span>
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle data-cy="listFieldMessage">
              <span>{{ $t('map.deviceAlerts.fields.message') }}: </span>
              <span>{{ alert.userMessage }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <span>{{ $t('map.deviceAlerts.fields.frequency') }}: </span>
              <span>{{ getFrequency(alert.frequencyType) }}</span>
            </v-list-item-subtitle>
          </span>
          <v-card-actions
            style="margin-top: 0px; padding-right: 0px"
            v-if="$vuetify.breakpoint.xs"
          >
            <v-spacer />
            <v-btn @click="startAlertEdit(alert)" class="icon-button" text>
              <v-icon left light>mdi-pencil</v-icon>
              <div>
                {{ $t('buttons.edit') }}
              </div>
            </v-btn>
            <v-btn
              @click="confirmDeleteAlert(alert.id)"
              class="icon-button"
              text
            >
              <v-icon data-cy="deleteButton" left light>mdi-delete</v-icon>
              <div>
                {{ $t('buttons.delete') }}
              </div>
            </v-btn>
          </v-card-actions>
        </v-list-item-content>
      </v-list-item>
    </v-form>
    <!-- add view -->
    <update-alert
      v-else-if="updateViewOpen"
      v-bind:currentAlert="currentAlert"
      :rules="rules"
      :isNotificationEnabled="isNotificationBtnEnabled"
      :isEditingAlert="editingAlert"
      :isSmsDisabled="isSmsDisabled"
      :isEmailDisabled="isEmailDisabled"
      :phone="phone"
      :deviceId="deviceId"
      :measurements="sensors"
      :helpText="helpText"
      @toggleNotification="toggleNotification"
      @onDisclaimerAgree="toggleNotificationsTitle"
    />

    <template v-slot:actions>
      <!-- Delete actions -->
      <template v-if="deleteViewOpen">
        <v-btn
          @click="cancelDeleteAlert()"
          outlined
          class="black--text"
          text
          data-cy="deleteCancelButton"
        >
          {{ $t('buttons.cancel') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="deleteAlert()"
          :class="{ disabled: alerts === null }"
          :disabled="alerts === null"
          class="primary white--text"
          text
          data-cy="deleteAlertButton"
        >
          {{ $t('buttons.delete') }}
        </v-btn>
      </template>
      <!-- Default actions -->
      <template v-else-if="!updateViewOpen && !isEnableNotification">
        <v-tooltip bottom :disabled="hasRemainingAlerts">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="startAlertAdd"
              outlined
              class="black--text"
              text
              data-cy="addButton"
              :class="[{ restrictedContent: !hasRemainingAlerts }]"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t('buttons.add') }}
            </v-btn>
          </template>
          <span>
            {{ $t('map.deviceAlerts.upgradeTooltip') }}
          </span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn
          @click="close"
          class="primary white--text"
          text
          data-cy="doneButton"
        >
          {{ $t('buttons.done') }}
        </v-btn>
      </template>
      <!-- update actions -->
      <template v-else-if="updateViewOpen && !isEnableNotification">
        <v-btn
          @click="closeUpdateView()"
          outlined
          class="black--text"
          text
          data-cy="cancelAddButton"
        >
          {{ $t('buttons.cancel') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="updateAlert"
          class="primary white--text"
          :disabled="!fieldsValid"
          text
          data-cy="saveButton"
        >
          {{ $t('buttons.save') }}
        </v-btn>
      </template>
    </template>
  </base-dialog>
</template>

<script>
import dialog from './Dialog'
import { SlugsEnum } from '../../permissions/SlugsEnum'
import UpdateAlert from '../alerts/UpdateAlert.vue'
import { measurementAlertPermissions } from '../../services/device-measurements'
const frequencyType = {
  once: 'onceCrossed',
}
const ValidNum = /^-?(0|[1-9]\d*)(\.\d+)?$/

export default {
  name: 'DeviceAlertsDialog',
  props: {
    open: Boolean,
    deviceId: String,
    permissions: Array,
  },
  components: {
    'base-dialog': dialog,
    'update-alert': UpdateAlert,
  },
  data() {
    return {
      deleteViewOpen: false,
      updateViewOpen: false,
      addingAlert: false,
      editingAlert: false,
      addViewOpen: false,
      currentAlert: {
        title: '',
        userMessage: '',
        enabled: true,
        severity: 'standard',
        condition: {
          field: '',
          type: 'number',
          operator: 'gte',
          value: '',
        },
        frequencyType: frequencyType.once,
        sendSms: false,
        sendEmail: false,
      },
      alertObjects: null,
      isNotificationBtnEnabled: false,
      isEnableNotification: false,
      phone: null,
      userDetails: {},
      isSmsDisabled: false,
      isEmailDisabled: false,
      isNotificationsTitle: false,
    }
  },
  mounted() {
    this.setAlerts()
  },
  watch: {
    open: function () {
      this.setAlerts()
    },
    userData(user) {
      if (user) {
        this.handleDeliveryMethods()
      }
    }
  },
  computed: {
    alerts() {
      // TODO: keep the computed value, remove alertObjects
      return this.alertObjects
    },
    fieldsValid() {
      if (
        this.rules.required(this.currentAlert.title) !== true ||
        this.rules.required(this.currentAlert.condition.value) !== true ||
        this.rules.threshold(this.currentAlert.condition.value) !== true ||
        this.rules.precision(this.currentAlert.condition.value) !== true ||
        this.rules.validNum(this.currentAlert.condition.value) !== true ||
        this.rules.required(this.currentAlert.condition.field) !== true
      ) {
        return false
      }
      return true
    },
    hasRemainingAlerts() {
      let maxAlerts = 0;
      if (this.permissions.includes(SlugsEnum.AlertsWrite)) {
        maxAlerts = Number.MAX_SAFE_INTEGER
      } else if (this.permissions.includes(SlugsEnum.AlertsWriteUpTo1)) {
        maxAlerts = 1
      }
      if (this.alertObjects) {
        return this.alertObjects.length < maxAlerts
      }
      return false
    },
    allowedRangeLimits() {
      return this.$store.getters['devicemodels/getAllowedRangeLimits'](
        this.device.model,
        this.device.submodel
      )
    },
    calibrationLimits() {
      return this.$store.getters['devicemodels/getCalibrationLimits'](
        this.device.model,
        this.device.submodel
      )
    },
    allowedRangePlusOffset() {
      const ranges = {}
      this.permittedSensors.forEach((measurement) => {
        // starting range for any measurement is min = 0 with no max
        let range = {
          min: 0,
          max: null,
        }
        const name = this.device.sensorMetaData[measurement].shortName

        // apply allowed range limits for measurement
        const limits = this.allowedRangeLimits[name]
        if (limits) {
          range = { ...range, ...limits }
        }

        // apply allowed user calibration offset limits to range
        const offset = this.calibrationLimits[name]?.offset
        if (offset) {
          range.min += offset.min
          if (range.max) {
            range.max += offset.max
          }
        }

        ranges[name] = range
      })
      return ranges
    },
    rules() {
      return {
        required: (value) =>
          !!value || value === 0 || this.$t('validation.required'),
        threshold: (value) => {
          const { operator, field } = this.currentAlert.condition
          const invalidEntry = this.$t('validation.invalidEntry')

          if (field) {
            const floatVal = parseFloat(value)
            const { shortName } = this.device.sensorMetaData[field]
            const { min, max } = this.allowedRangePlusOffset[shortName]

            // test whether value is in allowed range
            const valueInRange = this.valueInRange(floatVal, min, max)
            if (!valueInRange) {
              return invalidEntry
            }

            // test whether value is valid with chosen operator
            const validWithOperator = this.validWithOperator(
              floatVal,
              min,
              max,
              operator
            )
            if (!validWithOperator) {
              return invalidEntry
            }
          }

          return true
        },
        validNum: (value) => {
          return ValidNum.test(value) || this.$t('validation.invalidEntry')
        },
        precision: (value) => {
          const { field } = this.currentAlert.condition
          const fieldDetails = this.device.sensorMetaData[field]

          const valueArray = value.toString().split('.')
          if (valueArray.length > 1) {
            return (
              valueArray[1].length <= fieldDetails.precision ||
              this.$t('validation.invalidEntry')
            )
          }
          return true
        },
      }
    },
    helpText() {
      let message = ''
      const { field } = this.currentAlert.condition

      if (field) {
        const { precision, shortName } = this.device.sensorMetaData[field]
        const { min, max } = this.allowedRangePlusOffset[shortName]
        const measurementName = this.$t(
          `map.deviceAlerts.fields.sensors.${field}`
        )
        const decimalPlacesText = this.$tc(
          'map.deviceSettings.calibrationFactors.decimalPlaces',
          precision
        )

        if (max === null) {
          message = this.$t('map.deviceAlerts.fields.helpTextNoMax', {
            measurement: measurementName,
            rangeLow: min,
            decimalPlaces: decimalPlacesText,
          })
        } else {
          message = this.$t('map.deviceAlerts.fields.helpText', {
            measurement: measurementName,
            rangeLow: min,
            rangeHigh: max,
            decimalPlaces: decimalPlacesText,
          })
        }
        const unitNoteKey = 'map.deviceAlerts.fields.helpTextUnitNotes.' + shortName
        if (this.$te(unitNoteKey)) {
          message = `${message} ${this.$t(unitNoteKey)}`
        }
      }

      return message
    },
    device() {
      const model = this.$store.getters['devices/getModelByDeviceId'](
        this.deviceId
      )
      return {
        id: this.deviceId,
        model: model,
        submodel: this.$store.getters['devices/getSubmodelByDeviceId'](
          this.deviceId
        ),
        isMine: this.$store.getters['devices/getAccountByDeviceId'](
          this.deviceId
        ),
        isShared: this.$store.getters['devices/getIsSharedByDeviceId'](
          this.deviceId
        ),
        sensorMetaData:
          this.$store.getters['devicemodels/getTelemetryFieldsByModel'](model),
      }
    },
    permittedSensors() {
      const alertableSensors = this.$store.getters[
        'devicemodels/getAlertableMeasurements'
      ](this.device.model, this.device.submodel)

      // filter out gases that user does not have permission for
      return alertableSensors.filter((measurementName) => {
        const sensorMetaData = this.device.sensorMetaData[measurementName]
        let sensorPermission
        if (sensorMetaData) {
          sensorPermission = measurementAlertPermissions[sensorMetaData.shortName]
        }
        // Assume gas names with no matching slugs do not require permission to access, defined gas permissions return false if user does not have access
        const permitted = sensorPermission ? this.permissions.includes(sensorPermission) : true
        return permitted
      })
    },
    sensors() {
      return this.permittedSensors.map((measurement) => {
        const sensorMetaData = this.device.sensorMetaData[measurement]
        if (sensorMetaData) {
          return {
            value: measurement,
            text: this.$t(`map.deviceAlerts.fields.sensors.${measurement}`),
          }
        }
      })
    },
    userData() {
      return this.$store.state?.userProfile?.user
    }
  },
  methods: {
    valueInRange(value, min, max) {
      return max ? value >= min && value <= max : value >= min
    },
    validWithOperator(value, min, max, operator) {
      let isValid = true
      if (operator === 'lt') {
        isValid = value > min
      }
      if (operator === 'lte') {
        isValid = value >= min
      }
      if (operator === 'gt' && max !== null) {
        isValid = value < max
      }
      if (operator === 'gte' && max !== null) {
        isValid = value <= max
      }
      return isValid
    },
    async handleDeliveryMethods() {
      await this.getPrimaryPhone()
      this.isNotificationBtnEnabled = await !(
        this.phone?.allowSmsNotifications ||
        this.phone?.smsNotificationsPending ||
        this.userData?.allowEmailNotifications
      )
      this.isSmsDisabled = await !(
        this.phone?.allowSmsNotifications || this.phone?.smsNotificationsPending
      )
      this.isEmailDisabled = await !this.userData?.allowEmailNotifications
    },
    close() {
      this.$emit('close')
      this.closeUpdateView()
    },
    closeUpdateView() {
      // delay to give time for the closing animation
      setTimeout(() => {
        this.updateViewOpen = false
        this.addingAlert = false
        this.editingAlert = false
        this.isNotificationBtnEnabled = false
        this.isEnableNotification = false
        this.isNotificationsTitle = false
        this.clearCurrentAlert()
        this.setAlerts()
      }, 100)
    },
    setAlerts() {
      if (this.open) {
        this.$api
          .getAlerts(this.deviceId)
          .then((res) => {
            if (res.ok) {
              res.json().then((body) => {
                this.alertObjects = body
              })
            } else {
              this.alertObjects = []
            }
          })
          .catch(() => {
            this.alertObjects = []
          })
      }
    },
    async updateAlert() {
      if (this.addingAlert) await this.addAlert()
      if (this.editingAlert) await this.editAlert()

      this.setAlerts()
    },
    async addAlert() {
      const newAlert = this.currentAlert
      this.currentAlert.condition.value = parseFloat(
        this.currentAlert.condition.value
      )
      const condition = this.currentAlert.condition
      newAlert.condition = condition
      this.alertObjects = null
      this.updateViewOpen = false
      this.addingAlert = false

      this.$api
        .postAlert(newAlert, this.deviceId)
        .then(() => {
          this.setAlerts()
          this.clearCurrentAlert()
        })
        .catch(() => {
          this.setAlerts()
          this.clearCurrentAlert()
        })
    },
    deleteAlert() {
      if (this.alertSelectedForDeletion) {
        this.alertObjects = null

        this.$api
          .deleteAlert(this.deviceId, this.alertSelectedForDeletion)
          .then(() => {
            this.deleteViewOpen = false
            this.setAlerts()
          })
      }
    },
    async editAlert() {
      const uAlert = {}
      uAlert.condition = {}
      uAlert.condition.value = parseFloat(this.currentAlert.condition.value)
      uAlert.enabled = this.currentAlert.enabled
      uAlert.severity = this.currentAlert.severity
      uAlert.userMessage = this.currentAlert.userMessage
      uAlert.sendSms = this.currentAlert.sendSms
      uAlert.sendEmail = this.currentAlert.sendEmail
      uAlert.frequencyType = this.currentAlert.frequencyType
      const uId = this.currentAlert.id

      this.updateViewOpen = false
      this.editingAlert = false

      this.$api
        .patchAlert(uAlert, this.deviceId, uId)
        .then(() => {
          this.setAlerts()
          this.clearCurrentAlert()
        })
        .catch(() => {
          this.setAlerts()
          this.clearCurrentAlert()
        })
    },
    startAlertAdd() {
      if (this.hasRemainingAlerts) {
        this.updateViewOpen = true
        this.addingAlert = true
        this.handleDeliveryMethods()
      }
    },
    startAlertEdit(alert) {
      const eAlert = { ...{}, ...alert }
      eAlert.condition = alert.condition
      this.currentAlert = eAlert
      this.updateViewOpen = true
      this.editingAlert = true
      this.handleDeliveryMethods()
    },
    confirmDeleteAlert(id) {
      this.deleteViewOpen = true
      this.alertSelectedForDeletion = id
    },
    cancelDeleteAlert() {
      this.deleteViewOpen = false
      this.alertSelectedForDeletion = null
    },
    clearCurrentAlert() {
      this.currentAlert = {
        title: '',
        userMessage: '',
        enabled: true,
        severity: 'standard',
        condition: {
          field: '',
          operator: 'gte',
          type: 'number',
          value: '',
        },
        frequencyType: frequencyType.once,
        sendEmail: false,
        sendSms: false,
      }
    },
    toggleNotification() {
      this.isEnableNotification = !this.isEnableNotification
      this.isNotificationBtnEnabled = false
      this.isNotificationsTitle = false

      this.getPrimaryPhone()
    },
    toggleNotificationsTitle() {
      this.isNotificationsTitle = !this.isNotificationsTitle
    },
    async getPrimaryPhone() {
      const hasUserProfile = this.$store.hasModule('userProfile')
      if (hasUserProfile) {
        const resp = await this.$store.dispatch(
          'userProfile/getPhones', {
            accountsAPIHost: process.env.VUE_APP_ACCOUNTS_HOST,
            loggedInAsUser: this.getLoggedInAsUser()
          })
        //TODO should we share the isOkResponse method out of components somehow? Current not accessible
        if (resp.status >= 200 && resp.status <= 299) {
          const phones = resp.data
          this.phone = null

          if (phones) {
            this.phone = phones.find((phone) => phone.isPrimary)
          }
        } else {
          this.phone = null
        }
      }
    },
    getFrequency(frequency) {
      return this.$t(`map.deviceAlerts.fields.frequencyTypes.${frequency}`)
    },
    getLoggedInAsUser() {
      return this.$store.getters['loginas/getImpersonatedUser']
    },
  },
}
</script>

<style lang="scss" scoped>
.restrictedContent {
  cursor: not-allowed;
  opacity: 0.36;
}
.v-dialog {
  .v-card {
    padding: 16px;
  }
  .v-card__title {
    font-size: 24px;
    font-weight: 300;
    padding: 8px 24px 16px 16px;
    .header-icon {
      color: rgba(0, 0, 0, 0.76);
      margin-right: 16px;
    }
  }
  .v-card__subtitle {
    padding: 8px 16px 8px 8px;
  }

  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }

  .v-btn.icon-button {
    min-width: 120px !important;
    width: 120px !important;
    // text-indent: -30px;
  }
  .text-dark {
    color: black;
  }
  .row {
    padding-left: 0;
  }
  .v-card__actions {
    margin-top: 24px;
  }
}

.emptyMessage {
  height: 240px;
  line-height: 240px;
}

.f-14 {
  font-size: 16px;
}
</style>

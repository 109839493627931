<template>
  <v-list :class="addDeviceOptionsClass" v-click-outside="onClickOutside" :max-width="200">
    <v-card>
      <v-list-group v-model="expandAddDeviceOptions" :prepend-icon="titleIcon">
        <template v-slot:activator>
          <v-list-item-title>{{ titleText }}</v-list-item-title>
        </template>
        <v-list-item v-for="(item, i) in options" :key="i" @click="selectOption(item.action)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-card>
  </v-list>
</template>

<script>

export default {
  name: 'DropdownMenu',
  props: {
    titleText: String,
    titleIcon: String,
    options: Array,
    onClickHandler: Function,
    closeAfterClick: Boolean,
    closeOnClickOutside: Boolean
  },
  data() {
    return  {
      expandAddDeviceOptions: false,
    }
  },
  methods: {
    selectOption (action) {
      if (typeof this.onClickHandler === 'function') {
        this.onClickHandler(action)
      }
      if (this.closeAfterClick) {
        this.expandAddDeviceOptions = false
      }
    },
    onClickOutside () {
      if (this.closeOnClickOutside && this.expandAddDeviceOptions) {
        this.expandAddDeviceOptions = false
      }
    },
  },
  computed: {
    addDeviceOptionsClass() {
      return this.expandAddDeviceOptions ? '' : 'dropdown-options'
    }
  }

}
</script>


<style lang="scss" scoped>
@import '@/assets/global-variables.scss';

.dropdown-options {
  .v-card {
    box-shadow: none !important;
  }
}

.v-list::v-deep {
  height: 70px;

  .v-list-group__items {
    background-color: $white-color;
    position: relative;
    z-index: 1;
  }

  .v-list-group__header {
    &::before {
      opacity: 0 !important;
    }
  }

  .v-list-item {
    padding: 0 8px;
  }

  .v-list-item--active {
    color: $white-color;
    background-color: $deep-sky-blue-color;
  }

  .v-list-item__icon {
    min-width: unset !important;

    &:first-child {
      margin-right: 16px;
    }
  }

  .v-list-item__title {
    flex: unset;
  }
}
</style>

<template>
  <v-dialog
    @input="close"
    v-model="open"
    :content-class="dialogContentClass"
    :hide-overlay="isDetachedDialog"
    :persistent="true"
    :no-click-animation="isDetachedDialog"
    data-cy="historyDialog"
    :fullscreen="$vuetify.breakpoint.xs || fullscreen"
    :max-width="isDetachedDialog ? 320 : 600"
    max-height="800"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind="on"></slot>
    </template>
    <v-card>
      <v-card-title>
        <history-menu
          :selectedDevices="selectedDevices"
          :permissions="permissions"
          :passedinParameter.sync="selectedParameter"
          @parameterChange="parameterChange"
        />
        <div class="history-title">{{ $t('map.historyDialog.title') }}</div>
        <v-spacer></v-spacer>
        <div class="popoverIcons">
          <span v-if="!$vuetify.breakpoint.xs">
            <v-btn
              @click="toggleFullscreen"
              text
              data-cy="toggleFullscreenHistoryDialog"
            >
              <v-icon v-if="fullscreen" color="grey">
                mdi-fullscreen-exit
              </v-icon>
              <v-icon v-else color="grey">mdi-fullscreen</v-icon>
            </v-btn>
          </span>
          <span>
            <v-btn @click="close" text data-cy="closeHistoryDialog">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </span>
        </div>
      </v-card-title>
      <history-graph
        :open="isOpen"
        :isFullscreen="isFullscreen"
        :isDetachedDialog="isDetachedDialog"
        :removableDevices="removableDevices"
        :selectedDevices="selectedDevices"
        :selectedParameter="selectedParameter"
        :permissions="permissions"
        :accountId="accountId"
        @close="close"
        @removeDevice="removeDevice"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { getPermissionsByDeviceId } from '../../services/subscriptions'
import { getUserAccountId } from '../../helpers/loginas/logInAsHelper'
import HistoryGraph from '../history/HistoryGraph.vue'
import HistoryMenu from '../history/HistoryMenu.vue'
import measurementOptionsMixin from '../../mixins/widgets/measurementOptionsMixin'

const MAX_DEVICES = 5

export default {
  name: 'historyDialog',
  components: { 'history-graph': HistoryGraph, 'history-menu': HistoryMenu },
  props: {
    open: Boolean,
    currentDeviceId: String,
    isDetachedDialog: Boolean,
    removableDevices: Boolean,
  },
  mixins: [measurementOptionsMixin],
  data() {
    return {
      isOpen: false,
      fullscreen: false,
      defaultParameter: this.$store.getters['map/getMeasurementType'],
      deviceIds: [],
      accountId: null,
    }
  },
  async mounted() {
    this.accountId = await getUserAccountId(this.$auth)
    this.updateDeviceList()
    this.init()
  },
  watch: {
    currentDeviceId() {
      this.updateDeviceList()
    },
    open() {
      this.init()
    },
    allDevices() {
      this.checkDeviceAccess()
    },
  },
  computed: {
    allDevices() {
      return this.$store.state.devices.allDevices
    },
    isFullscreen: function () {
      return this.windowWidth < 500 || this.fullscreen
    },
    dialogContentClass: function () {
      return this.$vuetify.breakpoint.xs ||
        this.fullscreen ||
        !this.isDetachedDialog
        ? ''
        : 'history-dialog'
    },
    permissions: function () {
      let perms = {}
      for (const device of this.selectedDevices) {
        const deviceId = device.id
        perms[deviceId] = getPermissionsByDeviceId(deviceId)
      }
      return perms
    },
  },
  methods: {
    init() {
      // Note: nextTick is required, so that HistoryGraph is initalized with
      // open set to false, and then changed to true. This allows the graph to
      // know when it is opened and to load data
      Vue.nextTick(() => {
        this.isOpen = this.open
      })
    },
    close() {
      this.$emit('close')
      if (this.fullscreen) {
        this.toggleFullscreen()
      }
      // clear out list of selected devices
      this.deviceIds = []
    },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen
    },
    parameterChange(newValue) {
      this.selectedParameter = newValue
    },
    removeDevice(deviceIndex) {
      this.deviceIds.splice(deviceIndex, 1)
    },
    checkDeviceAccess() {
      if (Object.keys(this.allDevices).length > 0) {
        this.selectedDevices.forEach((item, index) => {
          const device = this.allDevices[item.id]
          if (!device) {
            if (this.selectedDevices.length === 1) {
              this.close()
            } else {
              this.removeDevice(index)
            }
          }
        })
      }
    },
    updateDeviceList() {
      if (
        this.currentDeviceId !== null &&
        this.deviceIds.length < MAX_DEVICES
      ) {
        // only add unique device ids to selected devices
        let duplicate = false
        for (const deviceId of this.deviceIds) {
          if (deviceId === this.currentDeviceId) {
            duplicate = true
            break
          }
        }

        if (!duplicate) {
          this.isLoading = true
          this.deviceIds.push(this.currentDeviceId)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@media (min-width: 400px) {
  .history-dialog {
    position: absolute;
    right: 10px;
    top: 70px;
  }
}
</style>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    padding: 0 !important;
  }
  .v-card__title {
    padding: 0;
    font-size: 15px;
    .history-title {
      margin-right: 4px;
      margin-left: 4px;
    }
  }
}
</style>

<template>
  <base-dialog
    @close="close"
    :open="open"
    :title="$t('subscriptionCancel.cancelCard.title')"
    data-cy="cancelSubscriptionDialog"
    :fullscreen="$vuetify.breakpoint.xs"
    :persistentOverlay="true"
  >
    <subscription-cancel-card />
    <template v-slot:actions>
      <subscription-cancel-actions
        :activationId="activationId"
        @close="close"
      />
    </template>
  </base-dialog>
</template>

<script>
import Dialog from '../dialogs/Dialog.vue'
import SubscriptionCancelCard from './SubscriptionCancelCard.vue'
import SubscriptionCancelActions from './SubscriptionCancelActions.vue'

export default {
  name: 'SubscriptionCancelDialog',
  props: {
    open: Boolean,
    activationId: String,
  },
  components: {
    'base-dialog': Dialog,
    'subscription-cancel-card': SubscriptionCancelCard,
    'subscription-cancel-actions': SubscriptionCancelActions,
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card__title.tab-content__title {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0 0 16px 0;
    opacity: 0.56;
  }
}
</style>

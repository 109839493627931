export const SlugsEnum = Object.freeze({
  AdminPanel: 'tsi:webapp:Administration:execute_sidebar_icon',
  AlertSetupCreateAlertCH2O: 'tsi:webapp:alert_setup:create_alert:CH2O',
  AlertSetupCreateAlertCO: 'tsi:webapp:alert_setup:create_alert:CO',
  AlertSetupCreateAlertNO2: 'tsi:webapp:alert_setup:create_alert:NO2',
  AlertSetupCreateAlertO3: 'tsi:webapp:alert_setup:create_alert:O3',
  AlertSetupCreateAlertPM10_PM25_Temp_RH: 'tsi:webapp:alert_setup:create_alert:PM2.5_PM10_Temp_RH',
  AlertSetupCreateAlertSO2: 'tsi:webapp:alert_setup:create_alert:SO2',
  AlertSetupCreateAlertTVOC: 'tsi:webapp:alert_setup:create_alert:TVOC',
  AlertsWrite: 'tsi:webapp:alerts:write:>1',
  AlertsWriteUpTo1: 'tsi:webapp:alerts:write:<=1',
  CollaboratorsWriteLessThan11: 'tsi:webapp:sharing:write_collaborators:<11',
  CollaboratorsWriteLessThan21: 'tsi:webapp:sharing:write_collaborators:<21',
  CollaboratorsWriteLessThanOrEqualTo2: 'tsi:webapp:sharing:write_collaborators:<=2',
  CleaningIntervalWriteAll:
    'tsi:webapp:device_settings:write_cleaning_interval:all',
  CalibrationWrite: 'tsi:webapp:user_calibration:write',
  DashboardCreateWidget: 'tsi:webapp:dashboard:create_widget',
  DeveloperPanel: 'tsi:webapp:Developer:execute_sidebar_icon',
  DeviceAddMoreThan1: 'tsi:webapp:activate_device:execute_add_device:>1',
  DeviceAddUpTo1: 'tsi:webapp:activate_device:execute_add_device:<=1',
  GasExportCH2O: 'tsi:webapp:history_chart:execute_export:CH2O',
  GasExportCO: 'tsi:webapp:history_chart:execute_export:CO',
  GasExportNO2: 'tsi:webapp:history_chart:execute_export:NO2',
  GasExportMonth: 'tsi:webapp:history-chart:execute_export:month',
  GasExportO3: 'tsi:webapp:history_chart:execute_export:O3',
  GasExportSO2: 'tsi:webapp:history_chart:execute_export:SO2',
  GasExportTVOC: 'tsi:webapp:history_chart:execute_export:VOC',
  GasReadCH2O: 'tsi:webapp:device_data:read_data:CH2O',
  GasReadCO: 'tsi:webapp:device_data:read_data:CO',
  GasReadNO2: 'tsi:webapp:device_data:read_data:NO2',
  GasReadO3: 'tsi:webapp:device_data:read_data:O3',
  GasReadPM10_PM25_Temp_RH: 'tsi:webapp:device_data:read_data:PM2.5_PM10_Temp_RH',
  GasReadSO2: 'tsi:webapp:device_data:read_data:SO2',
  GasReadTVOC: 'tsi:webapp:device_data:read_data:VOC',
  HistoryChartExecuteDataDay: 'tsi:webapp:history-chart:execute_data:=day',
  HistoryChartExecuteDataMonth: 'tsi:webapp:history-chart:execute_data:<=month',
  HistoryChartExecuteDataThreeMonths:
    'tsi:webapp:history-chart:execute_data:<=90 days',
  HistoryChartExecuteExportMonth:
    'tsi:webapp:history-chart:execute_export:<=month',
  HistoryChartExecuteExportThreeMonths:
    'tsi:webapp:history-chart:execute_export:<=90 days',
  LoggingIntervalWriteAll:
    'tsi:webapp:device_settings:write_logging_interval:all',
  LoggingIntervalWriteGreaterThanOrEqualTo15:
    'tsi:webapp:device_settings:write_logging_interval:>=15',
  LoggingIntervalWriteGreaterThan1:
    'tsi:webapp:device_settings:write_logging_interval:>1',
  MapViewPin: 'tsi:webapp:map:view_pin',
  MenuAlertSetup: 'tsi:webapp:Devices:execute_menu_choice:Alert_Set_Up',
  MenuCurrentReadings: 'tsi:webapp:Devices:execute_menu_choice:Current_Readings',
  MenuHistory: 'tsi:webapp:Devices:execute_menu_choice:History',
  MenuSettings: 'tsi:webapp:Devices:execute_menu_choice:Settings',
  MenuSharing: 'tsi:webapp:Devices:execute_menu_choice:Sharing',
  MenuViewOnMap: 'tsi:webapp:Devices:execute_menu_choice:View_on_Map',
  SideBarDashboardExecute: 'tsi:webapp:dashboard:execute_sidebar_icon',
  SideBarDevicesExecute: 'tsi:webapp:Devices:execute_sidebar_icon',
  SideBarDataServicesExecute: 'tsi:Data_Services:API:execute_sidebar_icon',
  SideBarStudyManagerExecute: 'tsi:webapp:study_manager:execute_sidebar_icon',
  UserCalibrationWrite: 'tsi:webapp:user_calibration:write',
  ReadConsecutiveZerosWarning:
    'tsi:webapp:warnings:read_consecutive_zeros_warning',
  ExternalApiV2SecretButton: 'tsi:webapp:api_services:execute_v2_secret_button',
  ExternalApiV3ApigeeButton: 'tsi:webapp:api_services:execute_v3_apigee_button',
  BulkDeviceRegistration: 'tsi:webapp:Devices:bulk_register_devices',
  LogInAs: 'tsi:webapp:Administration:execute_menu_choice:Log_In_As',
})

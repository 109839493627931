export function replaceData(obj, prop, data) {
  if (obj != undefined && data != undefined) {
    Vue.set(obj, prop, data)
  }
  return obj
}

export function deleteData(obj, key) {
  if (obj != undefined) {
    Vue.delete(obj, key)
  }
  return obj
}

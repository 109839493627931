const getGtmOptions = (router) => {
  return {
    id: process.env.VUE_APP_GTM_ID,
    defer: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  }
}

export default getGtmOptions

export const convertUTCToTSITimezoneDate = (str) => {
  //Ex: 2022-02-16T00:00:00.000Z -> 02/16/2022
  const d = new Date(str)
  if (!isNaN(d)) {
    // date object is valid
    return d.toLocaleString('en-US', {
      timeZone: 'America/Chicago',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
  }

  return ''
}

export const convertRFC3339ToMonthDayYearDateString = (str) => {
  //Ex: 2023-02-15 or 2023-02-15T00:00:00 -> 02/15/2023
  try {
    const cstDateParts = str.split('-')
    cstDateParts[2] = cstDateParts[2].split('T')[0]
    return `${cstDateParts[1]}/${cstDateParts[2]}/${cstDateParts[0]}`
  } catch (e) {
    // return empty string
  }

  return ''
}

export const convertObjectToArray = (value, convertFn) => {
  if (Array.isArray(value)) {
    return value
  }

  if (value && typeof value === 'object') {
    const array = []
    const keys = Object.keys(value)
    for (const key of keys) {
      if (value[key]) {
        if (typeof convertFn === 'function') {
          array.push(convertFn(key))
        } else {
          array.push(value[key])
        }
      }
    }

    return array
  }

  return []
}

export const getLatestTimestamp = (timestamps) => {
  let latestTimestamp = 0

  if (timestamps?.length) {
    for (const timestamp of timestamps) {
      const formattedTimestamp = timestamp ? new Date(timestamp).getTime() : 0
      if (formattedTimestamp > latestTimestamp) {
        latestTimestamp = formattedTimestamp
      }
    }
    return latestTimestamp ? new Date(latestTimestamp).toISOString() : null
  }

  return null
}

import { getConfigByKey } from './device-config'

export const ZERO_STATES = {
  USER_ZERO_APPLIED: 'user_zero_applied',
  USER_ZERO_REQUESTED: 'user_zero_requested',
  FACTORY_ZERO_APPLIED: 'factory_zero_applied',
  FACTORY_ZERO_REQUESTED: 'factory_zero_requested',
}
export const ZEROING_SENSORS = ['co', 'no2', 'so2', 'o3']

export const getUserZeros = (
  zeroableSensors,
  cloudConfig,
  zeroAppliedAtMap,
  zeroStateMap
) => {
  const userZeros = {}

  for (const sensorName of zeroableSensors) {
    const zeroStateFromConfig = getConfigByKey(
      cloudConfig,
      sensorName.concat('_zero_state')
    )
    const zeroRequestedAt = getConfigByKey(
      cloudConfig,
      sensorName.concat('_zero_requested_at')
    )
    let zeroAppliedAt, zeroStateFromState

    if (zeroAppliedAtMap) {
      zeroAppliedAt = zeroAppliedAtMap[sensorName.concat('_zero_applied_at')]
    }
    if (zeroStateMap) {
      zeroStateFromState = zeroStateMap[sensorName.concat('_zero_state')]
    }

    if (zeroStateFromConfig && zeroRequestedAt) {
      if (
        zeroAppliedAt &&
        Date.parse(zeroRequestedAt) <= Date.parse(zeroAppliedAt)
      ) {
        userZeros[sensorName] = {
          zero_state: zeroStateFromState,
          userzero: null,
          zero_requested_at: zeroRequestedAt,
          zero_applied_at: zeroAppliedAt,
        }
      } else {
        userZeros[sensorName] = {
          zero_state: zeroStateFromConfig,
          userzero: null,
          zero_requested_at: zeroRequestedAt,
          zero_applied_at: null,
        }
      }
    } else {
      // sensor can be zeroed, but has not been before
      userZeros[sensorName] = {
        zero_state: ZERO_STATES.FACTORY_ZERO_APPLIED,
        userzero: null,
        zero_requested_at: null,
        zero_applied_at: null,
      }
    }
  }
  return userZeros
}

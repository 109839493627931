import {
  defaultLoggingInterval,
  defaultCleaningInterval,
} from '../../../services/device-config'

export const STEP_NAMES = {
  UPLOAD_FILE: 'uploadFile',
  UPLOAD_SUCCEEDED: 'uploadSucceeded',
  UPLOAD_FAILED: 'uploadFailed',
  SHOW_CSV_ERRORS: 'showCsvErrors',
  VALIDATE_CSV: 'validateCsv',
  REGISTER_DEVICES: 'registerDevices',
  SHOW_REGISTRATION_RESULT: 'showRegistrationResult',
}

export const STEP_PROCESSES = {
  UPLOAD: 'upload',
  BEFORE_REGISTER: 'beforeRegister',
  FAILED: 'failed',
  PROGRESS: 'progress',
  AFTER_REGISTER: 'afterRegister',
}

export const CLOSE_ACTIONS = {
  STOP_REGISTRATION: 'stopRegistration',
}

const STEP_CSS_STYLES = {
  FIRST: 'first-style',
  SECOND: 'second-style',
  THIRD: 'third-style',
  FOURTH: 'fourth-style',
  FIFTH: 'fifth-style',
}

export const REGISTER_MULTIPLE_DEVICES_STEPS = {
  [STEP_NAMES.UPLOAD_FILE]: {
    name: STEP_NAMES.UPLOAD_FILE,
    process: STEP_PROCESSES.UPLOAD,
    downloadCsv: true,
    cssStyle: STEP_CSS_STYLES.FIRST,
    disableActionButton: true,
  },
  [STEP_NAMES.UPLOAD_SUCCEEDED]: {
    name: STEP_NAMES.UPLOAD_SUCCEEDED,
    process: STEP_PROCESSES.BEFORE_REGISTER,
    downloadCsv: true,
    cssStyle: STEP_CSS_STYLES.FOURTH,
  },
  [STEP_NAMES.UPLOAD_FAILED]: {
    name: STEP_NAMES.UPLOAD_FAILED,
    process: STEP_PROCESSES.FAILED,
    downloadCsv: true,
    cssStyle: STEP_CSS_STYLES.THIRD,
    overrideActionButtonText: 'buttons.retry',
  },
  [STEP_NAMES.SHOW_CSV_ERRORS]: {
    name: STEP_NAMES.SHOW_CSV_ERRORS,
    process: STEP_PROCESSES.FAILED,
    downloadCsv: true,
    cssStyle: STEP_CSS_STYLES.THIRD,
    overrideActionButtonText: 'buttons.retry',
  },
  [STEP_NAMES.VALIDATE_CSV]: {
    name: STEP_NAMES.VALIDATE_CSV,
    process: STEP_PROCESSES.PROGRESS,
    overrideSubtitle: 'registerMultipleDevices.uploading',
    cssStyle: STEP_CSS_STYLES.SECOND,
    disableActionButton: true,
  },
  [STEP_NAMES.REGISTER_DEVICES]: {
    name: STEP_NAMES.REGISTER_DEVICES,
    process: STEP_PROCESSES.PROGRESS,
    overrideSubtitle: 'registerMultipleDevices.addingDevices',
    cssStyle: STEP_CSS_STYLES.SECOND,
    disableActionButton: true,
    closeAction: CLOSE_ACTIONS.STOP_REGISTRATION,
  },
  [STEP_NAMES.SHOW_REGISTRATION_RESULT]: {
    name: STEP_NAMES.SHOW_REGISTRATION_RESULT,
    process: STEP_PROCESSES.AFTER_REGISTER,
    overrideSubtitle: 'registerMultipleDevices.registrationReport',
    cssStyle: STEP_CSS_STYLES.FIFTH,
    overrideActionButtonText: 'buttons.done',
    removeCancelButton: true,
  },
}

export const REGISTER_ERRORS = [
  {
    errorRegex: /device.*registered.*your account/,
    parsedError: '/serialNumber/ is already registered to your account.',
  },
  {
    errorRegex: /device.*registered.*another account/,
    parsedError: `/serialNumber/ is already registered to another user's account.`,
  },
  {
    errorRegex: /no.*subscription.*available/,
    parsedError:
      '/serialNumber/ no active subscription for this device.',
  },
  {
    errorRegex: /maximum.*registered.*devices/,
    parsedError:
      '/serialNumber/ exceeds available quantity of your TSI Link subscription.',
  },
  {
    errorRegex: /device.*not.*found/,
    parsedError:
      '/serialNumber/ does not exist. Please re-check the number and try again.',
  },
]

export const ROW_EXAMPLE = {
  ['Device Name']: 'Example Device',
  ['Location Name']: 'Example Location',
  ['Device Serial Number']: '81431234567',
  ['Make Device Public?']: 'Yes',
  ['Indoor Sensor?']: 'No',
  Latitude: '45.12345678',
  Longitude: '-25.12345678',
}

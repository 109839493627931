<template>
  <base-dialog
    :open="open"
    @close="close"
    data-cy="accessAdditionalFeaturesDialog"
    :fullscreen="$vuetify.breakpoint.xs"
    :persistentOverlay="true"
  >
    <template v-slot:header>
      <v-card-title data-cy="accessAdditionalFeaturesHeader">
        {{ $t('accessAdditionalFeaturesDialog.title') }}
      </v-card-title>
    </template>
    <v-card-text>
      {{ $t('accessAdditionalFeaturesDialog.message') }}
      <v-checkbox
        color="primary"
        v-model="isAddToBoth"
        @click="change"
        :label="$t('accessAdditionalFeaturesDialog.addToBoth')"
        data-cy="addToBothCheckbox"
      ></v-checkbox>
      <div
        v-for="model in subTierSelectByDeviceModel"
        :key="model.id"
        class="subscription-tier-select-group"
      >
        <v-card-subtitle>
          {{
            $t('accessAdditionalFeaturesDialog.selectTitle', {
              modelName: deviceModelNames[model],
              subscription: $t('activateDevice.subscription'),
            })
          }}
        </v-card-subtitle>
        <v-select
          v-model="subTiers[model]"
          :items="subscriptionTierItems"
          item-text="text"
          item-value="value"
          :label="$t('activateDevice.subscriptionTier')"
          :hint="selectHintByModel[model]"
          persistent-hint
          :rules="[rules.required]"
          @input="subTierChange"
          data-cy="apiSubscriptionTierSelect"
          class="text-field-required"
        ></v-select>
      </div>
    </v-card-text>
    <template v-slot:actions>
      <v-btn
        :v-if="$vuetify.breakpoint.xs"
        @click="cancel"
        text
        outlined
        class="black--text dialog-btn"
        data-cy="accessAdditionalFeaturesCancel"
        >{{ $t('buttons.cancel') }}</v-btn
      >
      <v-spacer />
      <v-btn
        :disabled="!fieldsValid || isClosing"
        :loading="isClosing"
        @click="close"
        class="primary white--text"
        text
        data-cy="accessAdditionalFeaturesClose"
      >
        <div class="trailing-icon-text">{{ $t('buttons.done') }}</div>
        <v-icon right light>mdi-chevron-right</v-icon>
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import dialog from '../dialogs/Dialog'

export default {
  name: 'AccessAdditionalFeaturesDialog',
  props: {
    open: Boolean,
    addToBoth: { type: Boolean, default: false },
    subscriptionTiers: { type: Object, default: () => {} },
    bulkDeviceModels: { type: Array, default: () => [] },
  },
  components: { 'base-dialog': dialog },
  data() {
    return {
      isClosing: false,
      isAddToBoth: this.addToBoth,
      subTiers: this.subscriptionTiers,
      subscriptionTierItems: [
        {
          text: this.$t('activateDevice.subscriptionTierOptions.basic'),
          value: 'basic',
        },
        {
          text: this.$t('activateDevice.subscriptionTierOptions.premium'),
          value: 'premium',
        },
      ],
      rules: {
        required: (value) => !!value || this.$t('validation.required'),
      },
    }
  },
  computed: {
    fieldsValid() {
      let subTierValid = true
      if (this.isAddToBoth && this.subTierSelectByDeviceModel.length > 0) {
        Object.keys(this.subTiers).forEach((key) => {
          if (
            this.subTierSelectByDeviceModel.includes(key) &&
            this.rules.required(this.subTiers[key]) !== true
          ) {
            subTierValid = false
          }
        })
      }
      return subTierValid
    },
    deviceModelNumbers() {
      return this.$store.getters['devicemodels/getModels']
    },
    deviceModelNames() {
      return this.$store.getters['devicemodels/getModelNamesMap']
    },
    bulkDeviceModelsSorted() {
      const orderedByName = [...Object.values(this.deviceModelNames)].sort()
      const sortBy = orderedByName.map((name) => {
        return Object.keys(this.deviceModelNames).find(
          (key) => this.deviceModelNames[key] === name
        )
      })
      return [...this.bulkDeviceModels].sort(function (a, b) {
        return sortBy.indexOf(a) - sortBy.indexOf(b)
      })
    },
    canAddToBasicSub() {
      let items = {}
      this.deviceModelNumbers.forEach((modelNumber) => {
        items[modelNumber] =
          this.$store.getters['subscriptions/canAddDeviceToBasicByModel'](
            modelNumber
          )
      })
      return items
    },
    canAddToPremiumSub() {
      let items = {}
      this.deviceModelNumbers.forEach((modelNumber) => {
        items[modelNumber] =
          this.$store.getters['subscriptions/canAddDeviceToPremiumByModel'](
            modelNumber
          )
      })
      return items
    },
    showSubTierSelect() {
      let items = {}
      this.deviceModelNumbers.forEach((modelNumber) => {
        items[modelNumber] =
          this.isAddToBoth &&
          this.canAddToBasicSub[modelNumber] &&
          this.canAddToPremiumSub[modelNumber]
      })
      return items
    },
    selectHintByModel() {
      let items = {}
      this.deviceModelNumbers.forEach((modelNumber) => {
        const modelName = this.deviceModelNames[modelNumber]
        const hint = this.$t(`activateDevice.subscriptionTierHint${modelName}`)
        items[modelNumber] = hint
      })
      return items
    },
    subTierSelectByDeviceModel() {
      return this.bulkDeviceModelsSorted.filter(
        (model) => this.showSubTierSelect[model]
      )
    },
  },
  watch: {
    addToBoth() {
      this.isAddToBoth = this.addToBoth
    },
    subscriptionTiers() {
      this.subTiers = this.subscriptionTiers
    },
    open() {
      this.isClosing = false
    },
  },
  methods: {
    change() {
      this.$emit('change', this.isAddToBoth)
    },
    subTierChange() {
      this.$emit('subTierChange', this.subTiers)
    },
    close() {
      this.isClosing = true
      this.$emit('close')
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    padding: 16px;
  }
  .v-card__title {
    font-weight: 300;
    padding: 8px 24px 16px 16px;
  }
  .v-card__subtitle {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 24px 16px 16px;
  }
  .v-card__text .v-card__subtitle {
    padding: 0;
  }
  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
    .trailing-icon-text {
      width: 100%;
      text-indent: 30px;
    }
  }
  .v-card__actions {
    margin-top: 24px;
  }
}
.subscription-tier-select-group {
  padding-top: 16px;
  & + & {
    margin-top: 24px;
  }
}
</style>

<template>
  <fusion-help-center />
</template>

<script>
import { accountExists, emailVerified, isAuthorized } from '@fusion/auth'

export default {
  mixins: [isAuthorized, accountExists, emailVerified],
}
</script>

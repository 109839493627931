var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{
    'ma-6': !this.$vuetify.breakpoint.xs,
    tableCard: true,
    'is-mobile': this.isMobile,
  },attrs:{"flat":_vm.$vuetify.breakpoint.xs}},[_c('div',[_c('v-text-field',{staticClass:"search-field",staticStyle:{"width":"200px"},attrs:{"prepend-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","data-cy":"searchField"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(!_vm.apiCallInProgress)?_c('v-data-table',{class:{ mobile: _vm.isMobile },attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.tsiLinkAccounts,"sort-by":_vm.sortedBy,"sort-desc":_vm.sortDesc,"itemsPerPage":_vm.itemsPerPage,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-text': _vm.isMobile
        ? _vm.$t('tables.rows')
        : _vm.$t('tables.rowsPerPage'),
      itemsPerPageOptions: [10, 25, 50],
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
    },"item-key":"accountId"},on:{"update:sortBy":function($event){_vm.sortedBy=$event},"update:sort-by":function($event){_vm.sortedBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([(!_vm.isMobile)?{key:"body",fn:function(ref){
    var items = ref.items;
return [_c('account-table-body',{attrs:{"items":items}})]}}:{key:"body",fn:function(ref){
    var items = ref.items;
return [_c('account-table-body-mobile',{attrs:{"items":items}})]}}],null,true)}):_c('div',{staticClass:"loading-animation"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { tempUnits } from '../../services/unit-conversion'
import { UnitOfMeasure } from '@fusion/units'

export default {
  setSettings(state, userSettings) {
    if (userSettings.bluesky) {
      if (!userSettings.bluesky.lastViewedVersion) {
        userSettings.bluesky.lastViewedVersion = '1.0.0'
      }
      state.settings = userSettings['bluesky']
      if (state.settings.viewedSubscriptionExpired === undefined) {
        state.settings.viewedSubscriptionExpired = null
      }
      if (state.settings.devicesUpdated === undefined) {
        state.settings.devicesUpdated = []
      }
    }
  },
  updateSettings(state, userSettings) {
    if (userSettings.bluesky) {
      state.settings = { ...state.settings, ...userSettings['bluesky'] }
    }
  },
}

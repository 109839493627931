<template>
  <div class="expandable-content-wrapper">
    <v-data-table v-if="showContent" :headers="headers" :items="item.devices" hide-default-footer disable-pagination :mobile-breakpoint="0">
    </v-data-table>
    <p v-if="showMessage">{{ $t('manageSubscriptions.sharedSubscriptionDevicesMessage') }}</p>
  </div>
</template>

<script>
import {
  subscriptionTiers
} from '../../../store/subscriptions/utils'

export default {
  name: 'ExpandableContent',
  props: {
    item: Object
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('manageSubscriptions.expandedTable.deviceName'),
          value: 'name',
          ...(!this.isMobile && { width: 170 }),
        },
        {
          text: this.$t('manageSubscriptions.expandedTable.deviceType'),
          value: 'type',
          ...(!this.isMobile && { width: 150 }),
        },
        {
          text: this.$t('manageSubscriptions.expandedTable.deviceSerial'),
          value: 'serial',
          ...(!this.isMobile && { width: 150 }),
        },
      ]
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    showMessage() {
      if (this.item?.subscriptionId === subscriptionTiers.TSILINK_STANDARD) {
        return false
      }
      return !!(this.item?.deviceQuantityUsed && this.item.devices &&
        this.item.deviceQuantityUsed !== this.item.devices.length)
    },
    showContent() {
      return !!(this.item?.devices?.length)
    },
  }
}
</script>

<style lang="scss">
@import '@/assets/global-variables.scss';

.v-data-table__expand-icon--active {
  transform: rotate(90deg);
}

.v-data-table__wrapper {
  .v-data-table__expanded__row {
    td {
      border-bottom: none !important;
    }
  }
}

.expandable-content-column:not(.mobile) {
  border-bottom: 3px dotted rgba(0, 0, 0, 0.3) !important;

  .expandable-content-wrapper {
    margin-left: 3.5rem;
  }

  table {
    margin: 15px 0;
  }
}

.expandable-content-column {
  table {
    background-color: $great-light-shade;
    max-width: 22rem !important;

    th,
    td {
      word-break: break-word;
    }
  }
}

.expandable-content-column.mobile {
  .v-data-table {
    background-color: inherit;
  }

  table {
    tr {
      display: flex;

      th,
      td {
        display: flex;
        padding: 8px !important;
        font-size: 0.75rem !important;
        height: auto !important;
        word-break: break-word;
        width: 32%;
      }

      th {
        align-items: center;
      }

      th:nth-child(1),
      td:nth-child(1) {
        width: 35%;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
p {
  font-style: italic;
  margin: 10px 0;
}

.expandable-content-column.mobile {
  p {
    font-size: 0.875rem;
  }
}
</style>

<template>
  <v-card class="adminPanel" flat>
    <v-row class="headerRow" no-gutters>
      <v-col cols="12" md="3">
        <v-card-title class="administrationHeader">
          {{ $t('app.routes.administration') }}
        </v-card-title>
      </v-col>
      <v-col cols="12" md="6" align="center">
        <div class="tabs">
          <v-tabs centered v-model="tab" class="tabStructure" color="blue">
            <v-tab data-cy="accountTable" key="accounts">
              {{ $t('administrationPanel.accounts') }}
            </v-tab>
            <v-tab data-cy="servicesTable" key="services">
              {{ $t('administrationPanel.services') }}
            </v-tab>
          </v-tabs>
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-tabs-items v-model="tab">
      <v-tab-item key="accounts">
        <account-table></account-table>
      </v-tab-item>
      <v-tab-item key="services">
        <third-party-table></third-party-table>
        <tsi-table></tsi-table>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  isAuthorized,
  emailVerified,
  hasTSIEmail,
  accountExists,
  routeHasPermission,
} from '@fusion/auth'
import AccountTable from '../components/adminPanel/accountTable.vue'
import ThirdPartyTable from '../components/adminPanel/thirdPartyTable.vue'
import TsiTable from '../components/adminPanel/tsiTable.vue'
import { SlugsEnum } from '../permissions/SlugsEnum'

export default {
  name: 'AdministrationPanel',
  mixins: [
    isAuthorized,
    emailVerified,
    hasTSIEmail,
    accountExists,
    routeHasPermission(SlugsEnum.AdminPanel),
  ],
  data() {
    return {
      tab: null,
    }
  },
  components: {
    'account-table': AccountTable,
    'third-party-table': ThirdPartyTable,
    'tsi-table': TsiTable
  },
}
</script>

<style lang="scss" scoped>
.adminPanel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  border-radius: 0;
  box-shadow: none;

  .administrationHeader {
    font-weight: 300;
    padding-bottom: 8px;
    padding-top: 8px;
    font-size: 24px;
    @media (max-width: 900px) {
      margin-left: 8px;
    }
    margin-left: 24px;
  }
  .headerRow {
    margin-top: 32px;
  }
  .v-divider {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 0px;
    margin-bottom: 16px;
  }
}
</style>

import { CachePolicy, LOG_IN_AS_HEADER_NAMES } from './utils'
import { getLogInAsHeaders } from './helper'

export const getDevices = async function (includePublic = false) {
  const publicQuery = includePublic ? '?public=true' : ''
  return this.GET(
    `/summary/devices${publicQuery}`,
    {
      ...getLogInAsHeaders([
        LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
        LOG_IN_AS_HEADER_NAMES.SAP_ID,
      ]),
    },
    { cache: CachePolicy.NoStore },
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

export const getUserDevices = async function () {
  return this.GET(
    '/devices',
    {
      ...getLogInAsHeaders([
        LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
        LOG_IN_AS_HEADER_NAMES.SAP_ID,
      ]),
    },
    {},
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

export const getDevice = function (deviceId) {
  return this.GET(
    `/devices/${deviceId}`,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
    },
    {},
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

export const postRegisterDevice = async function (deviceData) {
  const body = JSON.stringify(deviceData)
  return this.POST(
    '/devices/register',
    body,
    {
      ...getLogInAsHeaders([
        LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
        LOG_IN_AS_HEADER_NAMES.SAP_ID,
      ]),
    },
    {},
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

export const postUnregisterDevice = function (devId) {
  return this.POST(
    `/devices/${devId}/unregister`,
    {},
    {
      ...getLogInAsHeaders([
        LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
        LOG_IN_AS_HEADER_NAMES.SAP_ID,
      ]),
    },
    {},
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

export const patchUpdateDevice = function (deviceData, deviceId) {
  const body = JSON.stringify(deviceData)
  return this.PATCH(
    `/devices/${deviceId}`,
    body,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
    },
    {},
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

export const postShareDevice = function (userInfo, deviceId) {
  const body = JSON.stringify(userInfo)
  return this.POST(
    `/devices/${deviceId}/collaborators`,
    body,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
    },
    {},
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

export const getDeviceCollaborators = function (deviceId) {
  return this.GET(
    `/devices/${deviceId}/collaborators`,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
    },
    {},
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

export const deleteDeviceCollaborator = function (collaborator, deviceId) {
  return this.DELETE(
    `/devices/${deviceId}/collaborators/${collaborator}`,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
    },
    {},
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

export const getDeviceConfig = function (deviceId) {
  return this.GET(
    `/devices/${deviceId}/config`,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
    },
    {},
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

export const postDeviceConfig = function (config, deviceId) {
  const body = JSON.stringify(config)
  return this.POST(
    `/devices/${deviceId}/config`,
    body,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
    },
    {},
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

export const getModelsMetaData = function () {
  return this.GET(
    '/devices/models',
    {},
    {},
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

export const patchDeviceSubscription = async function (data) {
  const body = JSON.stringify(data)
  return this.PATCH(
    '/devices/subscription',
    body,
    {
      ...getLogInAsHeaders([
        LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
        LOG_IN_AS_HEADER_NAMES.SAP_ID,
      ]),
    },
    {},
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

<template>
  <v-card :class="{
      'ma-6': !this.$vuetify.breakpoint.xs,
      'manage-subscriptions-card': true,
      'is-mobile': this.isMobile,
    }">
    <v-card-subtitle>
      <v-btn id="returnButton" class="returnText" plain @click="$router.go(-1)" data-cy="manageSubscriptionsBackButton">
        <v-icon>mdi-arrow-left-circle-outline</v-icon>
      </v-btn>
      <span class="returnText">{{ $t('app.routes.manageSubscriptions') }}</span>
      <p class="timezoneDetails" v-if="
          !$auth.loading &&
          userSubscriptions !== null &&
          userSubscriptions.length !== 0
        ">
        {{ $t('manageSubscriptions.timezoneDetails') }}
      </p>
    </v-card-subtitle>
    <v-data-table v-if="
        !$auth.loading &&
        userSubscriptions !== null &&
        userSubscriptions.length !== 0
      " :headers="headers" :items="userSubscriptions" :single-expand="singleExpand" :expanded.sync="expanded"
      :show-expand="enableExpandableRows" item-key="activationId" :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
      :class="{
        mobile: isMobile,
        'last-row-expanded': this.isLastRowExpanded,
      }" :custom-filter="filterByColumnValueAndDeviceSerial" :header-props="headerProps" :search="customTableFilter"
      :options="tableOptions" :sort-by.sync="sortedBy" :sort-desc.sync="sortDesc" :itemsPerPage.sync="itemsPerPage"
      :footer-props="{
        'items-per-page-text': isMobile
          ? $t('tables.rows')
          : $t('tables.rowsPerPage'),
        itemsPerPageOptions: [10, 25, 50],
      }" data-cy="manageSubscriptionsTable" @current-items="updateTableComputedItems">
      <template v-slot:top>
        <div class="table-bar">
          <v-text-field v-model="search" prepend-icon="mdi-magnify" label="Search" single-line hide-details
            :style="isMobile ? '' : 'width: 375px'" class="search-field" data-cy="searchField" />
        </div>
      </template>
      <!-- desktop view -->
      <template v-if="!isMobile" v-slot:[`item.actions`]="{ item }">
        <td class="text-xs-left">
          <action-menu v-if="!isAddOnSub(item.subscriptionId)" :item="item" :isLogInAs="isLogInAs" @cancelSubscriptionOpen="cancelSubscriptionOpen"
            @subscriptionBillingInfoOpen="subscriptionBillingInfoOpen" />
        </td>
      </template>
      <!-- mobile view -->
      <template v-else v-slot:body="{ items, headers, expand, isExpanded }">
        <expand-collapse-buttons v-if="enableExpandableRows" @expandAll="expandAllDevices"
          @collapseAll="collapseAllDevices" />
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td class="mobile-list">
              <v-row no-gutters>
                <v-col>
                  <ul class="flex-content">
                    {{
                    item.subscription
                    }}
                    <action-menu v-if="!isAddOnSub(item.subscriptionId)" :item="item" :isLogInAs="isLogInAs" @cancelSubscriptionOpen="cancelSubscriptionOpen"
                      @subscriptionBillingInfoOpen="subscriptionBillingInfoOpen" />
                    <li v-for="header in getMobileHeaders(headers, item)" :key="header.value" class="flex-item">
                      <span>
                        {{ header.text }}:&nbsp;
                      </span>
                      <span>{{ item[header.value] }}</span>
                    </li>
                    <li v-if="enableExpandableRows && itemCanBeExpanded(item)" class="flex-item expandable-option-mobile">
                      <v-btn icon @click="expand(item,!isExpanded(item))"
                        class="v-data-table__expand-icon expandable-icon-mobile"
                        :class="{'v-data-table__expand-icon--active' : isExpanded(item)}">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                      <span> {{ $t('manageSubscriptions.devices') }}</span>
                    </li>
                    <li v-if="isExpanded(item)" class="flex-item expandable-content-column mobile">
                      <expandable-content :item="item" />
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
      </template>
      <!-- Show the expand button when the subscription has devices -->
      <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
        <td v-if="itemCanBeExpanded(item)" class="text-start">
          <v-btn icon @click="expand(!isExpanded)" class="v-data-table__expand-icon"
            :class="{'v-data-table__expand-icon--active' : isExpanded}">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </td>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="11" class="expandable-content-column">
          <expandable-content :item="item" />
        </td>
      </template>
      <template v-if="enableExpandableRows" v-slot:[`footer.prepend`]="{}">
        <expand-collapse-buttons @expandAll="expandAllDevices" @collapseAll="collapseAllDevices" />
      </template>
    </v-data-table>
    <!-- empty message -->
    <div v-else-if="
        !$auth.loading &&
        userSubscriptions !== null &&
        userSubscriptions.length === 0
      " class="text-center emptyMessage">
      {{ $t('manageSubscriptions.emptyMessage') }}
    </div>
    <!-- progress spinner -->
    <div v-else class="text-center emptyMessage">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <subscription-cancel-dialog :open="dialog.subscriptionCancel.open"
      :activationId="dialog.subscriptionCancel.activationId" @close="cancelSubscriptionClosed" />
    <subscription-billing-info-dialog :open="dialog.subscriptionBillingInfo.open"
      :activationId="dialog.subscriptionBillingInfo.activationId"
      :subscriptionId="dialog.subscriptionBillingInfo.subscriptionId" @close="subscriptionBillingInfoClosed" />
  </v-card>
</template>

<script>
import { accountExists, emailVerified, isAuthorized } from '@fusion/auth'
import {
  deviceIdToSerial,
  paymentTypes,
  subscriptionGenericTSILinkType,
  subscriptionDeviceType,
  subscriptionTiers,
  genericTSILinkSubscriptionTiers,
  subscriptionTierLength

} from '../store/subscriptions/utils'
import { deviceModelDisplay, deviceSubmodelDisplay } from '../services/map'
import { featureFlags } from '../services/feature-flags'
import { getUserAccountId } from '../helpers/loginas/logInAsHelper'
import SubscriptionCancelDialog from '../components/subscriptionCancel/SubscriptionCancelDialog.vue'
import SubscriptionBillingInfoDialog from '../components/dialogs/SubscriptionBillingInfoDialog.vue'
import ActionMenu from '../components/manageSubscriptions/table/ActionMenu.vue'
import ExpandCollapseButtons from '../components/common/buttons/ExpandCollapseButtons.vue'
import ExpandableContent from '../components/manageSubscriptions/table/ExpandableContent.vue'

const LOCAL_STORAGE_KEYS = 'tsi.subscriptionsTableSettings'

export default {
  name: 'ManageSubscriptions',
  mixins: [isAuthorized, accountExists, emailVerified],
  components: {
    'subscription-cancel-dialog': SubscriptionCancelDialog,
    'subscription-billing-info-dialog': SubscriptionBillingInfoDialog,
    'expand-collapse-buttons': ExpandCollapseButtons,
    'expandable-content': ExpandableContent,
    'action-menu': ActionMenu
  },
  async mounted() {
    this.accountId = await getUserAccountId(this.$auth)
    await this.getLatestStudyRegistrationHardware()
    await this.getFileImportCount()
    await this.getTotalStorageUsed()
    //make sure the additional add on amounts gets added as soon as api results complete
    this.updateTableComputedItems(this.tableComputedItems)
    this.getSubscriptionsTableSettings()
  },
  data() {
    return {
      sapAccountCount: -1,
      cloudAccountCount: -1,
      sapAccountStorage: -1,
      cloudAccountStorage: -1,
      studyGatewayDevices: new Map(),
      studyDevices: new Map(),
      accountId: null,
      dialog: {
        subscriptionCancel: {
          activationId: '',
          open: false,
        },
        subscriptionBillingInfo: {
          activationId: '',
          subscriptionId: '',
          open: false,
        },
      },
      sortedBy: ['type'],
      sortDesc: [false],
      itemsPerPage: 10,
      tableOptions: {},
      headerProps: {
        sortByText: this.$t('tables.sortBy'),
      },
      search: '',
      headers: [
        {
          text: this.$t('manageSubscriptions.tableHeaders.subscriptionTier'),
          align: 'left',
          value: 'subscription',
          class: 'subscription-tier-column',
        },
        {
          text: this.$t('manageSubscriptions.tableHeaders.type'),
          value: 'type'
        },
        {
          text: this.$t('manageSubscriptions.tableHeaders.deviceQuantityUsed'),
          value: 'deviceQuantityUsed',
        },
        {
          text: this.$t(
            'manageSubscriptions.tableHeaders.deviceQuantityAllowance'
          ),
          value: 'deviceQuantityAllowance',
        },
        {
          text: this.$t('manageSubscriptions.tableHeaders.activationDate'),
          value: 'activationDate',
        },
        {
          text: this.$t('manageSubscriptions.tableHeaders.renewalDate'),
          value: 'renewalDate',
        },
        {
          text: this.$t('manageSubscriptions.tableHeaders.endDate'),
          value: 'endDate',
        },
        {
          text: this.$t('manageSubscriptions.tableHeaders.billingMethod'),
          value: 'billingMethod',
        },
        {
          text: this.$t('manageSubscriptions.tableHeaders.paymentDetails'),
          value: 'paymentDetails',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      expanded: [],
      singleExpand: false,
      userSubscriptionsData: null,
      tableComputedItems: [],
    }
  },
  watch: {
    sortedBy: {
      deep: true,
      handler() {
        this.setSubscriptionsTableSettings()
      },
    },
    sortDesc: {
      deep: true,
      handler() {
        this.setSubscriptionsTableSettings()
      },
    },
    itemsPerPage: {
      handler() {
        this.setSubscriptionsTableSettings()
      },
    },
    search() {
      this.collapseAllDevices()
    },
  },
  methods: {
    /**
       * Data return will be in this format:
        [
          {
              "_id": "65b17166f882e3a8d5f5b362",
              "studies_id": "75b17166f882e3a8d5f5b362",
              "timestamp": "2024-01-24T20:21:57.745Z",
              "gateway_serial": "789776666600",
              "gateway_model": "7897",
              "gateway_name": "My SmartBridge",
              "device_serial": "759312kh43lkj43l3j43",
              "device_model": "8534",
              "device_name": "8534 DustTrak drx"
          },
          {
              "_id": "65b17166f882e3a8d5f5b361",
              "studies_id": "75b17166f882e3a8d5f5b361",
              "timestamp": "2024-01-24T20:21:57.744Z",
              "gateway_serial": "75933483942308423",
              "gateway_model": "7593-00",
              "gateway_name": "Smart Station",
              "device_serial": "7591017397924792",
              "device_model": "7591-01",
              "device_name": "PM1 Module"
          },
          {
              "_id": "65b17166f882e3a8d5f5b360",
              "studies_id": "75b17166f882e3a8d5f5b360",
              "timestamp": "2024-01-24T20:21:57.743Z",
              "gateway_serial": "",
              "gateway_model": "",
              "gateway_name": "",
              "device_serial": "7591017397924792",
              "device_model": "",
              "device_name": ""
          },
        ]
    */
    async getLatestStudyRegistrationHardware() {
      const resp = await this.$api.getLatestStudyRegistrations()
      if (!resp.ok) {
        return
      }
      const hardware = await resp.json()
      this.studyGatewayDevices = new Map()
      this.studyDevices = new Map()

      /**
       * The logic here is to map the latest study registration gateway serials to serial/model/name and device serials to serial/model/name.
       * As studies are registered the names of the gateway and device names could change so this sets the last known hardware configuration.
       * The api returns those registered studies with hardware info in order of latest registered so we only have to set the first
       * gateway and device by serial mapping and can ignore older registered study data.
       */
      hardware.forEach((hardware) => {
        if( hardware.gateway_serial && !this.studyGatewayDevices.has(hardware.gateway_serial) ){
          this.studyGatewayDevices.set(hardware.gateway_serial,{
            serial: hardware.gateway_serial,
            model: hardware.gateway_model ? hardware.gateway_model.trim() : "",
            name: hardware.gateway_name ? hardware.gateway_name.trim() : "",
          })
        }
        if( hardware.device_serial && !this.studyDevices.has(hardware.device_serial) ){
          this.studyDevices.set(hardware.device_serial, {
            serial: hardware.device_serial,
            model: hardware.device_model ? hardware.device_model.trim() : "",
            name: hardware.device_name ? hardware.device_name.trim() : "",
          })
        }
      })
    },
     /**
     * Data will return in this format:
     * {
        "cloud_account_id": 37,
        "sap_account_id": 55
      }
     */
     async getFileImportCount() {
      try {
        const resp = await this.$api.getFileImportCount()
        if (!resp.ok) {
          return
        }

        const countData = await resp.json()
        const { sap_account_id, cloud_account_id } = countData

        // store the counts in component's data properties
        this.sapAccountCount = sap_account_id
        this.cloudAccountCount = cloud_account_id
      } catch (error) {
        // ignore - handled in updateTableComputedItems
      }
    },
    /**
     * Data will return in this format:
     * {
        "cloud_account_size_in_bytes": 9370,
        "sap_account_size_in_bytes": 26814
      }
     */
    async getTotalStorageUsed() {
      try {
        const resp = await this.$api.getTotalStorageUsed()
        if (!resp.ok) {
          return
        }
        const storageData = await resp.json()
        const { sap_account_size_in_bytes, cloud_account_size_in_bytes } = storageData
        // convert the bytes and store the size and units in component's data properties
        this.sapAccountStorage = this.convertStorageUnits(sap_account_size_in_bytes)
        this.cloudAccountStorage = this.convertStorageUnits(cloud_account_size_in_bytes)
      } catch (error) {
        // ignore - handled in updateTableComputedItems
      }
    },

    /**
     * Takes in bytes and returns an array with [value, units]
     * Uses MB until the bytes meet or exceed the equivalent of .1 GB
     * Round up to 2 decimal places
     */
    convertStorageUnits(bytes) {
      const oneTenthGB = 100000000
      // if < 0.1 GB, convert to MB
      if (bytes < oneTenthGB) {
        let value = (bytes / 10000)
        value = Math.round(value)
        return [value/100, "MB"]
      } else {
        // else >= 0.1 GB, convert to GB
        let value = (bytes / 10000000)
        value = Math.round(value)
        return [value/100, "GB"]
      }
    },

    subIsGenericTSILink(subId) {
      return genericTSILinkSubscriptionTiers.includes(subId)
    },
    cancelSubscriptionClosed() {
      this.dialog.subscriptionCancel.open = false
    },
    cancelSubscriptionOpen(activationId) {
      this.dialog.subscriptionCancel.open = true
      this.dialog.subscriptionCancel.activationId = activationId
    },
    subscriptionBillingInfoClosed() {
      this.dialog.subscriptionBillingInfo.open = false
    },
    subscriptionBillingInfoOpen(activationId, subscriptionId) {
      this.dialog.subscriptionBillingInfo.open = true
      this.dialog.subscriptionBillingInfo.activationId = activationId
      this.dialog.subscriptionBillingInfo.subscriptionId = subscriptionId
    },
    isAddOnSub(subId) {
      return this.isDataStorage(subId) || this.isFileImport(subId)
    },
    isDataStorage(subId) {
      return subId === subscriptionTiers.TSILINK_ADD_ON_DATA_STORAGE
    },
    isFileImport(subId) {
      return subId === subscriptionTiers.TSILINK_ADD_ON_FILE_IMPORT
    },
    // Note: Smart Bridge Device Add On will use quantity for devices
    // (unlike file import and data storage Add-ons)
    isSmartBridgeDeviceFeeAddOn(subId) {
      return subId === subscriptionTiers.TSILINK_ADD_ON_SMARTBRIDGE_DEVICE_FEE
    },
    determineQuantityUsed(subs, subItem) {
      if (this.isAddOnSub(subs.id)) {
        return ' '
      } else {
        return Array.isArray(subs.used)
          ? subs.used[subItem]
          : subs.used
      }
    },
    determineQuantityAllowance(subs, subItem) {
      const isFileImport = this.isFileImport(subs.id)
        if (isFileImport) {
            const quantityAllowance = Array.isArray(subs.qty) ? subs.qty[subItem] : subs.qty
            return quantityAllowance * 200
        } else {
          return Array.isArray(subs.qty) ? subs.qty[subItem] : subs.qty
        }
    },
    subscriptionToTableHelper(userSubs) {
      const tableSubs = []
      const dataStorageSubIndex = {}
      const fileImportSubIndex = {}

      for (let subs of userSubs) {
        // Activation ids will always be present per subscription regardless of activation status
        for (let y = 0; y < subs.activationIds.length; y++) {
          const isAddOnSub = this.isAddOnSub(subs.id)
          const isDataStorage = this.isDataStorage(subs.id)
          const isFileImport = this.isFileImport(subs.id)
          const isGenericTSILinkSubTier = this.subIsGenericTSILink(subs.id)
          const type = isGenericTSILinkSubTier ? subscriptionGenericTSILinkType[subs.id] : subscriptionDeviceType[subs.id]
          const deviceQuantityUsed = this.determineQuantityUsed(subs, y)
          const deviceQuantityAllowance = this.determineQuantityAllowance(subs, y)
          const determinedRenewalDate = isAddOnSub ? this.$t('manageSubscriptions.notApplicable') : this.determineRenewalDate(subs, y)

          // create one row for add-on subscriptions
          if (isDataStorage) {
              if (dataStorageSubIndex.hasOwnProperty(subs.id)) {
                  // add to existing row quantity allowance
                  let dataStorageRow = tableSubs[dataStorageSubIndex[subs.id]]
                  dataStorageRow.deviceQuantityAllowance += deviceQuantityAllowance
                  continue // skip to next iteration to avoid making a new row
              } else {
                  // store row if doesn't exist yet
                  dataStorageSubIndex[subs.id] = tableSubs.length
              }
          }

          if (isFileImport) {
            if (fileImportSubIndex.hasOwnProperty(subs.id)) {
                  // add to existing row quantity allowance
                  let fileImportRow = tableSubs[fileImportSubIndex[subs.id]]
                  fileImportRow.deviceQuantityAllowance += deviceQuantityAllowance
                  continue // skip to next iteration to avoid making a new row
              } else {
                  // store row if doesn't exist yet
                  fileImportSubIndex[subs.id] = tableSubs.length
              }
          }

          tableSubs.push({
            activationId: subs.activationIds[y],
            subscription: subscriptionTierLength[subs.id],
            subscriptionId: subs.id,
            type: type,
            deviceIds: subs.deviceIds[y],
            devices: this.getSubscriptionDevices(subs.deviceIds[y],subs.id),
            deviceQuantityUsed: deviceQuantityUsed,
            deviceQuantityAllowance: deviceQuantityAllowance,
            activationDate: this.determineActivationDate(subs, y),
            renewalDate: determinedRenewalDate,
            endDate: this.determineEndDate(subs, y),
            billingMethod: this.determineBillingMethod(subs, y),
            paymentDetails: this.determinePaymentDetails(subs, y),
            allowCancellation:
              determinedRenewalDate !== this.$t('manageSubscriptions.canceled'),
          })
        }
      }

      return tableSubs
    },
    determineBillingMethod(subData, index) {
      if (this.isPurchaseOrderPaymentType(subData, index)) {
        return this.$t('manageSubscriptions.billingMethods.purchaseOrder')
      } else if (this.isCreditCardPaymentType(subData, index)) {
        return this.$t('manageSubscriptions.billingMethods.card')
      }
      return this.$t('manageSubscriptions.notApplicable')
    },
    determinePaymentDetails(subData, index) {
      if (
        this.isPurchaseOrderPaymentType(subData, index) &&
        subData.billingInfo[index].purchaseOrder
      ) {
        return subData.billingInfo[index].purchaseOrder
      } else if (
        this.isCreditCardPaymentType(subData, index) &&
        subData.billingInfo[index].ccNumLastFour
      ) {
        return '****-' + subData.billingInfo[index].ccNumLastFour
      }
      return this.$t('manageSubscriptions.notApplicable')
    },
    determineActivationDate(subData, index) {
      //This date comes in as an UTC date and time string. Ex: 2022-02-16T00:00:00.000Z.
      return subData.activationDate[index]
        ? this.convertUTCToTSITimezoneDate(subData.activationDate[index])
        : this.$t('manageSubscriptions.notActivated')
    },
    determineRenewalDate(subData, index) {
      //This date comes in as an CST date and time string without the 'Z' (weird I know). Ex: 2023-02-15T00:00:00
      if (
        subData.expires[index] &&
        subData.canceled &&
        subData.canceled[index]
      ) {
        return this.$t('manageSubscriptions.canceled')
      } else if (subData.canceled && subData.canceled[index]) {
        return this.$t('manageSubscriptions.cancellationPending')
      } else if (subData.billingInfo[index].autoRenewalDate) {
        return this.convertToDateString(
          subData.billingInfo[index].autoRenewalDate
        )
      }
      return this.$t('manageSubscriptions.notApplicable')
    },
    determineEndDate(subData, index) {
      //This date comes in as a CST date. Ex: 2023-02-15
      if (subData.expires[index]) {
        return this.convertToDateString(subData.expires[index])
      }
      return this.$t('manageSubscriptions.notApplicable')
    },
    convertToDateString(str) {
      //Ex: 2023-02-15 or 2023-02-15T00:00:00 -> 02/15/2023
      try {
        const cstDateParts = str.split('-')
        cstDateParts[2] = cstDateParts[2].split('T')[0]
        return `${cstDateParts[1]}/${cstDateParts[2]}/${cstDateParts[0]}`
      } catch (e) {
        // ignore, display str instead
      }
      //if conversion goes wrong just return the string so we don't stop displaying the table
      return str
    },
    convertUTCToTSITimezoneDate(str) {
      //Ex: 2022-02-16T00:00:00.000Z -> 02/16/2022
      const d = new Date(str)
      if (!isNaN(d)) {
        // date object is valid
        return d.toLocaleString('en-US', {
          timeZone: 'America/Chicago',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      }
      //if conversion goes wrong just return the string so we don't stop displaying the table
      return str
    },
    isPurchaseOrderPaymentType(subData, index) {
      return subData.billingInfo[index].paymentType === paymentTypes.PO
    },
    isCreditCardPaymentType(subData, index) {
      return subData.billingInfo[index].paymentType === paymentTypes.CC
    },
    setSubscriptionsTableSettings() {
      const save = {
        itemsPerPage: this.itemsPerPage,
        sortBy: this.sortedBy,
        sortDesc: this.sortDesc,
      }
      localStorage.setItem(LOCAL_STORAGE_KEYS, JSON.stringify(save))
    },
    getSubscriptionsTableSettings() {
      const settings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS))
      if (settings !== null) {
        this.sortDesc = settings.sortDesc
        this.itemsPerPage = settings.itemsPerPage
        this.sortedBy = settings.sortBy
      }
    },

    mapStandardSubscriptionDevicesInfo(deviceIds, ownedDevices) {
      const devices = []
      deviceIds.forEach((serial) => {
        let found = ownedDevices.find( d => d.serial === serial)
        if(found) {
          devices.push(found)
        } else {
          let hardware = this.getGatewayDeviceDetailsByStudyTypeSerial(serial)
          if(hardware) {
            devices.push(hardware)
            found = true
          }
          hardware = this.getDeviceDetailsByStudyTypeSerial(serial)
          if(hardware) {
            devices.push(hardware)
            found = true
          }
          if(!found) {
            devices.push({
                name: this.$t('manageSubscriptions.notApplicable'),
                type: this.$t('manageSubscriptions.notApplicable'),
                serial
            })
          }
        }
      })
      return devices
    },

    mapSubscriptionDevicesInfo(deviceIds, ignoreOwned = false) {
      const devices = []
      deviceIds.forEach((deviceModelAndSerial) => {
        let serial = deviceIdToSerial(deviceModelAndSerial)
        const deviceId = this.$store.getters['devices/getDeviceIdByDeviceSerial'](serial) || ''
        if (deviceId) {
          if( ignoreOwned || this.isDeviceOwned(deviceId) ) {
            devices.push({
              name: this.$store.getters['devices/getNameByDeviceId'](deviceId) || this.$t('manageSubscriptions.notApplicable'),
              type: this.getDeviceTypeDetails(deviceId) || this.$t('manageSubscriptions.notApplicable'),
              serial
            })
          }
        }
      })

      return devices
    },

    getSubscriptionDevices(deviceIds, subId) {
      let devices = []
      if (deviceIds && deviceIds.length) {
        if (subId === subscriptionTiers.TSILINK_STANDARD) {
          devices = this.mapSubscriptionDevicesInfo(deviceIds, true)
          devices = this.mapStandardSubscriptionDevicesInfo(deviceIds, devices)
        } else {
          devices = this.mapSubscriptionDevicesInfo(deviceIds)
        }
      }

      return devices
    },

    getStudyModelType (deviceModel) {
      const key = `model.${deviceModel}`
      if (!this.$te(key)) {
        return this.$t('manageSubscriptions.notApplicable')
      }
      return this.$t(key)
    },
    getGatewayDeviceDetailsByStudyTypeSerial(serial) {
      if( this.studyGatewayDevices.has(serial) ){
        const hardware = this.studyGatewayDevices.get(serial)
        return {
          name: hardware.name ? hardware.name : this.$t('manageSubscriptions.notApplicable'),
          type: this.getStudyModelType(hardware.model),
          serial
        }
      }
      return null
    },
    getDeviceDetailsByStudyTypeSerial(serial) {
      if( this.studyDevices.has(serial) ){
        const hardware = this.studyDevices.get(serial)
        return {
          name: hardware.name ? hardware.name : this.$t('manageSubscriptions.notApplicable'),
          type: this.getStudyModelType(hardware.model),
          serial
        }
      }
      return null
    },
    getDeviceTypeDetails(deviceId) {
      const model = this.$store.getters['devices/getModelByDeviceId'](deviceId) || ''
      const submodel = this.$store.getters['devices/getSubmodelByDeviceId'](deviceId) || ''
      const modelKey = model ? this.$store.getters['devicemodels/getModelKey'](model) : ''
      const submodelKey = this.$store.getters['devicemodels/getSubmodelKey'](model, submodel)
      const deviceModelName = deviceModelDisplay(modelKey, this.$t.bind(this))
      const deviceSubmodelName = deviceSubmodelDisplay(submodelKey, this.$t.bind(this))
      return submodel ? `${deviceModelName} (${deviceSubmodelName})` : deviceModelName
    },
    getMobileHeaders(headers, item) {
      return headers.filter((header) => {
        return item.hasOwnProperty(header.value) && header.value !== 'subscription'
      })
    },
    isDeviceOwned(deviceId) {
      return (
        this.accountId ===
        this.$store.getters['devices/getAccountByDeviceId'](deviceId)
      )
    },
    isSubscriptionExpandable(subscription) {
      if (subscription.subscriptionId === subscriptionTiers.TSILINK_STANDARD) {
        return true
      }

      return !!(subscription?.deviceQuantityUsed)
    },
    isRowExpanded(activationId) {
      return this.expanded.findIndex((subscription) => {
        return subscription.activationId === activationId
      }) !== -1
    },
    autoExpandDevices() {
      this.tableComputedItems.forEach((subscription) => {
        let foundSerial = false
        if (this.search && this.search.length >= 4) {
          subscription.devices.forEach((device) => {
            if (device.serial.indexOf(this.search.toString()) !== -1) {
              foundSerial = true
            }
          })
        }

        if (foundSerial) {
          this.expandSubscriptionDevices(subscription)
        }
      })
    },
    expandAllDevices() {
      this.expanded = this.expandableSubscriptions
    },
    expandSubscriptionDevices(subscription) {
      if (subscription) {
        const isExpanded = this.isRowExpanded(subscription.activationId)
        if (!isExpanded) {
          this.expanded.push(subscription)
        }
      }
    },
    collapseAllDevices() {
      this.expanded = []
    },
    collapseSubscriptionDevices(subscription) {
      if (subscription) {
        const isExpanded = this.isRowExpanded(subscription.activationId)
        if (isExpanded) {
          this.expanded = this.expanded.filter((expandedSubscription) => {
            return expandedSubscription.activationId !== subscription.activationId
          })
        }
      }
    },
    filterByColumnValueAndDeviceSerial(value, search, item) {
      const foundColumnValue = value != null && search != null &&
        (value.toString().toLowerCase().indexOf(search.toString().toLowerCase()) !== -1) // Filter by column values
      if (!foundColumnValue && search?.length >= 4) { // Else filter by serial number
        return item.devices.findIndex((device) => {
          return device.serial.indexOf(search.toString()) !== -1
        }) !== -1
      }

      return foundColumnValue
    },
     /**
     * this  method applies additional logic for rendering data for generic
     * TSI Link subs 7593-xx and Addons (File Imports, Data Storage)
     */
    updateTableComputedItems(computedItems) {
      const NA = this.$t('manageSubscriptions.notApplicable')
      computedItems.forEach((item) => {
        const subId = item.subscriptionId
        // Standard sub
        if (
          this.subIsGenericTSILink(subId)
          && !this.isAddOnSub(subId)
          && !this.isSmartBridgeDeviceFeeAddOn(subId)
        ) {
          item.deviceQuantityUsed = item.deviceQuantityAllowance = NA
        }
        // append GB to data storage displayed value
        if (this.isDataStorage(subId)) {
          let quantityAllowance = String(item.deviceQuantityAllowance)
          if (!quantityAllowance.includes('GB')) {
            item.deviceQuantityAllowance = `${quantityAllowance} GB`
          }
        }

        if (this.isDataStorage(subId)) {
          //Check if API call was successful. If so, show storage amounts. If not, leave field blank
          if (this.sapAccountStorage != -1 && this.cloudAccountStorage != -1){
            const [sapSize, sapUnits] = this.sapAccountStorage
            const [cloudSize, cloudUnits] = this.cloudAccountStorage
            const sapStorage = `${sapSize} ${sapUnits}`
            const cloudStorage = `${cloudSize} ${cloudUnits}`
            item.deviceQuantityUsed = this.$t('manageSubscriptions.quantityUsedAddons', {
              sapQuantity: sapStorage,
              cloudQuantity: cloudStorage,
            })
          }
        }

        if (
            this.enableFileCount // feature flag enabled
            && this.isFileImport(subId)
            && this.sapAccountCount != -1
            && this.cloudAccountCount != -1
          ) {
          item.deviceQuantityUsed = item.deviceQuantityUsed = this.$t('manageSubscriptions.quantityUsedAddons', {
              sapQuantity: this.sapAccountCount,
              cloudQuantity: this.cloudAccountCount,
            })
        }

        if (
          this.isAddOnSub(subId)
          && !this.isSmartBridgeDeviceFeeAddOn(subId)
        ) {
          item.activationDate = NA
          item.renewalDate = NA
          item.endDate = NA
          item.billingMethod = NA
          item.paymentDetails = NA
        }
      })

      this.tableComputedItems = computedItems
      this.autoExpandDevices()
    },
    itemCanBeExpanded(item) {
      const subId = item.subscriptionId
      if (subId === subscriptionTiers.TSILINK_STANDARD) {
        return true
      }
      if (this.isAddOnSub(subId)) {
        return false
      }
      const dqu = item.deviceQuantityUsed
      return !!(dqu && dqu !== this.$t('manageSubscriptions.notApplicable'))
    }
  },
  computed: {
    enableFileCount() {
      return this.$store.getters['featureFlags/getFeatureFlagBySlug'](
        featureFlags.FileImportCountEnabled
      )
    },
    genericTSILinkSubs() {
      return this.$store.getters['subscriptions/getGenericTSILink']
    },
    basicSubscriptions() {
      return this.$store.getters['subscriptions/getBasic']
    },
    premiumSubscriptions() {
      return this.$store.getters['subscriptions/getPremium']
    },
    dataSubscriptions() {
      return this.$store.getters['subscriptions/getDataServices']
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    userSubscriptions() {
      const userSubs = []
      userSubs.push(
        ...this.basicSubscriptions,
        ...this.premiumSubscriptions,
        ...this.dataSubscriptions,
        ...this.genericTSILinkSubs
      )
      const tableSubs = this.subscriptionToTableHelper(userSubs)
      return tableSubs
    },
    expandableSubscriptions() {
      return this.userSubscriptions.filter((subscription) => {
        return this.isSubscriptionExpandable(subscription)
      })
    },
    customTableFilter() {
      if (this.search.length >= 2) {
        return this.search
      } else if (this.search.length === 1) {
        return '//no-result//'
      }
      return ''
    },
    enableExpandableRows() {
      return !!((
        this.$store.getters['featureFlags/getFeatureFlagBySlug'](
          featureFlags.ExpandManageSubscriptionsRows
        ) && this.expandableSubscriptions.length)
      )
    },
    isLastRowExpanded() {
      const lastRow = this.tableComputedItems.length ? this.tableComputedItems[this.tableComputedItems.length - 1] : null
      return lastRow ? this.isRowExpanded(lastRow.activationId) : false
    },
    isLogInAs() {
      return this.$store.getters['loginas/getIsLogInAs']
    },
  },
}
</script>
<style lang="scss">
// requires non-scoped scss to override the vuetify styles
.manage-subscriptions-card {
  .v-data-table {
    .v-data-table-header-mobile__select {
      min-width: unset !important;
    }

    .v-data-table__wrapper {
      .subscription-tier-column {
        min-width: 130px;
      }
    }

    .v-data-table-header-mobile__wrapper {
      .v-select {
        max-width: 335px !important;
      }
    }

    .v-data-table__empty-wrapper {
      display: none;
    }

    .v-data-footer {
      margin-right: 8px !important;
    }
  }

  .table-bar {
    .v-input__prepend-outer {
      margin-left: 16px !important;
    }
  }

  .v-data-table--mobile {
    .v-data-footer__pagination {
      margin: 0 16px 0 12px !important;
    }
  }

  .v-data-table__expanded__content {
    box-shadow: none !important;
  }
}

.manage-subscriptions-card.is-mobile {
  .v-data-table-header__icon {
    opacity: 1 !important;
  }

  .v-data-table-header-mobile {
    th {
      border-bottom: none !important;
    }
  }
}
</style>
<style lang="scss" scoped>
#returnButton {
  margin-top: 1em;
}

.returnText {
  vertical-align: bottom;
  margin-bottom: -0.5em;
}

.timezoneDetails {
  padding-left: 22px;
  font-size: 0.75rem;
  color: black;
  margin-top: 16px;
  margin-bottom: 0;
}

.emptyMessage {
  height: 240px;
  line-height: 240px;
  color: rgba(0, 0, 0, 0.56);
}

.table-bar {
  display: flex;
  height: 50px;

  background-color: transparent;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;

  .search-field {
    max-width: 375px;
  }
}

ul {
  padding: 8px 8px 16px;
  list-style-type: none;

  .item-title {
    font-weight: 500;
    font-size: 15px;
  }
}

.v-data-table--mobile {
  .v-data-table__expand-icon {
    height: fit-content;
  }

  .expandable-option-mobile {
    margin-left: -0.7rem;
  }

  .v-data-footer {
    .expand-collapse-buttons {
      display: none;
    }
  }
}

::v-deep .last-row-expanded:not(.mobile) {
  .v-data-footer {
    border-top: none !important;
  }
}
</style>

<template>
  <base-widget
    :deviceIds="deviceIds"
    :widgetId="widgetId"
    :widgetType="widgetType"
    :widgetMaxDevices="widgetMaxDevices"
    :title="name"
    :subtitle="deviceType"
    class="history-widget"
    :fullscreen="true"
    :observeSize="true"
    @fullscreenChange="fullscreenChange"
    @removeDevice="removeDevice"
    @removeWidget="removeWidget"
    @widgetSizeChanged="updateWidgetSize"
    @refreshDashboard="refreshDashboard"
    data-cy="HistoryWidget"
  >
    <history-menu-items
      slot="actions"
      :selectedDevices="graph.selectedDevices"
      :passedinParameter.sync="selectedParameter"
      :widgetId="widgetId"
      :permissions="permissions"
      @parameterChange="parameterChange"
    />
    <history-graph
      slot="content"
      class="history-widget-content"
      ref="historyGraph"
      :open="graph.isOpen"
      :allowMonth="false"
      :allowWeek="false"
      :allowThreeMonths="false"
      :isFullscreen="graph.isFullscreen"
      :isDetachedDialog="graph.isDetachedDialog"
      :removableDevices="graph.removableDevices"
      :selectedDevices="graph.selectedDevices"
      :selectedParameter="selectedParameter"
      :selectedTimePeriod="graph.timePeriod"
      :width="dimensions.width"
      :height="dimensions.height"
      :permissions="permissions"
      @graphPeriodChanged="updateTimePeriod"
      @removeDevice="removeDevice"
    />
    <div slot="left-toolbar" class="toolbar">
      <v-btn text @click="refresh" data-cy="refreshHistory">
        <v-icon color="grey">mdi-refresh</v-icon>
      </v-btn>
    </div>
  </base-widget>
</template>

<script>
import BaseWidget from './BaseWidget.vue'
import HistoryGraph from '../history/HistoryGraph.vue'
import { widgetSettings } from '../../services/dashboard'
import HistoryMenuItems from '../history/HistoryMenuItems.vue'
import { deviceModelDisplay } from '../../services/map'
import { getPermissionsByDeviceId } from '../../services/subscriptions'
import { getUserAccountId } from '../../helpers/loginas/logInAsHelper'
import measurementOptionsMixin from '../../mixins/widgets/measurementOptionsMixin'

export default {
  components: {
    'base-widget': BaseWidget,
    'history-graph': HistoryGraph,
    'history-menu-items': HistoryMenuItems,
  },
  mixins: [widgetSettings, measurementOptionsMixin],
  props: {
    widgetId: String,
    widgetType: String,
    widgetMaxDevices: Object,
    deviceIds: {
      type: Array,
      default() {
        return []
      }
    },
    width: Number,
    height: Number,
    settings: Object,
  },
  async mounted() {
    this.accountId = await getUserAccountId(this.$auth)
    this.applyWidgetSettings()
    this.checkDevicesLoaded()
  },
  data() {
    return {
      accountId: null,
      graph: {
        isOpen: false,
        isFullscreen: false,
        isDetachedDialog: false,
        removableDevices: true,
        selectedDevices: [],
        timePeriod: 'day',
      },
      dimensions: {
        width: 0,
        height: 0,
      },
    }
  },
  watch: {
    devicesLoaded() {
      this.checkDevicesLoaded()
    },
    selectedDevices() {
      this.updateDeviceList()
    },
  },
  computed: {
    deviceId() {
      return this.deviceIds?.[0]
    },
    devicesLoaded() {
      let allLoaded = false
      const isLoaded = this.deviceIds.map((id) => {
        const name = this.$store.getters['devices/getNameByDeviceId'](id)
        return name ? true : false
      })
      allLoaded = isLoaded.includes(false) ? false : true
      return allLoaded
    },
    name() {
      return this.deviceIds.length === 1
        ? this.$store.getters['devices/getNameByDeviceId'](this.deviceId)
        : this.$t('widget.title.multiple')
    },
    deviceType() {
      let deviceType
      if (this.deviceIds.length === 1) {
        const deviceModel = this.$store.getters['devices/getModelByDeviceId'](
          this.deviceId
        )
        const modelKey =
          this.$store.getters['devicemodels/getModelKey'](deviceModel)
        deviceType = deviceModelDisplay(modelKey, this.$t.bind(this))
      }
      return deviceType
    },
    permissions() {
      let perms = {}
      for (const device of this.selectedDevices) {
        const deviceId = device.id
        perms[deviceId] = getPermissionsByDeviceId(deviceId)
      }
      return perms
    },
    defaultParameter() {
      return this.$store.getters['map/getMeasurementType']
    },
  },
  methods: {
    initWidget() {
      this.applyWidgetSettings()
      this.updateDeviceList()
      this.graph.selectedDevices = this.selectedDevices

      // Note: nextTick is required, so that HistoryGraph is initialized with
      // open set to false, and then changed to true. This allows the graph to
      // know when it is opened and to load data for visualization
      Vue.nextTick(() => {
        this.graph.isOpen = true
      })
    },
    checkDevicesLoaded() {
      // initialize once the devices exist
      if (this.devicesLoaded) {
        this.initWidget()
      }
    },
    parameterChange(newValue) {
      this.selectedParameter = newValue
    },
    fullscreenChange(newValue) {
      this.graph.isFullscreen = newValue
      this.$emit('fullscreenChange', this.graph.isFullscreen)
    },
    applyWidgetSettings() {
      if (this.settings) {
        const {timePeriod } = this.settings
        if (timePeriod) this.graph.timePeriod = timePeriod
      }
    },
    updateTimePeriod(timePeriod) {
      if (timePeriod) {
        this.graph.timePeriod = timePeriod
        this.saveWidgetSettings(this.widgetId, { timePeriod })
      }
    },
    removeDevice(deviceIndex, options) {
      const deviceIds = [...this.deviceIds]
      deviceIds.splice(deviceIndex, 1)
      this.updateWidgetDevices(deviceIds)
      if (deviceIds.length === 0) {
        this.removeWidget(options)
      }
    },
    updateWidgetDevices(deviceIds) {
      this.$emit('devicesChanged', deviceIds)
    },
    removeWidget(options) {
      this.$emit('removeWidget', options)
    },
    updateWidgetSize(dims) {
      this.dimensions = dims
    },
    refreshDashboard() {
      this.$emit('refreshDashboard')
    },
    refresh() {
      this.$refs.historyGraph.loadData()
    },
    updateDeviceList() {
      this.graph.selectedDevices = this.selectedDevices
    },
    getNameByDeviceId(id) {
      return this.$store.getters['devices/getNameByDeviceId'](id)
    },
    getModelByDeviceId(id) {
      return this.$store.getters['devices/getModelByDeviceId'](id)
    },
  },
}
</script>
<style lang="scss" scoped>
.history-widget {
  @media (max-width: 320px) {
    height: 500px;
  }
  .history-widget-content {
    margin: -18px -16px -16px -16px;
  }
}
.toolbar {
  padding-left: 6px;
  .v-btn {
    min-width: 10px !important;
    padding: 0 0.4rem !important;
  }
}
</style>

<template>
  <div class="multiple-devices-summary-message">
    <icon-with-message color="green" icon="mdi-check-circle"
      :message="formatMessage($t('registerMultipleDevices.devicesRegistered'), registeredDevices)"
      data-cy="registeredDevices" :data-devices="registeredDevices" />
    <icon-with-message color="red" icon="mdi-alert-circle" :message="failedFormattedMessage" data-cy="failedDevices"
      :data-devices="failedDevices" />
    <error-list v-if="showErrorList" :errors="errors" />
    <icon-with-message v-if="showNotProcessedDevices" color="orange darken-2" icon="mdi-alert"
      :message="formatMessage($t('registerMultipleDevices.devicesNotProcessed'), notProcessedDevices)"
      data-cy="notProcessedDevices" :data-devices="notProcessedDevices" />
  </div>
</template>

<script>
import errorList from './content/errorList.vue'
import iconWithMessage from './content/iconWithMessage.vue'

export default {
  name: "MultipleDevicesSummaryMessage",
  props: {
    errors: Array,
    registeredDevices: Number,
    failedDevices: Number,
    notProcessedDevices: Number
  },
  components: {
    'error-list': errorList,
    'icon-with-message': iconWithMessage
  },
  computed: {
    showErrorList() {
      return !!(this.errors && this.errors.length)
    },
    showNotProcessedDevices() {
      return !!((!this.registeredDevices && !this.failedDevices) || this.notProcessedDevices)
    },
    failedFormattedMessage() {
      const failedDevicesMessage = this.formatMessage(this.$t('registerMultipleDevices.devicesNotRegistered'), this.failedDevices)
      return this.failedDevices ? failedDevicesMessage : failedDevicesMessage.replace(':', '.')
    }
  },
  methods: {
    formatMessage(message, number) {
      return message.replace('/numberOfDevices/', number)
    }
  }
}
</script>

<style lang="scss" scoped>
.error-list {
  padding-left: 2.5rem;
}
</style>

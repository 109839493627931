<template>
  <base-dialog
    :open="open"
    :title="$t('alertHistory.export.title')"
    :persistentOverlay="true"
    icon="mdi-file-download"
    @close="close"
    padded
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" @click.stop="open = true" text>
        <v-icon class="op-76">mdi-file-download</v-icon>
        <span style="padding-left: 4px">{{ $t('buttons.export') }}</span>
      </v-btn>
    </template>
    <v-card v-if="downloading" class="padding-top-16" flat>
      <span class="message">
        {{ $t('alertHistory.export.dialog.message') }}
      </span>
    </v-card>
    <v-card v-else class="padding-24" flat>
      <div class="form-section-header">
        {{ $t('alertHistory.export.dialog.dateRange') }}
      </div>
      <v-row justify="center">
        <v-menu
          ref="fromDate"
          v-model="fromDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateStart"
              label="From"
              placeholder="MM/DD/YYYY"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dateStart"
            no-title
            @input="fromDate = false"
            :min="minDate"
            :max="maxDate"
            :show-current="false"
          />
        </v-menu>
      </v-row>
      <v-row justify="center">
        <v-menu
          ref="toDate"
          v-model="toDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateEnd"
              label="To"
              placeholder="MM/DD/YYYY"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dateEnd"
            no-title
            @input="toDate = false"
            :min="minDate"
            :max="maxDate"
            :show-current="false"
          />
        </v-menu>
      </v-row>
    </v-card>
    <template v-slot:actions>
      <template v-if="!downloading">
        <v-btn @click="close" outlined class="black--text" text>
          {{ $t('buttons.close') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="exportNotifications" class="primary white--text" text>
          {{ $t('buttons.export') }}
        </v-btn>
      </template>
      <template v-if="downloading">
        <v-spacer></v-spacer>
        <v-btn
          v-if="downloading"
          @click="close"
          class="primary white--text"
          text
        >
          {{ $t('buttons.done') }}
        </v-btn>
      </template>
    </template>
  </base-dialog>
</template>

<script>
import dialog from './Dialog'
const DATE_TODAY = new Date()
const ONE_DAY_MS = 1000 * 60 * 60 * 24
const THIRTY_DAYS_MS = ONE_DAY_MS * 30

export default {
  name: 'ExportNotifications',
  components: {
    'base-dialog': dialog,
  },
  data() {
    return {
      open: false,
      dateStart: this.formatDate(new Date().getTime() - ONE_DAY_MS),
      dateEnd: this.formatDate(new Date().getTime()),
      fromDate: false,
      toDate: false,
      downloading: false,
    }
  },
  computed: {
    minDate() {
      return this.formatDate(DATE_TODAY.getTime() - THIRTY_DAYS_MS + ONE_DAY_MS)
    },
    maxDate() {
      return this.formatDate(DATE_TODAY)
    },
  },
  watch: {
    dateStart() {
      if (new Date(this.dateStart) > new Date(this.dateEnd)) {
        this.dateEnd = this.dateStart
      }
    },
    dateEnd() {
      if (new Date(this.dateStart) > new Date(this.dateEnd)) {
        this.dateStart = this.dateEnd
      }
    },
  },
  methods: {
    close() {
      this.$emit('close')
      this.open = false
      setTimeout(() => {
        this.downloading = false
      }, 50)
    },
    async exportNotifications() {
      this.downloading = true

      let RFC3339End

      if (new Date(this.dateEnd).getUTCDate() == new Date().getUTCDate()) {
        RFC3339End = new Date().toISOString()
      } else {
        RFC3339End = new Date(this.dateEnd).toISOString()
      }
      const RFC3339Start = new Date(this.dateStart).toISOString()

      await this.$api.getNotificationsCSV(RFC3339Start, RFC3339End)
    },
    formatDate(date) {
      return new Date(date).toISOString().substr(0, 10)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    // padding: 24px;
    min-height: 350px;
    .v-input {
      padding-top: 24px;
    }
    .form-section-header {
      font-weight: 300;
      font-size: 18px;
      margin-left: -12px;
      opacity: 0.56;
    }
    .message {
      color: rgba(0, 0, 0, 0.56);
    }
  }
  .v-card__subtitle {
    min-height: 30px;
    padding: 16px 12px 8px 12px;
  }
  .v-card__title {
    font-weight: 300;
    padding: 0 0 16px 0;
  }
  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }
}

.op-76 {
  opacity: 0.76;
}
</style>

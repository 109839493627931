import { tempUnits } from '../../services/unit-conversion'
import { UnitOfMeasure } from '@fusion/units'

export default {
  settings: {
    visited: null,
    lastViewedVersion: '0.0.1',
    viewedSubscriptionExpired: null,
    devicesUpdated: [],
  },
}

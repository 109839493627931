import { GET, POST, PATCH, DELETE } from './services/helpers'
import {
  getDevices,
  getUserDevices,
  getDevice,
  postRegisterDevice,
  postUnregisterDevice,
  patchUpdateDevice,
  postShareDevice,
  getDeviceCollaborators,
  deleteDeviceCollaborator,
  getDeviceConfig,
  postDeviceConfig,
  getModelsMetaData,
  patchDeviceSubscription,
} from './services/device'
import { getCSV, getNotificationsCSV } from './services/data-export'
import {
  getLatest as getTelemetryLatest,
  getGraphData as getTelemetryGraphData,
  getAverages as getTelemetryAverages,
  getPercentChanged as getTelemetryPercentChanged,
} from './services/telemetry'
import { getMetadata } from './services/user-metadata'
import {
  getUserSubscriptions,
  getUserPermissions,
  postValidateSubscriptionCode,
  patchCancelSubscription,
  postBillingInfoPurchaseOrder,
  postBillingInfoCreditCard,
  getDelegoToken,
} from './services/user-subscription'
import {
  getUser,
  patchUpdateUser,
  postResetPassword,
  patchUserFullName,
  patchUserSettings,
  getUserSettings,
  postUserSettings,
} from './services/user'
import {
  getAlert,
  getAlerts,
  postAlert,
  patchAlert,
  deleteAlert,
  getAlertNotifications,
  patchClearNotification,
  postClearAllNotifications,
  postClearAllDeviceNotifications,
  deleteAlertNotification,
} from './services/alert'
import { deviceType } from './services/utils'
import {
  getWidgets,
  postWidget,
  patchWidget,
  deleteWidget,
  getLayout,
  postLayout,
} from './services/dashboard'
import {
  getAccountsForAdminPanel,
  healthChecker,
} from './services/admin-panel'
import {
  getLatestStudyRegistrations,
  getLatestDeviceStudy,
  getFileImportCount,
  getTotalStorageUsed,
} from './services/studies'
import {
  createEmulator,
  getAllEmulators,
} from './services/developer-panel'
let instance

export const getInstance = () => instance

const useApi = (opt) => {
  if (instance) return instance

  instance = new Vue({
    data() {
      return {
        $auth: opt.auth(),
        deviceType,
      }
    },
    methods: {
      GET,
      POST,
      PATCH,
      DELETE,
      getDevices,
      getUserDevices,
      getDevice,
      postRegisterDevice,
      patchUpdateDevice,
      postShareDevice,
      getDeviceCollaborators,
      deleteDeviceCollaborator,
      getDeviceConfig,
      postDeviceConfig,
      getModelsMetaData,
      patchDeviceSubscription,
      postUnregisterDevice,
      getCSV,
      getNotificationsCSV,
      getTelemetryLatest,
      getTelemetryGraphData,
      getTelemetryAverages,
      getTelemetryPercentChanged,
      getMetadata,
      getUser,
      getUserPermissions,
      patchUpdateUser,
      postResetPassword,
      patchUserFullName,
      patchUserSettings,
      getUserSubscriptions,
      postValidateSubscriptionCode,
      patchCancelSubscription,
      postBillingInfoPurchaseOrder,
      postBillingInfoCreditCard,
      getDelegoToken,
      getUserSettings,
      postUserSettings,
      getAlert,
      getAlerts,
      postAlert,
      patchAlert,
      deleteAlert,
      getWidgets,
      postWidget,
      patchWidget,
      deleteWidget,
      getLayout,
      postLayout,
      getAlertNotifications,
      patchClearNotification,
      postClearAllNotifications,
      postClearAllDeviceNotifications,
      deleteAlertNotification,
      getAccountsForAdminPanel,
      healthChecker,
      getLatestStudyRegistrations,
      getLatestDeviceStudy,
      getFileImportCount,
      getTotalStorageUsed,
      createEmulator,
      getAllEmulators
    },
  })

  return instance
}

export default {
  install(vue, options) {
    vue.prototype.$api = useApi(options)
  },
}

<template>
  <v-row justify="center" align="center" height="100%" class="page-container">
    <v-dialog v-model="showErrorDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h6">Account Creation Error</v-card-title>
        <v-card-text>
          <p>Unable to create your account for the following reason(s):</p>
          <p style="color: red">{{ errorMessage }}</p>
          <p>
            This should only happen in development. Please ensure the Auth0
            account has the required information in 'user_metadata' then come
            back and refresh the browser.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="loading-container">
      <v-card class="loading-card">
        <div class="progress-container">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          <p class="loading-text text-center">
            <i18n path="accountCreation.content" tag="p"></i18n>
          </p>
        </div>
      </v-card>
    </div>
  </v-row>
</template>

<script>
import { AuthEvents } from '@fusion/auth'

export default {
  data() {
    return {
      showErrorDialog: false,
      errorMessage: '',
    }
  },

  mounted() {
    if (this.$auth.isAuthenticated) {
      return this.checkAuthAndCreateAccount()
    }

    this.$auth.$on(AuthEvents.AUTH_CHANGED, async (isAuthenticated) => {
      if (isAuthenticated) {
        return this.checkAuthAndCreateAccount()
      }
    })
  },
  computed: {
    email: function () {
      return this.$auth.user.email
    },
  },
  methods: {
    async checkAuthAndCreateAccount() {
      if (this.$auth.isAuthenticated) {
        const email = this.$auth.user.email
        const firstName = this.$api.getMetadata('given_name')
        const lastName = this.$api.getMetadata('family_name')
        const location = this.$api.getMetadata('location')
        const errorMessages = []

        if (!email) {
          errorMessages.push('Auth0 email is required')
        }
        if (!firstName) {
          errorMessages.push('Auth0 given name is required')
        }
        if (!lastName) {
          errorMessages.push('Auth0 family name is required')
        }
        if (!location) {
          errorMessages.push('Auth0 location is required')
        }

        if (errorMessages.length) {
          this.errorMessage = errorMessages.join('. ') + '.'
          this.showErrorDialog = true
          return
        }

        await this.$auth.getTokenSilently({ cacheMode: 'off' })

        this.$router.push('/home').catch(() => {})
      }
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  padding: 16px;
}

.page-container {
  height: 90vh;
}

.v-subheader {
  margin-top: 16px;
  padding-bottom: 8px;
  font-size: 1.1rem;
  opacity: 0.76;
}

.v-card {
  padding-right: 0;
  @media (max-width: 791px) {
    padding: 0;
  }
}

.v-card__text {
  padding: 0 16px 0 16px;
}

.v-application .title {
  font-size: 24px !important;
  font-weight: 400;
}

.loading-container {
  width: 600px;

  .loading-card {
    .progress-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .v-progress-circular {
        margin-top: 16px;
        margin-bottom: 32px;
      }

      .loading-text {
        margin: 0px;
        opacity: 0.76;
        font-size: 1.5em;

        @media (max-width: 600px) {
          font-size: 1em;
        }
      }
    }
  }
}
</style>

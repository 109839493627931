<template>
  <v-card class="no-top-padding" flat>
    <div v-if="isLoading" class="loading-container">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-card-text
      class="no-top-padding buffered-content"
      :class="{ 'opacity-20': isLoading }"
    >
      <v-card-title>{{
        $t('accountSubscriptions.subscriptionDetails')
      }}</v-card-title>
      <div v-if="hasTSILinkSubs" data-cy="userProfileSubscriptionsWebsite">
        <v-card-subtitle>
          <strong>{{ $t('accountSubscriptions.TSISubscriptions') }}</strong>
        </v-card-subtitle>
        <template v-if="hasSubscriptions.basic">
          <v-card-text
            class="less-top"
            v-for="sub in basicSubscriptions"
            :key="sub.id"
          >
            {{ sub.name }}
            <br />
            {{ $t('accountSubscriptions.deviceAllowance') }}
            {{
              $t('accountSubscriptions.deviceSubscriptionsUsedRemaining', {
                qty: sub.totalUsed,
                remaining: sub.totalQtyRemaining,
              })
            }}
          </v-card-text>
        </template>
        <template v-if="hasSubscriptions.premium">
          <v-card-text
            v-for="sub in premiumSubscriptions"
            :key="sub.id"
            class="less-top"
          >
            {{ sub.name }}
            <br />
            {{ $t('accountSubscriptions.deviceAllowance') }}
            {{
              $t('accountSubscriptions.deviceSubscriptionsUsedRemaining', {
                qty: sub.totalUsed,
                remaining: sub.totalQtyRemaining,
              })
            }}
          </v-card-text>
        </template>
        <template v-if="hasOnlyFreeTier">
          <v-card-text v-for="sub in freeTier" :key="sub.id" :data-cy="sub.id">
            {{ $t('accountSubscriptions.freeTier') }}
            <br />
            {{ $t('accountSubscriptions.deviceAllowance') }}
            {{
              $t('accountSubscriptions.deviceSubscriptionsUsedRemaining', {
                qty: sub.totalUsed,
                remaining: sub.totalQtyRemaining,
              })
            }}
          </v-card-text>
        </template>
        <template v-if="hasSubscriptions.genericSubsWithoutSmartBridgeDeviceFee">
          <v-card-text class="less-top" v-for="sub in genericSubsWithoutSmartBridgeDeviceFee" :key="sub.id" :data-cy="sub.id">
            {{ sub.name }}
          </v-card-text>
        </template>
        <template v-if="hasSubscriptions.smartBridgeDeviceFeeSub">
          <v-card-text
            v-for="sub in smartBridgeDeviceFeeSub"
            :key="sub.id"
            class="less-top"
            :data-cy="sub.id"
          >
            {{ sub.name }}
            <br />
            {{ $t('accountSubscriptions.deviceAllowance') }}
            {{
              $t('accountSubscriptions.deviceSubscriptionsUsedRemaining', {
                qty: sub.totalUsed,
                remaining: sub.totalQtyRemaining,
              })
            }}
          </v-card-text>
        </template>
      </div>
      <div v-if="hasSubscriptions.dataServices">
        <v-card-subtitle class="less-top">
          <strong>{{
            $t('accountSubscriptions.dataServicesSubscriptions')
          }}</strong>
        </v-card-subtitle>
        <v-card-text
          v-for="sub in dataSubscriptions"
          :key="sub.id"
          class="less-top"
        >
          {{ sub.name }}
          <br />
          {{ $t('accountSubscriptions.deviceAllowance') }}
          {{
            $t('accountSubscriptions.deviceSubscriptionsUsedRemaining', {
              qty: sub.totalUsed,
              remaining: sub.totalQtyRemaining,
            })
          }}
        </v-card-text>
      </div>
      <br />
      <div>{{ $t('accountSubscriptions.upgradePrompt') }}</div>
      <v-btn
        @click="upgrade()"
        outlined
        class="black--text magenta-btn-color"
        id="upgradeButton"
        text
        >{{ $t('buttons.upgrade') }}</v-btn
      >
      <br />
      <div>{{ $t('accountSubscriptions.activationPrompt') }}</div>
      <v-form ref="form" v-model="validActivationCode" v-if="!validatingCode">
        <v-text-field
          :rules="rules"
          v-model="activationCode"
          @focus="reset"
          :error-messages="errorMessages"
          :success-messages="successMessages"
          data-cy="activationCodeInput"
        ></v-text-field>
        <v-btn
          id="activation-button"
          class="activate-button"
          color="primary"
          text
          :disabled="disableActivate"
          @click="activate()"
          >{{ $t('buttons.activate') }}</v-btn
        >
      </v-form>
      <v-progress-circular
        indeterminate
        color="primary"
        v-else
      ></v-progress-circular>
    </v-card-text>
    <v-card-actions text>
      <v-btn
        to="/manage-subscriptions"
        v-on:click.native="close()"
        outlined
        class="red-border red--text"
        text
        data-cy="manageSubscriptionsButton"
        >{{ $t('accountSubscriptions.manageSubscriptions') }}</v-btn
      >
      <v-spacer />
      <v-spacer />
      <v-btn
        @click="close"
        class="primary white--text"
        text
        data-cy="userProfileSubscriptionsDone"
        >{{ $t('buttons.done') }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  subscriptionTiers
} from '../../../store/subscriptions/utils'

const TSI_HOME_LINK = process.env.VUE_APP_UPGRADE_URL
const TSILINK_ADD_ON_SMARTBRIDGE_DEVICE_FEE = subscriptionTiers.TSILINK_ADD_ON_SMARTBRIDGE_DEVICE_FEE

export default {
  async mounted() {
    await this.getSubscriptions()
  },
  data() {
    return {
      activationCode: '',
      success: false,
      successMessage: this.$t('accountSubscriptions.activationSuccess'),
      error: false,
      errorMessage: this.$t(
        'accountSubscriptions.activationErrorResponses.InvalidActivationCodeError'
      ),
      validActivationCode: false,
      validatedActivationCode: false,
      validatingCode: false,
      isLoading: false,
      rules: [
        (value) => {
          // Pattern to match flexera valdition code xxxx-xxxx-xxxx-xxxx-xxxx-xxxx-xxxx-xxxx
          const pattern =
            /^[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}$/
          return (
            pattern.test(value) ||
            this.$t('accountSubscriptions.activationError')
          )
        },
      ],
    }
  },
  computed: {
    genericTSILinkSubs() {
      return this.$store.getters['subscriptions/getGenericTSILink']
    },
    genericSubsWithoutSmartBridgeDeviceFee() {
      const subsWithoutSB = this.genericTSILinkSubs.filter(sub => {
        return sub.id !== TSILINK_ADD_ON_SMARTBRIDGE_DEVICE_FEE
      })
      return subsWithoutSB
    },
    smartBridgeDeviceFeeSub() {
      const smartBridgeDeviceFeeSub = this.genericTSILinkSubs.filter(sub => {
        return sub.id === TSILINK_ADD_ON_SMARTBRIDGE_DEVICE_FEE
      })
      return smartBridgeDeviceFeeSub
    },
    freeTier() {
      return this.$store.getters['subscriptions/getFreeTier']
    },
    basicSubscriptions() {
      return this.$store.getters['subscriptions/getBasic']
    },
    premiumSubscriptions() {
      return this.$store.getters['subscriptions/getPremium']
    },
    dataSubscriptions() {
      return this.$store.getters['subscriptions/getDataServices']
    },
    hasSubscriptions() {
      return {
        freeTier: this.freeTier.length > 0,
        basic: this.basicSubscriptions.length > 0,
        premium: this.premiumSubscriptions.length > 0,
        dataServices: this.dataSubscriptions.length > 0,
        genericSubsWithoutSmartBridgeDeviceFee: this.genericSubsWithoutSmartBridgeDeviceFee.length > 0,
        smartBridgeDeviceFeeSub: this.smartBridgeDeviceFeeSub.length > 0
      }
    },
    hasTSILinkSubs() {
      return (
        this.hasSubscriptions.basic ||
        this.hasSubscriptions.premium ||
        this.hasSubscriptions.freeTier ||
        this.hasSubscriptions.genericTSILinkSubs ||
        this.hasSubscriptions.smartBridgeDeviceFeeSub
      )
    },
    hasOnlyFreeTier() {
      return (
        !this.hasSubscriptions.basic &&
        !this.hasSubscriptions.premium &&
        this.hasSubscriptions.freeTier
      )
    },
    errorMessages() {
      return this.validatedActivationCode && this.error ? this.errorMessage : ''
    },
    successMessages() {
      return this.validatedActivationCode && this.success
        ? this.successMessage
        : ''
    },
    disableActivate() {
      return !this.validActivationCode || this.success
    },
  },
  watch: {},
  methods: {
    // Get list of user subscriptions and sum up their total usable subs
    async getSubscriptions() {
      this.isLoading = true
      if (!this.$store.state.devices.allDevices) {
        await this.$store.dispatch('devices/setAllDevices')
      }
      await this.$store.dispatch('subscriptions/updateSubscriptions', {
        auth: this.$auth,
        api: this.$api,
        devices: this.$auth.isAuthenticated
          ? this.$store.state.devices.allDevices
          : [],
      })
      this.isLoading = false
    },
    // Temporary link until upgrade link is live
    upgrade() {
      window.open(TSI_HOME_LINK)
    },
    async activate() {
      this.validatingCode = true
      try {
        const resp = await this.$api.postValidateSubscriptionCode(
          this.activationCode
        )
        if (resp.ok) {
          this.success = true
          this.successMessage = this.$t(
            `accountSubscriptions.activationSuccess`
          )
          // Force auth0 to reauthenticate so rules are run again and user roles are updated
          await this.$auth.getTokenSilently({ cacheMode: 'off' })
          await this.getSubscriptions()
          // Force the UI to update with any new permissions
          window.location.reload()
        } else if (resp.status === 422) {
          //validation error handling
          const errData = await resp.json()
          const errorMessage = errData.errors[0].name //yes there could be more, but this is sufficient to inform user of validation error
          this.error = true
          this.errorMessage = this.$t(
            `accountSubscriptions.activationErrorResponses.${errorMessage}`
          )
        } else if (resp.status === 401) {
          // unauthorized error handling
          const errData = await resp.json()
          if(errData.name === "MissingSAPIDError") {
            this.error = true
            this.errorMessage = this.$t(
              `accountSubscriptions.activationErrorResponses.${errData.name}`
            )
          }
        }
      } catch (err) {
        //error activating code
      }
      this.validatedActivationCode = true
      this.validatingCode = false
    },
    reset() {
      this.validatedActivationCode = false
      this.success = false
      this.error = false
    },
    close() {
      this.$emit('close')
      this.reset()
      this.$refs.form.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
#upgradeButton {
  margin: 10px 0px 50px 0px;
}
#activation-button {
  padding: 0px;
}
#activation-button:disabled {
  padding: 0px;
  color: #c2c2c2 !important;
  background-color: transparent !important;
}
.magenta-btn-color {
  color: white !important;
  background: #ec0a8a;
}
.less-top {
  padding-top: 0;
}
.buffered-content {
  min-height: 400px;
}
.activate-button {
  background-color: transparent !important;
}
.text-field {
  padding: 0 !important;
  width: 80% !important;
  left: 30 !important;
  line-height: 0 !important;
}
.red-border {
  border-color: #f44336 !important;
}
.opacity-20 {
  opacity: 0.2;
}
.loading-container {
  position: absolute;
  top: 200px;
  left: calc(50% - 64px);
  opacity: 1;
}
.v-card__subtitle,
.v-card__text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 0.9rem;
  letter-spacing: 0.0071428571em;
}
</style>

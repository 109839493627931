<template>
  <tbody>
    <tr v-for="item in items" :key="item.id">
      <td data-cy="columnEmail">
        {{ item.email }}
      </td>
      <td data-cy="columnName">
        {{ item.username }}
      </td>
      <td data-cy="columnAccountId">
        {{ item.accountId }}
      </td>
      <td data-cy="columnSubscription">
        {{ item.subscription }}
      </td>
      <td data-cy="columnSapAcctId">
        {{ item.sapAcctId }}
      </td>
      <td data-cy="columnLastLogin">
        <div v-if="item.lastLogIn != 0" class="valid-date">
          {{ new Date(item.lastLogIn).toLocaleString() }}
        </div>
      </td>
      <td class="text-xs-left right-aligned">
        <account-action-menu
          :accountId="item.accountId"
          :userId="item.userId"
          :sapId="item.sapAcctId"
          :email="item.email"
        />
      </td>
    </tr>
  </tbody>
</template>

<script>
import AccountActionMenu from './accountActionMenu.vue'

export default {
  name: 'AccountTableBody',
  props: {
    items: Array,
  },
  components: {
    'account-action-menu': AccountActionMenu,
  },
}
</script>
<style lang="scss" scoped>
.right-aligned {
  direction: rtl;
}
</style>

<template>
  <tr :key="item.id" :data-cy="`device${item.deviceSerial}Row`" :class="{ 'child-expanded-row': isChild }" v-resize="handleResize">
    <td class="text-start" data-cy="columnExpand">
      <v-btn icon v-if="itemCanBeExpanded" @click="expand(!isExpanded)" class="v-data-table__expand-icon"
        :class="{'v-data-table__expand-icon--active' : isExpanded}">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </td>
    <td v-if="item.deviceName" style="max-width: 300px" data-cy="columnName">
      <v-tooltip
        bottom
        :max-width="600"
        :disabled="!isNameOverflowing"
      >
        <template v-slot:activator="{ on }">
          <div v-on="on" class="device-name" :ref="item.id + 'Name'">
            <span>{{ item.deviceName }}</span>
          </div>
        </template>
        <span>{{ item.deviceName }}</span>
      </v-tooltip>
    </td>
    <td v-else>{{ $t('manageDevices.unnamedDevice') }}</td>
    <td style="max-width: 300px" data-cy="columnLocationName">
      <v-tooltip
        v-if="item.deviceLocationName"
        bottom
        :max-width="600"
        :disabled="!isLocationNameOverflowing"
      >
        <template v-slot:activator="{ on }">
          <div v-on="on" class="device-name" :ref="item.id + 'LocationName'">
            <span>{{ item.deviceLocationName }}</span>
          </div>
        </template>
        <span>{{ item.deviceLocationName }}</span>
      </v-tooltip>
      <span v-else class="not-available-text">
        {{ $t('manageDevices.notAvailable') }}
      </span>
    </td>
    <td data-cy="columnDevice">
      {{ item.deviceType }}
    </td>
    <td style="max-width: 120px" data-cy="columnModel">
      {{ item.deviceModel }}
    </td>
    <td data-cy="columnSerial">
      {{ item.deviceSerial }}
    </td>
    <td
      :class="{
        'not-available-text': !item.showSubscription || !canAccess(access.FULL, item.accessCode),
      }"
      data-cy="columnSubscription"
    >
      {{ item.deviceSubscription }}
    </td>
    <td data-cy="columnAccess">
      {{ item.access }}
    </td>
    <td data-cy="columnCondition" style="min-width: 120px">
      <table-condition v-if="item.showCondition" :deviceId="item.id" :accessLevel="item.accessCode" />
    </td>
    <td
      style="min-width: 184px"
      :class="{ 'not-available-text': !item.showLastCommunication }"
      data-cy="columnLastCommunication"
    >
      <span v-if="item.lastCommunication">{{ item.lastCommunication }}</span>
      <span v-else>{{ $t('manageDevices.lastCommunicationNever') }}</span>
    </td>
    <td data-cy="columnAqi">
      <styled-reading  data-cy="aqiIcon" v-if="item.showAQI" :reading="item.reading" />
    </td>
    <td class="text-xs-left right-aligned" data-cy="columnActions">
      <action-menu :deviceId="item.id" :accessLevel="item.accessCode" @openDialog="openDialog" />
    </td>
  </tr>
</template>

<script>
import { deviceModels } from '../../store/subscriptions/utils'

import { accessUtil } from '../../services/device-access'
import { isChildOverflowing } from '../../services/html-utilities'
import debounce from 'lodash/debounce'

import actionMenu from './actionMenu.vue'
import styledReading from './styledReading.vue'
import tableCondition from './tableCondition.vue'

export default {
  name: 'DeviceTableBody',
  props: {
    item: Object,
    expand: Function,
    isExpanded: Boolean,
    isChild: Boolean,
  },
  mixins: [accessUtil],
  components: {
    'action-menu': actionMenu,
    'styled-reading': styledReading,
    'table-condition': tableCondition,
  },
  data() {
    return {
      isNameOverflowing: false,
      isLocationNameOverflowing: false,
    }
  },
  methods: {
    handleResize () {
      this.resizeHandler()
    },
    openDialog(dialogData) {
      this.$emit('openDialog', dialogData)
    },

  },
  computed: {
    itemCanBeExpanded() {
      const res = this.item.deviceModel === deviceModels.SMART_BRIDGE.toUpperCase() && this.item.associatedDevices?.length > 0
      return res
    },
  },
  created(){
    this.resizeHandler = debounce(() => {
      this.isNameOverflowing = isChildOverflowing(this.$refs[this.item.id + 'Name'])
      this.isLocationNameOverflowing = isChildOverflowing(this.$refs[this.item.id + 'LocationName'])
    }, 200)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/global-variables.scss';
.right-aligned {
  direction: rtl;
}
.device-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
tr.child-expanded-row {
  background-color: $great-light-shade !important;
}
.v-data-table__expand-icon--active {
  transform: rotate(90deg);
}
</style>

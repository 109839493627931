<template>
  <base-widget
    :deviceIds="deviceIds"
    :widgetId="widgetId"
    :widgetType="widgetType"
    :widgetMaxDevices="widgetMaxDevices"
    :title="name"
    :subtitle="deviceType"
    :enableDisconnectedDeviceTheme="!isDeviceConnected"
    :isDialog="isDialog"
    @close="close"
    @removeWidget="removeWidget"
    @refreshDashboard="refreshDashboard"
    :data-cy="latestDeviceStudy ? 'studyWidget' : 'continuousMonitoringWidget'"
  >
    <div slot="actions">
      <measurement-options
        :deviceIds="deviceIds"
        :passedinParameter="selectedParameter"
        :widgetId="widgetId"
        :filterMeasurementStatistics="true"
        @parameterChange="parameterChange"
      />
      <v-divider v-if="!isDialog" />
    </div>
    <popover-widget-toolbar slot="toolbar" :deviceId="deviceId" />
    <div slot="left-toolbar" class="toolbar">
      <v-btn text @click="refresh" data-cy="refreshPopover">
        <v-icon color="grey">mdi-refresh</v-icon>
      </v-btn>
    </div>
    <continuous-monitoring-and-study-content
      slot="content"
      :deviceId="deviceId"
      :isLoading="isLoading"
      :selectedParameter="selectedParameter"
      :latestDeviceStudy="latestDeviceStudy"
      :showStudyContent="!!lastTelemetryStudyId"
    />
    <popover-last-calibration-timestamp
      v-if="!isLoading"
      slot="left-footer"
      :deviceId="deviceId"
    />
    <popover-current-calibration-factor
      v-if="!isLoading"
      slot="right-footer"
      :deviceId="deviceId"
    />
  </base-widget>
</template>

<script>
import { mapActions } from 'vuex'
import BaseWidget from '../../BaseWidget.vue'
import ContinuousMonitoringAndStudyContent from '@/components/devicePopover/layouts/ContinuousMonitoringAndStudyContent.vue'
import popoverToolbar from '../../../devicePopover/content/title/popoverToolbar.vue'
import popoverLastCalibrationTimestamp from '../../../devicePopover/content/footer/popoverLastCalibrationTimestamp.vue'
import popoverCurrentCalibrationFactor from '../../../devicePopover/content/footer/popoverCurrentCalibrationFactor.vue'
import MeasurementOptions from '../../../devicePopover/content/dropdown/MeasurementOptions.vue'
import { deviceModelDisplay } from '../../../../services/map'
import {
  DEVICE_UPDATE_INTERVAL_MS,
  getLatestStudy,
} from '../../../../services/device-metadata'
import { measurementTypes } from '../../../../services/device-measurements'
import measurementOptionsMixin from '../../../../mixins/widgets/measurementOptionsMixin'
import { getUserAccountId } from '../../../../helpers/loginas/logInAsHelper'

export default {
  name: 'ContinuousMonitoringAndStudyWidget',
  components: {
    'base-widget': BaseWidget,
    'continuous-monitoring-and-study-content':
      ContinuousMonitoringAndStudyContent,
    'popover-widget-toolbar': popoverToolbar,
    'popover-last-calibration-timestamp': popoverLastCalibrationTimestamp,
    'popover-current-calibration-factor': popoverCurrentCalibrationFactor,
    'measurement-options': MeasurementOptions,
  },
  mixins: [measurementOptionsMixin],
  props: {
    widgetId: String,
    widgetType: String,
    widgetMaxDevices: Object,
    deviceIds: Array,
    settings: Object,
    isDialog: Boolean,
  },
  data() {
    return {
      accountId: null,
      loadDataInterval: null,
      deviceId: null,
      latestDeviceStudy: null,
      isLoading: false,
      defaultParameter: measurementTypes.pm25_raw,
      filterMeasurementStatistics: true,
    }
  },
  async mounted() {
    this.setDeviceId()
    this.accountId = await getUserAccountId(this.$auth)
    await this.initWidget()
  },
  destroyed() {
    this.stopLoadDataInterval()
  },
  watch: {
    deviceIds() {
      this.setDeviceId()
    },
    async deviceId(deviceId) {
      if (deviceId) {
        await this.initWidget()
      }
    },
  },
  computed: {
    name() {
      return this.$store.getters['devices/getNameByDeviceId'](this.deviceId)
    },
    model() {
      return this.$store.getters['devices/getModelByDeviceId'](this.deviceId)
    },
    deviceType() {
      const modelKey = this.$store.getters['devicemodels/getModelKey'](
        this.model
      )
      return deviceModelDisplay(modelKey, this.$t.bind(this))
    },
    lastTelemetryStudyId() {
      return this.$store.getters['devices/getLastTelemetryStudyIdByDeviceId'](
        this.deviceId
      )
    },
    isDeviceConnected() {
      return this.$store.getters['devices/getIsConnectedByDeviceId'](
        this.deviceId
      )
    },
  },
  methods: {
    async initWidget() {
      this.stopLoadDataInterval()
      await this.populateWidgetData()
      this.startLoadDataInterval()
    },
    startLoadDataInterval() {
      if (this.loadDataInterval === null) {
        this.loadDataInterval = setInterval(
          this.populateWidgetData,
          DEVICE_UPDATE_INTERVAL_MS
        )
      }
    },
    stopLoadDataInterval() {
      if (this.loadDataInterval !== null) {
        clearInterval(this.loadDataInterval)
        this.loadDataInterval = null
      }
    },
    close() {
      this.$emit('close')
    },
    removeWidget(options) {
      this.$emit('removeWidget', options)
    },
    refreshDashboard() {
      this.$emit('refreshDashboard')
    },
    async refresh() {
      this.populateWidgetData()
    },
    parameterChange(newValue) {
      this.selectedParameter = newValue
    },
    setDeviceId() {
      this.deviceId = this.deviceIds?.length ? this.deviceIds[0] : ''
    },

    ...mapActions('devices', [
      'getLatestTelemetryAndUpdate',
      'getDeviceDetailsAndUpdate',
    ]),
    async populateWidgetData() {
      this.isLoading = true
      // populate vuex store with data to display in child components
      await this.getLatestTelemetryAndUpdate(this.deviceId)
      await this.getDeviceDetailsAndUpdate(this.deviceId)
      await this.setLatestStudyData()
      this.isLoading = false
    },
    async setLatestStudyData() {
      // If latest telemetry doesn't have a study id, show continuous monitoring content
      if (this.lastTelemetryStudyId) {
        this.latestDeviceStudy = await getLatestStudy(this.deviceId)
      } else {
        this.latestDeviceStudy = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .widget-content-container {
  padding-top: 0;
}
</style>

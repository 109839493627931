<template>
  <div class="error-list">
    <ul>
      <li v-for="(error, index) in errors" :key="index">
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: "ErrorList",
  props: {
    errors: Array,
  }
}
</script>

<style lang="scss" scoped>
.error-list {
  line-height: 1.1rem;
  margin-top: 1rem;

  ul {
    padding-right: 12px;
  }

  @media (max-width: 599px),
  (max-height: 770px) {
    ul {
      font-size: 0.8rem;
    }
  }

  @media (max-height: 469px) {
    line-height: 1rem;
    margin-top: 0.5rem;
  }
}
</style>

<template>
  <div class="loading-container">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'LoadingContainer',
}
</script>

<style lang="scss" scoped>
.loading-container {
  position: absolute;
  top: 200px;
  left: calc(50% - 16px);
  opacity: 1;
}
</style>

import { LOG_IN_AS_HEADER_NAMES } from './utils'
import { getLogInAsHeaders } from './helper'

export const getLatest = function (devId) {
  return this.GET(
    `/devices/${devId}/telemetry/latest`,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
    },
    {},
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

export const getAverages = function (devId) {
  return this.GET(
    `/devices/${devId}/telemetry/averages`,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
    },
    {},
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

export const getPercentChanged = function (devId) {
  return this.GET(
    `/devices/${devId}/telemetry/change-over-time`,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
    },
    {},
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

export const getGraphData = function (dataParams) {
  return this.GET(
    `/devices/${dataParams.id}/telemetry/chart?sensor=${dataParams.sensor}&measurement=${dataParams.measurement}&tempunit=${dataParams.tempUnit}&pressureunit=${dataParams.pressureUnit}&pmunit=${dataParams.pmUnit}&start=${dataParams.start}&end=${dataParams.end}`,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
    },
    undefined,
    process.env.VUE_APP_DEVICES_HOST_V3
  )
}

import { ContentType, LOG_IN_AS_HEADER_NAMES } from './utils'
import { tempUnits } from '../../services/unit-conversion'
import { getQueryExpression } from '../../services/data-export'
import { getLogInAsHeaders, getLogInAsUrl } from './helper'
import { UnitOfMeasure } from '@fusion/units'

export const getCSV = async function (
  devId,
  start,
  end,
  tempUnit = tempUnits.fahrenheit,
  pressureUnit = UnitOfMeasure.INCH_MERCURY,
  pmUnit = UnitOfMeasure.MICROGRAMS_PER_CUBIC_METER,
  permissions,
  measurements,
  isShared = false
) {
  const queryExpression = getQueryExpression(start, end)
  const clientOffset = getClientOffset()
  const temp = getTempSymbol(tempUnit)
  const result = await this.GET(
    `/devices/${devId}/telemetry?${queryExpression}&tempunit=${temp}&pressureunit=${pressureUnit}&pmunit=${pmUnit}`,
    {
      Accept: ContentType.CSV,
      'X-Client-Offset': clientOffset,
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
    },
    {},
    process.env.VUE_APP_DEVICES_HOST_V3
  )
  return downloadCSV(result, {
    filter: true,
    permissions,
    supportedMeasurements: measurements,
    isShared,
  })
}
export const getNotificationsCSV = function (start, end) {
  const queryExpression = getQueryExpression(start, end)
  return this.$auth
    .getAccountId()
    .then((accountId) => {
      return this.GET(
        getLogInAsUrl(
          `/accounts/accountId/notifications/export?${queryExpression}`,
          accountId
        ),
        {
          Accept: ContentType.CSV,
          ...getLogInAsHeaders([
            LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
            LOG_IN_AS_HEADER_NAMES.USER_ID,
          ]),
        },
        {},
        process.env.VUE_APP_ALERT_HOST
      )
    })
    .then(downloadCSV)
}

const getTempSymbol = (tempUnit) => {
  switch(tempUnit) {
    case tempUnits.fahrenheit:
    case UnitOfMeasure.DEGREES_F:
      return 'f'
    case tempUnits.celsius:
    case UnitOfMeasure.DEGREES_C:
    default:
      return 'c'
  }
}

const getClientOffset = () => new Date().getTimezoneOffset()

const downloadCSV = async (res, options = null) => {
  const title = res.headers
    .get('Content-Disposition')
    .split('=')[1]
    .split('"')[1]

  // This is required to get the name of the latest downloaded file for Cypress tests
  const exportDataDoneButton = document.getElementsByClassName(
    'export-data-done-button'
  )
  if (exportDataDoneButton?.length) {
    exportDataDoneButton[0].setAttribute('data-downloaded-file-name', title)
  }

  let text = await res.text()

  const blob = new Blob([text], { type: 'text/csv;charset=utf-8' })
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, title)
  } else {
    const elem = window.document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = title
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }
}

<template>
  <div class="service-container">
    <div class="item logo-container">
      <a :href="sanitizeUrl" target="_blank" :title="`${this.serviceName} Status Page`" data-cy="serviceLogo">
        <img class="logo" :src="`/img/${this.serviceLower}-logo.png`" alt="logo">
      </a>
    </div>
    <div v-if="loading" class="icon" data-cy="loadingIcon">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else class="item icon-container" data-cy="statusIcons">
      <v-icon class="icon" :color="iconColor" large> {{ icon }} </v-icon>
    </div>
    <div class="item text-container">
      <span class="text" data-cy="text">
        {{ statusText }}
        <br>
        {{ checkedText }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ThirdPartyTable',
  props: {
    serviceName: String,
    serviceUrl: String,
  },
  data() {
    return {
      loading: true,
      seconds: -1,
      statusKey: '',
      icon: '',
      iconColor: '',
      serviceLower: this.serviceName.toLowerCase().replace(' ', ''),
      vuexMutator: `adminpanel/update${this.serviceName}Status`,
      vuexGetter: `adminpanel/get${this.serviceName}Status`,
    }
  },
  computed: {
    statusText() {
      return this.$t(`thirdPartyTable.${this.statusKey}`, {
        service: this.serviceName
      });
    },
    checkedText() {
      return this.$t('thirdPartyTable.checked', {
        seconds: this.seconds
      })
    },
    serviceStatus() {
      return this.$store.getters[this.vuexGetter.replace(' ', '')]
    },
    sanitizeUrl() {
      return this.$sanitize(this.serviceUrl)
    },
  },
  methods: {
    async healthChecker(service) {
      return await this.$api.healthChecker(service);
    },
    async updateFields() {
      this.seconds = 0;
      this.statusKey = 'loadingStatus';
      this.loading = true;
      this.$store.commit(this.vuexMutator.replace(' ', ''), await this.healthChecker(this.serviceLower));
      this.updateUI();
    },
    updateUI() {
      const response = this.serviceStatus;
      switch (response) {
        case 'none':
          this.icon = 'mdi-check-circle';
          this.iconColor = 'success';
          this.statusKey = 'noneStatus';
          break;
        case 'minor':
          this.icon = 'mdi-alert';
          this.iconColor = 'warning';
          this.statusKey = 'minorStatus';
          break;
        case 'maintenance':
          this.icon = 'mdi-alert';
          this.iconColor = 'warning';
          this.statusKey = 'maintenanceStatus';
          break;
        case 'error':
          this.icon = 'mdi-alert';
          this.iconColor = 'warning';
          this.statusKey = 'errorStatus';
          break;
        default:
          this.icon = 'mdi-alert-circle';
          this.iconColor = 'error';
          this.statusKey = 'downStatus';
          break;
      }
      this.loading = false;
    },
    updateCount() {
      this.seconds++;
    },
  },
  watch: {
    serviceStatus() {
      this.updateUI();
    }
  },
  created() {
    this.updateFields();
    setInterval(this.updateCount, 1000);
    setInterval(async () => {
      await this.updateFields();
      this.updateUI();
    }, 61000);
  }
}
</script>

<style lang="scss" scoped>
.service-container {
  display: flex;
  flex-wrap: wrap;
  width: 220px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
.text {
  font-size: small;
}
.logo {
  height: 25px;
  margin-top: 2px;
}
.icon {
  height: 30px;
  width: 30px;
  margin-left: 10px;
}
</style>

<template>
  <v-list-item @click="viewDeviceOnMap" link :disabled="!hasPermissions">
    <v-icon>mdi-map-marker</v-icon>
    <v-list-item-title data-cy="deviceViewOnMap">{{
      $t('manageDevices.actions.viewOnMap')
    }}</v-list-item-title>
  </v-list-item>
</template>

<script>
import { access } from '../../services/device-access'
import { getPermissionsByDeviceId } from '../../services/subscriptions'
import { SlugsEnum } from '../../permissions/SlugsEnum'
import { getUserAccountId } from '../../helpers/loginas/logInAsHelper'

export default {
  props: ['deviceId', 'accessLevel'],
  data() {
    return {
      accountId: null,
    }
  },
  computed: {
    deviceType() {
      return this.accessLevel === access.FULL
        ? this.$api.deviceType.USER
        : this.accessLevel === access.SHARED
        ? this.$api.deviceType.SHARED
        : this.$api.deviceType.PUBLIC
    },
    isMine() {
      return (
        this.accountId ===
        this.$store.getters['devices/getAccountByDeviceId'](this.deviceId)
      )
    },
    serial() {
      return this.$store.getters['devices/getSerialByDeviceId'](this.deviceId)
    },
    hasPermissions() {
      if (!this.isMine) {
        return true
      }
      const deviceSlugPermissions = getPermissionsByDeviceId(this.deviceId)
      const deviceHasSlugPermissions = deviceSlugPermissions?.includes(
        SlugsEnum.MenuViewOnMap
      )
      return deviceHasSlugPermissions
    },
  },
  async mounted() {
    this.accountId = await getUserAccountId(this.$auth)
  },
  methods: {
    viewDeviceOnMap() {
      const device = {
        deviceId: this.deviceId,
        deviceType: this.deviceType,
      }
      this.$store.dispatch('map/startDeviceViewMode', device)
      this.$router.push('/home').catch((err) => {
        /*
          Q: Why should we manually replicate vue-router-next behavior here?
          A: Please read this comment on vue-router repo (https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378)
            TL;DR: Let's say we are on page A, and click on a button to take us to page B.
            However, there is a Navigation Guard for page B (in our case, /home) which sends us to page C (/redirect)
            Because the user hasn't landed on page B (the desired task which the router hasn't been able to fulfill), we always have error message on console.

            This issue will be resolved in vue-router v4. Before v4 release, we have to manually replicate this behavior to suppress this error message.
            You can find more details here (https://github.com/vuejs/rfcs/blob/master/active-rfcs/0033-router-navigation-failures.md).

            Please remove this error handler when we have vue-router v4 (https://jira.tsi.com/browse/BI-471).
        */
        if (VueRouter.isNavigationFailure(err)) {
          return err
        }
        return Promise.reject(err)
      })
    },
  },
}
</script>

<style></style>

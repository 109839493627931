<template>
  <base-widget
    :deviceIds="deviceIds"
    :widgetId="widgetId"
    :widgetType="widgetType"
    :widgetMaxDevices="widgetMaxDevices"
    :title="name"
    :subtitle="deviceType"
    :fullscreen="fullscreen"
    @removeDevice="removeDevice"
    @removeWidget="removeWidget"
    @refreshDashboard="refreshDashboard"
    data-cy="MapWidget"
  >
    <div class="map-wrapper" slot="content">
      <div class="update-bar">
        <v-row no-gutters>
          <v-col cols="12" sm="6"></v-col>
          <v-col cols="12" sm="6">
            <div class="update-bar__item update-bar__item--last-contact">
              {{ $t('map.deviceInfo.lastContact') }}:
              {{ getTimeSinceDate(lastContact) }}
            </div>
          </v-col>
        </v-row>
      </div>
      <GmapMap
        ref="gmap"
        class="google-map"
        :center="center"
        :zoom="zoom"
        map-type-id="terrain"
        :options="{
          clickableIcons: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          minZoom: 4,
          maxZoom: 24,
          restriction: {
            latLngBounds: {
              north: 85,
              south: -85,
              west: -180,
              east: 180,
            },
          },
          styles: [
            {
              featureType: 'poi',
              stylers: [
                {
                  saturation: -100,
                },
                {
                  lightness: 30,
                },
              ],
            },
            {
              featureType: 'poi',
              elementType: 'labels.icon',
              stylers: [
                {
                  lightness: 55,
                },
              ],
            },
          ],
        }"
        @zoom_changed="updateZoom"
        @center_changed="updateCenter"
      >
        <map-pin
          :key="deviceId"
          :ref="deviceId"
          :deviceId="deviceId"
          :widget="true"
        />
      </GmapMap>
      <div class="map-legend">
        <map-legend isWidget />
      </div>
    </div>
  </base-widget>
</template>

<script>
import BaseWidget from './BaseWidget'
import MapPin from '../MapPin'
import MapLegend from '../MapLegend.vue'
import { deviceModelDisplay, getRelativeTimeSince } from '../../services/map'
import { widgetSettings } from '../../services/dashboard'
import { defaultMapLocation } from '../../store/map/utils'
import debounce from 'lodash/debounce'

export default {
  components: {
    BaseWidget,
    MapPin,
    MapLegend,
  },
  mixins: [widgetSettings],
  props: {
    widgetId: String,
    widgetType: String,
    widgetMaxDevices: Object,
    deviceIds: {
      type: Array,
      default() {
        return []
      }
    },
    width: Number,
    height: Number,
    settings: Object,
  },
  data() {
    return {
      fullscreen: false,
      center: defaultMapLocation.center,
      zoom: defaultMapLocation.zoom,
    }
  },
  computed: {
    deviceId() {
      return this.deviceIds?.[0]
    },
    name() {
      return this.$store.getters['devices/getNameByDeviceId'](this.deviceId)
    },
    deviceModel() {
      return this.$store.getters['devices/getModelByDeviceId'](this.deviceId)
    },
    deviceType() {
      const modelKey = this.$store.getters['devicemodels/getModelKey'](
        this.deviceModel
      )
      return deviceModelDisplay(modelKey, this.$t.bind(this))
    },
    lastContact() {
      return new Date(
        this.$store.getters['devices/getLastContactByDeviceId'](this.deviceId)
      )
    },
    coords() {
      return this.$store.getters['devices/getCoordsByDeviceId'](this.deviceId)
    },
  },
  mounted() {
    this.applyWidgetSettings()
    if (this.center === defaultMapLocation.center) {
      this.setCenter()
    }
    this.updateCenter = debounce(this.updateCenter, 250)
  },
  watch: {
    deviceId() {
      this.setCenter()
    },
  },
  methods: {
    setCenter() {
      const center = this.coords
      if (center) {
        this.center = center
      } else {
        this.unwatch = this.$store.watch(
          (state) => state.devices.coords,
          () => {
            this.setCenter()
            this.unwatch()
          }
        )
      }
    },
    updateZoom(zoom) {
      if (zoom) {
        this.saveWidgetSettings(this.widgetId, { zoom })
      }
    },
    updateCenter(coords) {
      if (coords) {
        const center = {
          lat: coords.lat(),
          lng: coords.lng(),
        }
        this.saveWidgetSettings(this.widgetId, { center })
      }
    },
    applyWidgetSettings() {
      if (
        this.settings.center &&
        this.settings.center.lat &&
        this.settings.center.lng
      ) {
        this.center = this.settings?.center
      }

      if (this.settings.zoom) {
        this.zoom = this.settings.zoom
      }
    },
    getTimeSinceDate(date) {
      return getRelativeTimeSince(date, this.$tc.bind(this))
    },
    removeDevice(index, options) {
      this.removeWidget(options)
    },
    removeWidget(options) {
      this.$emit('removeWidget', options)
    },
    refreshDashboard() {
      this.$emit('refreshDashboard')
    },
  },
}
</script>
<style lang="scss" scoped>
.v-card {
  overflow: hidden;
}
.map-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: -16px;
}
.update-bar {
  position: relative;
  z-index: 1;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.86);
  background-color: #fff;

  &__item {
    font-size: 0.75rem;
    line-height: 1.5;

    &--last-contact {
      @media (min-width: 600px) {
        text-align: right;
      }
    }
  }
}
.google-map {
  flex-grow: 1;
}
.gm-style-iw-t {
  .gm-style-iw-c {
    padding: 0 !important;
    width: 396px !important;
    // Prevent popover width getting too narrow at phone resolutions
    @media (max-width: 900px) {
      max-width: 376px !important;
    }
    @media (max-width: 460px) {
      max-width: 326px !important;
    }
    @media (max-width: 400px) {
      max-width: 300px !important;
    }
    .gm-style-iw-d {
      overflow-x: hidden !important;
      width: 396px !important;
      @media (max-width: 900px) {
        max-width: 376px !important;
      }
      @media (max-width: 460px) {
        max-width: 326px !important;
      }
      @media (max-width: 400px) {
        max-width: 300px !important;
      }
    }
  }
  button.gm-ui-hover-effect {
    top: 0 !important;
    right: 0 !important;
    display: none !important;
  }
}
.gm-style-iw-t:after {
  background: #ffffff !important;
}
.gm-style .gm-style-iw-d::-webkit-scrollbar {
  // -webkit-appearance: unset !important;
  width: 0 !important;
}

// hides the Google logo on bottom left of screen
a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.map-legend {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>

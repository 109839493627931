<template>
  <v-card class="studyManagerPage ma-6">
    <v-row class="pl-2 pr-2">
      <v-col>
        <fusion-study-manager
          ref="studyManager"
          :columns="columns"
          :detailsDialogFields="detailsDialogFields"
          :showDetailsBtn="true"
          :showEditBtn="true"
          :showDeleteBtn="true"
          :showDoneBtn="false"
          :showSendToReportCreatorBtn="false"
          :studiesLeftToComplete="studiesLeftToComplete"
          :sortBy="sortBy"
          :sortDesc="sortDesc"
          :intervalRequest="intervalRequest"
          data-cy="fusionStudyManager"
        >
          <template v-slot:header>
            <h1 class="pl-4 pt-4 studyManagerTitle" data-cy="studyManagerTitle">{{$t('studyManager.title')}}</h1>
          </template>
        </fusion-study-manager>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {
  isAuthorized,
  emailVerified,
  hasTSIEmail,
  accountExists,
  routeHasPermission,
} from '@fusion/auth'
import { SlugsEnum } from '../permissions/SlugsEnum'

export default {
  name: 'StudyManager',
  mixins: [
    isAuthorized,
    emailVerified,
    accountExists,
    routeHasPermission(SlugsEnum.SideBarStudyManagerExecute),
  ],
  data () {
    return {
      detailsDialogFields: {
        timestamp: true,
        duration: true,
        device: true,
        location: true,
        'study type': true,
        'number of samples': true,
        notes: true,
        gateway: true,
        sampling_interval: true,
        termination_reason: true
      },
      studiesLeftToComplete: 0,
      sortBy: 'cloud_timestamp',
      sortDesc: true,
      intervalRequest: 5
    }
  },
  computed: {
    columns () {
      const columnsData = [
        ['timestamp', 'cloud_timestamp', '15%'],
        ['name', 'study_name', '20%'],
        ['moduleType', 'device_model', '15%'],
        ['location', 'location', '15%'],
        ['duration', 'duration', '130px'],
        ['deviceName', 'device_name', '15%'],
        ['notes', 'notes', '20%'],
        ['access', 'is_shared', '90px']
      ]

      const columns = columnsData.map((columnData) => ({
        text: this.$t(`studyManager.columns.${columnData[0]}`),
        value: columnData[1],
        width: columnData[2]
      }))

      columns.push({
        align: 'center',
        sortable: false,
        text: this.$t('studyManager.columns.actions'),
        value: 'actions',
        width: '50px'
      })

      return columns
    }
  }
}
</script>

// Added unscoped style block to be able to style the editCancelDialog
<style lang="scss">
div.editCancelDialog {
  width: auto !important;
}
</style>

<style lang="scss" scoped>
.studyManagerPage {
  max-width: 100%;
}

.studyManagerTitle {
  font-weight: 300;
  font-size: 24px;
}

::v-deep .v-data-table__wrapper {
  height: auto !important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .studyManagerPage {
    &:deep(.v-data-table-header) {
      width: calc(100% - 65px);
      z-index: 1;
    }
    &:deep(.v-data-table-header th) {
      &:nth-child(1){
        min-width: 78px !important;
        width: 78px !important;
      }
      &:nth-child(2){
        min-width: 16% !important;
        width: 16% !important;
      }
      &:nth-child(3){
        min-width: 21% !important;
        width: 21% !important;
      }
      &:nth-child(4){
        min-width: 16% !important;
        width: 16% !important;
      }
      &:nth-child(5){
        min-width: 16% !important;
        width: 16% !important;
      }
      &:nth-child(6){
        min-width: 130px !important;
        width: 130px !important;
      }
      &:nth-child(7){
        min-width: 16% !important;
        width: 16% !important;
      }
      &:nth-child(8){
        min-width: 21% !important;
        width:21% !important;
      }
      &:nth-child(9){
        min-width: 50px !important;
        width: 50px !important;
      }
    }
    &:deep(tbody td) {
      &:nth-child(1){
        min-width: 78px !important;
        width: 78px !important;
      }
      &:nth-child(2){
        min-width: 16% !important;
        width: 16% !important;
      }
      &:nth-child(3){
        min-width: 21% !important;
        width: 21% !important;
      }
      &:nth-child(4){
        min-width: 16% !important;
        width: 16% !important;
      }
      &:nth-child(5){
        min-width: 16% !important;
        width: 16% !important;
      }
      &:nth-child(6){
        min-width: 130px !important;
        width: 130px !important;
      }
      &:nth-child(7){
        min-width: 16% !important;
        width: 16% !important;
      }
      &:nth-child(8){
        min-width: 21% !important;
        width: 21% !important;
      }
      &:nth-child(9){
        min-width: 50px !important;
        width: 50px !important;
      }
    }
  }
}
</style>

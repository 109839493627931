<template>
  <base-icon
    :showIcon="showIcon"
    imgSrc="/img/icons/live_studies_icon.svg"
    imgAlt="Live Studies Icon"
    :iconText="iconText"
    tooltipContentClass="live-studies-status-tooltip"
    dataCy="liveStudiesStatus"
  />
</template>

<script>
import BaseIcon from './BaseIcon.vue'

export default {
  name: 'LiveStudiesStatus',
  props: {
    deviceId: { type: String, default: '' },
  },
  components: {
    'base-icon': BaseIcon,
  },
  computed: {
    isDeviceConnected() {
      return this.$store.getters['devices/getIsConnectedByDeviceId'](
        this.deviceId
      )
    },
    isLivePresent() {
      return this.$store.getters['devices/getIsLivePresentByDeviceId'](
        this.deviceId
      )
    },
    iconText() {
      if (this.isDeviceConnected && this.isLivePresent) {
        return this.$t('device.icons.liveStudiesStatus')
      }

      return ''
    },
    showIcon() {
      return !!this.iconText
    },
  },
}
</script>

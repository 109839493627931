<template>
  <v-card flat>
    <popover-title
      :name="name"
      :deviceType="deviceType"
      :serial="serial"
      :accessLevel="accessLevel"
      tabIndex="0"
    >
      <popover-menu
        :deviceId="deviceId"
        :accessLevel="accessLevel"
        :permissions="permissions"
        slot="menu"
        @unregister="closeInfoWindow"
        data-cy="popoverMenu"
      />
      <popover-toolbar slot="status" :deviceId="deviceId" />
    </popover-title>
    <hr />
    <div class="card-content" data-cy="devicePopover">
      <popover-main-content
        :deviceId="deviceId"
        :permissions="permissions"
      ></popover-main-content>
      <v-btn
        outlined
        class="black--text history-btn"
        text
        @click="openHistoryDialog"
        data-cy="openHistoryDialog"
      >
        {{ $t('map.historyDialog.title') }}
      </v-btn>
    </div>
    <history-dialog
      :open="historyOpen"
      :currentDeviceId="historyId"
      :isDetachedDialog="true"
      :removableDevices="true"
      @close="closeHistoryDialog()"
    />
  </v-card>
</template>

<script>
// Title components
import popoverTitle from '../content/title/popoverTitle.vue'
import popoverMenu from '../content/title/popoverMenu.vue'
import PopoverToolbar from '../content/title/popoverToolbar.vue'

// content
import popoverMainContent from '../content/popoverMainContent'
import HistoryDialog from '@/components/dialogs/HistoryDialog.vue'

import { access } from '@/services/device-access'
import { deviceModelDisplay, deviceSubmodelDisplay } from '@/services/map'
import { getPermissionsByDeviceId } from '@/services/subscriptions'
import { getUserAccountId } from '@/helpers/loginas/logInAsHelper'

// This component is used to show the device details including device general information (name, device status, and system status),
// latest telemetry, change over time, and averages.
// getUserDevices function from App.vue updates the Vuex store every 60 seconds. This includes device general information and latest telemetry.
// There's no need to update device details card every 60 seconds, because the request to summary devices updates the store.
// The only data from device details card that isn't updated by summary devices is change over time and averages. So, these
// two calls are required every 60 seconds.
export default {
  props: {
    deviceId: String,
  },
  components: {
    'popover-title': popoverTitle,
    'popover-menu': popoverMenu,
    'popover-main-content': popoverMainContent,
    'history-dialog': HistoryDialog,
    'popover-toolbar': PopoverToolbar,
  },
  data() {
    return {
      accountId: null,
      historyOpen: false,
      historySelected: false,
    }
  },
  async mounted() {
    this.accountId = await getUserAccountId(this.$auth)
  },
  watch: {
    deviceId() {
      this.historySelected = false
    },
    async historySelected(value) {
      // If value is true, wait for the next Vue update flush, then set back to false.
      // This allows a device to be added back to the history graph if it was removed
      // and the popover wasn't closed.
      if (value === true) {
        await Vue.nextTick()
        this.historySelected = false
      }
    },
    allDevices() {
      if (this.deviceId) {
        this.checkDeviceAccess()
      }
    },
  },
  computed: {
    allDevices() {
      return this.$store.state.devices.allDevices
    },
    deviceModel() {
      const model = this.$store.getters['devices/getModelByDeviceId'](this.id)
      let modelKey
      if (model) {
        modelKey = this.$store.getters['devicemodels/getModelKey'](model)
      }
      return deviceModelDisplay(modelKey, this.$t.bind(this))
    },
    deviceSubmodel() {
      const model = this.$store.getters['devices/getModelByDeviceId'](this.id)
      const submodel = this.$store.getters['devices/getSubmodelByDeviceId'](
        this.id
      )
      const submodelKey = this.$store.getters['devicemodels/getSubmodelKey'](
        model,
        submodel
      )
      return deviceSubmodelDisplay(submodelKey, this.$t.bind(this))
    },
    deviceType: function () {
      return this.deviceSubmodel
        ? `${this.deviceModel} (${this.deviceSubmodel})`
        : this.deviceModel
    },
    id: function () {
      return this.deviceId || ''
    },
    serial() {
      return (this.isMine || this.isShared) ? this.$store.getters['devices/getSerialByDeviceId'](this.id) : ''
    },
    name: function () {
      return this.$store.getters['devices/getNameByDeviceId'](this.id)
    },
    historyId: function () {
      return this.historySelected ? this.id : null
    },
    isMine: function () {
      return (
        this.accountId ===
        this.$store.getters['devices/getAccountByDeviceId'](this.id)
      )
    },
    isShared: function () {
      if (this.isMine) return false
      return this.$store.getters['devices/getIsSharedByDeviceId'](this.id)
    },
    accessLevel: function () {
      if (this.isMine) return access.FULL
      if (this.isShared) return access.SHARED
      return access.NONE
    },
    permissions() {
      return getPermissionsByDeviceId(this.deviceId)
    },
  },
  methods: {
    openHistoryDialog: function () {
      this.historySelected = true
      this.historyOpen = true
    },
    closeHistoryDialog: function () {
      this.historySelected = false
      this.historyOpen = false
    },
    closeInfoWindow: function () {
      this.$emit('close')
    },
    checkDeviceAccess: function () {
      const device = this.allDevices[this.deviceId]
      if (!device) {
        this.closeInfoWindow()
      }
    },
  },
}
</script>

<style lang="scss">
// Unscoped to effect the status icons
.popover-status-icon {
  min-width: 10px !important;
  padding: 0 0.2rem !important;

  .v-icon {
    font-size: 24px;
  }
}
</style>

<style lang="scss" scoped>
.card-content {
  overflow: hidden;
  padding: 0 0.5rem 0rem 0.5rem;
}

hr {
  opacity: 0.16;
}

.history-btn {
  position: absolute;
  right: 16px;
  bottom: 16px;
}
</style>

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

import locationModule from '../location'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    location: locationModule,
  },
}

/**
 * Converts an array of objects to an object, based upon the specified key
 * @param {Object[]} array
 * @param {String} key
 */
export const convertArrayToObject = (array, key) => {
  if (Array.isArray(array) && key) {
    const initialValue = {}
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      }
    }, initialValue)
  }
  return null
}

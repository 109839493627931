<template>
  <div class="multiple-devices-success-message">
    <div class="success-icon">
      <v-icon color="green">
        {{ successIcon }}
      </v-icon>
    </div>
    <div v-if="formattedMessage && formattedMessage.length > 1" class="success-description">
      {{ formattedMessage[0] }}
      <span class="file-name">
        {{ fileName }}
      </span>
      {{ formattedMessage[1] }}
    </div>
  </div>
</template>
<script>

export default {
  name: "MultipleDevicesSuccessMessage",
  props: {
    icon: String,
    message: String,
    fileName: String
  },
  computed: {
    successIcon() {
      return this.icon || 'mdi-check-circle'
    },
    formattedMessage() {
      if (this.message) {
        return this.message.split('/fileName/');
      }

      return []
    }

  }
}
</script>

<style lang="scss" scoped>
.multiple-devices-success-message {
  .success-icon {
    position: relative;
    height: 50%;

    .v-icon {
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 7vh;
    }
  }

  .success-description {
    text-align: center;
    padding: 0.5rem;
    font-size: 1rem;

    .file-name {
      max-width: 346px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      position: relative;
      top: 0.35rem;
      font-weight: 500;
    }
  }

  @media (max-width: 599px) {
    .success-description {
      padding: 0.5rem;
      font-size: 0.9rem;

      .file-name {
        max-width: 200px;
        top: 0.39rem;
      }
    }
  }

  @media (max-height: 469px) {
    .success-icon {
      height: 35%;
    }

    .success-description {
      font-size: 0.8rem;
      padding: 0;

      .file-name {
        top: 0.4rem;
      }
    }
  }

  @media (min-height: 470px) and (max-height: 599px) {
    .success-description {
      font-size: 0.9rem;
      padding: 0;
    }
  }

  @media (min-height: 1000px) {
    .success-icon {
      .v-icon {
        font-size: 3.5rem !important;
      }
    }
  }

}
</style>

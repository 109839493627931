<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :min-width="260"
    :max-width="$vuetify.breakpoint.xs ? '100%' : 350"
    :nudge-bottom="$vuetify.breakpoint.xs ? 0 : 4"
    :nudge-right="$vuetify.breakpoint.xs ? 0 : 60"
    :bottom="$vuetify.breakpoint.xs ? false : true"
    :offset-y="$vuetify.breakpoint.xs ? false : true"
    :left="$vuetify.breakpoint.xs ? false : true"
    :max-height="$vuetify.breakpoint.xs ? '100%' : '600px'"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="$auth.isAuthenticated"
        v-show="haveNotes"
        v-on="on"
        v-bind="attrs"
        text
        style="height: 100%; margin-right: 16px"
        data-cy="globalAlertBellButton"
      >
        <v-badge color="accent" overlap>
          <template v-slot:badge>
            <span>{{ notificationsNum }}</span>
          </template>
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card
      :height="$vuetify.breakpoint.xs ? '100%' : 'unset'"
      :style="$vuetify.breakpoint.xs ? 'border-radius:unset;' : ''"
    >
      <template v-if="$vuetify.breakpoint.xs">
        <v-row no-gutters>
          <v-card-subtitle>
            {{ $t('alertHistory.notificationTitle') }}
          </v-card-subtitle>
          <v-spacer />
          <v-icon @click="menu = false">mdi-close</v-icon>
        </v-row>
      </template>
      <template v-else>
        <v-card-subtitle class="btn-subtitle">
          <div>{{ $t('alertHistory.notificationTitle') }}</div>
          <v-spacer />
          <v-btn
            text
            class="primary--text mobile-menu"
            data-cy="globalAlertViewAll"
            @click="viewAll"
          >
            {{ $t('alertHistory.viewAll') }}
          </v-btn>
        </v-card-subtitle>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in notifications"
          :key="index"
          three-line
        >
          <v-list-item-content>
            <v-divider v-if="index > 0" style="margin-bottom: 20px" />
            <v-row no-gutters>
              <v-col>
                <v-list-item-title>{{ item.trigger.title }}</v-list-item-title>
              </v-col>
              <v-spacer />
              <v-col>
                <v-list-item-title
                  class="anchor-right"
                  style="text-transform: capitalize"
                >
                  {{ item.trigger.severity }}
                </v-list-item-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-list-item-subtitle>
                  {{ item.device.name }}
                </v-list-item-subtitle>
              </v-col>
              <v-spacer />
              <v-col cols="6">
                <v-list-item-subtitle class="anchor-right">
                  {{ new Date(item.triggeredAt).toLocaleDateString() }}
                  {{ new Date(item.triggeredAt).toLocaleTimeString() }}
                </v-list-item-subtitle>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="8">
                <v-list-item-action-text>
                  <strong> {{ item.message }} </strong>
                </v-list-item-action-text>
              </v-col>
              <v-spacer />
              <v-col>
                <v-btn
                  class="clear-button"
                  @click="clear(item.id)"
                  :loading="buttonLoading[`${item.id}`]"
                  :disabled="clearingInProgress"
                  outlined
                >
                  {{ $t('buttons.clear') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-actions class="stick-bottom">
        <v-spacer />
        <v-btn
          class="clear-all-button black white--text"
          :loading="clearingAll"
          :disabled="clearingInProgress"
          @click="clearAll"
          text
        >
          {{ $t('buttons.clearAll') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  mounted() {
    this.getNotifications()
    this.int = setInterval(this.getNotifications, 60000)
  },
  destroyed() {
    if (this.int) {
      clearInterval(this.int)
    }
  },
  data() {
    return {
      menu: false,
      int: null,
      buttonLoading: {},
      clearingInProgress: false,
      clearingAll: false,
    }
  },
  computed: {
    notificationsNum() {
      let n = this.$store.getters['alerts/getNotificationsNum']
      return n > 8 ? '9+' : n
    },
    notifications() {
      const n = this.$store.getters['alerts/getNotifications']
      if (!n) return n
      const c = function (a, b) {
        const ad = Date.parse(a.triggeredAt)
        const bd = Date.parse(b.triggeredAt)
        if (ad < bd) return 1
        if (ad > bd) return -1
        return 0
      }
      return n.sort(c)
    },
    haveNotes() {
      let hasNotes = this.notificationsNum > 0 || this.notificationsNum == '9+'
      return hasNotes
    },
  },
  methods: {
    getNotifications() {
      this.$api
        .getAlertNotifications({
          isRead: false,
          limit: 25,
        })
        .then((res) => {
          if (res.ok) {
            res.json().then((body) => {
              this.$store.dispatch('alerts/setNotifications', body)
              if (this.notificationsNum < 1) {
                this.menu = false
              }
              this.clearingInProgress = false
              this.clearingAll = false
            })
          }
        })
    },
    clear(id) {
      this.clearingInProgress = true
      const buttonLoad = {}
      buttonLoad[`${id}`] = true
      this.buttonLoading = buttonLoad
      this.$api.patchClearNotification(id).then(() => {
        this.getNotifications()
      })
    },
    async clearAll() {
      this.clearingInProgress = true
      this.clearingAll = true
      await this.$api.postClearAllNotifications()
      await this.getNotifications()
    },
    viewAll() {
      this.menu = false
      const route = 'alert-history'
      if (this.$route.name !== route) this.$router.push({name: route})
    },
  },
}
</script>

<style lang="scss" scoped>
.row {
  flex: 1 0 100%;
}

.anchor-right {
  text-align: end;
}

.stick-bottom {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: white;
}

@media (max-width: 600px) {
  .v-menu__content {
    top: 0 !important;
    height: 100%;
    border-radius: unset;
  }
}

.v-btn.clear-button {
  min-width: 72px !important;
  height: 32px !important;
}

.v-btn.clear-all-button {
  width: 104px;
  min-width: 104px !important;
  height: 32px !important;
}

.v-card__subtitle {
  padding-bottom: 0;
  &.btn-subtitle {
    display: flex;
    align-items: center;
  }
}
</style>

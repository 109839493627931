<template>
  <base-dialog
    :open="open"
    @close="close"
    data-cy="apiBuilkRegistrationInfoDialog"
    :fullscreen="$vuetify.breakpoint.xs"
    :persistentOverlay="true"
    :max-width="maxWidth"
  >
    <template v-slot:header>
      <v-card-title data-cy="subscriptionExpiredHeader">
        {{ $t('bulkRegistrationInfoDialog.title') }}
      </v-card-title>
    </template>
    <v-card-text>
      <v-simple-table
        dense
        v-if="!isMobile"
        data-cy="registrationReportDesktop"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th scope="col" class="text-left">
                {{ $t('apiServices.table.serialNumber') }}
              </th>
              <th scope="col" class="text-left">
                {{ $t('apiServices.table.dataServices') }}
              </th>
              <th scope="col" class="text-left" v-if="addToBoth">
                {{ $t('apiServices.table.websiteSubs') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="result in results" :key="result.serial">
              <td>{{ result.serial }}</td>
              <td>
                <span
                  :class="
                    assignClass(result[subscriptionTypes.DATA_SERVICES].success)
                  "
                >
                  {{ result[subscriptionTypes.DATA_SERVICES].msg }}
                </span>
              </td>
              <td v-if="addToBoth">
                <span
                  :class="
                    assignClass(result[subscriptionTypes.WEBSITE].success)
                  "
                >
                  {{ result[subscriptionTypes.WEBSITE].msg }}
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-if="isMobile" class="list" data-cy="registrationReportMobile">
        <div v-for="result in results" :key="result.serial" class="list-item">
          <div class="list-item__group">
            <div class="list-item__content list-item__serial-number">
              <div class="list-item__title">
                {{ $t('apiServices.table.serialNumber') }}:&nbsp;
              </div>
              <div class="list-item__body">
                {{ result.serial }}
              </div>
            </div>
            <div class="list-item__content">
              <div class="list-item__title">
                {{ $t('apiServices.table.dataServices') }}
              </div>
              <div class="list-item__body">
                <span
                  :class="
                    assignClass(result[subscriptionTypes.DATA_SERVICES].success)
                  "
                >
                  {{ result[subscriptionTypes.DATA_SERVICES].msg }}
                </span>
              </div>
            </div>
            <div v-if="addToBoth" class="list-item__content">
              <div class="list-item__title">
                {{ $t('apiServices.table.websiteSubs') }}
              </div>
              <div class="list-item__body">
                <span
                  :class="
                    assignClass(result[subscriptionTypes.WEBSITE].success)
                  "
                >
                  {{ result[subscriptionTypes.WEBSITE].msg }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
    <template v-slot:actions>
      <v-btn
        v-if="allowRetry"
        :loading="isRetrying"
        @click="retry"
        class="primary white--text"
        text
        data-cy="apiBulkRegistrationRetry"
      >
        {{ $t('buttons.retryFailedReg') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="isRetrying"
        @click="close"
        class="primary white--text"
        text
        data-cy="apiBulkRegistrationClose"
      >
        {{ $t('buttons.done') }}
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import dialog from '../dialogs/Dialog'
import { subscriptionTypes } from '../../store/subscriptions/utils'

export default {
  name: 'APIBulkRegistrationInfoDialog',
  props: {
    open: Boolean,
    addToBoth: { type: Boolean, default: false },
    allowRetry: { type: Boolean, default: false },
    isRetrying: { type: Boolean, default: false },
    results: { type: Array, default: () => [] },
  },
  components: { 'base-dialog': dialog },
  data() {
    return {
      maxWidth: 700,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    subscriptionTypes() {
      return subscriptionTypes
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    retry() {
      this.$emit('retry')
    },
    assignClass(success) {
      if (success === true) {
        return 'success-text'
      }
      if (success === false) {
        return 'error-text'
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    padding: 16px;
  }
  .v-card__title {
    font-weight: 300;
    padding: 8px 24px 16px 16px;
  }
  .v-card__subtitle {
    padding: 8px 24px 16px 16px;
  }
  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }
  .v-card__actions {
    margin-top: 24px;
  }
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-top: 4px;
  padding-bottom: 4px;
  span {
    display: inline-block;
    line-height: 1.25;
  }
}
.error-text {
  color: rgba(222, 30, 30, 0.8);
}

.success-text {
  color: rgba(40, 160, 40, 0.8);
}

.list {
  margin: -8px 0;

  &-item {
    line-height: 1.25;
    padding: 8px 0;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);

    &:last-child {
      border: none;
    }

    &__group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: -8px;
      margin-right: -8px;
    }

    &__content {
      flex: 1 1 50%;
      min-width: 250px;
      padding: 0 8px;
      margin: 8px 0;
    }

    &__serial-number {
      flex: 0 0 100%;
      > * {
        display: inline-block;
      }
    }

    &__title {
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.75rem;
      font-weight: 700;
    }

    &__body {
      font-size: 0.85rem;
    }
  }
}
</style>

export const useToken = (token) => {
  return `Bearer ${token}`
}

export function checkStatus(res) {
  return res.ok
}

export const deviceType = Object.freeze({
  USER: 0,
  SHARED: 1,
  PUBLIC: 2,
})

export const ContentType = Object.freeze({
  JSON: 'application/json',
  CSV: 'text/csv',
})

export const HTTPMode = Object.freeze({
  CORS: 'cors',
})

export const CachePolicy = Object.freeze({
  NoStore: 'no-store',
})

export const HTTPMethods = Object.freeze({
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
})

export const HTTPStatus = Object.freeze({
  OK: 200,
  Created: 201,
  Accepted: 202,
  NoContent: 204,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  NotAcceptable: 406,
  Conflict: 409,
  UnprocessableEntity: 422,
  InternalServerError: 500,
})

export const LOG_IN_AS_HEADER_NAMES = Object.freeze({
  LOG_IN_AS: 'X-Log-in-as',
  ACCOUNT_ID: 'X-Account-Id',
  EMAIL: 'X-Email',
  USER_ID: 'X-User-Id',
  SAP_ID: 'X-SAPAccount-Id',
})

export const LOG_IN_AS_HEADER_USER_VALUES = Object.freeze({
  [LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]: 'accountId',
  [LOG_IN_AS_HEADER_NAMES.EMAIL]: 'email',
  [LOG_IN_AS_HEADER_NAMES.USER_ID]: 'userId',
  [LOG_IN_AS_HEADER_NAMES.SAP_ID]: 'sapId',
})

export const PATH_PARAMS = Object.freeze({
  ACCOUNT_ID: 'accountId',
  USER_ID: 'userId'
})

<template>
  <fusion-non-verified />
</template>

<script>
import { emailNotVerified, isAuthorized } from '@fusion/auth'

export default {
  mixins: [isAuthorized, emailNotVerified],
}
</script>

<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn v-if="item.allowCancellation" class="menu-dropdown black--text" text v-on="on"
        data-cy="subscriptionMenuDropdown">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list v-if="item.allowCancellation">
      <v-tooltip left :disabled="!isLogInAs">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-list-item :disabled="isLogInAs" @click.stop="$emit('cancelSubscriptionOpen', item.activationId)" link data-cy="cancelSubscriptionActivator">
                <v-icon>mdi-close</v-icon>
                <v-list-item-title>
                  {{ $t('manageSubscriptions.menu.cancelSubscription') }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </template>
          <span>{{ $t('manageSubscriptions.unavailableForLogInAs') }}</span>
        </v-tooltip>
        <v-tooltip left :disabled="!isLogInAs">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-list-item :disabled="isLogInAs" @click.stop="$emit('subscriptionBillingInfoOpen', item.activationId, item.subscriptionId)" link
                data-cy="subscriptionBillingInfoActivator">
                <v-icon>mdi-credit-card</v-icon>
                <v-list-item-title>
                  {{ $t('manageSubscriptions.menu.updateBilling') }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </template>
          <span>{{ $t('manageSubscriptions.unavailableForLogInAs') }}</span>
        </v-tooltip>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'ActionMenu',
  props: {
    item: Object,
    isLogInAs: Boolean
  },
}
</script>

<style lang="scss" scoped>
.menu-dropdown {
  min-width: 10px !important;
  padding: 0 0.4rem !important;
}
</style>

<template>
  <div class="upload-csv-file" @mouseover="hover = true" @mouseleave="hover = false" @click="onClickHandler">
    <v-file-input ref="fileInput" class="add-file-input" prepend-icon="mdi-plus-circle-outline" @change="onChange"
      accept="text/csv"
      :label="hover ? $t('registerMultipleDevices.selectFile') : $t('registerMultipleDevices.browseFiles')"
      :value="value">
    </v-file-input>
  </div>
</template>

<script>
export default {
  name: 'FileInput',
  props: {
    value: File
  },
  data() {
    return {
      hover: false
    }
  },
  methods: {
    onChange(file) {
      this.$emit('onFileChanged', file)
    },
    onClickHandler() {
      this.hover = true
      setTimeout(() => {
        this.hover = false
      }, 500)
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/assets/global-variables.scss';

.upload-csv-file::v-deep {
  width: 100%;
  height: 100%;
  position: relative;

  .add-file-input {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 0;
    margin-top: 0;

    .v-input__control,
    .v-input__slot,
    .v-text-field__slot {
      height: 100%;
    }

    .v-input__prepend-outer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .v-icon {
        color: $deep-sky-blue-color;
        font-size: 8vh;
      }
    }

    .v-text-field__details,
    .v-file-input__text,
    .v-input__append-inner {
      display: none;
    }

    .v-input__control {
      display: block;
    }

    .v-input__slot {
      margin-bottom: 0;

      &::before {
        border: none;
      }

      &::after {
        transform: scaleX(0) !important;
      }
    }

    .v-text-field__slot {
      cursor: pointer;
    }

    .v-label {
      transform: translate(-50%, -50%);
      top: 80%;
      left: 50% !important;
      text-transform: uppercase;
      text-decoration: underline;
      font-weight: 600;
      color: $deep-sky-blue-color;
      cursor: pointer;
      pointer-events: none;
    }

    .v-label--active {
      transform: translate(-50%, -50%);
    }

    @media (max-height: 469px) {
      .v-input__prepend-outer {
        top: 35%;
      }

      .v-label {
        font-size: 0.9rem;
      }
    }

    @media (min-height: 1000px) {
      .v-icon {
        font-size: 5rem !important;
      }
    }
  }
}
</style>

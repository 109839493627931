<template>
  <div v-if="!hideLegend">
    <v-card class="no-bottom-border-radius" width="250">
      <v-card-subtitle
        class="title"
        height="40"
        @click="toggle"
        data-cy="mapLegendToggle"
      >
        {{ $t('map.legend.title') }}
        <v-spacer></v-spacer>
        <span class="right">
          <v-icon v-show="!open">mdi-chevron-up</v-icon>
          <v-icon v-show="open">mdi-chevron-down</v-icon>
        </span>
      </v-card-subtitle>
    </v-card>
    <v-expand-transition>
      <v-card
        class="bottom-card no-top-border-radius no-bottom-border-radius"
        flat
        v-show="open"
        data-cy="mapLegendExpanded"
      >
        <div class="subtitle-large hide-large">
          {{ $t('map.legend.levels') }}
        </div>
        <v-row class="legend" no-gutters>
          <v-col cols="18" class="hide-small">
            <div class="subtitle">
              {{ $t('map.legend.levels') }}
            </div>
          </v-col>
          <v-col cols="18">
            <div class="entry good">
              <div class="title">
                {{ $t('map.popover.descriptions.aqi_good.title') }}
              </div>
              <div class="range">
                {{ $t('map.popover.descriptions.aqi_good.range') }}
              </div>
            </div>
          </v-col>
          <v-col cols="18">
            <div class="entry moderate">
              <div class="title">
                {{ $t('map.popover.descriptions.aqi_moderate.title') }}
              </div>
              <div class="range">
                {{ $t('map.popover.descriptions.aqi_moderate.range') }}
              </div>
            </div>
          </v-col>
          <v-col cols="18">
            <div class="entry unhealthy-sensitive">
              <div class="title">
                {{
                  $t('map.popover.descriptions.aqi_unhealthy_sensitive.title')
                }}
              </div>
              <div class="range">
                {{
                  $t('map.popover.descriptions.aqi_unhealthy_sensitive.range')
                }}
              </div>
            </div>
          </v-col>
          <v-col cols="18">
            <div class="entry unhealthy">
              <div class="title">
                {{ $t('map.popover.descriptions.aqi_unhealthy.title') }}
              </div>
              <div class="range">
                {{ $t('map.popover.descriptions.aqi_unhealthy.range') }}
              </div>
            </div>
          </v-col>
          <v-col cols="18">
            <div class="entry very-unhealthy">
              <div class="title">
                {{ $t('map.popover.descriptions.aqi_very_unhealthy.title') }}
              </div>
              <div class="range">
                {{ $t('map.popover.descriptions.aqi_very_unhealthy.range') }}
              </div>
            </div>
          </v-col>
          <v-col cols="18">
            <div class="entry hazardous">
              <div class="title">
                {{ $t('map.popover.descriptions.aqi_hazardous.title') }}
              </div>
              <div class="range">
                {{ $t('map.popover.descriptions.aqi_hazardous.range') }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  props: {
    isWidget: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // should be closed by default when on mobile or inside a widget
      open: !this.$vuetify.breakpoint.xs && !this.isWidget,
    }
  },
  methods: {
    toggle: function () {
      this.open = !this.open
    },
  },
  computed: {
    hideLegend() {
      return this.$store.state.map.movingMode
    },
  },
}
</script>

<style lang="scss" scoped>
$max-breakpoint: 900px;
$min-breakpoint: 901px;

.v-card {
  .title.v-card__subtitle {
    font-weight: 400;
    font-size: 14px !important;
    color: black;
    height: 44px;
    line-height: 44px;
    padding-top: 0;
    padding-bottom: 0;
    cursor: pointer;

    .right {
      position: absolute;
      right: 12px;
      top: 0;
      i {
        font-size: 22px;
        color: black;
      }
    }
  }
}

.no-bottom-border-radius {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.no-top-border-radius {
  border-top-left-radius: 0 !important;

  @media (max-width: $max-breakpoint) {
    border-top-right-radius: 0 !important;
  }

  .widget & {
    border-top-right-radius: 0 !important;
  }
}
.bottom-card {
  @media (max-width: $max-breakpoint) {
    width: 250px;
    .hide-small {
      display: none;
    }
  }
  @media (min-width: $min-breakpoint) {
    .hide-large {
      display: none;
    }
  }

  .widget & {
    width: 250px;
    .hide-small {
      display: none;
    }
  }

  .subtitle-large {
    font-size: 12px !important;
    margin-left: 16px;
  }
}
.legend {
  padding: 8px 8px 8px 16px;
  @media (max-width: $max-breakpoint) {
    padding-top: 0px;
  }
  .widget & {
    padding-top: 0px;
  }
  .subtitle {
    font-size: 12px !important;
    line-height: 12px;
    padding-top: 24px;
    width: 100px;
    height: 50px;
  }
  .entry {
    width: 100px;
    @media (min-width: $min-breakpoint) {
      margin-left: 2px;
    }
    .title {
      font-size: 12px !important;
      line-height: 44px;
      height: 30px;
    }
    .range {
      font-size: 14px;
      width: 100%;
      padding-left: 4px;
      padding-right: 4px;
      font-weight: 500;
    }
  }
  .good {
    .range {
      background-color: #00e400;
    }
  }
  .moderate {
    .range {
      background-color: #ffff00;
    }
  }
  .unhealthy-sensitive {
    .title {
      line-height: 14px;
      @media (max-width: $max-breakpoint) {
        margin-top: 13px;
      }
      .widget & {
        margin-top: 13px;
      }
    }
    // @media (max-width: $max-breakpoint) {
    //   .title {
    //     margin-top: 13px;
    //   }
    // }
    .range {
      background-color: #ff7e00;
    }
  }
  .unhealthy {
    .range {
      background-color: #ff0000;
      color: white;
    }
    .title {
      @media (max-width: $max-breakpoint) {
        margin-top: 13px;
      }
      .widget & {
        margin-top: 13px;
      }
    }
  }
  .very-unhealthy {
    .range {
      background-color: #8f3f97;
      color: white;
    }
  }
  .hazardous {
    .range {
      background-color: #7e0023;
      color: white;
    }
  }
}
</style>

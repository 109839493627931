import { locationCoordsRegex } from '../../../../services/location'
import {
  registerDeviceTemplateFields,
  registerDeviceValidationErrors,
} from '../validation/utils'

export const readCsvContent = async (csvFile) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsText(csvFile, 'UTF-8')
    reader.onload = (evt) => {
      resolve(evt.target.result)
    }
    reader.onerror = reject
  })
}
export const validateCsvFile = async (csvContent) => {
  if (csvContent.length === 0) {
    return registerDeviceValidationErrors.blankFile
  } else {
    var lines = getCsvLines(csvContent)

    if(lines.length <= 1) {
    return registerDeviceValidationErrors.blankFile
    }
    else {
      var headers = getCsvHeaders(lines)
      if (JSON.stringify(headers) !==  JSON.stringify(Object.values(registerDeviceTemplateFields))) {
        return registerDeviceValidationErrors.columHeaders
      }
    }
    return ''
  }
}

export const getCsvData = async (csvContent) => {
  var jsonData = []
  var lines = getCsvLines(csvContent)
  var headers = getCsvHeaders(lines)

  for (var i = 1; i < lines.length; i++) {
    var jsonRow = {}
    var currentLine = lines[i].split(',')
    if (currentLine.every( cl => cl === "" )) {
      continue;
    }
    for (var j = 0; j < headers.length; j++) {
      jsonRow[headers[j]] = currentLine[j].trim()
    }
    jsonRow.RowId = i + 1;
    jsonData.push(jsonRow)
  }
  return jsonData
}

const getCsvLines = (csvContent) => {
  return csvContent.replaceAll(';', ',').split('\r\n').filter(line => line !== "")
}

const getCsvHeaders = (lines) => {
  return lines[0].split(',')
}

const createValidation = (validation, errorMessage) => (data, row) => {
  const validationResult = validation(data)
  return {
    dataValidation: message =>
    validationResult ? message.result(validationResult) :  `Row ${row} - ${message.result(errorMessage)}`
  };
}

const getValidationResults = values =>
  Object.keys(values).map((key) => ({
    result: values[key].dataValidation({
      result: (r) => r
    })
  })).filter(item => item.result !== true);

  export const validateCsvRow = (csvData) => {
    const csvValues = {
      deviceName: createDeviceNameValidation(csvData[registerDeviceTemplateFields.deviceName], csvData['RowId']),
      locationName: createLocationNameValidation(csvData[registerDeviceTemplateFields.locationName], csvData['RowId']),
      deviceSerial: createDeviceSerialValidation(csvData[registerDeviceTemplateFields.deviceSerialNumber], csvData['RowId']),
      indoorSensor: createIndoorSensorValidation(csvData[registerDeviceTemplateFields.indoorSensor].toLowerCase(), csvData['RowId']),
      latitude: createLatitudeValidation(csvData[registerDeviceTemplateFields.latitude], csvData['RowId']),
      longitude: createLongitudeValidation(csvData[registerDeviceTemplateFields.longitude], csvData['RowId']),
      nullIsland: createNullIslandValidation((csvData[registerDeviceTemplateFields.longitude] + "," + csvData[registerDeviceTemplateFields.latitude]), csvData['RowId'])
    }
    return getValidationResults(csvValues).map(error => error.result);
  }

const createDeviceNameValidation = createValidation(
      (data) => data && data.length <= 255,
      registerDeviceValidationErrors.deviceName
);

const createLocationNameValidation = createValidation(
  (data) => data && data.length <= 255,
  registerDeviceValidationErrors.locationName
);

const createDeviceSerialValidation = createValidation(
  (data) =>
    data &&
    (((data.startsWith('8143') || data.startsWith('8144')) &&
      data.length === 11) ||
      (data.startsWith('8145') && data.length === 13)),
      registerDeviceValidationErrors.deviceSerialNumber
);

const createIndoorSensorValidation = createValidation(
  (data) => ((data && data === 'yes' || data === 'no') || (data && data === 'y' || data === 'n')),
  registerDeviceValidationErrors.indoorSensor
);

const createLatitudeValidation = createValidation(
  (data) => data && locationCoordsRegex.latitude.test(data),
  registerDeviceValidationErrors.latitude
);

const createLongitudeValidation = createValidation(
  (data) => data && locationCoordsRegex.longitude.test(data),
  registerDeviceValidationErrors.longitude
);

const createNullIslandValidation = createValidation(
  (data) => data && (data !== "0,0"),
  registerDeviceValidationErrors.nullIsland
);

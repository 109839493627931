<template>
  <v-dialog
    @input="close"
    v-model="isOpen"
    :content-class="contentClass"
    :max-width="maxWidth ? maxWidth : 520"
    :max-height="maxHeight ? maxHeight : 800"
    :fullscreen="$vuetify.breakpoint.xs"
    :hide-overlay="persistent"
    :persistent="persistent || persistentOverlay"
    :no-click-animation="persistent"
    scrollable
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind="on"></slot>
    </template>
      <v-card
      :class="[{ mobile: $vuetify.breakpoint.xs, 'dialog-card--shaded': shaded }, customCss] "
    >
      <slot v-if="!noHeader" name="header">
        <v-card-title>
          <v-icon class="header-icon" v-if="icon">{{ icon }}</v-icon>
          {{ title }}
          <!-- This should NOT be used unless you have no other choice, ex: delego iframe -->
          <v-spacer v-if="useXCloseButton"></v-spacer>
          <v-btn
            v-if="useXCloseButton"
            icon
            @click="close"
            data-cy="baseDialogXCloseButton"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      </slot>
      <fusion-scroll>
        <v-card-text
          :class="{
            noHeader: noHeader,
            mobile: $vuetify.breakpoint.xs,
            padded: padded,
          }"
        >
          <slot></slot>
        </v-card-text>
      </fusion-scroll>
      <v-card-actions v-if="showErrorsBlock" class="errors" text>
        <div v-if="error" class="hint-text err-font">
          {{ error }}
          <a v-if="urlError != ''" :href="urlError"> {{ urlErrorMsg }} </a>
        </div>
        <div v-if="secondaryError" class="hint-text err-font ml-auto">
          {{ secondaryError }}
        </div>
      </v-card-actions>
      <v-card-actions v-if="!noActions" class="actions" text>
        <slot name="actions"></slot>
      </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    open: Boolean,
    error: String,
    secondaryError: String,
    urlError: String,
    urlErrorMsg: String,
    maxWidth: Number,
    maxHeight: Number,
    title: String,
    useXCloseButton: Boolean,
    noHeader: Boolean,
    noActions: Boolean,
    noMessage: Boolean,
    persistentErrors: Boolean,
    persistent: Boolean,
    persistentOverlay: Boolean,
    padded: Boolean,
    'content-class': String,
    icon: String,
    shaded: Boolean,
    customCss: String
  },
  computed: {
    isOpen() {
      return this.open
    },
    isErrored() {
      return !!this.error || !!this.secondaryError
    },
    showErrorsBlock() {
      return this.isErrored || !!this.persistentErrors
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/global-variables.scss';

.v-dialog {
  .v-card {
    padding: 16px;
    padding-right: 2px !important;

    &.dialog-card--shaded {
      background-color: #f5f5f5;

      .v-card__actions {
        margin-top: 16px;
      }

      .v-card__actions.errors + .v-card__actions.actions {
        margin-top: 0;
      }

      ::v-deep .v-btn--outlined {
        background-color: #ffffff;
      }
    }
  }

  .device-settings.mobile {
    padding: 0 !important;

    .__vuescroll {
      background-color: $white-color;
    }

    .v-card__text {
      padding: 0 !important;
    }

    .actions {
      padding: 16px 24px;
    }

    .v-card__title,
    .v-tabs-bar {
      background-color: $white-smoke-color;
    }
  }

  .v-card__text {
    padding: 0;
    padding-right: 13px;

    &.padded {
      padding: 16px;
      padding-right: 30px;
    }
  }

  &.v-dialog--fullscreen {
    .v-card:not(.device-settings) {
      padding-right: 6px !important;
    }

    .v-card__text {
      padding-right: 18px;

      &.padded {
        padding: 0;
        padding-right: 18px;
      }
    }

    .errors {
      padding-right: 26px;
    }

    .actions {
      padding-right: 34px;
    }
  }

  .v-card__title {
    font-weight: 300;
    padding: 8px 24px 16px 16px;
  }

  .errors {
    flex-wrap: wrap;
    min-height: 26px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 8px;
    padding-right: 22px;
  }

  .actions {
    padding: 16px;
    padding-right: 30px;
  }

  .hint-text {
    font-size: 12px;
    opacity: 0.76;
    padding: 4px 8px;

    &.err-font {
      color: red;
    }
  }
}
</style>

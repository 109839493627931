export const getStatisticsReadingsFromMeasurements = (
  measurements,
  timestamp
) => {
  if (measurements?.Value?.length) {
    const measurementStatistics = {}
    for (const measurement of measurements.Value) {
      if (measurement.Key && measurement.Value?.[0]) {
        measurementStatistics[measurement.Key] = {
          name: measurement.Key,
          value: measurement.Value[0].Value,
          timestamp,
        }
      }
    }
    return measurementStatistics
  }

  return null
}

export const getStatisticsReadingsFromSensor = (sensor, timestamp) => {
  if (sensor?.Value?.length) {
    let measurementStatistics = {}
    for (const measurements of sensor.Value) {
      measurementStatistics = {
        ...measurementStatistics,
        ...getStatisticsReadingsFromMeasurements(measurements, timestamp),
      }
    }

    return measurementStatistics
  }

  return null
}

export const getStatisticsReadingsFromSensors = (sensors, timestamp) => {
  if (sensors?.value?.length) {
    let measurementStatistics = {}
    for (const sensor of sensors.value) {
      measurementStatistics = {
        ...measurementStatistics,
        ...getStatisticsReadingsFromSensor(sensor, timestamp),
      }
    }
    return measurementStatistics
  }

  return null
}

export const getStatisticsReadingsFromStudyStates = (states, timestamp) => {
  if (states?.length) {
    for (const state of states) {
      const statisticsReadings = getStatisticsReadingsFromSensors(
        state?.device_state_data?.statistics,
        timestamp
      )
      if (statisticsReadings) {
        return statisticsReadings
      }
    }
  }

  return null
}

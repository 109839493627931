var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{
    'ma-6': !this.$vuetify.breakpoint.xs,
    'manage-devices-card': true,
    'is-mobile': this.isMobile,
  }},[_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t('manageDevices.title')))]),(_vm.enableBulkDeviceRegistration)?_c('v-container',{staticClass:"menu-options",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"auto search-column"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","data-cy":"searchField"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"fit-space",attrs:{"md":"auto"}},[(!_vm.isLoading)?_c('dropdown-menu',_vm._b({attrs:{"data-cy":"addDeviceDropdown"}},'dropdown-menu',_vm.getAddDevicesDropDownProps(),false)):_vm._e()],1)],1)],1):_c('div',{staticClass:"table-bar"},[_c('v-text-field',{staticClass:"search-field",style:(_vm.isMobile ? '' : 'width: 200px'),attrs:{"prepend-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","data-cy":"searchField"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),(!_vm.isLoading)?_c('span',{staticClass:"register"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.openRegisterDevice.apply(null, arguments)}}},[_c('v-icon',{staticClass:"op-76"},[_vm._v("mdi-map-marker-plus")]),_c('span',{staticStyle:{"padding-left":"4px"}},[_vm._v(" "+_vm._s(_vm.$t('manageDevices.addDevice'))+" ")])],1)],1):_vm._e()],1),(_vm.showTable)?_c('v-data-table',{class:{ mobile: _vm.isMobile },attrs:{"headers":_vm.headers,"items":_vm.devices,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"header-props":_vm.headerProps,"custom-filter":_vm.filterByColumnValueAndDeviceAttributes,"search":_vm.customTableFilter,"options":_vm.tableOptions,"sort-by":_vm.sortedBy,"sort-desc":_vm.sortDesc,"itemsPerPage":_vm.itemsPerPage,"show-expand":"","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"footer-props":{
      'items-per-page-text': _vm.isMobile
        ? _vm.$t('tables.rows')
        : _vm.$t('tables.rowsPerPage'),
      itemsPerPageOptions: [10, 25, 50],
    },"data-cy":"manageDevicesTable"},on:{"update:sortBy":function($event){_vm.sortedBy=$event},"update:sort-by":function($event){_vm.sortedBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:expanded":function($event){_vm.expanded=$event},"current-items":_vm.updateTableComputedItems},scopedSlots:_vm._u([(this.isMobile)?{key:"body.prepend",fn:function(ref){return [(_vm.enableExpandCollapseButtons)?_c('expand-collapse-buttons',{on:{"expandAll":_vm.expandAllAssociatedDevices,"collapseAll":_vm.collapseAllAssociatedDevices}}):_vm._e()]}}:null,{key:"item",fn:function(ref){
    var item = ref.item;
    var expand = ref.expand;
    var isExpanded = ref.isExpanded;
return [_c(_vm.tableItemComponent,{tag:"component",attrs:{"item":item,"expand":expand,"isExpanded":isExpanded},on:{"openDialog":_vm.openDialog}})]}},{key:"expanded-item",fn:function(ref){
    var item = ref.item;
return _vm._l((item.associatedDevices),function(i){return _c(_vm.tableItemComponent,{key:i.id,tag:"component",attrs:{"item":i,"isChild":true},on:{"openDialog":_vm.openDialog}})})}},(!this.isMobile)?{key:"footer.prepend",fn:function(ref){return [(_vm.enableExpandCollapseButtons)?_c('expand-collapse-buttons',{on:{"expandAll":_vm.expandAllAssociatedDevices,"collapseAll":_vm.collapseAllAssociatedDevices}}):_vm._e()]}}:null],null,true)}):(_vm.showEmptyMessage)?_c('div',{staticClass:"text-center emptyMessage"},[_vm._v(" "+_vm._s(_vm.$t('manageDevices.emptyMessage'))+" ")]):_c('div',{staticClass:"text-center emptyMessage"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),_c('register-device',{attrs:{"open":_vm.dialogs.registerDevice,"deviceSerial":'',"deviceModel":''},on:{"close":function($event){_vm.dialogs.registerDevice = false}}}),_c('register-multiple-devices',{attrs:{"open":_vm.dialogs.registerMultipleDevices},on:{"close":function($event){_vm.dialogs.registerMultipleDevices = false}}}),_c('action-menu-dialogs',{attrs:{"dialog":_vm.currentDialog,"deviceId":_vm.currentDeviceId},on:{"closeDialog":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { HTTPMethods, HTTPMode, useToken, ContentType } from './utils'

export const GET = function (
  route,
  optHeaders = {},
  optConfig = {},
  host = process.env.VUE_APP_CLIENT_URL
) {
  return this.$auth.getTokenSilently().then((token) => {
    const headerConfig = {
      Authorization: useToken(token),
      Accept: ContentType.JSON,
    }

    const fetchConfig = {
      method: HTTPMethods.GET,
      mode: HTTPMode.CORS,
      headers: new Headers({ ...headerConfig, ...optHeaders }),
    }

    return fetch(`${host}${route}`, {
      ...fetchConfig,
      ...optConfig,
    })
  })
}

export const POST = function (
  route,
  body,
  optHeaders = {},
  optConfig = {},
  host = process.env.VUE_APP_CLIENT_URL
) {
  return this.$auth.getTokenSilently().then((token) => {
    const headerConfig = {
      Authorization: useToken(token),
      Accept: ContentType.JSON,
      'Content-Type': ContentType.JSON,
    }

    const fetchConfig = {
      method: HTTPMethods.POST,
      mode: HTTPMode.CORS,
      headers: new Headers({ ...headerConfig, ...optHeaders }),
      body,
    }

    return fetch(`${host}${route}`, {
      ...fetchConfig,
      ...optConfig,
    })
  })
}

export const PATCH = function (
  route,
  body,
  optHeaders = {},
  optConfig = {},
  host = process.env.VUE_APP_CLIENT_URL
) {
  return this.$auth.getTokenSilently().then((token) => {
    const headerConfig = {
      Authorization: useToken(token),
      Accept: ContentType.JSON,
      'Content-Type': ContentType.JSON,
    }

    const fetchConfig = {
      method: HTTPMethods.PATCH,
      mode: HTTPMode.CORS,
      headers: new Headers({ ...headerConfig, ...optHeaders }),
      body,
    }

    return fetch(`${host}${route}`, {
      ...fetchConfig,
      ...optConfig,
    })
  })
}

export const DELETE = function (
  route,
  optHeaders = {},
  optConfig = {},
  host = process.env.VUE_APP_CLIENT_URL
) {
  return this.$auth.getTokenSilently().then((token) => {
    const headerConfig = {
      Authorization: useToken(token),
      Accept: ContentType.JSON,
    }

    const fetchConfig = {
      method: HTTPMethods.DELETE,
      mode: HTTPMode.CORS,
      headers: new Headers({ ...headerConfig, ...optHeaders }),
    }

    return fetch(`${host}${route}`, {
      ...fetchConfig,
      ...optConfig,
    })
  })
}

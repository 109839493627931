<template>
  <fusion-public-page></fusion-public-page>
</template>

<script>
import { isNotAuthorized } from '@fusion/auth'

export default {
  mixins: [isNotAuthorized],
}
</script>

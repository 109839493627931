import { getInstance } from '../api/service'

export const DEVICE_UPDATE_INTERVAL_MS = 60000

export const getTelemetryAverages = async (deviceId, deviceType) => {
  let averages
  const api = getInstance()

  try {
    const resp = await api.getTelemetryAverages(deviceId, deviceType)
    if (resp.ok) {
      const body = await resp.json()
      if (body) {
        averages = body
      }
    }
  } catch (error) {
    // ignore, returns undefined averages
  }

  return averages
}

export const getPercentChanged = async (deviceId) => {
  let percentChanged
  const api = getInstance()

  try {
    const pctResp = await api.getTelemetryPercentChanged(deviceId)
    if (pctResp.ok) {
      const body = await pctResp.json()
      if (body) {
        percentChanged = body
      }
    }
  } catch (error) {
    // ignore, returns undefined percentChanged
  }

  return percentChanged
}

export const getLatestTelemetry = async (deviceId) => {
  let telemetry = {}
  const api = getInstance()
  try {
    const resp = await api.getTelemetryLatest(deviceId)
    if (resp.ok) {
      const body = await resp.json()
      if (body) {
        telemetry.readings = body
        telemetry.timestamp = body.timestamp
        telemetry.lastTelemetryStudyId = body.study_id
      }
    }
  } catch (error) {
    // ignore, returns empty telemetry
  }

  return telemetry
}

export const getLatestStudy = async (deviceId) => {
  let study = null
  const api = getInstance()
  try {
    const resp = await api.getLatestDeviceStudy(deviceId)
    if (resp.ok) {
      study = await resp.json()
    }
  } catch (error) {
    // ignore, return empty study
  }

  return study
}

export const getDeviceDetails = async (deviceId) => {
  let detail = {}
  const api = getInstance()

  try {
    const resp = await api.getDevice(deviceId)
    if (resp.ok) {
      const body = await resp.json()

      detail.model = body.model
      detail.serial = body.serial

      if (body.cloud_state_data) {
        detail.name = body.cloud_state_data.name
        detail.locationName = body.cloud_state_data.location_name
        detail.indoor = body.cloud_state_data.isIndoor
        detail.isPublic = body.cloud_state_data.isPublic
        detail.zeroAppliedAt = body.cloud_state_data.zero_applied_at
        detail.zeroState = body.cloud_state_data.zero_state
      }

      if (body.device_state_data) {
        detail.timestamp = body.device_state_data.config_timestamp
        detail.firmware = body.device_state_data.firmware_version
        detail.rssi = body.device_state_data.ap_rssi
        detail.spsEoL = body.device_state_data.spsEoL
        detail.connectionType = body.device_state_data.connection_type
        detail.lastCalibrationTimestamp = body.device_state_data.calibration_date
      }
    }
  } catch (error) {
    // ignore, returns empty detail
  }

  return detail
}

<template>
  <div class="loading-container">
    {{ this.$t('map.popover.noDataAvailable') }}
  </div>
</template>

<script>
export default {
  name: 'LoadingContainer',
}
</script>

<style lang="scss" scoped>
@import '@/assets/global-mixins.scss';

.loading-container {
  @include center-absolute-element;
  width: 100%;
  text-align: center;
}
</style>

<template>
  <!-- unregister card -->
  <v-card class="unregister-warning" flat>
    <div class="unregister-title">
      {{ $t('deviceUnregister.unregisterCard.warningTitle') }}
    </div>
    <div class="unregister-subtitle">
      {{ $t('deviceUnregister.unregisterCard.warningSubtitle') }}
    </div>
    <div class="unregister-message">
      <template>
        <p v-if="hasSBD2" data-cy="unregisterMessageZero">
          {{ unregisterMessage['partZero'] }}
        </p>
        <p data-cy="unregisterMessageOne">
          {{ unregisterMessage['partOne'] }}
        </p>
        <p data-cy="unregisterMessageTwo">
          {{ unregisterMessage['partTwo'] }}
        </p>
      </template>
    </div>
  </v-card>
</template>

<script>
import { deviceModels, subscriptionTypes } from '../../store/subscriptions/utils'
export default {
  name: 'DeviceUnregisterCard',
  props: {
    deviceId: String,
    useWebsite: { type: Boolean, default: true },
  },
  data() {
    return {
      cache: null,
    }
  },
  mounted() {
    this.$root.$on('device-unregistering', this.handleUnregistering)
  },
  beforeDestroy() {
    this.$root.$off('device-unregistering', this.handleUnregistering)
  },
  methods: {
    handleUnregistering(value) {
      if (value) {
        this.cache = {
          hasDataServices: this.hasDataServices,
          hasWebsiteAndDataServices: this.hasWebsiteAndDataServices,
          hasSBD2: this.hasSBD2,
          onAPIPage: this.onAPIPage,
        }
      } else {
        this.cache = null
      }
    },
  },
  computed: {
    serial() {
      return this.$store.getters['devices/getSerialByDeviceId'](this.deviceId)
    },
    isSmartBridge() {
      return this.$store.getters['devices/getModelByDeviceId'](this.deviceId) === deviceModels.SMART_BRIDGE
    },
    hasDataServices() {
      return (
        this.$store.getters['subscriptions/hasDataServicesSubscription'](this.serial)
      )
    },
    hasWebsite() {
      return (
        this.$store.getters['subscriptions/hasWebsiteSubscription'](this.serial) ||
        this.$store.getters['subscriptions/hasFreeTierSubscription'](this.serial)
      )
    },
    hasSBD2() {
      // SA-1426: use device store for all subscription types
      const subType = this.$store.getters['devices/getWebsiteSubscriptionSubType'](this.deviceId)
      return subType === subscriptionTypes.DEVICE_SMART_BRIDGE
    },
    hasWebsiteAndDataServices() {
      if (this.cache) {
        return this.cache.hasWebsiteAndDataServices
      }
      return this.hasWebsite && this.hasDataServices
    },
    onAPIPage() {
      if (this.cache) {
        return this.cache.onAPIPage
      }

      return this.$route.name === 'api-services'
    },
    unregisterMessage() {
      let localeStringPrefix = 'deviceUnregister.unregisterCard.messages.'
      const partZero = this.hasSBD2 ? this.$t(localeStringPrefix + 'partZero.smartbridgeConnected') : ""
      let partOne = ""
      switch (true) {
        case this.useWebsite && this.hasWebsiteAndDataServices:
          partOne = this.$t(localeStringPrefix + 'partOne.dual.website')
          break
        case !this.useWebsite && this.hasWebsiteAndDataServices:
          partOne = this.$t(localeStringPrefix + 'partOne.dual.dataServices')
          break
        case this.hasWebsite:
          partOne = this.$t(localeStringPrefix + 'partOne.website')
          break
        case this.hasDataServices:
          partOne = this.$t(localeStringPrefix + 'partOne.dataServices')
          break
        case this.hasSBD2:
          partOne = this.$t(localeStringPrefix + 'partOne.smartbridgeConnected')
          break
        case this.isSmartBridge:
          partOne = this.$t(localeStringPrefix + 'partOne.smartbridgeDevice')
          break
        default:
      }
      const pageSpecifier = this.onAPIPage ? 'fromAPIPage' : 'notFromAPIPage'
      const partTwo = this.$t(localeStringPrefix + 'partTwo.' + pageSpecifier)
      return {
        partZero,
        partOne,
        partTwo
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.v-card {
  padding: 24px;
  min-height: 350px;

  .unregister-warning {
    .unregister-title {
      color: red;
      font-size: 18px;
    }
    .unregister-subtitle {
      color: red;
      font-size: 16px;
      margin-bottom: 16px;
    }
    .unregister-message {
      p {
        font-size: 14px;
        opacity: 0.86;
      }
    }
  }
}
</style>

import { getInstance } from '../api/service'

/**
 * Saves widget settings to dashboard store
 * @param {String} widgetId ID of widget
 * @param {Object} settings settings to save
 */
export const saveWidgetSettings = (widgetId, settings) => {
  const api = getInstance()
  api.patchWidget({ settings: settings }, widgetId)
}

export const widgetSettings = {
  methods: {
    saveWidgetSettings,
  },
}

import { AUTOCAL_SENSORS } from '../services/device-calibration'

export const defaultLoggingInterval = 900 // 15 min
export const defaultCleaningInterval = 604800 // 1 week

export const DEVICE_DEFAULT_CONFIG = {
  cloud_config: {
    send_rate: defaultLoggingInterval,
  },
  sensor_config: {
    pm: {
      clean_interval: defaultCleaningInterval,
    },
  },
}

export const getIso8601WithoutMilliseconds = (date) => {
  return date.toISOString().slice(0, -5) + 'Z'
}

export const writeDeviceConfig = (
  send_rate,
  clean_interval,
  userCals,
  userZeros
) => {
  const config = {
    cloud_config: {
      send_rate,
    },
    sensor_config: {
      pm: {
        clean_interval,
      },
    },
  }

  for (const uc of userCals) {
    const name = uc.name
    if (name === 'pm10_raw') {
      config.sensor_config['pm']['mcpm10_userslope'] = Number(uc.multiFactor)
      if (!isNaN(uc.offset)) {
        config.sensor_config['pm']['mcpm10_useroffset'] = Number(uc.offset)
      }
    } else if (name === 'pm25_raw') {
      config.sensor_config['pm']['mcpm2x5_userslope'] = Number(uc.multiFactor)
      if (!isNaN(uc.offset)) {
        config.sensor_config['pm']['mcpm2x5_useroffset'] = Number(uc.offset)
      }
    } else {
      if (name === 'temp') {
        config.sensor_config['temp'] = { useroffset: Number(uc.offset) }
      } else if (name === 'rh') {
        config.sensor_config['rh'] = { useroffset: Number(uc.offset) }
      } else if (name === 'baro_inhg') {
        config.sensor_config['baro'] = {
          useroffset: Number(uc.offset),
        }
      } else if (AUTOCAL_SENSORS.includes(name)) {
        config.sensor_config[name] = {
          userslope: Number(uc.multiFactor),
          useroffset: Number(uc.offset),
          ascCal: Boolean(uc.ascCal),
        }
      } else {
        config.sensor_config[name] = {
          userslope: Number(uc.multiFactor),
          useroffset: Number(uc.offset),
        }
      }
    }
  }

  if (userZeros) {
    for (const [key, value] of Object.entries(userZeros)) {
      if (value.userzero !== null) {
        config.sensor_config[key].userzero = value.userzero
      }
    }
  }

  return config
}

export const getConfigByKey = (cfg, key) => {
  if (cfg && Array.isArray(cfg)) {
    const kvp = cfg.filter((item) => item.key == key)
    if (kvp.length > 0) {
      return kvp[0]?.value ?? null
    }
  }
  return null
}

export default {
  setIsLogInAs(state, isLogInAs) {
    state.isLogInAs = isLogInAs
  },
  setImpersonatedUser(state, impersonatedUser) {
    state.impersonatedUser = impersonatedUser
  },
  setImpersonatedUserPermissions(state, permissions) {
    state.impersonatedUserPermissions = permissions
  }
}

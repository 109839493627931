import getAuthOpts from './auth'
import componentsOpts from './components'
import mapsOpts from './maps'
import permissionsOpts from './permissions'
import vuetifyOpts from './vuetify'
import getGtmOpts from './gtmOpts'


export {
  getAuthOpts,
  componentsOpts,
  mapsOpts,
  permissionsOpts,
  vuetifyOpts,
  getGtmOpts
}

export default {
  getAuth: getAuthOpts,
  components: componentsOpts,
  maps: mapsOpts,
  permissions: permissionsOpts,
  vuetify: vuetifyOpts,
  getGtm: getGtmOpts
}

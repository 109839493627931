export default {
  accounts: [],
  auth0Status: '',
  sendgridStatus: '',
  twilioStatus: '',
  flexeraStatus: '',
  mongodbStatus: '',
  clearbladeStatus: '',
  apigeeStatus: '',
  googlemapsStatus: '',
  godaddyStatus: '',
}

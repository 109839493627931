<template>
  <div
    :class="{
      'expand-collapse-buttons': true,
      'is-mobile': this.$vuetify.breakpoint.smAndDown,
    }"
  >
    <v-btn data-cy="expandAll" @click="$emit('expandAll')">{{
      $t('manageSubscriptions.tableFooter.expandAll')
    }}</v-btn>
    <span class="separator">{{ '|' }}</span>
    <v-btn data-cy="collapseAll" @click="$emit('collapseAll')">{{
      $t('manageSubscriptions.tableFooter.collapseAll')
    }}</v-btn>
  </div>
</template>

<script>
export default {
  name: 'ExpandCollapseButtons',
}
</script>

<style lang="scss" scoped>
.expand-collapse-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding-left: 3.5rem;

  .v-btn {
    text-transform: none;
    background-color: white;
    box-shadow: none;
    font-size: 0.9rem;
    font-weight: normal;
    letter-spacing: 0;
  }

  .separator {
    font-size: 1.3rem;
    font-weight: 200;
  }
}

.expand-collapse-buttons.is-mobile {
  padding: 16px 10px;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>

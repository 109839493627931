<template>
  <v-card-subtitle :class="customClass" :data-cy="dataCy">
    <span> {{ label }}: </span>
    <span class="value">
      {{ formattedTimestamp }}
    </span>
  </v-card-subtitle>
</template>

<script>
import { validateTimestamp } from '@/services/device-status'

export default {
  props: {
    timestamp: String,
    label: String,
    customClass: String,
    dataCy: String,
  },
  computed: {
    formattedTimestamp() {
      const noTS = this.$t('map.popover.timestamp.lastMeasurementNever')
      const timestamp = validateTimestamp(
        this.timestamp,
        noTS
      )

      return timestamp != noTS
        ? new Date(timestamp).toLocaleString()
        : timestamp
    },
  },
}
</script>

<template>
  <v-card :class="{ 'ma-6': !this.$vuetify.breakpoint.xs, }">
    <v-card-title class="titles">
        {{ $t ('thirdPartyTable.titleTSI') }}
      </v-card-title>
    <v-container fluid>
    </v-container>
  </v-card>
</template>

<script>

export default {
  name: 'TsiTable',
}
</script>

<style lang="scss" scoped>
.titles {
  font-weight: 300;
}
</style>

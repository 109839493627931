<template>
  <base-dialog
    :open="open"
    @close="close"
    :fullscreen="$vuetify.breakpoint.xs"
    :persistentOverlay="true"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        class="popover-status-icon device-notifications-icon"
        text
        v-on="on"
        @click.stop="open = true"
        v-if="showNotifications"
        :class="showNotificationsClass"
      >
        <v-badge color="accent" overlap right offset-x="12" offset-y="14">
          <template v-slot:badge>
            <span>{{ notificationsNum }}</span>
          </template>
          <v-icon color="grey">mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <template v-slot:header>
      <v-card-title>
        <div>
          <v-icon class="header-icon">mdi-bell</v-icon>
          {{ $t('map.popover.menu.deviceNotifications') }}
        </div>
        <v-spacer />
        <v-btn text class="primary--text mobile-menu" @click="viewAll">
          {{ $t('alertHistory.viewAll') }}
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        <v-tooltip bottom :max-width="600" :disabled="!isNameOverflowing">
          <template v-slot:activator="{ on }">
            <div v-on="on" class="device-name" ref="deviceName">
              <span>{{ deviceName }}</span>
            </div>
          </template>
          <span>{{ deviceName }}</span>
        </v-tooltip>
      </v-card-subtitle>
    </template>

    <v-list>
      <v-list-item
        v-for="(item, index) in notifications"
        :key="index"
        three-line
      >
        <v-list-item-content>
          <v-divider v-if="index > 0" style="margin-bottom: 20px" />
          <v-row no-gutters>
            <v-col>
              <v-list-item-title>{{ item.trigger.title }}</v-list-item-title>
            </v-col>
            <v-col cols="5">
              <v-list-item-title
                class="anchor-right"
                style="text-transform: capitalize"
              >
                {{ item.trigger.severity }}
              </v-list-item-title>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-list-item-subtitle>
                {{ item.device.name }}
              </v-list-item-subtitle>
            </v-col>
            <v-col cols="5">
              <v-list-item-subtitle class="anchor-right">
                {{ new Date(item.triggeredAt).toLocaleDateString() }}
                {{ new Date(item.triggeredAt).toLocaleTimeString() }}
              </v-list-item-subtitle>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-list-item-action-text>
                <strong> {{ item.message }} </strong>
              </v-list-item-action-text>
            </v-col>
            <v-col cols="5">
              <v-btn
                class="clear-button"
                @click="clear(item.id)"
                :loading="buttonLoading[`${item.id}`]"
                :disabled="clearingInProgress"
                outlined
              >
                {{ $t('buttons.clear') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:actions>
      <v-spacer />
      <div style="margin-right: 16px">
        <div>
          <v-btn
            class="clear-all-button black white--text"
            :loading="clearingAllLoading"
            :disabled="clearingInProgress"
            @click="clearAll(deviceId)"
            text
          >
            {{ $t('buttons.clearAll') }}
          </v-btn>
        </div>
        <div>
          <v-btn class="primary white--text" @click="open = false" text>
            {{ $t('buttons.done') }}
          </v-btn>
        </div>
      </div>
    </template>
  </base-dialog>
</template>

<script>
import dialog from '@/components/dialogs/Dialog'
import { isChildOverflowing } from '@/services/html-utilities'

export default {
  components: {
    'base-dialog': dialog,
  },
  props: {
    deviceId: String,
    deviceName: String,
  },
  data() {
    return {
      open: false,
      buttonLoading: {},
      clearingInProgress: false,
      clearingAllLoading: false,
      isNameOverflowing: false,
    }
  },
  watch: {
    notificationsNum() {
      if (this.notificationsNum < 1) {
        this.close()
      }
    },
    open() {
      if (this.open) {
        this.setIsNameOverflowing()
      }
    },
  },
  computed: {
    notifications() {
      return this.$store.getters['alerts/getNotificationsByDeviceId'](
        this.deviceId
      )
    },
    notificationsNum() {
      const nn = this.$store.getters['alerts/getNotificationsNumByDeviceId'](
        this.deviceId
      )
      return nn > 9 ? '9+' : '' + nn
    },
    showNotifications() {
      return this.notificationsNum > 0 || this.notificationsNum === '9+'
    },
    showNotificationsClass() {
      return this.notificationsNum === '9+' ? 'badge-wide' : ''
    },
  },
  methods: {
    close() {
      this.open = false
    },
    async getNotifications() {
      const res = await this.$api.getAlertNotifications({
        isRead: false,
        limit: 25,
      })
      if (res.ok) {
        const body = await res.json()
        this.$store.dispatch('alerts/setNotifications', body)
        this.clearingInProgress = false
        this.clearingAllLoading = false
      }
    },
    async clear(id) {
      this.clearingInProgress = true
      const buttonLoad = {}
      buttonLoad[`${id}`] = true
      this.buttonLoading = buttonLoad
      await this.$api.patchClearNotification(id)
      this.getNotifications()
    },
    async clearAll(deviceId) {
      this.clearingInProgress = true
      this.clearingAllLoading = true
      await this.$api.postClearAllDeviceNotifications(deviceId)
      this.getNotifications()
    },
    viewAll() {
      this.close()
      this.$router.push({ name: 'alert-history' })
    },
    async setIsNameOverflowing() {
      await Vue.nextTick()
      this.isNameOverflowing = isChildOverflowing(this.$refs.deviceName)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    padding: 16px;
  }
  .v-card__title {
    font-weight: 300;
    padding: 8px 24px 16px 16px;
    .header-icon {
      color: rgba(0, 0, 0, 0.76);
      margin-right: 16px;
    }
  }
  .v-card__subtitle {
    padding: 8px 16px 8px 16px;
  }

  .device-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .v-btn.clear-button {
    min-width: 72px !important;
    height: 32px !important;
  }

  .v-btn.clear-all-button {
    width: 104px;
    min-width: 104px !important;
    height: 32px !important;
    margin-bottom: 44px;
    @media (max-width: 495px) {
      width: 120px !important;
      height: 36px !important;
      margin-bottom: 16px;
    }
  }

  .row {
    flex: 1 0 100%;
  }
}

.anchor-right {
  text-align: end;
}

.badge-wide {
  margin-right: 6px;
}

@media (max-width: 600px) {
  .v-menu__content {
    top: 0 !important;
    height: 100%;
    border-radius: unset;
  }
}
</style>

<template>
  <v-textarea
    v-model="localValue"
    :label="$t('activateDevice.deviceLocationName')"
    :maxlength="maxLength"
    :counter="maxLength"
    rows="1"
    auto-grow
    @keypress="preventDeniedCharacters($event)"
    :data-cy="cyTag"
  ></v-textarea>
</template>

<script>
export default {
  props: {
    value: String,
    cyTag: String,
  },
  data() {
    return {
      maxLength: 255,
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(localValue) {
        this.$emit('input', localValue)
      },
    },
  },
  methods: {
    preventDeniedCharacters(event) {
      const deniedChars = [13] // 'enter' (new line)
      const keypress = event.keyCode || event.which || event.charCode
      if (deniedChars.includes(keypress)) {
        event.preventDefault()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-textarea ::v-deep textarea {
  line-height: 1.5;
  padding: 8px 0;
  overflow: hidden;
}
</style>

import Highcharts from 'highcharts/highstock'

// default dataset colors used for graph
export const defaultGraphColors = [
  '#00aeef',
  '#ec038a',
  '#f48123',
  '#4fb74a',
  '#4b4bf5',
]

export const getTooltipFormatterCallback = (units, decimals) => {
  // format any exponent in the units string
  units = units.replace('^', '<sup>')
  units += '</sup>'

  return function () {
    const value =
      this.series && this.series.options.type === 'flags'
        ? this.point.text
        : Highcharts.numberFormat(this.y, decimals, '.', '') + units
    return (
      `
      <span>${new Date(this.x).toLocaleString()}</span>
      <br />
      <span>
        <b style="color: ${this.series.color};">` +
      value +
      `</b>
      </span>
    `
    )
  }
}

// default Dygraph options
export const defaultGraphOptions = {
  accessibility: {
    enabled: false
  },
  chart: {
    zoomType: 'x',
    height: null,
  },
  credits: {
    enabled: false,
  },
  boost: {
    // useGPUTranslations: true,
    // debug: {
    //   timeSetup: true,
    //   timeSeriesProcessing: true,
    //   timeBufferCopy: true,
    //   timeKDTree: true,
    //   showSkipSummary: true
    // },
    usePreallocated: false,
  },

  title: {
    text: '',
  },

  legend: {
    enabled: false,
  },

  subtitle: {
    text: '',
  },

  tooltip: {
    valueDecimals: 2,
    borderColor: '#00aeef',
    useHTML: true,
    formatter: getTooltipFormatterCallback('', 0),
  },
  time: {
    useUTC: false,
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      millisecond: '%l:%M:%S.%L %p',
      second: '%l:%M:%S %p',
      minute: '%l:%M %p',
      hour: '%l:%M %p',
      day: '%e. %b',
      week: '%e. %b',
      month: "%b '%y",
      year: '%Y',
    },
  },

  yAxis: {
    title: '',
    labels: {
      enabled: true,
    },
  },

  series: [],

  navigator: {
    enabled: true,
    height: 30,
    margin: 16,
    xAxis: {
      dateTimeLabelFormats: {
        millisecond: '%l:%M:%S.%L %p',
        second: '%l:%M:%S %p',
        minute: '%l:%M %p',
        hour: '%l:%M %p',
        day: '%e. %b',
        week: '%e. %b',
        month: "%b '%y",
        year: '%Y',
      },
    },
  },
  scrollbar: {
    enabled: false,
  },
  rangeSelector: {
    enabled: false,
  },
}

const getInvalidAQIStartTimestamp = (readings) => {
  const timestamps = []
  for (let i = 0; i < readings.length; i++) {
    const timestamp = readings[i][0]
    const aqi = readings[i][1]
    if (aqi === null) {
      if (i === 0) {
        timestamps.push(timestamp)
      } else {
        const prevAqi = readings[i - 1][1]
        if (prevAqi !== null) {
          timestamps.push(timestamp)
        }
      }
    }
  }
  return timestamps
}

export const createFlags = (readings, color) => {
  const invalidAQITimestamp = getInvalidAQIStartTimestamp(readings)
  if (invalidAQITimestamp.length > 0) {
    const flags = {
      type: 'flags',
      data: invalidAQITimestamp.map((t) => {
        return {
          x: t,
          title: '!',
          text: 'Error: AQI Data is not available because the value has exceeded the allowable U.S. AQI range (0-500).',
        }
      }),
      shape: 'circlepin',
      color: color,
      fillColor: color,
      width: 16,
      style: {
        // style for title
        color: 'white',
      },
    }
    return flags
  }
  return null
}

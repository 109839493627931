<template>
  <div>
    <base-dialog :open="showDialog" @close="close" :persistentOverlay="true" icon="mdi-alert">
      <template v-slot:header>
        <v-card-title>
          <v-icon class="header-icon">mdi-map-marker-plus</v-icon>
          {{ $t('apiServices.registerDevices') }}
        </v-card-title>
      </template>
      <div class="">
        <v-card-text>
          <p>{{ $t('apiServices.registrationFormat') }}</p>
          <p>{{ $t('apiServices.registrationSubscription') }}</p>
          <v-textarea v-model="bulkDevices" :placeholder="$t('apiServices.registerPlaceholder')"
            :rules="[bulkDevicesValid]" outlined data-cy="apiBulkDevices"
            @keypress="preventNonNumbers($event)"></v-textarea>
        </v-card-text>
      </div>
      <template v-slot:actions>
        <v-btn @click="close" data-cy="closeRegisterDevicesDialog" text class="black--text cancel-btn" outlined
          :disabled="bulkSpinner">
          {{ $t('buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn text class="primary white--text" :loading="bulkSpinner" :disabled="submitDisabled"
          @click="bulkRegisterExternalDevices" data-cy="apiBulkRegisterSubmit">
          {{ $t('buttons.submit') }}
        </v-btn>
      </template>
    </base-dialog>
    <access-additional-features-dialog :open="dialogs.accessAdditionalFeatures.open"
      :addToBoth="addToBothWebsiteAndDataServices" :subscriptionTiers="subscriptionTiers"
      :bulkDeviceModels="bulkDeviceModels" @change="changeAddToBothWebsiteAndDataServices"
      @subTierChange="changeSubscriptionTiers" @cancel="cancelAccessAdditionalFeaturesDialog"
      @close="closeAccessAdditionalFeaturesDialog" />

    <api-bulk-registration-info-dialog :open="dialogs.apiBulkRegistrationInfo.open"
      :isRetrying="dialogs.apiBulkRegistrationInfo.isRetrying" :addToBoth="addToBothWebsiteAndDataServices"
      :allowRetry="allowRetry" :results="results" @retry="retryBulkRegistration"
      @close="closeAPIBulkRegistrationInfoDialog" />
  </div>
</template>

<script>
import Dialog from '@/components/dialogs/Dialog.vue'
import {
  subscriptionTypes,
  deviceModels,
} from '@/store/subscriptions/utils'
import AccessAdditionalFeaturesDialog from './AccessAdditionalFeaturesDialog.vue'
import APIBulkRegistrationInfoDialog from './APIBulkRegistrationInfoDialog.vue'
import { HTTPStatus } from '@/api'
import { serialIsValid } from '@/services/device-serial'
import { DEVICE_DEFAULT_CONFIG } from '@/services/device-config'

const NO_ATTEMPT_MSG = 'NoAttempt'

export default {
  name: 'RegisterDevicesDialog',
  props: {
    open: Boolean,
    removeLock: Boolean,
    subLock: Boolean,
  },
  data() {
    return {
      bulkDevices: '',
      validDevices: [],
      results: [],
      bulkSpinner: false,
      addToBothWebsiteAndDataServices: false,
      subscriptionTiers: {
        [deviceModels.BLUESKY]: null,
        [deviceModels.BLUESKY_GAS]: null,
        [deviceModels.AIRASSURE]: null,
      },
      dialogs: {
        accessAdditionalFeatures: {
          open: false,
        },
        apiBulkRegistrationInfo: {
          open: false,
          isRetrying: false,
        },
      },
    }
  },
  components: {
    'base-dialog': Dialog,
    'access-additional-features-dialog': AccessAdditionalFeaturesDialog,
    'api-bulk-registration-info-dialog': APIBulkRegistrationInfoDialog,
  },
  computed: {
    bulkDevicesValidFlag() {
      if (this.bulkDevices.length === 0) {
        return true
      }

      const bulkDeviceSerials = this.bulkDevices
        .split(', ')
        .map((x) => x.trim())

      // limit to 100 devices registered at once
      if (bulkDeviceSerials.length > 101) {
        return false
      }

      for (let serial of bulkDeviceSerials) {
        const deviceModelNumber = serial.substring(0, 4)

        const serialLength =
          this.$store.getters['devicemodels/getSerialLength'](deviceModelNumber)

        if (!serialIsValid(deviceModelNumber, serial, serialLength)) {
          return false
        }
      }

      return true
    },
    showDialog() {
      return (
        this.open &&
        !this.dialogs.accessAdditionalFeatures.open &&
        !this.dialogs.apiBulkRegistrationInfo.open
      )
    },
    bulkDeviceModels() {
      const commaSeparatorRegex = /[\s, ]+/
      const serials =
        this.bulkDevicesValid === true
          ? this.bulkDevices.split(commaSeparatorRegex)
          : []
      const models = [...new Set(serials.map((serial) => serial.slice(0, 4)))]
      return models
    },
    bulkDevicesValid() {
      return this.bulkDevicesValidFlag || this.$t('apiServices.invalidList')
    },
    submitDisabled() {
      return (
        this.bulkSpinner ||
        !this.bulkDevicesValidFlag ||
        this.bulkDevices.length === 0 ||
        this.removeLock ||
        this.subLock
      )
    },
    retryValidDevices() {
      const devsList = this.results.filter(
        (result) =>
          result[subscriptionTypes.DATA_SERVICES].allowRetry ||
          result[subscriptionTypes.WEBSITE].allowRetry
      )
      let devices = []
      for (let dev of devsList) {
        const serial = dev.serial
        const model = serial.slice(0, 4)
        devices.push({
          model,
          serial,
        })
      }
      return devices
    },
    allowRetry() {
      return this.retryValidDevices.length > 0
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    preventNonNumbers(event) {
      const keypress = event.keyCode || event.which || event.charCode
      const validCodes = [0, 8, 32, 44]
      if ((!validCodes.includes(keypress) && keypress < 48) || keypress > 57) {
        event.preventDefault()
      }
    },
    changeSubscriptionTiers(value) {
      this.subscriptionTiers = value
    },
    cancelAccessAdditionalFeaturesDialog() {
      this.validDevices = []
      this.dialogs.accessAdditionalFeatures.open = false
    },
    changeAddToBothWebsiteAndDataServices(value) {
      this.addToBothWebsiteAndDataServices = value
    },
    async closeAccessAdditionalFeaturesDialog() {
      await this.processBulkRegistration()
      this.dialogs.accessAdditionalFeatures.open = false
    },
    closeAPIBulkRegistrationInfoDialog() {
      this.dialogs.apiBulkRegistrationInfo.open = false
      this.results = []
      this.close()
    },
    async retryBulkRegistration() {
      this.dialogs.apiBulkRegistrationInfo.isRetrying = true
      await this.processBulkRegistration(this.retryValidDevices)
      this.dialogs.apiBulkRegistrationInfo.isRetrying = false
    },
    async patchSubscription(device, isExternal) {
      const resp = await this.$api.patchDeviceSubscription(
        {
          isExternal,
          ...device
        }
      )
      const body = await resp.text()
      this.logResult({
        serial: device.serial,
        status: resp.status,
        msg: body,
        subType: isExternal ? subscriptionTypes.DATA_SERVICES : subscriptionTypes.WEBSITE,
      })
    },
    async registerDevice(device) {
      const resp = await this.$api.postRegisterDevice(device)
      let body = ''
      if (resp.status === HTTPStatus.Created) {
        body = await resp.json()
        this.logResult({
          serial: device.serial,
          status: resp.status,
          msg: body,
          subType: subscriptionTypes.DATA_SERVICES,
        })
        await this.$api.postDeviceConfig(
          DEVICE_DEFAULT_CONFIG,
          body.cloud_device_id
        )
      } else {
        body = await resp.text()
        this.logResult({
          serial: device.serial,
          status: resp.status,
          msg: body,
          subType: subscriptionTypes.DATA_SERVICES,
        })
      }
      return resp.status
    },
    async processBulkRegistration(devices = this.validDevices) {
      this.bulkDevices = ''
      this.bulkSpinner = true

      for (const d of devices) {
        const device = {
          model: d.model,
          serial: d.serial,
          subscription: subscriptionTypes.DATA_SERVICES,
        }
        await this.processRegistration(device)
      }
      this.bulkSpinner = false
      this.dialogs.apiBulkRegistrationInfo.open = true
      this.$emit('getUserDevices')
    },
    async processRegistration(device) {
        // Device is already registered and associated with a website or free tier subscription,
        // patch data services onto it
        if (
          this.deviceInWebsite(device.serial) ||
          this.deviceInFreeTier(device.serial)
        ) {
          await this.patchSubscription(device, true)
          if (this.addToBothWebsiteAndDataServices) {
            const msg = 'Device already registered to your account'
            this.logResult({
              serial: device.serial,
              status: null,
              msg: msg,
              subType: subscriptionTypes.WEBSITE,
            })
          }
        } else {
          // register device with data service subscription first
          const status = await this.registerDevice(device)
          if (this.addToBothWebsiteAndDataServices) {
            if (status === HTTPStatus.Created) {
              // add website subscription
              device.subscription = this.getSubscriptionByModel(device.model)
              await this.patchSubscription(device, false)
            } else {
              const msg = NO_ATTEMPT_MSG
              this.logResult({
                serial: device.serial,
                status: null,
                msg: msg,
                subType: subscriptionTypes.WEBSITE,
              })
            }
          }
        }

    },
    getSubscriptionByModel(model) {
      return this.subscriptionTiers[model] || null
    },
    deviceOnSub(serial, subType) {
      let deviceOnSub = false
      if (subType === subscriptionTypes.WEBSITE) {
        if (
          this.deviceInWebsite(serial) ||
          this.deviceInFreeTier(serial) ||
          this.deviceInBasic(serial) ||
          this.deviceInPremium(serial)
        ) {
          deviceOnSub = true
        }
      }
      if (subType === subscriptionTypes.DATA_SERVICES) {
        if (this.deviceInDataServices(serial)) {
          deviceOnSub = true
        }
      }
      return deviceOnSub
    },
    hasSubscription(model, subType) {
      let hasSub = false
      if (subType === subscriptionTypes.WEBSITE) {
        const basicSubIDs = this.$store.getters['subscriptions/getBasic'].map(
          (sub) => sub.id
        )
        const premiumSubIDs = this.$store.getters[
          'subscriptions/getPremium'
        ].map((sub) => sub.id)
        if (
          basicSubIDs.find((sub) => sub.includes(model)) ||
          premiumSubIDs.find((sub) => sub.includes(model))
        ) {
          hasSub = true
        }
      }
      if (subType === subscriptionTypes.DATA_SERVICES) {
        const subIDs = this.$store.getters['subscriptions/getDataServices'].map(
          (sub) => sub.id
        )
        if (subIDs.find((sub) => sub.includes(model))) {
          hasSub = true
        }
      }
      return hasSub
    },
    setResultValues(opts) {
      const { serial, status, msg, subType } = opts
      let success = null
      let message = msg

      if (status === HTTPStatus.OK || status === HTTPStatus.Created) {
        success = true
        message = this.$t(
          'bulkRegistrationInfoDialog.messages.registrationSuccessful'
        )
      } else if (
        status === HTTPStatus.InternalServerError ||
        status === HTTPStatus.NotAcceptable ||
        status === HTTPStatus.UnprocessableEntity ||
        msg.includes('error activating subscription')
      ) {
        success = false
        message = this.$t(
          'bulkRegistrationInfoDialog.messages.registrationFailed'
        )
      } else if (status === HTTPStatus.NotFound) {
        success = false
        message = this.$t(
          'bulkRegistrationInfoDialog.messages.serialNumberIncorrect'
        )
      } else if (
        msg.includes('Device already registered to your account') ||
        (msg.includes('EntityDuplicateError') &&
        subType === subscriptionTypes.DATA_SERVICES)
      ) {
        success = true
        message = this.$t(
          'bulkRegistrationInfoDialog.messages.alreadyRegistered'
        )
      } else if (msg.includes('Device is already registered to another account')) {
          success = false
          message = this.$t(
            'bulkRegistrationInfoDialog.messages.existsAnotherAccount'
          )
      } else if (msg.includes('maximum number of registered devices reached')) {
        // response message is the same for max devices reached whether user has a subscription or not
        // check whether device subscription exists and change message accordingly
        success = false
        const model = serial.slice(0, 4)
        const hasSub = this.hasSubscription(model, subType)

        message = hasSub
          ? this.$t('bulkRegistrationInfoDialog.messages.maxDevices')
          : this.$t('bulkRegistrationInfoDialog.messages.noSubscription')
      } else if (msg.includes(NO_ATTEMPT_MSG)) {
        message = this.$t('bulkRegistrationInfoDialog.messages.noAttempt')
      }

      return {
        success,
        message,
      }
    },
    logResult(opts) {
      const { serial, status, subType } = opts
      // check whether device already exists in results
      const found = this.results.find((result) => result.serial === serial)
      // device not found, add with default state
      if (!found) {
        this.results.push({
          serial: serial,
          [subscriptionTypes.DATA_SERVICES]: {
            success: null,
            allowRetry: false,
            status: null,
            msg: '',
          },
          [subscriptionTypes.WEBSITE]: {
            success: null,
            allowRetry: false,
            status: null,
            msg: '',
          },
        })
      }
      // get index of device in results
      const index = this.results.findIndex((result) => result.serial === serial)
      const values = this.setResultValues(opts)
      this.results[index][subType].success = values.success
      this.results[index][subType].msg = values.message
      this.results[index][subType].status = status
      // only status 500 should allow retry
      if (status === HTTPStatus.InternalServerError) {
        this.results[index][subType].allowRetry = true
      } else {
        this.results[index][subType].allowRetry = false
      }
    },
    deviceInDataServices(serial) {
      return this.$store.getters['subscriptions/hasDataServicesSubscription'](
        serial
      )
    },
    deviceInWebsite(serial) {
      return this.$store.getters['subscriptions/hasWebsiteSubscription'](serial)
    },
    deviceInFreeTier(serial) {
      return this.$store.getters['subscriptions/hasFreeTierSubscription'](
        serial
      )
    },
    deviceInBasic(serial) {
      //if there are devices in basic
      if (this.$store.getters['subscriptions/getDeviceBasic']) {
        //then check if this device is in there
        if (
          this.$store.getters[
            'subscriptions/getDeviceBasicSubscriptionByDeviceSerial'
          ](serial)
        ) {
          return true
        }
      }
      return false
    },
    deviceInPremium(serial) {
      //if there are devices in premium
      if (this.$store.getters['subscriptions/getDevicePremium']) {
        //then check if this device is in there
        if (
          this.$store.getters[
            'subscriptions/getDevicePremiumSubscriptionByDeviceSerial'
          ](serial)
        ) {
          return true
        }
      }
      return false
    },
    async bulkRegisterExternalDevices() {
      const devsListString = this.bulkDevices.replace(/\s/g, '')

      const devsList = devsListString.split(',')
      const models = []
      this.validDevices = []
      let unsubscribedDeviceExists = false

      for (let dev of devsList) {
        const model = dev.slice(0, 4)
        const serialLength =
          this.$store.getters['devicemodels/getSerialLength'](model)
        if (serialIsValid(model, dev, serialLength)) {
          if (!models.includes(model)) {
            models.push(model)
          }
          unsubscribedDeviceExists ||=
            !this.deviceInWebsite(dev) && !this.deviceInFreeTier(dev)
          this.validDevices.push({
            model: model,
            serial: dev,
          })
        }
      }

      if (this.validDevices.length) {
        //Before Data Service registration,
        //check if has available Website subscriptions
        //and if so pop up the AccessAdditionalFeaturesDialog

        const subscriptionsAvailable =
          models
            .map((model) =>
              this.$store.getters['subscriptions/canAddDeviceToBasicByModel'](
                model
              )
            )
            .some((result) => result === true) ||
          models
            .map((model) =>
              this.$store.getters['subscriptions/canAddDeviceToPremiumByModel'](
                model
              )
            )
            .some((result) => result === true)
        if (subscriptionsAvailable && unsubscribedDeviceExists) {
          this.dialogs.accessAdditionalFeatures.open = true
        } else {
          //otherwise just process
          this.addToBothWebsiteAndDataServices = false
          return this.processBulkRegistration()
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.cancel-btn {
  margin-right: 12px;
}

.v-btn {
  min-width: 120px !important;

  @media (min-width: 496px) {
    min-width: 160px !important;
  }
}
</style>

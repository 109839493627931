<template>
  <base-dialog
    :open="open"
    @close="close"
    data-cy="devicesUpdatedDialog"
    :fullscreen="$vuetify.breakpoint.xs"
    :persistentOverlay="true"
  >
    <template v-slot:header>
      <v-card-title data-cy="devicesUpdatedHeader">
        {{ $t('devicesUpdatedDialog.newPmSensor.title') }}
      </v-card-title>
    </template>
    <fusion-scroll>
      <div v-if="devicesHavingTsiPmSensor.length > 0">
        <v-card-text>
          {{ $t('devicesUpdatedDialog.newPmSensor.tsiSensorMessage') }}
          <ul id="devicesHavingTsiPmSensor" data-cy="devicesHavingTsiPmSensor">
            <li v-for="device in devicesHavingTsiPmSensor" :key="device.serial">
              {{ device.modelName }} "{{ device.name }}" ({{ device.serial }})
            </li>
          </ul>
        </v-card-text>
      </div>
      <div v-if="devicesHavingNonTsiPmSensor.length > 0">
        <v-card-text>
          {{ $t('devicesUpdatedDialog.newPmSensor.nonTsiSensorMessage') }}
          <ul
            id="devicesHavingNonTsiPmSensor"
            data-cy="devicesHavingNonTsiPmSensor"
          >
            <li
              v-for="device in devicesHavingNonTsiPmSensor"
              :key="device.serial"
            >
              {{ device.modelName }} "{{ device.name }}" ({{ device.serial }})
            </li>
          </ul>
        </v-card-text>
      </div>
    </fusion-scroll>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="close"
        class="primary white--text"
        text
        data-cy="devicesUpdatedClose"
        >{{ $t('buttons.done') }}
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import dialog from './Dialog'

const updateEnums = {
  hasTsiPmSensor: 0,
  hasNonTsiPmSensor: 1,
}

export default {
  name: 'DevicesUpdatedDialog',
  props: ['open', 'devices'],
  components: { 'base-dialog': dialog },
  data() {
    return {}
  },
  computed: {
    devicesHavingTsiPmSensor() {
      return this.devices
        .map((d) => ({
          ...d,
          modelName: this.$store.getters['devicemodels/getModelName'](d.model),
        }))
        .filter((d) => d.update === updateEnums.hasTsiPmSensor)
    },
    devicesHavingNonTsiPmSensor() {
      return this.devices
        .map((d) => ({
          ...d,
          modelName: this.$store.getters['devicemodels/getModelName'](d.model),
        }))
        .filter((d) => d.update === updateEnums.hasNonTsiPmSensor)
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    padding: 16px;
  }
  .v-card__title {
    font-weight: 300;
    padding: 8px 24px 16px 16px;
  }
  .v-card__subtitle {
    padding: 8px 24px 16px 16px;
  }
  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }
  .v-card__actions {
    margin-top: 24px;
  }
  ul {
    margin-top: 12px;
  }
}
</style>

<template>
  <base-dialog
    :open="open"
    :title="dialogTitle"
    data-cy="addWidgetsDialog"
    :fullscreen="$vuetify.breakpoint.xs"
    :persistentOverlay="true"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind="on"></slot>
    </template>
    <template v-slot:header>
      <v-card-title>
        <v-icon class="header-icon append-plus">mdi-view-dashboard</v-icon>
        {{ dialogTitle }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('dashboard.addWidgetsDialog.subtitle') }}
      </v-card-subtitle>
    </template>
    <v-card-text>
      <v-row no-gutters v-if="!editing">
        <v-col cols="12">
          <v-select
            v-model="selectedWidget"
            :items="widgetOptions"
            :rules="[widgetsRemainingValid]"
            :label="$t('dashboard.addWidgetsDialog.widgetType')"
            @change="getWidgetsRemaining()"
            data-cy="widgetTypeDropdown"
          ></v-select>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-autocomplete
            v-if="allowMultipleDevices"
            v-model="selectedDeviceId"
            multiple
            clearable
            :items="selectOptions"
            :label="$t('dashboard.addWidgetsDialog.device.label')"
            :no-data-text="$t('dashboard.addWidgetsDialog.device.noDataText')"
            :hint="
              $t('dashboard.addWidgetsDialog.device.hintMultipleDevices', {
                qty: maxDeviceSelect,
              })
            "
            persistent-hint
            :disabled="disabledDeviceSelect"
            @focus="setDropdownWidth"
            data-cy="widgetDeviceDropdown"
          >
            <template v-slot:selection="{ item, index }">
              <div
                v-if="selectedDeviceIds.length === 1"
                class="device-selections"
              >
                {{ item.text }}
              </div>
              <div
                v-if="selectedDeviceIds.length > 1 && index === 0"
                class="device-selections"
              >
                {{
                  $t('dashboard.addWidgetsDialog.device.qtyDevicesSelected', {
                    qty: selectedDeviceIds.length,
                  })
                }}
              </div>
            </template>
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item
                v-on="on"
                v-bind="attrs"
                :disabled="disableDeviceSelectItem(item)"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :ripple="false"
                      :input-value="active"
                      :disabled="disableDeviceSelectItem(item)"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content class="select-item-content">
                    <v-list-item-title class="select-item__title">
                      <span>{{ item.title }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.subtitle }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-else
            v-model="selectedDeviceId"
            :items="selectOptions"
            :label="$t('dashboard.addWidgetsDialog.device.label')"
            :no-data-text="$t('dashboard.addWidgetsDialog.device.noDataText')"
            :disabled="disabledDeviceSelect"
            @focus="setDropdownWidth"
            data-cy="widgetDeviceDropdown"
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content class="select-item-content">
                  <v-list-item-title class="select-item__title">
                    <span>{{ item.title }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.subtitle }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <template v-slot:actions>
      <v-btn
        @click="close"
        text
        outlined
        class="black--text"
        data-cy="addWidgetsCancelButton"
        >{{ $t('buttons.cancel') }}</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        @click="save"
        :disabled="saveDisabled"
        class="primary white--text"
        text
        data-cy="addWidgetsDoneButton"
        >{{ $t('buttons.done') }}</v-btn
      >
    </template>
  </base-dialog>
</template>

<script>
import { deviceModelDisplay, deviceSubmodelDisplay } from '../../services/map'
import { getPermissionsByDeviceId } from '../../services/subscriptions'
import { SlugsEnum } from '../../permissions/SlugsEnum'
import { featureFlags } from '../../services/feature-flags'
import dialog from './Dialog'
import { waitForElement } from '../../services/html-utilities'
import { getUserAccountId } from '../../helpers/loginas/logInAsHelper'

const MAX_WIDGETS_PER_TYPE = 5
const MAX_WIDGETS_BY_TYPE = {
  MapWidget: 30,
  HistoryWidget: 10,
  PopoverWidget: 30,
}

export default {
  name: 'AddWidgetsDialog',
  props: {
    deviceIds: Array,
    widgetId: String,
    open: Boolean,
    widgets: Array,
    widgetType: String,
    widgetMaxDevices: Object,
    editing: { type: Boolean, default: false },
  },
  components: { 'base-dialog': dialog },
  data() {
    return {
      accountId: null,
      selectedDeviceId: null,
      selectedWidget: null,
      widgetsRemaining: MAX_WIDGETS_PER_TYPE,
      widgetOptions: [
        {
          text: this.$t('dashboard.addWidgetsDialog.widgetTypes.map'),
          value: 'MapWidget',
        },
        {
          text: this.$t('dashboard.addWidgetsDialog.widgetTypes.history'),
          value: 'HistoryWidget',
        },
        {
          text: this.$t('dashboard.addWidgetsDialog.widgetTypes.popover'),
          value: 'PopoverWidget',
        },
      ],
      devices: [],
    }
  },
  computed: {
    selectOptions() {
      return this.devices
        .filter((device) => {
          return this.allowDeviceWidget(device?.deviceId)
        })
        .map((device) => {
          const { deviceId, name, model, submodel } = device
          const modelKey =
            this.$store.getters['devicemodels/getModelKey'](model)
          const modelName = deviceModelDisplay(modelKey, this.$t.bind(this))
          const submodelKey = this.$store.getters[
            'devicemodels/getSubmodelKey'
          ](model, submodel)
          const submodelName = deviceSubmodelDisplay(
            submodelKey,
            this.$t.bind(this)
          )
          const subtitle = submodel
            ? `${modelName} (${submodelName})`
            : modelName
          return {
            text: `${name} | ${subtitle}`,
            title: name,
            subtitle: subtitle,
            value: deviceId,
          }
        })
    },
    maxDeviceSelect() {
      return this.widgetMaxDevices?.[this.selectedWidget] || 0
    },
    allowMultipleDevices() {
      return this.maxDeviceSelect > 1
    },
    disabledDeviceSelect() {
      return !this.selectedWidget ? true : false
    },
    saveDisabled() {
      if (this.editing) {
        return this.selectedDeviceIds.length === 0
      }
      return (
        this.selectedDeviceIds.length === 0 ||
        this.selectedWidget === null ||
        this.widgetsRemaining <= 0
      )
    },
    selectedDeviceIds() {
      if (Array.isArray(this.selectedDeviceId)) {
        const deviceIds = [...this.selectedDeviceId]
        // Default sort is by order of selection
        // Sort by Devices order (alphabetical)
        const order = this.devices.map((device) => device.deviceId)
        deviceIds.sort((a, b) => {
          return order.indexOf(a) - order.indexOf(b)
        })
        // Splice off anything over the max devices allowed as a final gate
        deviceIds.splice(this.maxDeviceSelect, this.devices.length)
        return deviceIds
      } else if (this.selectedDeviceId !== null) {
        return [this.selectedDeviceId]
      }
      return []
    },
    newWidget() {
      if (this.selectedWidget !== null && this.selectedDeviceId !== null) {
        return {
          widgetComponent: this.selectedWidget,
          deviceIds: this.selectedDeviceIds,
        }
      }
      return {}
    },
    configuredWidget() {
      if (this.widgetId !== null && this.selectedDeviceId !== null) {
        return {
          widgetId: this.widgetId,
          deviceIds: this.selectedDeviceIds,
          previousDeviceIds: this.deviceIds,
        }
      }
      return {}
    },
    dialogTitle() {
      return this.editing
        ? this.$t('dashboard.addWidgetsDialog.titleEditing')
        : this.$t('dashboard.addWidgetsDialog.title')
    },
    widgetsRemainingValid() {
      return (
        this.widgetsRemaining > 0 ||
        this.$t('dashboard.addWidgetsDialog.maxWidgetType')
      )
    },
  },
  watch: {
    async open() {
      if (this.open) {
        this.widgetsRemaining = MAX_WIDGETS_PER_TYPE
        this.getDevices()
        if (this.editing) {
          this.selectedWidget = this.widgetType
          await Vue.nextTick()
          this.selectedDeviceId = this.getSelectedDeviceId()
        }
      }
    },
    selectedWidget(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedDeviceId = null
      }
    },
  },
  methods: {
    close() {
      this.$emit('close')
      this.selectedDeviceId = null
      this.selectedWidget = null
    },
    save() {
      if (this.devices.length > 0) {
        if (this.editing) {
          const widget = JSON.parse(JSON.stringify(this.configuredWidget))
          this.$emit('widgetUpdated', widget)
        } else {
          const widget = JSON.parse(JSON.stringify(this.newWidget))
          this.$emit('widgetAdded', widget)
        }
      }
      this.close()
    },
    allowDeviceWidget(deviceId) {
      const deviceSlugPermissions = getPermissionsByDeviceId(deviceId)
      const deviceHasSlugPermissions = deviceSlugPermissions.includes(
        SlugsEnum.DashboardCreateWidget
      )
      return (
        deviceHasSlugPermissions || this.allowWidgetForConnectedDevice(deviceId)
      )
    },
    allowWidgetForConnectedDevice(deviceId) {
      const isAConnectedDevice =
        !!this.$store.getters[
          'devices/getConnectedSmartBridgeSerialByDeviceId'
        ](deviceId)

      if (isAConnectedDevice && typeof this.selectedWidget === 'string') {
        switch (this.selectedWidget) {
          case 'PopoverWidget':
            return this.$store.getters['featureFlags/getFeatureFlagBySlug'](
              featureFlags.EnabledCurrentReadingsForConnectedDevices
            )
          case 'HistoryWidget':
            return this.$store.getters['featureFlags/getFeatureFlagBySlug'](
              featureFlags.EnabledHistoryChartForConnectedDevices
            )
          default:
            return false
        }
      }

      return false
    },
    getDevices() {
      let devices = []
      const deviceNames = this.$store.state.devices.name
      for (const key in deviceNames) {
        if (this.isDeviceSharedOrOwned(key)) {
          const device = {
            deviceId: key,
            name: this.$store.getters['devices/getNameByDeviceId'](key),
            model: this.$store.getters['devices/getModelByDeviceId'](key),
            submodel: this.$store.getters['devices/getSubmodelByDeviceId'](key),
          }
          devices.push(device)
        }
      }
      devices.sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })
      this.devices = devices
    },
    getWidgetsRemaining() {
      let widgetsRemaining = MAX_WIDGETS_PER_TYPE

      let limitIncreased = false
      switch (this.selectedWidget) {
        case 'PopoverWidget':
          limitIncreased = this.$store.getters[
            'featureFlags/getFeatureFlagBySlug'
          ](featureFlags.CurrentReadingsWidgetLimitIncreaseEnabled)
          break
        case 'MapWidget':
          limitIncreased = this.$store.getters[
            'featureFlags/getFeatureFlagBySlug'
          ](featureFlags.MapWidgetLimitIncreaseEnabled)
          break
        case 'HistoryWidget':
          limitIncreased = this.$store.getters[
            'featureFlags/getFeatureFlagBySlug'
          ](featureFlags.HistoryWidgetLimitIncreaseEnabled)
          break
      }

      if (limitIncreased) {
        widgetsRemaining = MAX_WIDGETS_BY_TYPE[this.selectedWidget]
      }

      this.widgets.forEach((widget) => {
        if (widget.widgetComponent == this.selectedWidget) {
          widgetsRemaining--
        }
      })
      this.widgetsRemaining = widgetsRemaining
    },
    getSelectedDeviceId() {
      if (this.allowMultipleDevices) {
        const deviceIds = [...this.deviceIds]
        deviceIds.forEach((deviceId, index) => {
          const isFound = this.devices.find(
            (device) => device.deviceId === deviceId
          )
          if (!isFound) {
            deviceIds.splice(index, 1)
          }
        })
        return deviceIds
      } else {
        let deviceId = this.deviceIds?.[0]
        const isFound = this.devices.find(
          (device) => device.deviceId === deviceId
        )
        return isFound ? deviceId : null
      }
    },
    disableDeviceSelectItem(item) {
      if (
        this.allowMultipleDevices &&
        Array.isArray(this.selectedDeviceId) &&
        this.selectedDeviceId.length === this.maxDeviceSelect &&
        !this.selectedDeviceId.includes(item.value)
      ) {
        return true
      }
      return false
    },
    async setDropdownWidth(event) {
      const width = event.target.parentElement.offsetWidth
      const id = event.target.id.split('-')[1]
      const listId = `#list-${id}`
      const listEl = await waitForElement(listId)
      if (listEl instanceof Element) {
        listEl.parentElement.style.width = `${width}px`
      }
    },
    isDeviceSharedOrOwned(deviceId) {
      const isOwned =
        this.accountId ===
        this.$store.getters['devices/getAccountByDeviceId'](deviceId)
      const isShared =
        this.$store.getters['devices/getIsSharedByDeviceId'](deviceId)
      return isOwned || isShared
    },
  },
  async mounted() {
    this.accountId = await getUserAccountId(this.$auth)
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    padding: 16px;
  }
  .v-card__title {
    font-weight: 300;
    padding: 8px 24px 16px 16px;
  }
  .v-card__subtitle {
    padding: 8px 24px 16px 16px;
  }
  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }
  .v-card__actions {
    margin-top: 24px;
  }
}
.append-plus {
  margin-right: 0.25em;

  &::after {
    content: '+';
    width: 0.5em;
    height: auto;
    left: 100%;
    top: 10%;
    z-index: 1;
    background-color: transparent;
    opacity: 1;
    font-size: 0.5em;
    font-style: normal;
    font-weight: 700;
  }
}
.device-selections {
  flex-basis: 100%;
  max-height: 32px;
  padding: 8px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-item__title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

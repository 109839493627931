import { getPermissionsInstance } from '@fusion/auth'
import { deviceModels } from './utils'
import { SlugsEnum } from '../../permissions/SlugsEnum'

function qtyAllowed() {
  const isAllowed = getPermissionsInstance().isAllowed(SlugsEnum.DeviceAddMoreThan1)
  const isAllowedAddUpTo1 = getPermissionsInstance().isAllowed(SlugsEnum.DeviceAddUpTo1)

  if(isAllowed) {
    return Number.MAX_SAFE_INTEGER
  } else if (isAllowedAddUpTo1) {
    return 1
  } else {
    return 0
  }
}

function canAddMoreDevicesToWebsite(state) {
  const allowed = qtyAllowed()
  const used = totalWebsiteQtyUsed(state)
  return used < allowed
}

function freeTierQtyRemaining(state) {
  return state.freeTier.length ? state.freeTier[0].totalQtyRemaining : 0
}
function freeTierQtyUsed(state) {
  return state.freeTier.length ? state.freeTier[0].totalUsed : 0
}

function websiteQtyRemainingByModel(state, model) {
  if (model === deviceModels.BLUESKY) {
    return state.websiteQtyRemainingBlueSky
  } else if (model === deviceModels.BLUESKY_GAS) {
    return state.websiteQtyRemainingBlueSkyGas
  } else if (model === deviceModels.AIRASSURE) {
    return state.websiteQtyRemainingAirAssure
  }
  return 0
}

function totalWebsiteQtyUsed(state) {
  return  state.websiteQtyUsedAirAssure +
          state.websiteQtyUsedBlueSky +
          state.websiteQtyUsedBlueSkyGas +
          freeTierQtyUsed(state)
}

function totalWebsiteQtyRemaining(state) {
  return  state.websiteQtyRemainingAirAssure +
          state.websiteQtyRemainingBlueSky +
          state.websiteQtyRemainingBlueSkyGas +
          freeTierQtyRemaining(state)
}

export default {
  getDefault(state) {
    return state.default
  },
  getFreeTier(state) {
    return state.freeTier
  },
  getGenericTSILink(state) {
    return state.genericTSILink
  },
  getWebsite(state) {
    return state.website
  },
  getBasic(state) {
    return state.basic
  },
  getPremium(state) {
    return state.premium
  },
  getDataServices(state) {
    return state.dataServices
  },
  getDeviceFreeTier(state) {
    return state.deviceFreeTier
  },
  getDeviceWebsite(state) {
    return state.deviceWebsite
  },
  getDeviceBasic(state) {
    return state.deviceBasic
  },
  getDevicePremium(state) {
    return state.devicePremium
  },
  getDeviceDataServices(state) {
    return state.deviceDataServices
  },
  getDeviceFreeTierByDeviceSerial: (state) => (serial) => {
    return state.deviceFreeTier ? state.deviceFreeTier[serial] : null
  },
  getDeviceSubscriptionByDeviceSerial: (state) => (serial) => {
    return state.deviceWebsite ? state.deviceWebsite[serial] : null
  },
  getDeviceBasicSubscriptionByDeviceSerial: (state) => (serial) => {
    return state.deviceBasic ? state.deviceBasic[serial] : null
  },
  getDevicePremiumSubscriptionByDeviceSerial: (state) => (serial) => {
    return state.devicePremium ? state.devicePremium[serial] : null
  },
  getDeviceDataServicesByDeviceSerial: (state) => (serial) => {
    return state.deviceDataServices ? state.deviceDataServices[serial] : null
  },
  getDeviceFreeTierSubscriptionByDeviceSerial: (state) => (serial) => {
    return state.deviceFreeTier ? state.deviceFreeTier[serial] : null
  },
  getSubscriptionNameById: (state) => (id) => {
    return state.subscriptionNames ? state.subscriptionNames[id] : null
  },
  getSubscriptionById: (state) => (id) => {
    return state.subscriptions.find((subscription) => {
      return subscription.id === id
    })
  },
  getQtyRemaining: (state) => (model) => {
    if(!canAddMoreDevicesToWebsite(state)) {
      return 0
    }

    let qtyRemaining = 0
    if (state.website.length !== 0) {
      qtyRemaining = websiteQtyRemainingByModel(state, model)
    } else if(state.freeTier.length) {
      qtyRemaining = freeTierQtyRemaining(state)
    }
    return Math.min(qtyAllowed(), qtyRemaining)
  },
  hasQtyRemaining(state) {
    return canAddMoreDevicesToWebsite(state) && totalWebsiteQtyRemaining(state) > 0
  },
  hasDataServicesSubscription: (state) => (serial) => {
    return !!(
      state.deviceDataServices &&
      state.deviceDataServices[serial]
    )
  },
  hasWebsiteSubscription: (state) => (serial) => {
    return !!(
      state.deviceWebsite &&
      state.deviceWebsite[serial]
    )
  },
  hasFreeTierSubscription: (state) => (serial) => {
    return !!(
      state.deviceFreeTier &&
      state.deviceFreeTier[serial]
    )
  },
  canAddDeviceToWebsiteByModel: (state) => (model) => {
    const isAllowed = getPermissionsInstance().isAllowed(SlugsEnum.DeviceAddMoreThan1)

    //if allowed then check the qty remaining for any model
    if (isAllowed) {
      if (model === deviceModels.BLUESKY) {
        return state.websiteQtyRemainingBlueSky > 0
      } else if (model === deviceModels.BLUESKY_GAS) {
        return state.websiteQtyRemainingBlueSkyGas > 0
      } else if (model === deviceModels.AIRASSURE) {
        return state.websiteQtyRemainingAirAssure > 0
      }
    }

    // No subscription found at all, default to false
    return false
  },
  canAddDeviceToBasicByModel: (state) => (model) => {
    const isAllowed = getPermissionsInstance().isAllowed(SlugsEnum.DeviceAddMoreThan1)

    //if allowed then check the qty remaining for any model
    if (isAllowed) {
      if (model === deviceModels.BLUESKY) {
        return state.basicQtyRemainingBlueSky > 0
      } else if (model === deviceModels.AIRASSURE) {
        return state.basicQtyRemainingAirAssure > 0
      }
    }

    // No subscription found at all, default to false
    return false
  },
  canAddDeviceToPremiumByModel: (state) => (model) => {
    const isAllowed = getPermissionsInstance().isAllowed(SlugsEnum.DeviceAddMoreThan1)

    //if allowed then check the qty remaining for any model
    if (isAllowed) {
      if (model === deviceModels.BLUESKY) {
        return state.premiumQtyRemainingBlueSky > 0
      } else if (model === deviceModels.BLUESKY_GAS) {
        return state.premiumQtyRemainingBlueSkyGas > 0
      } else if (model === deviceModels.AIRASSURE) {
        return state.premiumQtyRemainingAirAssure > 0
      }
    }

    // No subscription found at all, default to false
    return false
  },
  getPermissionsByRole: (state) => (role) => {
    return state.permissionsInRole[role]
  },
}

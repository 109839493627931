<template>
  <div :class="`content-wrapper ${hover ? 'hovered' : ''}`">
    <div class="sub-wrapper" :class="cssStyle">
      <div class="content" @mouseover="hover = true" @mouseleave="hover = false" @click="onClickHandler">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DialogContent',
  props: {
    cssStyle: String
  },
  data() {
    return {
      hover: false
    }
  },
  methods: {
    onClickHandler() {
      this.hover = true
      setTimeout(() => {
        this.hover = false
      }, 500)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/global-variables.scss';

.hovered {
  .first-style {
    label,
    div,
    button {
      color: $white-color !important;
      text-decoration: none !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/global-variables.scss';

@mixin content-style($bgColor, $borderStyle) {
  border: $borderStyle;

  .content {
    background-color: $bgColor;
  }
}

.content-wrapper {
  padding: 1rem 2rem 3rem;

  @media (max-width: 599px) {
    padding: 1rem 0 2rem;

    .sub-wrapper {
      height: 35vh !important;
    }
  }

  @media (max-height: 469px) {
    padding: 0.5rem;

    .sub-wrapper {
      height: 25vh !important;
    }
  }

  @media (min-height: 1000px) {
    .sub-wrapper {
      height: 18rem !important;
    }
  }

  .first-style {
    @include content-style($light-blue-color, dotted 4px $deep-sky-blue-color);
  }

  .second-style {
    @include content-style($light-blue-color, solid 2px $deep-sky-blue-color);
  }

  .third-style {
    @include content-style($light-red-color, solid 2px $red-color);
  }

  .fourth-style {
    @include content-style($light-green-color, solid 2px $green-color);
  }

  .fifth-style {
    @include content-style($light-gray-color, solid 2px $dark-gray-color);
  }

  .sub-wrapper {
    height: 30vh;
    border-radius: 3px;

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: $dark-gray-color;
        border-radius: 10px;
      }

      >* {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.content-wrapper.hovered {
  .first-style {
    border-style: solid;

    .content {
      background-color: $deep-sky-blue-color;
    }
  }

}
</style>

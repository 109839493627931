<template>
  <base-dialog
    @close="close"
    :open="open"
    :title="$t('map.mapSettings.title')"
    icon="mdi-settings"
    :max-width="320"
    :persistent="true"
    :content-class="$vuetify.breakpoint.xs ? '' : 'map-settings'"
    data-cy="mapSettingsHeader"
  >
    <div class="card-content" data-cy="mapSettingsDialog">
      <!-- empty element is bandaid for blur bug on first element in dialog -->
      <v-switch style="display: none"></v-switch>
      <v-select
        v-model="measurementType"
        :items="measurementTypeItems"
        item-text="text"
        item-value="value"
        :label="$t('map.mapSettings.measurementType')"
        @change="changeMeasurementType"
        data-cy="mapSettingsMeasurementTypeSelect"
      ></v-select>
      <v-select
        class="mb-4"
        :value="selectedDeviceModels"
        :items="deviceModelItems"
        item-text="text"
        item-value="value"
        multiple
        :label="$t('map.mapSettings.deviceModels.label')"
        :placeholder="$t('map.mapSettings.deviceModels.placeholder')"
        :hint="$t('map.mapSettings.deviceModels.hint')"
        persistent-hint
        @change="changeDeviceModels"
        data-cy="mapSettingsDeviceSelect"
      ></v-select>
      <v-switch
        color="primary"
        :label="$t('map.mapSettings.showMine')"
        v-model="toggles.showMine"
        @change="changeMine"
        data-cy="mapSettingsShowMySensors"
      ></v-switch>
      <v-switch
        color="primary"
        :label="$t('map.mapSettings.showShared')"
        v-model="toggles.showShared"
        @change="changeShared"
        data-cy="mapSettingsShowSharedSensors"
      ></v-switch>
      <v-switch
        color="primary"
        :label="$t('map.mapSettings.showPublic')"
        v-model="toggles.showPublic"
        @change="changePublic"
        data-cy="mapSettingsShowPublicSensors"
      ></v-switch>
      <div class="secondary-indicator">
        <v-switch
          color="primary"
          :label="$t('map.mapSettings.showOutdoor')"
          v-model="toggles.showOutdoor"
          @change="changeOutdoor"
          data-cy="mapSettingsShowOutdoor"
        ></v-switch>
        <div class="device-type-indicator">
          <img src="img/map/pin-blank-outdoor.png" alt="Outdoor Device Pin" />
        </div>
      </div>
      <div class="secondary-indicator">
        <v-switch
          color="primary"
          :label="$t('map.mapSettings.showIndoor')"
          v-model="toggles.showIndoor"
          @change="changeIndoor"
          data-cy="mapSettingsShowIndoor"
        ></v-switch>
        <div class="device-type-indicator">
          <img
            src="img/map/pin-blank-indoor.png"
            class="indoor-type"
            alt="Indoor Device Pin"
          />
        </div>
      </div>
    </div>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="close"
        class="primary white--text"
        text
        data-cy="mapSettingsClose"
      >
        {{ $t('buttons.done') }}
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import {
  measurementTypes,
  defaultMapSettings,
} from '../../store/map/utils'
import { featureFlags } from '../../services/feature-flags'
import dialog from './Dialog.vue'

export default {
  name: 'mapSettingsDialog',
  props: ['open'],
  components: {
    'base-dialog': dialog,
  },
  data() {
    return {
      isOpen: this.open,
      measurementType: defaultMapSettings.pinMeasurementType,
      measurementTypeItems: [
        {
          text: this.$t('map.mapSettings.measurementOptions.aqiPm25'),
          value: measurementTypes.pm25,
        },
        {
          text: this.$t('map.mapSettings.measurementOptions.aqiPm10'),
          value: measurementTypes.pm10,
        },
      ],
      toggles: {
        showMine: defaultMapSettings.showMine,
        showPublic: defaultMapSettings.showPublic,
        showShared: defaultMapSettings.showShared,
        showOutdoor: defaultMapSettings.showOutdoor,
        showIndoor: defaultMapSettings.showIndoor,
      },
    }
  },
  mounted() {
    this.measurementType = this.pinMeasurementType
    this.toggles.showMine = this.showMine
    this.toggles.showPublic = this.showPublic
    this.toggles.showShared = this.showShared
    this.toggles.showOutdoor = this.showOutdoor
    this.toggles.showIndoor = this.showIndoor
  },
  watch: {
    open() {
      this.isOpen = this.open
    },
  },
  computed: {
    showMine() {
      return this.$store.getters['map/getShowMine']
    },
    showPublic() {
      return this.$store.getters['map/getShowPublic']
    },
    showShared() {
      return this.$store.getters['map/getShowShared']
    },
    showOutdoor() {
      return this.$store.getters['map/getShowOutdoor']
    },
    showIndoor() {
      return this.$store.getters['map/getShowIndoor']
    },
    pinMeasurementType() {
      return this.$store.getters['map/getMeasurementType']
    },
    selectedDeviceModels() {
      return this.$store.getters['map/getDeviceModels']
    },
    enableBlueSkyGases() {
      return this.$store.getters['featureFlags/getFeatureFlagBySlug'](
        featureFlags.BlueSkyGasesEnabled
      )
    },
    deviceModelItems() {
      const blueSkyGasModelNumber = '8145'
      const models = this.$store.getters['devicemodels/getShowMapSettingsModels']
      const modelItems = []
      models.forEach((modelNumber) => {
        if (modelNumber !== blueSkyGasModelNumber || this.enableBlueSkyGases) {
          const modelKey =
            this.$store.getters['devicemodels/getModelKey'](modelNumber)
          modelItems.push({
            text: this.$t(`map.mapSettings.deviceModelNames.${modelKey}`),
            value: modelNumber,
          })
        }
      })
      return modelItems
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    changeMeasurementType() {
      this.$store.dispatch('map/updateSettings', {
        pinMeasurementType: this.measurementType,
      })
    },
    changeMine() {
      this.$store.dispatch('map/updateSettings', {
        showMine: this.toggles.showMine,
      })
    },
    changeShared() {
      this.$store.dispatch('map/updateSettings', {
        showShared: this.toggles.showShared,
      })
    },
    changePublic() {
      this.$store.dispatch('map/updateSettings', {
        showPublic: this.toggles.showPublic,
      })
    },
    changeOutdoor() {
      this.$store.dispatch('map/updateSettings', {
        showOutdoor: this.toggles.showOutdoor,
      })
    },
    changeIndoor() {
      this.$store.dispatch('map/updateSettings', {
        showIndoor: this.toggles.showIndoor,
      })
    },
    changeDeviceModels(deviceModels) {
      this.$store.dispatch('map/updateSettings', {
        deviceModels,
      })
    },
  },
}
</script>

<style lang="scss">
.map-settings {
  position: absolute;
  right: 10px;
  top: 70px;
}
</style>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    padding: 16px;
  }
  .v-card__title {
    font-weight: 300;
    padding: 4px 24px 24px 5px;
  }
  .v-card__subtitle {
    padding: 8px 16px;
  }
  .top-subtitle {
    padding: 20px 16px 4px 24px;
    font-size: 16px;
  }
  .secondary-indicator {
    display: flex;
    .device-type-indicator {
      align-self: center;
      margin: 0 0 8px 16px;
      img {
        height: 36px;
      }
    }
    .indoor-type {
      margin-left: 11px;
    }
  }
  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }
  .v-input--switch {
    margin-top: 8px;
  }
  .text-dark {
    color: black;
  }
}

.card-content {
  padding: 0.4rem;
}
</style>

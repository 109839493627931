export default {
  applicationID: process.env.VUE_APP_APPLICATION_ID,
  clientID: process.env.VUE_APP_CLIENT_ID,
  clientURL: process.env.VUE_APP_CLIENT_URL,
  accountsAPIHost: process.env.VUE_APP_ACCOUNTS_HOST,
  applicationsAPIHost: process.env.VUE_APP_APPLICATIONS_HOST,
  devicesAPIHost: process.env.VUE_APP_DEVICES_HOST_V3,
  studiesAPIHostV2: process.env.VUE_APP_STUDIES_HOST_V2,
  studiesAPIHostV3: process.env.VUE_APP_STUDIES_HOST_V3,
  createAccountLink: process.env.VUE_APP_CREATE_ACCOUNT_URL,
  learnMoreLink: process.env.VUE_APP_LEARN_MORE_URL,
}

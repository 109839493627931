<template>
  <v-card
    :class="{
      'ma-6': !this.$vuetify.breakpoint.xs,
      'manage-devices-card': true,
      'is-mobile': this.isMobile,
    }"
  >
    <v-card-subtitle>{{ $t('manageDevices.title') }}</v-card-subtitle>
    <v-container v-if="enableBulkDeviceRegistration" class="menu-options" fluid>
      <v-row>
        <v-col md="auto search-column">
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            data-cy="searchField"
          />
        </v-col>
        <v-col md="auto" class="fit-space">
          <dropdown-menu
            v-if="!isLoading"
            data-cy="addDeviceDropdown"
            v-bind="getAddDevicesDropDownProps()"
          />
        </v-col>
      </v-row>
    </v-container>
    <div v-else class="table-bar">
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        :style="isMobile ? '' : 'width: 200px'"
        class="search-field"
        data-cy="searchField"
      />
      <v-spacer />
      <span v-if="!isLoading" class="register">
        <v-btn @click.stop="openRegisterDevice" text>
          <v-icon class="op-76">mdi-map-marker-plus</v-icon>
          <span style="padding-left: 4px">
            {{ $t('manageDevices.addDevice') }}
          </span>
        </v-btn>
      </span>
    </div>
    <!-- add 'show-select' prop for checkboxes -->
    <v-data-table
      v-if="showTable"
      :headers="headers"
      :items="devices"
      :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
      :class="{ mobile: isMobile }"
      :header-props="headerProps"
      :custom-filter="filterByColumnValueAndDeviceAttributes"
      :search="customTableFilter"
      :options="tableOptions"
      :sort-by.sync="sortedBy"
      :sort-desc.sync="sortDesc"
      :itemsPerPage.sync="itemsPerPage"
      show-expand
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :footer-props="{
        'items-per-page-text': isMobile
          ? $t('tables.rows')
          : $t('tables.rowsPerPage'),
        itemsPerPageOptions: [10, 25, 50],
      }"
      data-cy="manageDevicesTable" @current-items="updateTableComputedItems">
      <template v-if="this.isMobile" v-slot:[`body.prepend`]="{}">
        <expand-collapse-buttons v-if="enableExpandCollapseButtons" @expandAll="expandAllAssociatedDevices"  @collapseAll="collapseAllAssociatedDevices" />
      </template>
      <!-- table item slot -->
      <template v-slot:item="{ item, expand, isExpanded }">
        <component :is="tableItemComponent"
          :item="item"
          :expand="expand"
          :isExpanded="isExpanded"
          @openDialog="openDialog"
        />
      </template>
      <!-- Show the expand button when a SmartBridge device has devices -->
      <template v-slot:expanded-item="{ item }">
        <component :is="tableItemComponent"
          v-for="i in item.associatedDevices"
          :item="i"
          :isChild="true"
          :key="i.id"
          @openDialog="openDialog"
        />
      </template>
      <template v-if="!this.isMobile" v-slot:[`footer.prepend`]="{}">
        <expand-collapse-buttons v-if="enableExpandCollapseButtons" @expandAll="expandAllAssociatedDevices"  @collapseAll="collapseAllAssociatedDevices" />
      </template>
    </v-data-table>
    <!-- empty message -->
    <div v-else-if="showEmptyMessage" class="text-center emptyMessage">
      {{ $t('manageDevices.emptyMessage') }}
    </div>
    <!-- progress spinner -->
    <div v-else class="text-center emptyMessage">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <register-device
      :open="dialogs.registerDevice"
      :deviceSerial="''"
      :deviceModel="''"
      @close="dialogs.registerDevice = false"
    />
    <register-multiple-devices
      :open="dialogs.registerMultipleDevices"
      @close="dialogs.registerMultipleDevices = false"
    />
    <action-menu-dialogs :dialog="currentDialog" :deviceId="currentDeviceId" @closeDialog="closeDialog" />
  </v-card>
</template>

<script>
import {
  accountExists,
  emailVerified,
  isAuthorized,
  routeHasPermission,
} from '@fusion/auth'
import { access } from '../services/device-access'
import { sortDeviceCondition } from '../services/device-table'
import { validateTimestamp } from '../services/device-status'
import { measurementTypes } from '../store/map/utils'
import { deviceModelDisplay, deviceSubmodelDisplay } from '../services/map'
import { DEVICE_UPDATE_INTERVAL_MS } from '../services/device-metadata'

import registerDevice from '../components/registerDevice/registerDialog.vue'
import registerMultipleDevices from '../components/registerDevice/multipleDevices/registerMultipleDevices.vue'
import tableBody from '../components/manageDevices/tableBody.vue'
import tableBodyMobile from '../components/manageDevices/tableBodyMobile.vue'
import dropDownMenuVue from '../components/menu/dropDownMenu.vue'
import actionMenuDialogs from '../components/manageDevices/actionMenuDialogs.vue'
import ExpandCollapseButtons from '../components/common/buttons/ExpandCollapseButtons.vue'

import { getMeasurementByType } from '../components/devicePopover/utils'
import { SlugsEnum } from '../permissions/SlugsEnum'
import { featureFlags } from '../services/feature-flags'
import {
  Measurement_Names,
  searchableDeviceAttributes,
  connectionTypes,
} from '../store/devices/utils'
import { getUserAccountId } from '../helpers/loginas/logInAsHelper'

const LOCAL_STORAGE_KEYS = 'tsi.devicesTableSettings'

export default {
  name: 'ManageDevices',
  mixins: [
    isAuthorized,
    routeHasPermission(SlugsEnum.SideBarDevicesExecute),
    accountExists,
    emailVerified,
  ],
  components: {
    'register-device': registerDevice,
    'register-multiple-devices': registerMultipleDevices,
    'device-table-body': tableBody,
    'device-table-body-mobile': tableBodyMobile,
    'dropdown-menu': dropDownMenuVue,
    'action-menu-dialogs': actionMenuDialogs,
    'expand-collapse-buttons': ExpandCollapseButtons,
  },
  async mounted() {
    this.accountId = await getUserAccountId(this.$auth)
    this.getDevicesTableSettings()
    await this.startUserDevicesInterval()
  },
  destroyed() {
    clearInterval(this.interval)
  },
  data() {
    return {
      accountId: null,
      expanded: [],
      singleExpand: false,
      sortedBy: ['deviceName'],
      sortDesc: [false],
      itemsPerPage: 10,
      firstSearch: true,
      search: '',
      tableOptions: {},
      access: access,
      headerProps: {
        sortByText: this.$t('tables.sortBy'),
      },
      dialogs: {
        registerDevice: false,
        registerMultipleDevices: false,
      },
      interval: null,
      isDevicesFirstCallInProgress: false,
      tableComputedItems: [],
      currentDialog: '',
      currentDeviceId: '',
    }
  },
  watch: {
    sortedBy: {
      deep: true,
      handler() {
        this.setDevicesTableSettings()
      },
    },
    sortDesc: {
      deep: true,
      handler() {
        this.setDevicesTableSettings()
      },
    },
    itemsPerPage: {
      handler() {
        this.setDevicesTableSettings()
      },
    },
    search() {
      if (this.firstSearch) {
        this.firstSearch = false
      }
      this.collapseAllAssociatedDevices()
    },
  },
  methods: {
    getMeasurementType(measurementType) {
      switch (measurementType) {
        case measurementTypes.pm25:
          return 'pm2.5 aqi'
        case measurementTypes.pm10:
          return 'pm10 aqi'
      }
    },
    async startUserDevicesInterval() {
      this.isDevicesFirstCallInProgress = true
      await this.getUserDevices()
      this.isDevicesFirstCallInProgress = false
      // Updates all user devices data in Vuex every 60 seconds.
      this.interval = setInterval(
        this.getUserDevices,
        DEVICE_UPDATE_INTERVAL_MS
      )
    },
    openRegisterDevice() {
      this.dialogs.registerDevice = true
    },
    openRegisterMultipleDevices() {
      this.dialogs.registerMultipleDevices = true
    },
    getTimestampLocaleString(timestamp) {
      return new Date(timestamp).toLocaleString()
    },
    setDevicesTableSettings() {
      const save = {
        itemsPerPage: this.itemsPerPage,
        sortBy: this.sortedBy,
        sortDesc: this.sortDesc,
      }
      localStorage.setItem(LOCAL_STORAGE_KEYS, JSON.stringify(save))
    },
    getDevicesTableSettings() {
      const settings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS))
      if (settings !== null) {
        this.sortDesc = settings.sortDesc
        this.itemsPerPage = settings.itemsPerPage
        this.sortedBy = settings.sortBy
      }
    },
    getAddDevicesDropDownProps() {
      return {
        titleText: this.$t('manageDevices.addDeviceDropdownLabel'),
        titleIcon: 'mdi-plus',
        options: [
          {
            text: this.$t('manageDevices.addDeviceOptions.single'),
            icon: 'mdi-numeric-1-box',
            action: 'single',
          },
          {
            text: this.$t('manageDevices.addDeviceOptions.multiple'),
            icon: 'mdi-plus-box-multiple',
            action: 'multiple',
          },
        ],
        onClickHandler: this.onClickAddDeviceHandler,
        closeAfterClick: true,
        closeOnClickOutside: true,
      }
    },
    onClickAddDeviceHandler(action) {
      switch (action) {
        case 'single':
          this.openRegisterDevice()
          break
        case 'multiple':
          this.openRegisterMultipleDevices()
          break
      }
    },
    async getUserDevices() {
      await this.$store.dispatch('devices/setAllUserDevices')
      this.updateSubscriptions()
    },
    getDeviceModel(model, subModel) {
      const formattedSubModel = subModel
        ? subModel.replace('gases', '').trim()
        : subModel
      if (formattedSubModel) {
        return `${model}-${formattedSubModel}`
      }

      return model
    },
    getDeviceModelName(model) {
      const modelKey = model
        ? this.$store.getters['devicemodels/getModelKey'](model)
        : ''

      return modelKey ? deviceModelDisplay(modelKey, this.$t.bind(this)) : ''
    },
    getDeviceSubModelName(model, subModel) {
      const submodelKey = subModel
        ? this.$store.getters['devicemodels/getSubmodelKey'](model, subModel)
        : ''

      return submodelKey
        ? deviceSubmodelDisplay(submodelKey, this.$t.bind(this))
        : ''
    },
    getDeviceType(model, subModel) {
      const deviceModelName = this.getDeviceModelName(model)
      const deviceSubmodelName = this.getDeviceSubModelName(model, subModel)

      return deviceSubmodelName
        ? `${deviceModelName} (${deviceSubmodelName})`
        : deviceModelName
    },
    getFormattedLatestCommunication(lastCommunication, showLastCommunication) {
      if (!showLastCommunication) {
        return this.$t('manageDevices.notAvailable')
      }

      const formattedLastCommunication = validateTimestamp(
        lastCommunication,
        this.$t('manageDevices.lastCommunicationNever')
      )
      return formattedLastCommunication !=
        this.$t('manageDevices.lastCommunicationNever')
        ? this.getTimestampLocaleString(formattedLastCommunication)
        : formattedLastCommunication
    },
    getDeviceReading(measurements, isConnected) {
      if (measurements) {
        const sensorReadings = [
          {
            name: Measurement_Names.mcpm2x5_aqi,
            value: measurements.mcpm2x5_aqi,
          },
          {
            name: Measurement_Names.mcpm10_aqi,
            value: measurements.mcpm10_aqi,
          },
        ]
        return isConnected
          ? getMeasurementByType(sensorReadings, this.measurementType, null)
          : null
      }

      return null
    },
    updateSubscriptions() {
      this.$store.dispatch('subscriptions/updateSubscriptions', {
        auth: this.$auth,
        api: this.$api,
      })
    },
    getDeviceSubscriptionLabel(deviceId, showSubscription) {
      const subscriptionTypeFromApi =
      this.$store.getters['devices/getSubscriptionTypeByDeviceId'](deviceId)

      if (!showSubscription || !subscriptionTypeFromApi) {
        return this.$t('subscriptionType.notAvailable')
      }

      return this.$t(`subscriptionType.${subscriptionTypeFromApi}`)
    },
    filterByColumnValueAndDeviceAttributes(value, search, item) {
      let foundColumnValue = value != null && search != null &&
        (value.toString().toLowerCase().indexOf(search.toString().toLowerCase()) !== -1) // Filter by column values

      if (!foundColumnValue && search?.length && value != null) { // Else filter by device attributes
        if (item.associatedDevices?.length) {
          foundColumnValue =  this.findAssociatedDevice(search, item);
        }
      }
      return foundColumnValue
    },
    findAssociatedDevice(search, item) {
      for (const associatedDevice of item.associatedDevices) {
        for (const searchableAttribute of searchableDeviceAttributes) {
          if (associatedDevice[searchableAttribute] &&
            associatedDevice[searchableAttribute].toString().toLowerCase().indexOf(search.toString().toLowerCase()) !== -1)
            {
              return true;
            }
        }
      }
        return false;
    },
    updateTableComputedItems(computedItems) {
      this.tableComputedItems = computedItems
      this.autoExpandAssociatedDevices()
    },
    autoExpandAssociatedDevices() {
      this.tableComputedItems.forEach((device) => {
        let foundSerial = false
       if (this.search) {
        if (device.associatedDevices?.length) {
          foundSerial = this.findAssociatedDevice(this.search, device)
        }
      }

        if (foundSerial) {
          this.expandAssociatedDevices(device)
        }
      })
    },
    expandAssociatedDevices(device) {
      if (device && !this.isRowExpanded(device.id)) {
        this.expanded.push(device)
      }
    },
    expandAllAssociatedDevices() {
      this.expanded = this.expandableDevices
    },
    isDeviceExpandable(device) {
      return !!device.associatedDevices?.length
    },
    collapseAllAssociatedDevices() {
      this.expanded = []
    },
    collapseAssociatedDevices(device) {
      if (device && this.isRowExpanded(device.id)) {
        this.expanded = this.expanded.filter((expandedDevice) => {
          return expandedDevice.id !== device.id
        })
      }
    },
    isRowExpanded(deviceId) {
      return this.expanded.findIndex((device) => {
        return device.id === deviceId
      }) !== -1
    },
    openDialog(dialogData) {
      if (dialogData) {
        this.currentDialog = dialogData.dialog
        this.currentDeviceId = dialogData.deviceId
      }
    },
    closeDialog() {
      this.currentDialog = ''
      this.currentDeviceId = ''
    },
    isUserAccessible(deviceId) {
      return (
        this.accountId === this.$store.getters['devices/getAccountByDeviceId'](deviceId) ||
        !!this.$store.getters['devices/getIsSharedByDeviceId'](deviceId)
      )
    }
  },
  computed: {
    devices() {
      const devices = []
      const sbDeviceQueue = []

      for (const [key, value] of Object.entries(
        this.$store.state.devices.allUserDevices
      )) {
        if (value && this.isUserAccessible(key)) {
          const model = this.$store.getters['devices/getModelByDeviceId'](key)
          const isShared =
            this.$store.getters['devices/getIsSharedByDeviceId'](key)
          const subModel =
            this.$store.getters['devices/getSubmodelByDeviceId'](key)
          const lastCommunication =
            this.$store.getters['devices/getLastCommunicationTimeByDeviceId'](key)
          const gatewaySerial =
            this.$store.getters[
              'devices/getConnectedSmartBridgeSerialByDeviceId'
            ](key)
          const isConnected =
            this.$store.getters['devices/getIsConnectedByDeviceId'](key)

          const device = {}
          device.id = key
          device.showSubscription =
            this.$store.getters['devicemodels/getShowSubscription'](model)
          device.showCondition =
            this.$store.getters['devicemodels/getShowCondition'](model)
          device.showAQI = this.$store.getters['devicemodels/getShowAQI'](model)
          device.showLastCommunication =
            this.$store.getters['devicemodels/getShowLastCommunication'](model)
          device.deviceName =
            this.$store.getters['devices/getNameByDeviceId'](key)
          device.deviceLocationName =
            this.$store.getters['devices/getLocationNameByDeviceId'](key)
          device.accessCode = isShared ? access.SHARED : access.FULL
          device.access = isShared
            ? this.$t('manageDevices.accessTypes.shared')
            : this.$t('manageDevices.accessTypes.owned')
          device.deviceType = this.getDeviceType(model, subModel)
          device.deviceModel = this.getDeviceModel(
            model,
            subModel
          ).toUpperCase()
          device.deviceSerial =
            this.$store.getters['devices/getSerialByDeviceId'](key)
          device.deviceSubscription = this.getDeviceSubscriptionLabel(
            key,
            device.showSubscription
          )
          device.lastCommunication = this.getFormattedLatestCommunication(
            lastCommunication,
            device.showLastCommunication
          )
          device.reading = this.getDeviceReading(
            this.$store.getters['devices/getMeasurementsByDeviceId'](key),
            isConnected
          )

          if (gatewaySerial) {
            device.gatewaySerial = gatewaySerial
            sbDeviceQueue.push(device)
          } else {
            devices.push(device)
          }
        }
      }

      // go back and attach SB connected devices to their SB
      for (const sbDev of sbDeviceQueue) {
        const parentDev = devices.find(
          (d) => d.deviceSerial === sbDev.gatewaySerial
        )
        // if the associated SB is not found, treat sbDev like a normal device
        if (!parentDev) {
          devices.push(sbDev)
        } else if (parentDev.associatedDevices) {
          parentDev.associatedDevices.push(sbDev)
        } else {
          parentDev.associatedDevices = [sbDev]
        }
      }
      return devices
    },
    headers() {
      return [
        {
          text: '',
          value: 'data-table-expand',
        },
        {
          text: this.$t('manageDevices.tableHeaders.deviceName'),
          align: 'left',
          value: 'deviceName',
        },
        {
          text: this.$t('manageDevices.tableHeaders.deviceLocationName'),
          align: 'left',
          value: 'deviceLocationName',
        },
        {
          text: this.$t('manageDevices.tableHeaders.deviceType'),
          value: 'deviceType',
        },
        {
          text: this.$t('manageDevices.tableHeaders.deviceModel'),
          value: 'deviceModel',
        },
        {
          text: this.$t('manageDevices.tableHeaders.deviceSerial'),
          value: 'deviceSerial',
        },
        {
          text: this.$t('manageDevices.tableHeaders.deviceSubscription'),
          value: 'deviceSubscription',
        },
        {
          text: this.$t('manageDevices.tableHeaders.access'),
          value: 'access',
        },
        {
          text: this.$t('manageDevices.tableHeaders.condition'),
          value: 'id',
          sort: (a, b) => {
            // setup device objects with pertinent fields for sorting
            const deviceA = {
              isOwned: !this.$store.getters['devices/getIsSharedByDeviceId'](a),
              spsEol: this.$store.getters['devices/getSpsEoLByDeviceId'](a),
              deviceErrors:
                this.$store.getters['devices/getDeviceStatusByDeviceId'](a),
              sensorErrors:
                this.$store.getters['devices/getSensorStatusByDeviceId'](a),
              rssi: this.$store.getters['devices/getRSSIByDeviceId'](a),
              isConnected: this.$store.getters['devices/getIsConnectedByDeviceId'](a),
              connectionType:
                this.$store.getters['devices/getConnectionTypeByDeviceId'](a) ||
                connectionTypes.WIFI,
            }

            const deviceB = {
              isOwned: !this.$store.getters['devices/getIsSharedByDeviceId'](b),
              spsEol: this.$store.getters['devices/getSpsEoLByDeviceId'](b),
              deviceErrors:
                this.$store.getters['devices/getDeviceStatusByDeviceId'](b),
              sensorErrors:
                this.$store.getters['devices/getSensorStatusByDeviceId'](b),
              rssi: this.$store.getters['devices/getRSSIByDeviceId'](b),
              isConnected: this.$store.getters['devices/getIsConnectedByDeviceId'](b),
              connectionType:
                this.$store.getters['devices/getConnectionTypeByDeviceId'](b) ||
                connectionTypes.WIFI,
            }

            const res = sortDeviceCondition(deviceA, deviceB)
            return res
          },
        },
        {
          text: this.$t('manageDevices.tableHeaders.lastCommunication'),
          value: 'lastCommunication',
          sort: (a, b) => {
            // turn strings into dates, subtract them to get a value that is either negative, positive, or zero
            return new Date(a).getTime() - new Date(b).getTime()
          },
        },
        {
          text: this.measurementLabel,
          align: 'left',
          value: 'reading',
          sort: (a, b) => {
            const numberA = parseInt(a)
            const numberB = parseInt(b)
            const deviceA = isNaN(numberA) ? -1 : numberA
            const deviceB = isNaN(numberB) ? -1 : numberB
            return deviceA - deviceB
          },
        },
        { text: 'Actions', value: 'actions', sortable: false, align: 'right' },
      ]
    },
    isEmpty() {
      return !this.devices?.length
    },
    isLoading() {
      return this.$auth.loading || this.isDevicesFirstCallInProgress
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    showTable() {
      return !this.isLoading && !this.isEmpty
    },
    showEmptyMessage() {
      return !this.isLoading && this.isEmpty
    },
    customTableFilter() {
      if (this.search.length >= 2) {
        return this.search
      } else if (this.search.length === 1) {
        return '//no-result//'
      }
      return ''
    },
    measurementType() {
      return this.getMeasurementType(
        this.$store.getters['map/getMeasurementType']
      )
    },
    measurementLabel() {
      const type = this.$store.getters['map/getMeasurementType']
      if (type === measurementTypes.pm10) {
        return this.$t('manageDevices.tableHeaders.aqi10')
      } else {
        return this.$t('manageDevices.tableHeaders.aqi25')
      }
    },
    enableBulkDeviceRegistration() {
      return (
        this.$store.getters['featureFlags/getFeatureFlagBySlug'](
          featureFlags.EnableBulkDeviceRegistration
        ) && this.$permissions?.flags['allowBulkDeviceRegistration']
      )
    },
    tableItemComponent() {
      return this.isMobile ? "device-table-body-mobile" : "device-table-body"
    },
    expandableDevices(){
      return this.devices.filter((device) => {
        return this.isDeviceExpandable(device)
      })
    },
    enableExpandCollapseButtons(){
      return !!this.expandableDevices.length
    },
  },
}
</script>

<style lang="scss">
// requires non-scoped scss to override the vuetify styles
.manage-devices-card {
  .v-data-table {
    .v-data-table-header-mobile__select {
      min-width: unset !important;
    }
    .v-data-footer {
      margin-right: 8px !important;
    }
    .v-data-table-header-mobile__wrapper {
      .v-select {
        max-width: 335px !important;
      }
    }
    th {
      white-space: nowrap;
    }
  }
  .table-bar {
    .v-input__prepend-outer {
      margin-right: 16px !important;
    }
  }
  .v-data-table--mobile {
    .v-data-footer__pagination {
      margin: 0 16px 0 12px !important;
    }
  }
}
.manage-devices-card.is-mobile {
  .v-data-table-header__icon {
    opacity: 1 !important;
  }
}
.menu-options {
  .v-input__prepend-outer {
    margin-right: 16px !important;
  }
}
</style>

<style lang="scss" scoped>
.emptyMessage {
  height: 240px;
  line-height: 240px;
  color: rgba(0, 0, 0, 0.56);
}

.table-bar {
  display: flex;
  height: 50px;

  background-color: transparent;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;

  .search-field {
    max-width: 375px;
  }
  .register {
    padding-top: 14px;
  }
}

.op-76 {
  opacity: 0.76;
}

.fit-space {
  flex-grow: unset;
}

.menu-options {
  padding: 0 16px;

  .search-column {
    width: 100%;
    max-width: 400px;
    min-width: 150px;
  }

  @media (max-width: 480px) {
    padding: 0 12px;
  }
}
</style>

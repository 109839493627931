export const registerDeviceTemplateFields = Object.freeze({
  deviceName: 'Device Name',
  locationName: 'Location Name',
  deviceSerialNumber: 'Device Serial Number',
  makeDevicePublic: 'Make Device Public?',
  indoorSensor: 'Indoor Sensor?',
  latitude: 'Latitude',
  longitude: 'Longitude',
})

export const registerDeviceValidationErrors = Object.freeze({
  deviceName: 'Device Name must be less than 256 characters.',
  locationName: 'Location Name must be less than 256 characters.',
  deviceSerialNumber: 'Device Serial Number is incorrectly formatted.',
  indoorSensor: 'Indoor Sensor selection must be Yes or No.',
  latitude: 'Latitude must be a number between -90 and 90, and must include no more than 8 decimal points.',
  longitude: 'Longitude must be a number between -180 and 180, and must include no more than 8 decimal points.',
  nullIsland: 'Lat/Long cannot be 0,0.',
  blankFile: 'This file contains no data. Please check your file and try again.',
  columHeaders: 'Please ensure your columns are in the correct order and try again. See-CSV template-for reference.'
})

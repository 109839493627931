export const DEVICES_TYPES = {
  BLUESKY: 'Blue Sky'
}

export const MODEL_NUMBERS = {
  BLUESKY: '8143'
}

export const DEVICE_TO_MODEL = {
  [DEVICES_TYPES.BLUESKY]: MODEL_NUMBERS.BLUESKY
}

export const DEVICES = [
  {
    id: 1,
    model_number: MODEL_NUMBERS.BLUESKY,
    name: `${DEVICES_TYPES.BLUESKY} (${MODEL_NUMBERS.BLUESKY})`
  }
]

<template>
  <base-icon
    :showIcon="showIcon"
    :imgSrc="imgSrc"
    imgAlt="Communication Icon"
    :iconText="iconText"
    tooltipContentClass="communication-status-tooltip"
    dataCy="communicationStatus"
  />
</template>

<script>
import BaseIcon from './BaseIcon.vue'

const DEVICE_COMMUNICATION_IMG = '/img/icons/device_communication_icon.svg'
const CONNECTED_DEVICE_COMMUNICATION_IMG =
  '/img/icons/connected_device_communication_icon.svg'

export default {
  name: 'CommunicationStatus',
  props: {
    deviceId: { type: String, default: '' },
  },
  components: {
    'base-icon': BaseIcon,
  },
  computed: {
    smartBridgeDeviceId() {
      return this.$store.getters['devices/getConnectedSmartBridgeIdByDeviceId'](
        this.deviceId
      )
    },
    parentDeviceId() {
      return this.smartBridgeDeviceId || this.deviceId
    },
    isDeviceConnectedToSmartBridge() {
      return !!this.smartBridgeDeviceId
    },
    isSmartBridgeConnected() {
      return this.$store.getters['devices/getIsConnectedByDeviceId'](
        this.parentDeviceId
      )
    },
    isDeviceConnected() {
      return this.$store.getters['devices/getIsConnectedByDeviceId'](
        this.deviceId
      )
    },
    isDeviceSendingTelemetry() {
      return !!this.$store.getters['devices/getTelemTxTypeByDeviceId'](
        this.deviceId
      )
    },
    imgSrc() {
      return this.isDeviceConnectedToSmartBridge
        ? CONNECTED_DEVICE_COMMUNICATION_IMG
        : DEVICE_COMMUNICATION_IMG
    },
    iconText() {
      return this.isDeviceConnectedToSmartBridge
        ? this.connectedDeviceIconText
        : this.deviceIconText
    },
    deviceIconText() {
      if (this.isDeviceConnected && this.isDeviceSendingTelemetry) {
        return this.$t('device.icons.communicationStatus.device')
      }

      return ''
    },
    connectedDeviceIconText() {
      if (
        this.isSmartBridgeConnected &&
        this.isDeviceConnected &&
        this.isDeviceSendingTelemetry
      ) {
        return this.$t('device.icons.communicationStatus.connectedDevice')
      }

      return ''
    },
    showIcon() {
      return !!this.iconText
    },
  },
}
</script>

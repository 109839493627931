import { LOG_IN_AS_HEADER_NAMES } from './utils'
import { getLogInAsHeaders, getLogInAsUrl } from './helper'

// Returns array of alert triggers

/*
 ** Success: 200
 ** Errors: 404 if devId for accountId is not found
 */
export const getAlerts = function (devId) {
  return this.$auth.getAccountId().then((accountId) => {
    return this.GET(
      getLogInAsUrl(`/accounts/accountId/devices/${devId}/triggers`, accountId),
      {
        ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
      },
      {},
      process.env.VUE_APP_ALERT_HOST
    )
  })
}

// Returns alert trigger
/*
 ** Success: 200
 ** Error: 404 if devId for accountId is not found
 ** Error: 404 if triggerId for devId for accountId is not found
 */
export const getAlert = function (devId, triggerId) {
  return this.$auth.getAccountId().then((accountId) => {
    return this.GET(
      getLogInAsUrl(
        `/accounts/accountId/devices/${devId}/triggers/${triggerId}`,
        accountId
      ),
      {
        ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
      },
      {},
      process.env.VUE_APP_ALERT_HOST
    )
  })
}

// Creates alert trigger
/*
 ** Success: 201
 ** Error: 404 if devId for accountId is not found
 ** Error: 422 if trigger does not pass validation
 */
export const postAlert = function (alertData, devId) {
  const body = JSON.stringify(alertData)
  return this.$auth.getAccountId().then((accountId) => {
    return this.POST(
      getLogInAsUrl(`/accounts/accountId/devices/${devId}/triggers`, accountId),
      body,
      {
        ...getLogInAsHeaders([
          LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
          LOG_IN_AS_HEADER_NAMES.USER_ID,
        ]),
      },
      {},
      process.env.VUE_APP_ALERT_HOST
    )
  })
}

// Replaces alert trigger
/*
 ** Success: 204
 ** Error: 404 if devId for accountId is not found
 ** Error: 404 if triggerId for devId for accountID is not found
 */
export const patchAlert = function (alertData, devId, triggerId) {
  const body = JSON.stringify(alertData)
  return this.$auth.getAccountId().then((accountId) => {
    return this.PATCH(
      getLogInAsUrl(
        `/accounts/accountId/devices/${devId}/triggers/${triggerId}`,
        accountId
      ),
      body,
      {
        ...getLogInAsHeaders([
          LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
          LOG_IN_AS_HEADER_NAMES.USER_ID,
        ]),
      },
      {},
      process.env.VUE_APP_ALERT_HOST
    )
  })
}

// Deletes alert trigger
/*
 ** Success: 204
 ** Error: 404 if devId for accountId is not found
 ** Error: 404 if triggerId for devId for accountId is not found
 */
export const deleteAlert = function (devId, triggerId) {
  return this.$auth.getAccountId().then((accountId) => {
    return this.DELETE(
      getLogInAsUrl(
        `/accounts/accountId/devices/${devId}/triggers/${triggerId}`,
        accountId
      ),
      {
        ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
      },
      {},
      process.env.VUE_APP_ALERT_HOST
    )
  })
}

export const getAlertNotifications = function (q) {
  let query = ''
  if (q) {
    if (q.isRead != undefined) {
      query = '?isRead=false'
    }
    if (q.limit != undefined) {
      query += query != '' ? '&' : '?'
      query += `limit=${q.limit}`
    }
  }

  return this.$auth.getAccountId().then((accountId) => {
    return this.GET(
      getLogInAsUrl(`/accounts/accountId/notifications${query}`, accountId),
      {
        ...getLogInAsHeaders([
          LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
          LOG_IN_AS_HEADER_NAMES.USER_ID,
        ]),
      },
      {},
      process.env.VUE_APP_ALERT_HOST
    )
  })
}

export const patchClearNotification = function (noteId) {
  const body = JSON.stringify({
    isRead: true,
  })
  return this.$auth.getAccountId().then((accountId) => {
    return this.PATCH(
      getLogInAsUrl(`/accounts/accountId/notifications/${noteId}`, accountId),
      body,
      {
        ...getLogInAsHeaders([
          LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
          LOG_IN_AS_HEADER_NAMES.USER_ID,
        ]),
      },
      {},
      process.env.VUE_APP_ALERT_HOST
    )
  })
}

export const postClearAllNotifications = async function () {
  const accountId = await this.$auth.getAccountId()
  return this.POST(
    getLogInAsUrl(`/accounts/accountId/notifications/clear`, accountId),
    null,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
    },
    {},
    process.env.VUE_APP_ALERT_HOST
  )
}

export const postClearAllDeviceNotifications = async function (deviceId) {
  const accountId = await this.$auth.getAccountId()
  return this.POST(
    getLogInAsUrl(
      `/accounts/accountId/devices/${deviceId}/notifications/clear`,
      accountId
    ),
    null,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID]),
    },
    {},
    process.env.VUE_APP_ALERT_HOST
  )
}

export const deleteAlertNotification = function (noteId) {
  return this.$auth.getAccountId().then((accountId) => {
    return this.DELETE(
      getLogInAsUrl(`/accounts/accountId/notifications/${noteId}`, accountId),
      {
        ...getLogInAsHeaders([
          LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
          LOG_IN_AS_HEADER_NAMES.USER_ID,
        ]),
      },
      {},
      process.env.VUE_APP_ALERT_HOST
    )
  })
}

/*
Schema:
Trigger
NOTES:
- Only ONE condition will be supported at this time.
- Only ONE recipient will be supported at this time.
- "field" must equal the field name sent by the device.
- "operator" valid values are:
    - "lt" -> Less Than
    - "lte" -> Less Than or Equal To
    - "eq" -> Equal To
    - "gte" -> Greater Than or Equal To
    - "gt" -> Greater Than
- "value" must be sent as a string
- valid boolean string values
    - "true" -> true
    - "false" -> false
- valid UTC timestamp string values must be either RFC3339 or ISO8601
    - example: "2020-06-12T17:13:10.095769Z"
- will NOT support "PATCH” operations
- conditions "alias" field is used for string substitutions for future support of multiple conditions
- only supports "in app" notifications as recipients at this time - "notification" is used to indicate "in app", additional options like "email" and "sms" will come later.
{
	"title":"My first alert",
	"message”:”Backyard PM 2.5 of {pm2.5|value} exceeded safe level of 2.5",
	"conditions": [
		{
			"field":"sps30_mcpm2x5",
			"operator":"gt",
			"value":”2.7”,
      "alias":"pm2.5",
      "enabled":true,
      "severity":"standard/warning/critical"
		}
	],
	"recipients":[
		{
			"userId":"bosp2mq23akg01o2fjd0",
			"notification":true
		}
	]
}

7/10 - There will be two properties, one called “enabled” that will be a boolean, and the other call “severity” that will be a string can have the following values (standard/warning/critical)
*/

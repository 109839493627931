var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"calibration-card",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"tab-content__title"},[_vm._v(" "+_vm._s(_vm.$t('map.deviceSettings.calibrationFactors.title'))+" ")]),_c('v-card-subtitle',[_c('i18n',{staticClass:"hint-text",attrs:{"path":"map.deviceSettings.calibrationFactors.subtitle","tab":"div","for":"map.deviceSettings.calibrationFactors.faq"}},[_c('a',{on:{"click":_vm.navigateFaq}},[_vm._v(" "+_vm._s(_vm.$t('map.deviceSettings.calibrationFactors.faq'))+" ")])])],1),_c('v-row',{staticClass:"calibration-header"},[_c('v-col',{attrs:{"cols":_vm.columnWidth}}),_c('v-col',{staticClass:"multi-factor column-header",attrs:{"cols":_vm.columnWidth}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('map.deviceSettings.calibrationFactors.multiFactor'))+" ")])]),_c('v-col',{staticClass:"offset column-header",attrs:{"cols":_vm.columnWidth}},[_c('label',[_vm._v(_vm._s(_vm.$t('map.deviceSettings.calibrationFactors.offset')))])]),(_vm.showAutoCal)?_c('v-col',{attrs:{"cols":_vm.columnWidth}},[_c('div',{staticClass:"auto-cal-tooltip"},[_c('label',[_vm._v(_vm._s(_vm.$t('map.deviceSettings.calibrationFactors.autoCal')))]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({attrs:{"icon":""}},'span',attrs,false),on),[_c('v-icon',{attrs:{"data-cy":"autoCalTooltip"}},[_vm._v("mdi-information-outline")])],1)]}}],null,false,4020986171)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('map.deviceSettings.calibrationFactors.autoCalTooltip'))+" ")])])],1)]):_vm._e(),(_vm.showZeroing)?_c('v-col',{attrs:{"cols":_vm.columnWidth}},[_c('label',{staticClass:"zero-column-header",attrs:{"data-cy":"zeroingColumnHeader"}},[_vm._v(_vm._s(_vm.$t('map.deviceSettings.zeroing.zero')))])]):_vm._e()],1),_c('fusion-scroll',{staticClass:"calibration-scroll"},[_c('v-tooltip',{attrs:{"open-on-click":true,"bottom":"","disabled":_vm.hasPermission},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"calibration-body"},'div',attrs,false),on),_vm._l((_vm.userCals),function(item){return _c('v-row',{key:item.name,attrs:{"align":"center","data-cy":"userCalRows"}},[_c('v-col',{attrs:{"cols":_vm.columnWidth}},[_c('div',{staticClass:"label"},[_c('img',{staticClass:"measurement-icon",attrs:{"src":_vm.icons[item.name],"alt":"Measurement Icon"}}),_c('span',[_vm._v(_vm._s(_vm.$t(("readings." + (item.name)))))])])]),_c('v-col',{staticClass:"multi-factor cell",attrs:{"cols":_vm.columnWidth}},[_c('v-text-field',{attrs:{"name":"multiplicationFactor","disabled":item.multiFactor === undefined || !_vm.hasPermission,"rules":_vm.multiFactorRules[item.name],"hide-details":true},on:{"focus":function($event){return _vm.setHelpText(item.name, 'multiplicationFactor')},"blur":_vm.clearHelpText},model:{value:(item.multiFactor),callback:function ($$v) {_vm.$set(item, "multiFactor", $$v)},expression:"item.multiFactor"}})],1),_c('v-col',{staticClass:"offset cell",attrs:{"cols":_vm.columnWidth}},[_c('v-text-field',{attrs:{"name":"offset","disabled":item.offset === undefined || !_vm.hasPermission,"rules":_vm.offsetRules[item.name],"hide-details":true},on:{"focus":function($event){return _vm.setHelpText(item.name, 'offset')},"blur":_vm.clearHelpText},model:{value:(item.offset),callback:function ($$v) {_vm.$set(item, "offset", $$v)},expression:"item.offset"}})],1),(_vm.showAutoCal)?_c('v-col',{attrs:{"cols":_vm.columnWidth}},[(_vm.sensorCanAutoCal(item.name))?_c('v-switch',{attrs:{"disabled":!_vm.hasPermission,"name":"ascCal"},model:{value:(item.ascCal),callback:function ($$v) {_vm.$set(item, "ascCal", $$v)},expression:"item.ascCal"}}):_vm._e()],1):_vm._e(),(_vm.showZeroing)?_c('v-col',{staticClass:"zero-cell",attrs:{"cols":_vm.columnWidth}},[(_vm.sensorCanZero(item.name))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.getButtonStyles(item.name, true),attrs:{"icon":"","data-cy":("addUserZeroButton_" + (item.name))},on:{"click":function($event){return _vm.requestZero(item.name, true)}}},'v-btn',attrs,false),on),[_c('span',{staticClass:"zero-btn-overlay"},[_vm._v("0")]),_c('v-icon',[_vm._v("mdi-restore")])],1)]}}],null,true)},[_c('span',{attrs:{"data-cy":"zeroingTooltipText"}},[_vm._v(" "+_vm._s(_vm.getZeroButtonTooltipText(item.name))+" ")])]):_vm._e(),(_vm.showRemoveUserZeroButton(item.name))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:((_vm.getButtonStyles(item.name, false)) + " request-factory-zero-btn"),attrs:{"icon":"","data-cy":("removeUserZeroButton_" + (item.name))},on:{"click":function($event){return _vm.requestZero(item.name, false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('map.deviceSettings.zeroing.tooltip.userRemove')))])]):_vm._e()],1):_vm._e()],1)}),1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('map.deviceSettings.calibrationFactors.upgradeTooltip')))])])],1),(_vm.helpText)?_c('v-row',{class:("calibration-footer " + _vm.calibrationFooterClass)},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"help-text"},[_vm._v(_vm._s(_vm.helpText))])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-list>
    <v-list-item>
      <v-radio-group
        @change="parameterChange()"
        v-model="selectedParameter"
        :mandatory="false"
        class="radio-buttons scrollable"
        ref="radioGroupScrollable"
      >
        <div v-for="(item, index) in parameterOptions" :key="index">
          <v-tooltip bottom :disabled="!item.disabled">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-radio
                  :disabled="item.disabled"
                  :value="item.value"
                  :label="item.label"
                  :key="index"
                  color="primary"
                  :data-cy="'measurementParameter' + index"
                ></v-radio>
              </div>
            </template>
            <span>{{ $t('accountSubscriptions.upgradeData') }}</span>
          </v-tooltip>
        </div>
      </v-radio-group>
    </v-list-item>
  </v-list>
</template>

<script>
import measurementOptionsMixin from '@/mixins/widgets/measurementOptionsMixin'
import { getWidgetMeasurementOptions } from '@/helpers/widgets/helper'
import { getUserAccountId } from '@/helpers/loginas/logInAsHelper'
import { widgetSettings } from '@/services/dashboard'

export default {
  name: 'MeasurementOptions',
  props: {
    deviceIds: Array,
    passedinParameter: Number,
    widgetId: String,
    filterMeasurementStatistics: Boolean,
  },
  mixins: [measurementOptionsMixin, widgetSettings],
  data() {
    return {
      accountId: null,
    }
  },
  async mounted() {
    this.accountId = await getUserAccountId(this.$auth)
  },
  watch: {
    parameterOptions() {
      // determine the index of selected element plus 1 which corresponds to the padding
      const selectedParameterIndex = (
        this.parameterOptions.findIndex(parameter => parameter.value === this.selectedParameter)
        ) + 1
      if (selectedParameterIndex > 0) {
        this.setScroll(selectedParameterIndex)
      }
    },
    selectedParameter(selectedParameter) {
      if (typeof selectedParameter === 'number' && this.widgetId) {
        this.saveWidgetSettings(this.widgetId, { selectedParameter })
      }
    },
  },
  computed: {
    parameterOptions() {
      return getWidgetMeasurementOptions(
        this.$t.bind(this),
        this.availableMeasurements,
        this.permissionSet
      )
    },
  },
  methods: {
    parameterChange() {
      this.$emit('parameterChange', this.selectedParameter)
    },
    setScroll(selectedParameterIndex){
      this.$nextTick(() => {
        setTimeout(() => {
        const radioGroupHeight = this.$refs.radioGroupScrollable.$el.clientHeight
        if (radioGroupHeight > 0) {
          // calculate the amount of visible items based on the radio group height to
          // determine the scrollTop value assigned to the radio group
          const itemHeight = 24
          const visibleItems = Math.round(radioGroupHeight / itemHeight) - 1
          const visibleSection = Math.ceil(selectedParameterIndex / visibleItems)
          this.$refs.radioGroupScrollable.$el.scrollTop = radioGroupHeight * (visibleSection - 1 )
        }
        }, 100)
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.v-list {
  padding: 0px;
}

.v-list-item {
  padding: 0;
}

.scrollable {
  overflow: auto;
  max-height: 380px;
  padding: 16px 16px 0;
  margin-top: 0;
}
</style>

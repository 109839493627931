<template>
  <div class="aqi">
    <div class="label label__full">
      {{ $t('map.popover.currentAqi') }}
    </div>
    <div class="label label__abbr">
      {{ $t('map.popover.abbrevAqi') }}
    </div>
    <v-btn
      class="reading"
      text
      @click="openAQIDialog"
      :style="aqiStyles(aqi)"
      data-cy="currentAQI"
    >
      {{ validateAQI(aqi) }}
    </v-btn>
    <device-aqi-dialog :open="aqiOpen" @close="closeAQIDialog()" />
  </div>
</template>

<script>
import { getAQIStyles, aqiValidator } from '@/services/map'
import deviceAQIDialog from '@/components/dialogs/DeviceAQIDialog'

export default {
  props: ['aqi'],
  components: {
    'device-aqi-dialog': deviceAQIDialog,
  },
  mixins: [aqiValidator],
  data() {
    return {
      aqiOpen: false,
    }
  },
  methods: {
    aqiStyles: function (aqi) {
      const styles = getAQIStyles(aqi)

      if (styles.background === '#eeeeee') {
        return `color:${styles.color};transition:background-color 0s ease-in;`
      }
      return `background-color:${styles.background};color:${styles.color}`
    },
    openAQIDialog: function () {
      this.aqiOpen = true
    },
    closeAQIDialog: function () {
      this.aqiOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.aqi {
  padding: 0px 8px 0px 8px;
  .label {
    white-space: nowrap;
    margin-bottom: 0.2rem;
    text-transform: uppercase;
    font-size: 13px;

    &__abbr {
      display: none;
    }
    @media (max-width: 460px) {
      font-size: 12px !important;
    }
  }

  .reading {
    background-color: #eeeeee;
    color: white;
    font-size: 30px;
    text-align: center;
    letter-spacing: 0px;
    font-weight: 500;
    border-radius: 2px;
    height: 39px;
    width: 70px;
    @media (max-width: 400px) {
      font-size: 24px;
      width: 60px;
    }
  }

  @media (max-width: 460px) {
    max-width: 90px;
    padding: 0 !important;
    margin-left: 6px;
    .label__full {
      display: none;
    }
    .label__abbr {
      display: block;
    }
    .reading {
      width: 60px;
    }
  }

  .widget-xs &,
  .widget-sm & {
    max-width: 90px;
    padding: 0 !important;
    margin-left: 6px;
    .label__full {
      display: none;
    }
    .label__abbr {
      display: block;
    }
    .reading {
      width: 60px;
    }
  }
}
</style>

<template>
  <base-dialog
    :open="open"
    :title="$t('map.deviceInfo.title')"
    data-cy="deviceInfoDialog"
    :fullscreen="$vuetify.breakpoint.xs"
    :persistentOverlay="true"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind="on"></slot>
    </template>
    <template v-slot:header>
      <v-card-title>
        <v-icon class="header-icon">mdi-information</v-icon>
        {{ $t('map.deviceInfo.title') }}
      </v-card-title>
    </template>
    <v-row no-gutters>
      <v-col cols="12">
        <v-list>
          <v-list-item class="device-name">
            <!--when separate Device and Location names is enabled-->
            <v-list-item-content v-if="enableDeviceLocationName">
              <v-list-item-title>
                {{ $t('map.deviceInfo.deviceName') }}:
              </v-list-item-title>
              <v-tooltip bottom :max-width="600" :disabled="!isNameOverflowing">
                <template v-slot:activator="{ on }">
                  <v-list-item-subtitle v-on="on" ref="deviceName">
                    <span data-cy="deviceInfoName">{{ friendlyName }}</span>
                  </v-list-item-subtitle>
                </template>
                <span>{{ friendlyName }}</span>
              </v-tooltip>
              <v-list-item-title style="margin-top: 5px">
                {{ $t('map.deviceInfo.locationName') }}:
              </v-list-item-title>
              <v-tooltip bottom :max-width="600" :disabled="!isLocationNameOverflowing">
                <template v-slot:activator="{ on }">
                  <v-list-item-subtitle v-on="on" ref="deviceLocationName">
                    <span data-cy="deviceInfoLocation">{{ locationName }}</span>
                  </v-list-item-subtitle>
                </template>
                <span>{{ locationName }}</span>
              </v-tooltip>
            </v-list-item-content>
            <!--when Device/Location is one line-->
            <v-list-item-content v-else>
              <v-list-item-title>
                {{ $t('map.deviceInfo.name') }}:
              </v-list-item-title>
              <v-tooltip bottom :max-width="600" :disabled="!isNameOverflowing">
                <template v-slot:activator="{ on }">
                  <v-list-item-subtitle v-on="on" ref="deviceName">
                    <span data-cy="deviceInfoName">{{ friendlyName }}</span>
                  </v-list-item-subtitle>
                </template>
                <span>{{ friendlyName }}</span>
              </v-tooltip>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" sm="6">
        <v-list>
          <v-list-item data-cy="deviceInfoDevice">
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('map.deviceInfo.device') }}:
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ modelDisplayName }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item data-cy="deviceInfoModel">
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('map.deviceInfo.model') }}:
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ displayModel }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item data-cy="deviceInfoSubModel">
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('map.deviceInfo.subModel') }}:
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ submodel }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item data-cy="deviceInfoSerial">
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('map.deviceInfo.serial') }}:
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ serial }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" sm="6">
        <v-list>
          <v-list-item data-cy="deviceInfoLastCommunication">
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('map.deviceInfo.lastCommunication') }}:
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ getTimeSinceDate(lastCommunication) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- Render with Wired Icon when Ethernet Connection-->
          <v-list-item v-if="isEthernet" data-cy="deviceInfoWiredConnection">
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('map.deviceInfo.connectionSignalStrength') }}:
              </v-list-item-title>
              <v-list-item-subtitle>
                <img :src="ethernetIcon" alt="Ethernet Icon" width="18" height="18">
                {{ $t('map.deviceInfo.wiredConnection') }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- Render Wi-Fi info -->
          <v-list-item v-else data-cy="deviceInfoWifi">
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('map.deviceInfo.wifiStrength') }}:
              </v-list-item-title>
              <v-list-item-subtitle v-if="showConnectivity" >
                {{ connectionStrengthText }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else >
                {{ $t('map.deviceInfo.notApplicable') }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item data-cy="deviceInfoRssi">
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('map.deviceInfo.rssi') }}:
              </v-list-item-title>
              <v-list-item-subtitle v-if="isEthernet || !showConnectivity">
                {{ $t('map.deviceInfo.notApplicable') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>
                {{ rssi }} {{ $t('map.deviceInfo.rssiUnits') }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item data-cy="deviceInfoFirmware">
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('map.deviceInfo.firmware') }}:
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ firmware }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <device-subscription-row :deviceId="deviceId" :showSubscriptionType="subscriptionTypes.WEBSITE" />
    <device-subscription-row :deviceId="deviceId" :showSubscriptionType="subscriptionTypes.DATA_SERVICES" />
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="close"
        class="primary white--text"
        text
        data-cy="deviceInfoDoneButton"
      >
        {{ $t('buttons.done') }}
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import dialog from '../Dialog'
import {
  deviceModelDisplayLong,
  getRelativeTimeSince,
} from '../../../services/map'
import { isChildOverflowing } from '../../../services/html-utilities'
import { subscriptionTypes } from '../../../store/subscriptions/utils'
import DeviceSubscriptionRow from './content/SubscriptionDetailsRow.vue'
import { featureFlags } from '../../../services/feature-flags'

const ethernet = 'ethernet'

export default {
  name: 'DeviceInfoDialog',
  props: ['open', 'deviceId'],
  components: {
    'base-dialog': dialog,
    'device-subscription-row': DeviceSubscriptionRow
  },
  data() {
    return {
      isNameOverflowing: false,
      isLocationNameOverflowing: false,
      subscriptionTypes,
      ethernetIcon: '/img/icons/ethernet_icon.svg'
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    showConnectivity() {
      return this.$store.getters['devicemodels/getShowConnectivity'](this.model)
    },
    rssi() {
        const rssi = this.$store.getters['devices/getRSSIByDeviceId'](
        this.deviceId
      )
      return rssi ? rssi : this.$t('map.popover.valueBlank')
    },
    isEthernet() {
      const connectionType = this.$store.getters['devices/getConnectionTypeByDeviceId'](
        this.deviceId
      )
      return connectionType === ethernet
    },
    connectionStrengthText() {
              if (this.rssi) {
          if (this.rssi > -67) {
            return this.$t('map.popover.connectionStrength.wifi.veryGood')
          }
          if (this.rssi > -70) {
            return this.$t('map.popover.connectionStrength.wifi.good')
          }
          if (this.rssi > -80) {
            return this.$t('map.popover.connectionStrength.wifi.okay')
          }
          if (this.rssi > -90) {
            return this.$t('map.popover.connectionStrength.wifi.bad')
          }
          if (this.rssi <= -90) {
            return this.$t('map.popover.connectionStrength.wifi.unusable')
          }
        }
        return this.$t('map.popover.valueBlank')
    },
    lastCommunication() {
      const lastCommunicationTimestamp = this.$store.getters[
        'devices/getLastCommunicationTimeByDeviceId'
      ](this.deviceId)
      if (!lastCommunicationTimestamp) {
        return null
      }
      return new Date(lastCommunicationTimestamp)
    },
    friendlyName() {
      return this.$store.getters['devices/getNameByDeviceId'](this.deviceId)
    },
    enableDeviceLocationName() {
      return this.$store.getters['featureFlags/getFeatureFlagBySlug'](
        featureFlags.DeviceLocationNameEnabled
      )
    },
    locationName() {
      const locationName = this.$store.getters['devices/getLocationNameByDeviceId'](
        this.deviceId
      )
      return  locationName || this.$t('map.popover.valueBlank')
    },
    model() {
      const model = this.$store.getters['devices/getModelByDeviceId'](this.deviceId)
      return model
    },
    displayModel() {
      const modelName = this.model
      return modelName ? modelName.toUpperCase() : this.$t('map.popover.valueBlank')
    },
    submodel() {
      const sub = this.$store.getters['devices/getSubmodelByDeviceId'](
        this.deviceId
      )
      return sub ? sub : this.$t('map.deviceInfo.noSubModel')
    },
    serial() {
      return this.$store.getters['devices/getSerialByDeviceId'](this.deviceId)
    },
    firmware() {
      const fw = this.$store.getters['devices/getFirmwareByDeviceId'](
        this.deviceId
      )
      return fw ? fw : this.$t('map.popover.valueBlank')
    },
    modelDisplayName() {
      const modelKey = this.model
        ? this.$store.getters['devicemodels/getModelKey'](this.model)
        : ''
      const name = deviceModelDisplayLong(modelKey, this.$t.bind(this))
      return name ? name : this.$t('map.popover.valueBlank')
    },
  },
  methods: {
    init() {
      if (this.open) {
        this.setIsNameOverflowing()
        this.setIsLocationNameOverflowing()
      }
    },
    close() {
      this.$emit('close')
    },
    getTimeSinceDate(date) {
      return getRelativeTimeSince(date, this.$tc.bind(this))
    },
    async setIsNameOverflowing() {
      await Vue.nextTick()
      this.isNameOverflowing = isChildOverflowing(this.$refs.deviceName)
    },
    async setIsLocationNameOverflowing() {
      await Vue.nextTick()
      this.isLocationNameOverflowing = isChildOverflowing(this.$refs.deviceLocationName)
    },
  },
  watch: {
    open() {
      this.init()
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    padding: 16px;
  }
  .v-card__title {
    font-weight: 300;
    padding: 8px 24px 4px 16px;
  }
  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }
  .v-card__actions {
    margin-top: 24px;
  }
}
.v-list-item {
  .v-list-item__content {
    padding: 8px 0;
  }
  .v-list-item__title {
    font-size: 0.875rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    .device-name & {
      font-size: 1.425rem;
    }
  }
  .v-list-item__subtitle {
    font-size: 0.875rem;
    font-weight: 400;
    color: black;
  }
  &.device-name {
    .v-list-item__title,
    .v-list-item__subtitle {
      font-size: 1.125rem;
    }
    .v-list-item__subtitle {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

</style>

<template>
  <div>
    <popover-last-telemetry-timestamp :deviceId="deviceId" />
    <v-row no-gutters>
      <aqi-main-value :aqi="currentReading.aqi" />
      <v-spacer />
      <aqi-averages :deviceId="deviceId" ref="averages" />
    </v-row>
    <aqi-percent-changed :deviceId="deviceId" ref="percentChanged" />
    <aqi-description :aqi="currentReading.aqi" />
  </div>
</template>

<script>
// Content components
import popoverLastTelemetryTimestamp from '../../header/popoverLastTelemetryTimestamp.vue'
import aqiMainValue from './aqiMainValue.vue'
import aqiAverages from './aqiAverages.vue'
import aqiPercentChanged from './aqiPercentChanged.vue'
import aqiDescription from './aqiDescription.vue'

import { getMeasurementByType } from '@/components/devicePopover/utils'
import { measurementTypes } from '@/store/map/utils'
import { activeLastDay } from '@/services/device-status'

export default {
  components: {
    'popover-last-telemetry-timestamp': popoverLastTelemetryTimestamp,
    'aqi-main-value': aqiMainValue,
    'aqi-averages': aqiAverages,
    'aqi-percent-changed': aqiPercentChanged,
    'aqi-description': aqiDescription,
  },
  props: {
    deviceId: String,
  },
  computed: {
    lastTelemetryTimestamp: function () {
      return this.$store.getters['devices/getLastTelemetryTimestampByDeviceId'](
        this.deviceId
      )
    },
    sensorReadings: function () {
      return this.$store.getters['devices/getSensorReadingsByDeviceId'](this.deviceId)
    },
    currentReading: function () {
      let cr = { aqi: '- -', raw: '- -' }
      if (activeLastDay(this.lastTelemetryTimestamp)) {
        const measType = this.$store.getters['map/getMeasurementType']
        if (measType == measurementTypes.pm25) {
          cr.aqi = getMeasurementByType(
            this.sensorReadings,
            'pm2.5 aqi',
            undefined
          )
          cr.raw = getMeasurementByType(this.sensorReadings, 'pm2.5', undefined)
        }
        if (measType == measurementTypes.pm10) {
          cr.aqi = getMeasurementByType(
            this.sensorReadings,
            'pm10 aqi',
            undefined
          )
          cr.raw = getMeasurementByType(this.sensorReadings, 'pm10', undefined)
        }
      }
      return cr
    },
  },
  methods: {
    // This is used by the parent component refresh
    refresh: function () {
      this.$refs.averages.loadData()
      this.$refs.percentChanged.loadData()
      this.$store.dispatch('devices/getLatestTelemetryAndUpdate', this.deviceId)
    },
  },
}
</script>

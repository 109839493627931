<template>
  <div v-if="allowedActions.length">
    <v-menu offset-y v-model="isMenuOpened">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="menu-dropdown black--text"
          text
          v-bind="attrs"
          v-on="on"
          data-cy="devicePopoverDropdown"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list v-if="isMenuOpened" data-cy="devicePopoverMenuOptions">
        <!-- Device Info -->
        <v-list-item
          v-if="canPerformAction(actionsEnum.DEVICE_INFO)"
          @click.stop="openInfo"
          link
          data-cy="deviceInfoActivator"
        >
          <v-icon>mdi-information</v-icon>
          <v-list-item-title>
            {{ $t('map.popover.menu.deviceInfo') }}
          </v-list-item-title>
        </v-list-item>
        <!-- Device Settings -->
        <v-list-item
          v-if="canPerformAction(actionsEnum.SETTINGS)"
          @click.stop="openSettings"
          link
          data-cy="deviceSettingsActivator"
        >
          <v-icon>mdi-settings-box</v-icon>
          <v-list-item-title>
            {{ $t('map.popover.menu.deviceSettings') }}
          </v-list-item-title>
        </v-list-item>
        <!-- Device Alerts -->
        <v-list-item
          v-if="canPerformAction(actionsEnum.ALERT_SETUP)"
          @click.stop="openAlerts"
          link
          data-cy="deviceAlertsActivator"
        >
          <v-icon>mdi-bell</v-icon>
          <v-list-item-title>
            {{ $t('map.popover.menu.deviceAlerts') }}
          </v-list-item-title>
        </v-list-item>
        <!-- Device Sharing -->
        <v-list-item
          v-if="canPerformAction(actionsEnum.SHARING)"
          @click.stop="openSharing"
          link
          data-cy="deviceSharing"
        >
          <v-icon>mdi-share-variant</v-icon>
          <v-list-item-title>
            {{ $t('map.popover.menu.deviceSharing') }}
          </v-list-item-title>
        </v-list-item>
        <!-- History -->
        <v-list-item
          v-if="canPerformAction(actionsEnum.HISTORY)"
          @click.stop="openHistory"
          link
          data-cy="historyActivator"
        >
          <v-icon>mdi-chart-line</v-icon>
          <v-list-item-title>
            {{ $t('map.popover.menu.deviceHistory') }}
          </v-list-item-title>
        </v-list-item>
        <!-- Current Readings -->
        <v-list-item
          v-if="canPerformAction(actionsEnum.CURRENT_READINGS)"
          @click.stop="openCurrentReadings"
          link
          data-cy="currentReadingsActivator"
        >
          <v-icon>mdi-newspaper-variant-outline</v-icon>
          <v-list-item-title>
            {{ $t('manageDevices.actions.currentReadings') }}
          </v-list-item-title>
        </v-list-item>
        <!-- View on Map -->
        <view-on-map-menu
          v-if="canPerformAction(actionsEnum.VIEW_ON_MAP)"
          :deviceId="deviceId"
          :permissions="permissions"
          :accessLevel="accessLevel"
        />
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { access, canAccess } from '../../services/device-access'
import ViewOnMapMenu from './viewOnMapMenu'
import { getPermissionsByDeviceId } from '../../services/subscriptions'
import { SlugsEnum } from '../../permissions/SlugsEnum'
import { featureFlags } from '../../services/feature-flags'

export default {
  name: 'DeviceActionMenu',
  props: {
    deviceId: String,
    accessLevel: Number,
  },
  data() {
    return {
      isMenuOpened: false,
      actionsEnum: {
        DEVICE_INFO: 'DeviceInfo',
        SETTINGS: 'MenuSettings',
        ALERT_SETUP: 'MenuAlertSetup',
        SHARING: 'MenuSharing',
        HISTORY: 'MenuHistory',
        VIEW_ON_MAP: 'MenuViewOnMap',
        CURRENT_READINGS: 'MenuCurrentReadings'
      }
    }
  },
  components: {
    'view-on-map-menu': ViewOnMapMenu,
  },
  methods: {
    canPerformAction(slugName) {
      switch (slugName) {
        case this.actionsEnum.DEVICE_INFO:
          //As long as you own the device
          return canAccess(access.FULL, this.accessLevel)
        case this.actionsEnum.SETTINGS:
          //As long as you own the device
          return (
            canAccess(access.FULL, this.accessLevel)
          )
        case this.actionsEnum.ALERT_SETUP:
          //As long as you own the device
          //AND the device has the permission
          return (
            canAccess(access.FULL, this.accessLevel) &&
            this.hasDevicePermission(SlugsEnum.MenuAlertSetup)
          )
        case this.actionsEnum.SHARING: {
          //As long as you own the device
          //AND the device has the permission
          const deviceHasPermission = this.hasDevicePermission(SlugsEnum.MenuSharing)
          const sbFeatureEnabled = this.hasSBConnectedDevicePermissionFromFeatureFlag(featureFlags.EnabledSharingForSBAndConnectedDevices)
          return (
            canAccess(access.FULL, this.accessLevel) &&
            deviceHasPermission &&
            sbFeatureEnabled
          )
        }
        case this.actionsEnum.HISTORY: {
          const userHasPermission = this.hasUserPermission(SlugsEnum.MenuHistory)
          const deviceHasPermission = this.hasDevicePermission(SlugsEnum.MenuHistory)
          const sbDeviceHasPermission = this.hasSBConnectedDevicePermissionFromFeatureFlag(featureFlags.EnabledHistoryChartForConnectedDevices)
          return (
            ( // AA/BS/BSG permissions
              !this.isAConnectedDevice && !this.isAGatewayDevice &&
              canAccess(access.SHARED, this.accessLevel) &&
              userHasPermission &&
              deviceHasPermission
            ) ||
            ( // DT permissions
              this.isAConnectedDevice &&
              canAccess(access.FULL, this.accessLevel) &&
              userHasPermission &&
              deviceHasPermission &&
              sbDeviceHasPermission
            )
          )
        }
        case this.actionsEnum.VIEW_ON_MAP:
          //As long as you own the device OR the device has been shared with you
          //AND you as the user have the permission
          //AND the device is either shared with you OR if you own it then the device has the permission
          return (
            canAccess(access.SHARED, this.accessLevel) &&
            this.hasUserPermission(SlugsEnum.MenuViewOnMap) &&
            this.hasDevicePermission(SlugsEnum.MenuViewOnMap)
          )
        case this.actionsEnum.CURRENT_READINGS:
          // AA/BS/BSG permissions
          if (!this.isAConnectedDevice && !this.isAGatewayDevice) {
            return (
              canAccess(access.SHARED, this.accessLevel) &&
              // TO DO: SA-1621
              // Include a device permission validation
              // this.hasDevicePermission(SlugsEnum.MenuCurrentReadings) &&
              this.$store.getters['featureFlags/getFeatureFlagBySlug'](
                featureFlags.EnableManageDevicesCurrentReadings
              )
            )
          }

          // DT permissions
          return (
            this.isAConnectedDevice &&
            canAccess(access.FULL, this.accessLevel) &&
            this.hasUserPermission(SlugsEnum.MenuCurrentReadings) &&
            this.hasSBConnectedDevicePermissionFromFeatureFlag(
              featureFlags.EnabledCurrentReadingsForConnectedDevices
            )
          )
      }
      return false
    },
    hasUserPermission(slugName) {
      return this.$permissions.isAllowed(slugName)
    },
    hasDevicePermission(slugName) {
      return this.permissions.includes(slugName)
    },
    hasSBConnectedDevicePermissionFromFeatureFlag(flag) {
      if (this.isAConnectedDevice || this.isAGatewayDevice) {
        return this.$store.getters['featureFlags/getFeatureFlagBySlug'](
          flag
        )
      }
      return true // return true if it is not a device affected by the flags
    },
    openInfo() {
      this.openDialog('info')
    },
    openSettings() {
      this.openDialog('settings')
    },
    openAlerts() {
      this.openDialog('alerts')
    },
    openCurrentReadings() {
      this.openDialog('currentReadings')
    },
    openSharing() {
      this.openDialog('sharing')
    },
    openHistory() {
      this.openDialog('history')
    },
    openDialog(dialog) {
      this.$emit('openDialog', {
        dialog,
        deviceId: this.deviceId,
      })
    },
  },
  computed: {
    permissions() {
      return getPermissionsByDeviceId(this.deviceId)
    },
    allowedActions() {
      const slugNames = Object.values(this.actionsEnum)
      return slugNames.filter((n) => this.canPerformAction(n))
    },
    isAConnectedDevice() {
      return !!this.$store.getters[
        'devices/getConnectedSmartBridgeSerialByDeviceId'
      ](this.deviceId)
    },
    isAGatewayDevice() {
      return this.$store.getters[
        'devices/getModelByDeviceId'
      ](this.deviceId) === 'sbd1' // fine hardcoding this because it will go away when feature flags are removed
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-dropdown {
  min-width: 10px !important;
  padding: 0 0.4rem !important;
}
</style>

export default {
  getAccountsTableData(state) {
    return state.accounts
  },
  getAuth0Status(state) {
    return state.auth0Status
  },
  getSendGridStatus(state) {
    return state.sendgridStatus
  },
  getTwilioStatus(state) {
    return state.twilioStatus
  },
  getFlexeraStatus(state) {
    return state.flexeraStatus
  },
  getMongoDBStatus(state) {
    return state.mongodbStatus
  },
  getClearBladeStatus(state) {
    return state.clearbladeStatus
  },
  getApigeeStatus(state) {
    return state.apigeeStatus
  },
  getGoogleMapsStatus(state) {
    return state.googlemapsStatus
  },
  getGoDaddyStatus(state) {
    return state.godaddyStatus
  },
}

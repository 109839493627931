<template>
  <base-icon
    :showIcon="showIcon"
    :icon="batteryIcon"
    :iconColor="color"
    imgAlt="Battert Icon"
    :iconText="iconText"
    tooltipContentClass="battery-status-tooltip"
    dataCy="batteryStatus"
  />
</template>

<script>
import BaseIcon from './BaseIcon.vue'
export default {
  name: 'BatteryStatus',
  props: {
    deviceId: { type: String, default: '' },
  },
  components: {
    'base-icon': BaseIcon,
  },
  computed: {
    smartBridgeDeviceId() {
      return this.$store.getters['devices/getConnectedSmartBridgeIdByDeviceId'](
        this.deviceId
      )
    },
    batteryChargeRounded() {
      if (typeof this.batteryRemaining !== 'number') {
        return 0
      }
      const roundedResult = Math.round(this.batteryRemaining / 10) * 10
      if (roundedResult >= 100) {
        return 100
      }
      if (roundedResult <= 0) {
        return 0
      }
      return roundedResult
    },
    isDeviceConnectedToSmartBridge() {
      return !!this.smartBridgeDeviceId
    },
    isThereABatteryState() {
      return this.$store.getters['devices/getIsThereAValue'](
        this.deviceId,
        'batteryInstalled'
      )
    },
    isDeviceConnected() {
      return this.$store.getters['devices/getIsConnectedByDeviceId'](
        this.deviceId
      )
    },
    isSmartBridgeConnected() {
      return this.$store.getters['devices/getIsConnectedByDeviceId'](
        this.smartBridgeDeviceId
      )
    },
    batteryRemaining() {
      return this.$store.getters['devices/getBatteryRemainingByDeviceId'](
        this.deviceId
      )
    },
    isBatteryCharging() {
      return this.$store.getters['devices/getIsBatteryChargingByDeviceId'](
        this.deviceId
      )
    },
    isBatteryInstalled() {
      return this.$store.getters['devices/getIsBatteryInstalledByDeviceId'](
        this.deviceId
      )
    },
    iconText() {
      if (!this.isThereABatteryState) {
        return ''
      }
      if (!this.isBatteryInstalled) {
        return this.$t('device.icons.batteryStatus.noBatteryInstalled')
      }
      return `${this.batteryRemaining}%`
    },
    batteryIcon() {
      if (!this.isBatteryInstalled) {
        return 'mdi-battery-off-outline'
      }
      if (this.isBatteryCharging) {
        return 'mdi-battery-charging'
      }
      return this.batteryIconFromChargeRounded
    },
    batteryIconFromChargeRounded() {
      let icon = ''
      const chargeRounded = this.batteryChargeRounded
      switch (chargeRounded) {
        case 0: {
          icon = 'mdi-battery-outline'
          break
        }
        case 100: {
          icon = 'mdi-battery'
          break
        }
        default: {
          icon = `mdi-battery-${chargeRounded}`
        }
      }
      return icon
    },
    color() {
      return this.batteryRemaining <= 20 && !this.isBatteryCharging
        ? 'var(--tsi-color--accent-error)'
        : 'grey'
    },

    showIcon() {
      return (
        this.isDeviceConnectedToSmartBridge &&
        this.isThereABatteryState &&
        this.isSmartBridgeConnected &&
        this.isDeviceConnected
      )
    },
  },
}
</script>

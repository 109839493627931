<template>
  <base-dialog
    :open="open"
    @close="close"
    data-cy="subscriptionExpiredDialog"
    :fullscreen="$vuetify.breakpoint.xs"
    :persistentOverlay="true"
  >
    <template v-slot:header>
      <v-card-title data-cy="subscriptionExpiredHeader">
        {{ $t('subscriptionExpiredDialog.title') }}
      </v-card-title>
    </template>
    <v-card-text>{{ $t('subscriptionExpiredDialog.message') }}</v-card-text>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="close"
        class="primary white--text"
        text
        data-cy="subscriptionExpiredClose"
        >{{ $t('buttons.done') }}</v-btn
      >
    </template>
  </base-dialog>
</template>

<script>
import dialog from './Dialog'

export default {
  name: 'SubscriptionExpiredDialog',
  props: ['open'],
  components: { 'base-dialog': dialog },
  data() {
    return {}
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    padding: 16px;
  }
  .v-card__title {
    font-weight: 300;
    padding: 8px 24px 16px 16px;
  }
  .v-card__subtitle {
    padding: 8px 24px 16px 16px;
  }
  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }
  .v-card__actions {
    margin-top: 24px;
  }
}
</style>

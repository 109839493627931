export const serialHasValidCharacters = (deviceModelNumber, serialNumber) => {
  // must pass in params
  if(!deviceModelNumber || !serialNumber) {
    return false
  }
  // if 1st character of the model is a number then digits regex else alphanumeric regex
  return !isNaN(deviceModelNumber.charAt(0)) ? /^[0-9]*$/.test(serialNumber) : /^[0-9a-zA-Z]*$/.test(serialNumber)
}

export const serialHasValidModelNumber = (deviceModelNumber, serialNumber) => {
  return serialNumber.toLowerCase().includes(deviceModelNumber)
}

export const serialHasValidLength = (serialNumber, serialLength) => {
  return serialNumber.length === serialLength
}

export const serialIsValid = (
  deviceModelNumber,
  serialNumber,
  serialLength
) => {
  return (
    serialHasValidLength(serialNumber, serialLength) &&
    serialHasValidCharacters(deviceModelNumber, serialNumber) &&
    serialHasValidModelNumber(deviceModelNumber, serialNumber)
  )
}

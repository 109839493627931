<template>
  <component
    :v-if="componentName"
    :is="componentName"
    :widgetId="widgetId"
    :widgetType="widgetType"
    :widgetMaxDevices="widgetMaxDevices"
    :deviceIds="deviceIds"
    :width="width"
    :height="height"
    :settings="settings"
    :isDialog="isDialog"
    @close="close"
    @removeWidget="removeWidget"
    @refreshDashboard="refreshDashboard"
  />
</template>

<script>
import PopoverWidget from './content/PopoverWidget.vue'
import ContinuousMonitoringAndStudyWidget from './content/ContinuousMonitoringAndStudyWidget.vue'

export default {
  name: 'CurrentReadingsWidget',
  components: {
    PopoverWidget,
    ContinuousMonitoringAndStudyWidget,
  },
  props: {
    widgetId: String,
    widgetType: String,
    widgetMaxDevices: Object,
    deviceIds: {
      type: Array,
      default() {
        return []
      }
    },
    width: Number,
    height: Number,
    settings: Object,
    isDialog: Boolean,
  },
  computed: {
    deviceId() {
      return this.deviceIds?.[0] || ''
    },
    serial() {
      return this.$store.getters['devices/getSerialByDeviceId'](this.deviceId)
    },
    isDeviceConnectedToSmartBridge() {
      return !!this.$store.getters[
        'devices/getConnectedSmartBridgeIdByDeviceId'
      ](this.deviceId)
    },
    componentName() {
      if (this.serial) { // If device information has finished saving in Vuex
        return this.isDeviceConnectedToSmartBridge
          ? 'ContinuousMonitoringAndStudyWidget'
          : 'PopoverWidget'
      }

      return ''
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    removeWidget(options) {
      this.$emit('removeWidget', options)
    },
    refreshDashboard() {
      this.$emit('refreshDashboard')
    },
  },
}
</script>

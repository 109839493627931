<template>
  <div class="icon-message-wrapper">
    <v-icon :color="color">
      {{ icon }}
    </v-icon>
    <span>
      {{ message }}
    </span>
  </div>
</template>

<script>
export default {
  name: "IconWithMessage",
  props: {
    color: String,
    icon: String,
    message: String
  },
}
</script>

<style lang="scss" scoped>
.icon-message-wrapper {
  padding: 0.5rem 1rem 0 1rem;

  .v-icon {
    font-size: 2rem;
  }

  @media (max-width: 599px),
  (max-height: 770px) {
    font-size: 0.8rem;
  }
}
</style>

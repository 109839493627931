<template>
  <v-row no-gutters>
    <div class="descriptive-text">
      <span v-if="aqi != $t('map.popover.valueBlank')">
        {{ $t(`map.popover.descriptions.aqi_${getAQIName(aqi)}.range`) }}:
      </span>
      {{ $t(`map.popover.descriptions.aqi_${getAQIName(aqi)}.message`) }}
    </div>
  </v-row>
</template>

<script>
import { getAQIName as getAqiName } from '@/services/map'

export default {
  props: ['aqi'],
  methods: {
    getAQIName: function (aqi) {
      return getAqiName(aqi)
    },
  },
}
</script>

<style lang="scss" scoped>
.descriptive-text {
  padding: 8px;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  min-height: 32px;
}

.widget-xxs,
.widget-xs,
.widget-sm {
  .descriptive-text {
    font-size: 12px;
    line-height: 12px;
  }
}
</style>

import { getMapSettingsObject, getMapLocationObject } from './utils'

const storedMapSettings = getMapSettingsObject()
const storedMapLocation = getMapLocationObject()

export default {
  movingMode: false,
  deviceToMove: null,
  deviceViewMode: false,
  deviceToView: null,
  location: {
    center: storedMapLocation.center,
    zoom: storedMapLocation.zoom,
    lastZoom: storedMapLocation.lastZoom,
  },
  shownSettings: {
    pinMeasurementType: storedMapSettings.pinMeasurementType,
    deviceModels: storedMapSettings.deviceModels,
    showMine: storedMapSettings.showMine,
    showPublic: storedMapSettings.showPublic,
    showShared: storedMapSettings.showShared,
    showIndoor: storedMapSettings.showIndoor,
    showOutdoor: storedMapSettings.showOutdoor,
  },
}

export default {
  getMeasurementType: (state) => {
    return state.shownSettings.pinMeasurementType
  },
  getDeviceModels: (state) => {
    return state.shownSettings.deviceModels
  },
  getShowMine: (state) => {
    return state.shownSettings.showMine
  },
  getShowPublic: (state) => {
    return state.shownSettings.showPublic
  },
  getShowShared: (state) => {
    return state.shownSettings.showShared
  },
  getShowIndoor: (state) => {
    return state.shownSettings.showIndoor
  },
  getShowOutdoor: (state) => {
    return state.shownSettings.showOutdoor
  },
}

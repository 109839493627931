<template>
  <tbody>
    <tr v-for="item in items" :key="item.id">
      <td class="mobile-list">
        <div class="item-title">
          <account-action-menu
            :accountId="item.accountId"
            :userId="item.userId"
            :sapId="item.sapAcctId"
            :email="item.email"
          />
          <div class="account-email">
            <span>{{ item.email }}</span>
          </div>
        </div>
        <ul class="flex-content">
          <li class="flex-item">
            <span> {{ $t('accountTable.tableHeaders.username') }}:&nbsp; </span>
            {{ item.username }}
          </li>
          <li class="flex-item">
            <span>
              {{ $t('accountTable.tableHeaders.accountID') }}:&nbsp;
            </span>
            {{ item.accountId }}
          </li>
          <li class="flex-item">
            <span>
              {{ $t('accountTable.tableHeaders.userSubscription') }}:&nbsp;
            </span>
            {{ item.subscription }}
          </li>
          <li class="flex-item">
            <span>
              {{ $t('accountTable.tableHeaders.sapAcctId') }}:&nbsp;
            </span>
            {{ item.sapAcctId }}
          </li>
          <li class="flex-item">
            <div v-if="item.lastLogIn == 0" class="invalid-date">
              <span> {{ $t('accountTable.tableHeaders.logins') }}:&nbsp; </span>
            </div>
            <div v-else class="valid-date">
              <span> {{ $t('accountTable.tableHeaders.logins') }}:&nbsp; </span>
              {{ new Date(item.lastLogIn).toLocaleString() }}
            </div>
          </li>
        </ul>
      </td>
    </tr>
  </tbody>
</template>
<script>
import AccountActionMenu from './accountActionMenu.vue'
export default {
  name: 'AccountTableBodyMobile',
  props: {
    items: Array,
  },
  components: {
    'account-action-menu': AccountActionMenu,
  },
}
</script>
<style lang="scss" scoped>
.v-data-table td.mobile-list {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 48px;

  .item-title {
    display: flex;
    line-height: 36px;
    padding-top: 8px;
    .account-email {
      margin-left: 8px;
      font-weight: 700;
    }
  }
  ul {
    padding-top: 8px;
    padding-bottom: 16px;
    padding-left: 12px;
    list-style-type: none;
    .item-title {
      font-weight: 500;
      font-size: 15px;
    }
  }
  .mobile-action-menu-container {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
</style>

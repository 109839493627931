<template>
  <div v-if="measurements" class="measurement-list-wrapper">
    <popover-measurement
      v-for="measurement in measurements"
      :key="measurement.label"
      :reading="measurement.reading"
      :label="measurement.label"
      :unitsLabel="measurement.unitsLabel"
      :imageSrc="measurement.imageSrc"
      :showInactiveReading="measurement.showInactiveReading"
      :showRealValue="true"
      :customClass="measurement.customClass"
    />
  </div>
</template>

<script>
import popoverMeasurement from '../popoverMeasurement.vue'

export default {
  name: 'MeasurementList',
  components: {
    'popover-measurement': popoverMeasurement,
  },
  props: {
    measurements: Array,
  },
}
</script>

<style lang="scss" scoped>
.measurement-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0 0 7%;

  ::v-deep .measurement {
    width: 100%;
    max-width: 36%;
  }
}
</style>

import { UnitOfMeasure, getInstance as getUnitsInstance } from "@fusion/units"

const STANDARD_PRESSURE_NAME = 'baro_inhg'
const supportedPressureUnits = [UnitOfMeasure.INCH_MERCURY, UnitOfMeasure.HECTOPASCAL, UnitOfMeasure.MILLIBAR]
const supportedPMUnits = [UnitOfMeasure.MICROGRAMS_PER_CUBIC_METER, UnitOfMeasure.MILLIGRAMS_PER_CUBIC_METER]

export const tempUnits = {
  celsius: 0,
  fahrenheit: 1,
  kelvin: 2,
}

export const getUnitName = (type, unit) => {
  if (type === 'temp') {
    if (unit == tempUnits.celsius) return 'Celsius'
    if (unit == tempUnits.fahrenheit) return 'Fahrenheit'
    if (unit == tempUnits.kelvin) return 'Kelvin'
  }

  return ''
}

export const convertCelsiusToFahrenheit = (degreesCelsius) => {
  if (typeof degreesCelsius === 'number') {
    return Math.round(10 * ((degreesCelsius * 9) / 5 + 32)) / 10
  } else {
    return degreesCelsius
  }
}

export const convertPMReading = (reading, userSetting, deviceType, pmReadingType) => {
  if (supportedPMUnits.includes(userSetting) && reading?.value !== undefined ) {
    const { value, unit, resolution } = getMetrology(reading, pmReadingType, deviceType)
    const unitsLibrary = getUnitsInstance()
    return unitsLibrary.convertUnit(unit, userSetting, value, resolution)
  }
}

export const convertPressureReading = (reading, userSetting, deviceType) => {
  if (supportedPressureUnits.includes(userSetting) && reading?.value !== undefined ) {
    const { value, unit, resolution } = getMetrology(reading, STANDARD_PRESSURE_NAME, deviceType)
    const unitsLibrary = getUnitsInstance()
    return unitsLibrary.convertUnit(unit, userSetting, value, resolution)
  }
}

const getMetrology = (reading, measurementType, deviceType = '') => {
  if (reading.unit && reading.resolution) {
    return reading
  }
  // use Units library getMetrology if reading is not in UDS format
  const unitsLibrary = getUnitsInstance()
  const metrology = unitsLibrary.getMetrology(deviceType, measurementType)
  return {
    value: reading.value,
    unit: metrology.unit,
    resolution: metrology.resolution
  }
}

export const roundMeasurementToTenths = (measurement) => {
  if (typeof measurement === 'number') {
    return Math.round((measurement + Number.EPSILON) * 10) / 10
  } else {
    return measurement
  }
}

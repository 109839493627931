import store from '@/store'
import { UnitOfMeasure } from '@fusion/units'

export function AddUserSetting(settingObj) {
  const settings = {}
  settings['bluesky'] = settingObj
  return settings
}

export const getPMSelectedUnit = () => {
  return (
    store.state.userProfile?.settings?.pmUnit ||
    UnitOfMeasure.MICROGRAMS_PER_CUBIC_METER
  )
}

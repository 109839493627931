<template>
  <div class="measurement-container">
    <measurements-small v-if="showSmallMeasurements" v-bind="$props" />
    <measurements-large v-else v-bind="$props" />
  </div>
</template>

<script>
import measurementsLarge from './layouts/measurementsLarge.vue'
import measurementsSmall from './layouts/measurementsSmall.vue'

const smallOnlyReadings = [
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13
]

export default {
  props: {
    deviceId: String,
    permissions: Array,
    sensorReadings: Array,
  },
  components: {
    'measurements-large': measurementsLarge,
    'measurements-small': measurementsSmall,
  },
  computed: {
   model() {
      return this.$store.getters['devices/getModelByDeviceId'](this.deviceId)
    },
    submodel() {
      return this.$store.getters['devices/getSubmodelByDeviceId'](this.deviceId)
    },
    supportedMeasurements() {
      return this.$store.getters['devicemodels/getSupportedMeasurements'](
        this.model,
        this.submodel
      )
    },
    showSmallMeasurements() {
      if (!this.sensorReadings) {
        return false
      }
      return this.supportedMeasurements.some((r) => smallOnlyReadings.includes(r))
    },
  },
}
</script>

<style lang="scss" scoped>
.measurement-container {
  margin-top: -4px;
  margin-bottom: 8px;
  margin-left: 8px;
}
</style>

<template>
  <div>
    <base-dialog @close="close" :open="open" :persistentOverlay="true" :max-width="550"
      :customCss="`register-multiple-devices ${step}`">
      <template v-slot:header>
        <v-card-title>
          {{ dialogTitle }}
        </v-card-title>
        <v-card-subtitle>
          {{ dialogSubTitle }}
          <a v-if="downloadCsv" href="csv/Bulk device registration import template.csv"
            :aria-label="$t('registerMultipleDevices.downloadCsvFileAriaLabel')" class="download-link" download>
            {{ $t('registerMultipleDevices.downloadCsvFileText') }}
          </a>
        </v-card-subtitle>
      </template>
      <dialog-content :cssStyle="cssStyle">
        <template v-slot:content>
          <slot name="content"></slot>
        </template>
      </dialog-content>
      <template v-slot:actions>
        <v-btn v-if="!removeCancelButton" @click="close" data-cy="registerMultipleClose" outlined class="black--text" text>
          {{ $t('buttons.cancel')}}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn data-cy="registerMultipleActionButton" class="primary white--text" text :disabled="disableActionButton"
          @click="onClickActionButton()">
          <div class="trailing-icon-text">{{ dialogActionButtonText }}</div>
        </v-btn>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import dialog from '../../../dialogs/Dialog.vue'
import dialogContent from '../dialog/dialogContent.vue';

export default {
  name: 'RegisterMultipleDevicesDialog',
  props: {
    title: String,
    subTitle: String,
    downloadCsv: Boolean,
    open: Boolean,
    disableActionButton: Boolean,
    actionButtonText: String,
    onClickActionButton: Function,
    cssStyle: String,
    step: String,
    removeCancelButton: Boolean
  },
  components: {
    'base-dialog': dialog,
    'dialog-content': dialogContent,
  },
  computed: {
    dialogTitle() {
      return this.title || this.$t('registerMultipleDevices.title')
    },
    dialogSubTitle() {
      return this.subTitle || this.$t('registerMultipleDevices.subtitle')
    },
    dialogActionButtonText() {
      return this.actionButtonText || this.$t('registerMultipleDevices.addDevicesButtonText')
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.register-multiple-devices {
  .__vuescroll {
    height: fit-content !important;
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/global-variables.scss';

.v-dialog {
  .v-card__title {
    font-weight: 300;
  }

  .v-card__subtitle {
    padding: 16px 24px;
  }

  .v-btn {
    min-width: 120px !important;

    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }
}

.download-link {
  text-decoration: underline;
  color: $deep-sky-blue-color;
  font-weight: 500;
  cursor: pointer;
}

.register-multiple-devices.validateCsv,
.register-multiple-devices.registerDevices {
  .v-card__subtitle {
    text-align: center;
    font-size: 1.2rem;
    padding-top: 2.4rem;
    color: rgba(0, 0, 0, 0.7);
  }

}

.register-multiple-devices.showRegistrationResult {
  .v-card__subtitle {
    font-size: 1.2rem;
    color: $black-color;
  }
}
</style>

const HIGH_RESOLUTION_ZOOM_LEVEL = 16

export default {
  startMoving({ commit, state }, device) {
    commit('setLastZoom', state.location.zoom)
    commit('setZoom', HIGH_RESOLUTION_ZOOM_LEVEL)
    commit('setMovingPin', device)
    commit('setMovingMode', true)
    commit('updateLocation')
  },
  stopMoving({ commit }) {
    commit('setMovingMode', false)
  },
  cancelMove({ commit, state }) {
    commit('setZoom', state.location.lastZoom)
    commit('setMovingMode', false)
    commit('updateLocation')
  },
  setZoom({ commit }, zoom) {
    commit('setZoom', zoom)
    commit('updateLocation')
  },
  setCenter({ commit }, center) {
    commit('setCenter', center)
    commit('updateLocation')
  },
  updateSettings({ commit }, data) {
    commit('updateSettings', data)
  },
  startDeviceViewMode({ commit, state }, device) {
    commit('setLastZoom', state.location.zoom)
    commit('setZoom', HIGH_RESOLUTION_ZOOM_LEVEL)
    commit('setDeviceViewMode', true)
    commit('setDeviceToView', device)
    commit('updateLocation')
  },
  stopDeviceViewMode({ commit }) {
    commit('setDeviceViewMode', false)
    commit('setDeviceToView', null)
  },
}

import { HTTPStatus, getInstance } from '../../api'
import {
  getMeasurementsFormattedResponse,
  getSensorStatusFormattedResponse,
  getDeviceStatusFormattedResponse,
  getSensorsV1Format,
} from '../../store/devices/helper'

/**
 * getAllDevices
 * Returns an array of state objects to dispatch to setState and an array of devices to set
 * @returns { state: [], devices: {}, activation: {} }
 */
export async function getAllDevices(includePublic = true) {
  const apiService = getInstance()
  const devs = await apiService.getDevices(includePublic)

  return await unpackDevices(devs)
}

export async function unpackDevices(devs) {
  if (devs.status === HTTPStatus.OK) {
    const body = await devs.json()
    const devices = {}
    const devStates = []
    const devActivations = []
    const devIotCoreData = []

    for (const dev of body) {
      const { device, deviceId, state, activation, iotCoreData } =
        unpackDevice(dev)
      if (state) {
        devStates.push(state)
      }
      if (activation) {
        devActivations.push(activation)
      }
      if (iotCoreData) {
        devIotCoreData.push(iotCoreData)
      }
      devices[deviceId] = device
    }
    return {
      devices: devices,
      state: devStates,
      activation: devActivations,
      iotCoreData: devIotCoreData,
    }
  }

  throw new Error(`HTTP Request failed with status ${devs.status}`)
}

export function unpackDevice(dev) {
  let state = undefined
  let device = {}
  let activation = {}
  let iotCoreData = {}

  let deviceId = null
  if (dev?.Device?.deviceId) {
    deviceId = dev.Device.deviceId

    const stateObj = {
      deviceId: deviceId,
      state: dev.State || null,
    }
    state = stateObj
    if (dev.Activations) {
      const activationObj = {
        deviceId,
        activation: dev.Activations,
      }
      activation = activationObj
    }
    if (dev.iot_core_data) {
      const iotCoreDataObj = {
        deviceId,
        iotCoreData: dev.iot_core_data,
      }
      iotCoreData = iotCoreDataObj
    }
  }

  let sensorReadings = []
  let sensorStatus = []

  if (dev?.Telemetry?.sensors) {
    const sensors = getSensorsV1Format(dev.Telemetry.sensors)
    sensors.forEach((sensor) => {
      sensorReadings = sensorReadings.concat(
        getMeasurementsFormattedResponse(sensor.measurements)
      )
      sensorStatus = sensorStatus.concat(
        getSensorStatusFormattedResponse(sensor)
      )
    })
  }

  const telemetry = dev.Telemetry || []
  const deviceStatus = telemetry
    ? getDeviceStatusFormattedResponse(telemetry)
    : []
  const meta = dev.Device || {}
  const attributes = dev.Attributes || {}
  const coords = {
    lat: meta.latitude,
    lng: meta.longitude,
  }
  const deviceData = {
    coords,
    accountId: meta.accountId,
    deviceId: deviceId,
    deviceStatus: deviceStatus,
    model: meta.model,
    submodel: attributes.submodel,
    serial: meta.serial,
    indoor: meta.isIndoor,
    isPublic: meta.isPublic,
    isShared: meta.isShared,
    privacyZone: meta.privacyZone,
    name: meta.friendlyName,
    readings: sensorReadings,
    sensorStatus: sensorStatus,
    telemetry: telemetry,
  }

  deviceData.timestamp =
    telemetry && telemetry.timestamp ? telemetry.timestamp : null

  device = deviceData

  return { state, device, deviceId, activation, iotCoreData }
}

export async function getModelsMetaData() {
  const apiService = getInstance()
  const response = await apiService.getModelsMetaData()
  if (response.status === HTTPStatus.OK) {
    const data = await response.json()
    return unpackModelsMetaData(data)
  }
  throw new Error(`HTTP Request failed with status ${response.status}`)
}

/**
 * Flattens translates the telemetryFields array into objects for each model using reading name as the key
 * @param {models metadata} metaData
 * @returns object { model: { telemetryFields: { sensor1: {}, sensor2: {} }, ... }}
 */
export function unpackModelsMetaData(metaData) {
  const metaDataObject = JSON.parse(JSON.stringify(metaData))
  for (const model in metaDataObject) {
    if (metaDataObject[model].telemetryFields) {
      metaDataObject[model].telemetryFields = metaDataObject[
        model
      ].telemetryFields.reduce((allFields, currentField) => {
        allFields[currentField.name] = currentField
        return allFields
      }, {})
    } else {
      metaDataObject[model].telemetryFields = {}
    }
  }

  return metaDataObject
}

export async function getUserDevices() {
  const apiService = getInstance()
  const devs = await apiService.getUserDevices()

  return await unpackUserDevices(devs)
}

export async function unpackUserDevices(devs) {
  if (devs.status !== HTTPStatus.OK) {
    throw new Error(`HTTP Request failed with status ${devs.status}`)
  }

  const body = await devs.json()
  const devices = {}
  const activations = []

  if (body) {
    for (const device of body) {
      if (device?.id) {
        devices[device.id] = device

        if (device.activations) {
          activations.push({
            deviceId: device.id,
            activation: device.activations,
          })
        }
      }
    }
  }

  return { devices, activations }
}

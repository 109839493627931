<template>
  <div class="subscriptions-cancel-actions-row">
    <v-btn
      v-if="isCancelling === false"
      @click="close"
      outlined
      data-cy="cancelSubscriptionCloseButton"
      class="black--text"
      text
      >{{ $t('buttons.close') }}</v-btn
    >
    <v-spacer></v-spacer>
    <div
      v-if="isCancelling"
      id="progressSpinner"
      class="text-center emptyMessage"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-btn
      v-else
      @click="cancelSubscriptionConfirm"
      class="error white--text"
      text
      data-cy="cancelSubscriptionConfirmButton"
      >{{ $t('subscriptionCancel.cancelCard.actionButton') }}</v-btn
    >
  </div>
</template>

<script>
import { HTTPStatus } from '../../api'
import {
  messageTypes,
  SNACKBAR_STATIC_DURATION_MS,
} from '../../services/notifications'
export default {
  name: 'SubscriptionCancelActions',
  props: {
    activationId: String,
  },
  data() {
    return {
      isCancelling: false,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async cancelSubscriptionConfirm() {
      this.isCancelling = true
      const resp = await this.$api.patchCancelSubscription(this.activationId)
      let message = ''
      if (resp.status === HTTPStatus.OK) {
        message = {
          text: this.$t('subscriptionCancel.cancelCard.toasts.cancelSuccess'),
          type: messageTypes.SUCCESS,
          timeout: SNACKBAR_STATIC_DURATION_MS,
        }
        await this.$store.dispatch('subscriptions/updateSubscriptions', {
          auth: this.$auth,
          api: this.$api,
          devices: this.$auth.isAuthenticated
            ? this.$store.state.devices.allDevices
            : [],
        })
      } else {
        message = {
          text: this.$t('subscriptionCancel.cancelCard.toasts.cancelFailure'),
          type: messageTypes.ERROR,
          timeout: SNACKBAR_STATIC_DURATION_MS,
        }
      }
      this.isCancelling = false
      this.$store.dispatch('notifications/addMessage', message)
      this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.subscriptions-cancel-actions-row {
  display: flex;
  width: 100%;

  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }
}
#progressSpinner {
  width: 100%;
}
</style>

<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn class="ellipsis" text v-on="on" data-cy="accountPopoverDropdown">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item :disabled="!hasLogInAsPermission" @click="openLogInAs" link data-cy="logInAsActionItem">
          <v-icon>mdi-arrow-up-bold-box-outline</v-icon>
          <v-list-item-title>
            {{ $t('accountTable.actionMenu.logInAs') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { Buffer } from 'buffer'
import { SlugsEnum } from '../../permissions/SlugsEnum'

export default {
  name: 'AccountActionMenu',
  props: {
    accountId: String,
    userId: String,
    sapId: String,
    email: String
  },
  data() {
    return {}
  },
  computed: {
    hasLogInAsPermission() {
      return this.$permissions?.isAllowed(SlugsEnum.LogInAs) || false
    },
  },
  methods: {
    openLogInAs() {
      try {
        const encodedUserData = Buffer.from(JSON.stringify({
          accountId: this.accountId,
          userId: this.userId,
          sapId: this.sapId,
          email: this.email
        })).toString('base64')

        const logInAsRoute = this.$router.resolve({name: 'home-private', query: {
          user: encodedUserData,
        }})

        window.open(logInAsRoute.href)
      } catch(e) {
        // It doesn't open a new tab
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.ellipsis {
  min-width: 10px !important;
  padding: 0 0.4rem !important;
}
</style>

<template>
  <v-row no-gutters  :data-cy="`${showSubscriptionType}SubscriptionRow`">
    <v-col cols="12" class="subscription-title">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ title }}:
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col v-if="showSubscriptionDetails" cols="12" sm="6">
      <v-list class="subscription-content">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('map.deviceInfo.subscriptionTier') }}:
            </v-list-item-title>
            <v-list-item-subtitle data-cy="subscriptionTier">
              {{ subscriptionDetails.subscriptionTier }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('manageSubscriptions.tableHeaders.renewalDate') }}:
            </v-list-item-title>
            <v-list-item-subtitle data-cy="subscriptionRenewalDate">
              {{ subscriptionDetails.renewalDate }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col v-if="showSubscriptionDetails" cols="12" sm="6">
      <v-list class="subscription-content">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('manageSubscriptions.tableHeaders.activationDate') }}:
            </v-list-item-title>
            <v-list-item-subtitle data-cy="subscriptionActivationDate">
              {{ subscriptionDetails.activationDate }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('manageSubscriptions.tableHeaders.endDate') }}:
            </v-list-item-title>
            <v-list-item-subtitle data-cy="subscriptionEndDate">
              {{ subscriptionDetails.endDate }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col v-if="!showSubscriptionDetails" cols="12" sm="6">
      <v-list class="not-applicable-subscription">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title data-cy="notAvailableSubscription">
              {{ $t('manageDevices.notAvailable') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import { getDeviceSubscriptionFormattedDetails } from '../../../../helpers/subscriptions/getSubscriptionDataHelper'
import { renewalDateStatuses, subscriptionTypes } from '../../../../store/subscriptions/utils'

export default {
  name: 'SubscriptionDetailsRow',
  props: {
    deviceId: String,
    showSubscriptionType: String,
  },
  computed: {
    title() {
      switch (this.showSubscriptionType) {
        case subscriptionTypes.WEBSITE:
          return this.$t('map.deviceInfo.subscriptions.tsiLink')
        case subscriptionTypes.DATA_SERVICES:
          return this.$t('map.deviceInfo.subscriptions.dataService')
        default:
          return this.$t('manageDevices.notAvailable')
      }
    },
    model() {
      return this.$store.getters['devices/getModelByDeviceId'](this.deviceId)
    },
    serial() {
      return this.$store.getters['devices/getSerialByDeviceId'](this.deviceId)
    },
    subscriptionId() {
      switch (this.showSubscriptionType) {
        case subscriptionTypes.WEBSITE:
          return this.$store.getters['subscriptions/getDeviceSubscriptionByDeviceSerial'](this.serial)
          || this.$store.getters['subscriptions/getDeviceFreeTierByDeviceSerial'](this.serial)
          || ''
        case subscriptionTypes.DATA_SERVICES:
          return this.$store.getters['subscriptions/getDeviceDataServicesByDeviceSerial'](this.serial) || ''
        default:
          return ''
      }
    },
    subscription() {
      if (this.subscriptionId) {
        return this.$store.getters['subscriptions/getSubscriptionById'](this.subscriptionId)
      }
      return null
    },
    subscriptionDetails() {
      const details = getDeviceSubscriptionFormattedDetails(this.subscription, `${this.model}-${this.serial}`)
      return this.formatSubscriptionDetails(details)
    },
    showSubscriptionDetails() {
      return !!this.subscription
    }
  },
  methods: {
    getRenewalDateText(renewalDate) {
      if (renewalDate && renewalDate.status) {
        switch (renewalDate.status) {
          case renewalDateStatuses.Canceled:
            return this.$t('manageSubscriptions.canceled')
          case renewalDateStatuses.CancellationPending:
            return this.$t('manageSubscriptions.cancellationPending')
          case renewalDateStatuses.AutoRenewal:
            return renewalDate.date || this.$t('manageSubscriptions.notApplicable')
          default:
            return this.$t('manageSubscriptions.notApplicable')
        }
      }

      return this.$t('manageSubscriptions.notApplicable')
    },
    formatSubscriptionDetails(details) {
      details.subscriptionTier = details.subscriptionTier || this.$t('manageSubscriptions.notApplicable')
      details.activationDate = details.activationDate || this.$t('manageSubscriptions.notActivated')
      details.endDate = details.endDate || this.$t('manageSubscriptions.notApplicable')
      details.renewalDate = this.getRenewalDateText(details.renewalDate)
      return details
    },
  },
}
</script>

<style lang="scss" scoped>
.subscription-content {
  padding-top: 0;

  .v-list-item__title ::v-deep {
    font-size: 0.875rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }

  .v-list-item__subtitle ::v-deep {
    font-size: 0.875rem;
    font-weight: 400;
    color: black;
  }
}

.v-list-item__content ::v-deep {
  padding: 8px 0;
}

.subscription-title {

  .v-list,
  .v-list-item__content ::v-deep {
    padding-bottom: 0;
  }
}

.not-applicable-subscription {
  padding-top: 0;

  .v-list-item__content ::v-deep {
    padding-top: 0;
  }

  .v-list-item__title {
    font-size: 0.875rem;
    font-weight: 400;
    color: black;
  }
}
</style>

<template>
  <div>
    <base-dialog
      @close="close"
      :open="open"
      :error="dialogError"
      :urlError="dialogUrlError"
      :urlErrorMsg="dialogUrlErrorMsg"
      :max-width="800"
      :persistentOverlay="true"
      customCss="register-single-device"
    >
      <template v-slot:header>
        <v-card-title>
          <v-icon class="header-icon">mdi-map-marker-plus</v-icon>
          {{ $t('activateDevice.title') }}
        </v-card-title>
        <v-card-subtitle>{{ $t('activateDevice.subtitle') }}</v-card-subtitle>
      </template>
      <v-form ref="form" data-cy="registerDeviceCard">
        <v-row no-gutters>
          <v-col cols="12">
            <v-card class="pa-2" flat>
              <div class="section-padding">
                <device-name
                  v-model="deviceName"
                  :rules="[rules.required]"
                  :cyTag="'deviceName'"
                ></device-name>
              </div>
            </v-card>
            <v-card class="pa-2" flat>
              <div class="section-padding">
                <device-location-name v-if="enableDeviceLocationName"
                  v-model="deviceLocationName"
                  :cyTag="'deviceLocationName'"
                ></device-location-name>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card class="pa-2" flat>
              <div class="section-padding">
                <div class="form-section-header">
                  {{ $t('activateDevice.details') }}
                </div>
                <v-select
                  v-model="deviceType"
                  :items="deviceTypeItems"
                  :disabled="prefilled"
                  item-text="text"
                  item-value="value"
                  :rules="[rules.required, devicesRemainingValid]"
                  :label="$t('activateDevice.deviceType')"
                  @change="deviceTypeChanged()"
                  data-cy="deviceTypeSelect"
                  class="text-field-required"
                ></v-select>
                <v-text-field
                  :disabled="prefilled || deviceType === ''"
                  v-model="idSerialNumber"
                  v-bind:label="$t('activateDevice.idSerialNumber')"
                  v-bind:hint="serialNumberHintText"
                  persistent-hint
                  :counter="serialLength"
                  :maxlength="serialLength"
                  :rules="[
                    rules.required,
                    serialHasValidCharacters,
                    rules.serialLength,
                    serialHasValidModelNumber,
                  ]"
                  @keypress="preventNonNumbers($event)"
                  data-cy="deviceSerial"
                  class="text-field-required"
                  ref="idSerialNumberRef"
                ></v-text-field>
                <br />
                <v-switch
                  v-model="isPublic"
                  v-bind:label="$t('activateDevice.isPublic')"
                  color="primary"
                  v-bind:hint="$t('activateDevice.isPublicDescription')"
                  persistent-hint
                  :disabled="isSmartBridge"
                  data-cy="public"
                ></v-switch>
                <div v-if="!isSmartBridge" class="secondary-indicator">
                  <v-switch
                    v-model="indoorSensor"
                    :disabled="isIndoorOnly"
                    color="primary"
                    :hint="indoorSensorHint"
                    :persistent-hint="isIndoorOnly"
                    data-cy="isIndoor"
                  >
                    <template v-slot:label>
                      {{ $t('activateDevice.indoorSensor') }}
                      <div class="device-type-indicator">
                        <img
                          v-if="indoorSensor"
                          :src="indoorPinSrc"
                          alt="Indoor Device Pin"
                        />
                        <img
                          v-if="!indoorSensor"
                          :src="outdoorPinSrc"
                          alt="Outdoor Device Pin"
                        />
                      </div>
                    </template>
                  </v-switch>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card v-if="showSubTierSelect" class="pa-2" flat>
              <div class="section-padding">
                <div class="form-section-header">
                  {{ $t('activateDevice.subscription') }}
                </div>
                <v-select
                  v-model="subscriptionTier"
                  :items="subscriptionTierItems"
                  item-text="text"
                  item-value="value"
                  :label="$t('activateDevice.subscriptionTier')"
                  :hint="subTierHint"
                  persistent-hint
                  :rules="[rules.required]"
                  data-cy="subscriptionTierSelect"
                  class="text-field-required"
                ></v-select>
              </div>
            </v-card>
            <v-card :disabled="isSmartBridge" class="pa-2" flat >
              <div class="section-padding">
                <div class="form-section-header">
                  {{ $t('activateDevice.location') }}
                </div>
                <br />
                <div>
                  <v-btn
                    :loading="gettingLocation"
                    @click="useBrowserLocation"
                    text
                    class="primary--text"
                    v-bind:disabled="locationError"
                    data-cy="useBrowserLocation"
                    >{{ $t('activateDevice.useMyLocation') }}</v-btn
                  >
                </div>
                <div class="errorMessage">{{ this.locationErrorMessage }}</div>
                <div class="hint-text hint-text-button">
                  {{ $t('activateDevice.useMyLocationHint') }}
                </div>
                <br />
                <v-text-field
                  v-model="coords.latitude"
                  v-bind:label="$t('activateDevice.latitude')"
                  min="-90"
                  max="90"
                  maxlength="12"
                  :rules="[rules.latitude]"
                  @keypress="preventNonCoords($event)"
                  data-cy="registerLatitude"
                  class="text-field-required"
                  ref="latRef"
                ></v-text-field>
                <v-text-field
                  v-bind:label="$t('activateDevice.longitude')"
                  persistent-hint
                  v-model="coords.longitude"
                  min="-180"
                  max="180"
                  maxlength="13"
                  :rules="[rules.longitude]"
                  @keypress="preventNonCoords($event)"
                  data-cy="registerLongitude"
                  class="text-field-required"
                  ref="lngRef"
                ></v-text-field>
                <div class="hint-text">
                  {{ $t('activateDevice.coordinateHint') }}
                  <a
                    v-bind:href="$t('activateDevice.coordinateHintLink')"
                    class="hint-text-link"
                    target="_blank"
                    >{{ $t('activateDevice.coordinateHintLink') }}</a
                  >
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
      <template v-slot:actions>
        <v-btn
          @click="close"
          outlined
          class="black--text"
          text
          :disabled="registering"
          data-cy="registerClose"
          >{{ $t('buttons.close') }}</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          @click="save"
          class="primary white--text"
          :disabled="!fieldsValid || registering"
          :loading="registering"
          data-cy="registerDoneButton"
          text
        >
          <div class="trailing-icon-text">{{ $t('buttons.done') }}</div>
          <v-icon right light>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { SlugsEnum } from '../../permissions/SlugsEnum'
import { locationGetter, locationCoordsRegex } from '../../services/location'
import {
  serialHasValidCharacters,
  serialHasValidModelNumber,
} from '../../services/device-serial'
import { DEVICE_DEFAULT_CONFIG } from '../../services/device-config'
import { featureFlags } from '../../services/feature-flags'
import dialog from '../dialogs/Dialog.vue'
import DeviceName from '../deviceSettings/DeviceNameTextarea.vue'
import DeviceLocationName from '../deviceSettings/DeviceLocationNameTextarea.vue'
import { deviceModels, subscriptionTypes, subscriptionTiers } from '../../store/subscriptions/utils'
import { formatRegisterError } from './multipleDevices/helper'
const LINK_CUSTOMER = 'Customer Support.'
const MANAGE_DEVICES_ROUTE_NAME = 'manage-devices'
const TSILINK_STANDARD = subscriptionTiers.TSILINK_STANDARD

export default {
  mixins: [locationGetter],
  props: ['open', 'deviceSerial', 'deviceModel', 'deviceId'],
  components: {
    'base-dialog': dialog,
    'device-name': DeviceName,
    'device-location-name': DeviceLocationName,
  },
  data() {
    return {
      devicesRemaining: 0,
      gettingLocation: false,
      locationError: false,
      locationErrorMessage: '',
      registerErrorMessage: '',
      registerError: false,
      urlError: '',
      urlErrorMsg: '',
      requiredError: false,
      registering: false,
      prefilled: false,
      deviceType: '',
      idSerialNumber: '',
      deviceName: '',
      deviceLocationName: '',
      isPublic: false,
      indoorSensor: false,
      coords: {
        latitude: '',
        longitude: '',
      },
      subscriptionTier: null,
      subscriptionTierItems: [
        {
          text: this.$t('activateDevice.subscriptionTierOptions.basic'),
          value: 'basic',
        },
        {
          text: this.$t('activateDevice.subscriptionTierOptions.premium'),
          value: 'premium',
        },
      ],
      rules: {
        required: (value) => !!value || this.$t('validation.required'),
        serialLength: (value) => {
          return (
            value.length === this.serialLength ||
            this.$t('validation.invalidLength')
          )
        },
        latitude: (value) => {
          return (
            locationCoordsRegex.latitude.test(value) ||
            this.$t('validation.invalidEntry')
          )
        },
        longitude: (value) => {
          return (
            locationCoordsRegex.longitude.test(value) ||
            this.$t('validation.invalidEntry')
          )
        },
      },
    }
  },
  computed: {
    fieldsValid() {
      if (this.isSmartBridge) {
        if (
          this.rules.required(this.idSerialNumber) !== true ||
          this.serialHasValidCharacters !== true ||
          this.serialHasValidModelNumber !== true ||
          this.rules.required(this.deviceName) !== true ||
          this.rules.serialLength(this.idSerialNumber) !== true
        )
        {
          return false
        }
      } else {
        const lng = parseFloat(this.coords.longitude)
        const lat = parseFloat(this.coords.latitude)
        const subTierValid = this.showSubTierSelect
          ? this.rules.required(this.subscriptionTier) === true
          : true
          if (
            this.devicesRemaining === 0 ||
            this.rules.required(this.deviceName) !== true ||
            this.rules.required(this.idSerialNumber) !== true ||
            this.serialHasValidCharacters !== true ||
            this.serialHasValidModelNumber !== true ||
            this.rules.serialLength(this.idSerialNumber) !== true ||
            this.rules.required(lng) !== true ||
            this.rules.longitude(lng) !== true ||
            this.rules.required(lat) !== true ||
            this.rules.latitude(lat) !== true ||
            !subTierValid
        ) {
          return false
        }
      }
      return true
    },
    dialogError() {
      let err

      if (this.registerError) {
        err = this.registerErrorMessage
      } else if (this.requiredError) {
        err = this.$t('activateDevice.validationRequiredError')
      } else {
        err = this.$t('validation.requiredHelp')
      }

      return err
    },
    dialogUrlError() {
      return this.urlError
    },
    dialogUrlErrorMsg() {
      return this.urlErrorMsg
    },
    isSmartBridge() {
      return this.modelNumber === deviceModels.SMART_BRIDGE
    },
    isTSILinkStandardConnectedSub() {
      return this.$store.getters['subscriptions/getGenericTSILink'].some(sub => sub.id === TSILINK_STANDARD)
    },
    modelNumber() {
      const modelNamesMap = this.$store.getters['devicemodels/getModelNamesMap']
      return (
        Object.keys(modelNamesMap).find(
          (modelNumber) => modelNamesMap[modelNumber] === this.deviceType
        ) || ''
      )
    },
    devId() {
      let deviceId
      if (this.deviceId) {
        deviceId = this.deviceId
      } else if (this.idSerialNumber) {
        deviceId = this.$store.getters['devices/getDeviceIdByDeviceSerial'](
          this.idSerialNumber
        )
      }
      return deviceId
    },
    devicesRemainingValid() {
      if (this.isSmartBridge) {
      // Smart Bridge specific validation - checks for a valid subscription
      return this.isTSILinkStandardConnectedSub || this.$t('activateDevice.noActiveSmartBridgeSubFound')
    } else {
      // Other devices validation
      return this.devicesRemaining > 0 || this.$t('activateDevice.noDeviceAllowances', {
        modelName: this.deviceType,
      })
    }
  },
    serialHasValidCharacters() {
      return (
        serialHasValidCharacters(this.modelNumber, this.idSerialNumber) ||
        this.$t('validation.invalidCharacters')
      )
    },
    serialHasValidModelNumber() {
      return (
        serialHasValidModelNumber(this.modelNumber, this.idSerialNumber) ||
        this.$t('validation.invalidEntry')
      )
    },
    serialNumberHintText() {
      // empty string is the default value for deviceType
      if (this.deviceType === '') {
        return this.$t('activateDevice.idSerialNumberDisabled')
      }
      return this.isSmartBridge ? this.$t('activateDevice.idSerialNumberHintSB') : this.$t('activateDevice.idSerialNumberHint')
    },
    isIndoorOnly() {
      return (
        this.modelNumber &&
        this.$store.getters['devicemodels/getIndoorOnly'](this.modelNumber)
      )
    },
    modelNameKey() {
      return this.deviceType
        ? this.deviceType.toLowerCase().replace(/ /g, '_')
        : 'bluesky'
    },
    indoorSensorHint() {
      if (this.isIndoorOnly) {
        return this.$t('activateDevice.isIndoorOnlyHint', {
          modelName: this.deviceType,
        })
      }
      return ''
    },
    indoorPinSrc() {
      return this.$store.getters['devicemodels/getPinPath'](this.modelNumber, 'indoor')
    },
    outdoorPinSrc() {
      return this.$store.getters['devicemodels/getPinPath'](this.modelNumber, 'outdoor')
    },
    canAddToBasicSub() {
      return this.$store.getters['subscriptions/canAddDeviceToBasicByModel'](
        this.modelNumber
      )
    },
    canAddToPremiumSub() {
      return this.$store.getters['subscriptions/canAddDeviceToPremiumByModel'](
        this.modelNumber
      )
    },
    hasDataServices() {
      return this.$store.getters[
        'subscriptions/getDeviceDataServicesByDeviceSerial'
      ](this.idSerialNumber)
        ? true
        : false
    },
    showSubTierSelect() {
      return this.canAddToBasicSub && this.canAddToPremiumSub
    },
    subTierHint() {
      return this.deviceType
        ? this.$t(`activateDevice.subscriptionTierHint${this.deviceType}`)
        : ''
    },
    serialLength() {
      return (
        this.modelNumber &&
        this.$store.getters['devicemodels/getSerialLength'](this.modelNumber)
      )
    },
    enableSmartBridgeOption() {
      return this.$store.getters['featureFlags/getFeatureFlagBySlug'](
        featureFlags.SmartBridgeEnabled
      )
    },
    enableDeviceLocationName() {
      return this.$store.getters['featureFlags/getFeatureFlagBySlug'](
        featureFlags.DeviceLocationNameEnabled
      )
    },
    deviceTypeItems() {
      const models = this.$store.getters['devicemodels/getModels']
      const modelItems = []
      models.forEach((model) => {
        if ((model !== deviceModels.SMART_BRIDGE || this.enableSmartBridgeOption) && this.showRegistration(model)) {
          const modelKey =
            this.$store.getters['devicemodels/getModelKey'](model)
            modelItems.push({
            text: this.$t(`map.mapSettings.deviceModelNames.${modelKey}`),
            value:
              this.$store.getters['devicemodels/getModelName'](model),
          })
        }
      })
      return modelItems
    },
  },
  watch: {
    isIndoorOnly(newVal) {
      if (newVal === true) {
        this.indoorSensor = true
      } else {
        this.indoorSensor = false
      }
    },
    deviceType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.subscriptionTier = null
      }
    },
    open() {
      this.idSerialNumber = this.deviceSerial
      this.deviceType = this.deviceModel
      // Only check qty remaining if device type is pre filled
      if (this.deviceType && this.idSerialNumber) {
        this.deviceTypeChanged()
        this.prefilled = true
      }
      // Make it possible to prefill name, locationName and coords when adding data services device to website
      const name = this.$store.getters['devices/getNameByDeviceId'](
        this.deviceId
      )
      if (name) {
        this.deviceName = name
      }
      const locationName = this.$store.getters['devices/getLocationNameByDeviceId'](
        this.deviceId
      )
      if(locationName) {
        this.deviceLocationName = locationName
      }
      const coords = this.$store.getters['devices/getCoordsByDeviceId'](
        this.devId
      )
      if (coords && coords.lat && coords.lng) {
        this.coords.latitude = coords.lat
        this.coords.longitude = coords.lng
      }
    },
  },
  methods: {
    showRegistration(model) {
      const showRegistration = this.$store.getters['devicemodels/getShowRegistration'](model)
      // If the showRegistration flag isn't available (Devices API deployment hasn't been done or has been rolled back),
      // default to true - otherwise the registration dropdown could be empty
      return showRegistration === undefined ? true : showRegistration
    },
    resetDeviceTypeRelatedFormValidations() {
      if (!this.idSerialNumber) {
        this.$refs.idSerialNumberRef.resetValidation()
      }
      if (this.isSmartBridge) {
        this.$refs.latRef.reset()
        this.$refs.lngRef.reset()
      }
    },
    preventNonNumbers(event) {
      // if 1st character of the model is a number then prevent non numbers
      if (!isNaN(this.modelNumber.charAt(0))) {
        const keypress = event.keyCode || event.which || event.charCode
        if ((keypress != 8 && keypress != 0 && keypress < 48) || keypress > 57) {
          event.preventDefault()
        }
      }
    },
    sanitizeUrl (url) {
      if (url !== " "){
        return url.replace(/[^-A-Za-z0-9+&@#/%?=~_|!:,.;\(\)]/g, "").toLowerCase();
      }else{
        return url;
      }
    },
    async deviceTypeChanged() {
      if (this.modelNumber) {
        this.resetDeviceTypeRelatedFormValidations()
        const qtyRemaining = await this.$store.getters[
          'subscriptions/getQtyRemaining'
        ](this.modelNumber)
        this.devicesRemaining = qtyRemaining
      }
    },
    setCoords(coords) {
      if (coords) {
        if (coords.lat && coords.lng) {
          this.coords.latitude = `${coords.lat.toFixed(8)}`
          this.coords.longitude = `${coords.lng.toFixed(8)}`
        } else {
          this.locationError = true
          this.locationErrorMessage = this.$t(
            'activateDevice.locationNotAvailable'
          )
        }
      } else {
        this.locationError = true
        this.locationErrorMessage = this.$t('activateDevice.locationDenied')
      }
      this.gettingLocation = false
    },
    useBrowserLocation() {
      this.gettingLocation = true

      this.getGeolocation((coords) => {
        this.setCoords(coords)
      })
    },
    preventNonCoords(event) {
      const keypress = event.keyCode || event.which || event.charCode
      if (keypress > 57) {
        // NOTE: may need to modify this to prevent input like "--4.323" or "0100..43" etc
        event.preventDefault()
      }
    },
    updateMap(deviceId, deviceModel) {
      // Update map settings to show device model if not set to visible
      const visibleDeviceModels = this.$store.getters['map/getDeviceModels']
      if (!visibleDeviceModels.includes(deviceModel)) {
        visibleDeviceModels.push(deviceModel)
        this.$store.dispatch('map/updateSettings', {
          deviceModels: visibleDeviceModels,
        })
      }
      // pass along device ID so that moving mode can be enabled
      this.$emit('deviceCreated', deviceId)
    },
    async updateDeviceConfig(deviceId) {
      await this.$api.postDeviceConfig(DEVICE_DEFAULT_CONFIG, deviceId)
    },
    async registerDevice(deviceData) {
      const resp = await this.$api.postRegisterDevice(deviceData)
      if (resp.ok) {
        const body = await resp.json()
        const setData = {
          accountId: body.cloud_account_id,
          deviceId: body.cloud_device_id,
          model: body.model,
          submodel: body.subModel,
          serial: body.serial,
          name: body.cloud_state_data?.name?.value,
          locationName: body.cloud_state_data?.location_name?.value,
          indoor: body.cloud_state_data?.is_indoor?.value,
          isPublic: body.cloud_state_data?.is_public?.value,
          coords: {
            lng: body.cloud_state_data?.location?.value?.coordinates[0],
            lat: body.cloud_state_data?.location?.value?.coordinates[1],
          },
        }
        this.$store.dispatch('devices/setDevice', setData)
        await this.updateDeviceConfig(body.cloud_device_id)
        return body.cloud_device_id
      }
      const message = await resp.text()
      const formatedError = formatRegisterError(message, deviceData.serial)
      throw new Error(formatedError)
    },
    async updateDevice(deviceData, deviceId) {
      let registrationSuccessful = false

      const patchSub = await this.$api.patchDeviceSubscription({
        model: deviceData.model,
        serial: deviceData.serial,
        subscription: this.subscriptionTier,
        isExternal: false,
      })
      if (patchSub.ok) {
        // patch successful, update device meta
        const updateDevice = await this.$api.patchUpdateDevice(
          deviceData,
          deviceId
        )
        if (updateDevice.ok) {
          // update successful, registration successful
          registrationSuccessful = true
        } else {
          // update unsuccessful, delete previously patched sub
          await this.$api.patchDeviceSubscription({
            model: deviceData.model,
            serial: deviceData.serial,
            subscription: this.subscriptionTier,
            delete: true,
            isExternal: false,
          })
        }
      }else{
        const message = await patchSub.text()
        throw new Error(message)
      }
      if (registrationSuccessful) {
        this.$store.dispatch('devices/updateDeviceCoords', {
          deviceId: deviceId,
          coords: {
            lng: parseFloat(deviceData.cloud_state_data.longitude),
            lat: parseFloat(deviceData.cloud_state_data.latitude),
          },
        })
        this.$store.dispatch('devices/updateMetadata', {
          deviceId: deviceId,
          isPublic: deviceData.cloud_state_data.isPublic,
          indoor: deviceData.cloud_state_data.isIndoor,
          name: deviceData.cloud_state_data.name,
        })
        await this.updateDeviceConfig(deviceId)
        return deviceId
      }
      return false
    },
    async save() {
      this.registering = true
      this.requiredError = false
      this.registerError = false
      this.urlError = ''
      this.urlErrorMsg = ''

      if (!this.fieldsValid) {
        this.requiredError = true
        return
      }

      const deviceData = {
        model: this.modelNumber,
        serial: this.isSmartBridge ? this.idSerialNumber.toLowerCase() : this.idSerialNumber,
        cloud_state_data: {
          isIndoor: this.indoorSensor,
            isPublic: this.isPublic,
            longitude: this.isSmartBridge ? parseFloat('0.0') : parseFloat(this.coords.longitude),
            latitude: this.isSmartBridge ? parseFloat('0.0') : parseFloat(this.coords.latitude),
            name: this.deviceName,
        },
        subscription: this.isSmartBridge ? null : this.getDeviceSubscription(),
      }

      try {
        let success = false
        /* Smart Bridge is not associated with Data Services */
        if (this.hasDataServices) {
          success = await this.updateDevice(deviceData, this.devId)
        } else {
          // saving location name only supported on create NOT update right now
          deviceData.cloud_state_data.locationName = this.deviceLocationName
          success = await this.registerDevice(deviceData)
        }
        if (!success) {
          this.registerError = true
        } else {
          const isManageDevicesRoute = this.$router?.currentRoute?.name === MANAGE_DEVICES_ROUTE_NAME
          const isSmartBridge = this.isSmartBridge
          // Set a new device data to Vuex
          if (isManageDevicesRoute) {
            await this.$store.dispatch('devices/setAllUserDevices')
            await this.$store.dispatch('devices/setAllDevices')
          } else {
            await this.$store.dispatch('devices/setAllDevices')
          }
          // Only update subscriptions and map with non-Smart Bridge device model data
          if (!isSmartBridge) {
            await this.$store.dispatch('subscriptions/updateSubscriptions', {
            auth: this.$auth,
            api: this.$api,
            devices: this.$auth.isAuthenticated
              ? this.$store.state.devices.allDevices
              : [],
          })
            this.updateMap(success, deviceData.model)
          }
          // only redirect to manage devices page if all of this is true
          if (isSmartBridge && !isManageDevicesRoute && (this.$permissions?.isAllowed(SlugsEnum.SideBarDevicesExecute) || false)) {
            this.$router.push({ name: MANAGE_DEVICES_ROUTE_NAME })
          }
          else {
            // otherwise just close the dialog
            this.close()
          }
        }
      } catch (e) {
        this.registerError = true
        if(e.toString().includes(LINK_CUSTOMER)){
          this.registerErrorMessage = e.toString().replace(LINK_CUSTOMER, '')
          this.urlError = this.sanitizeUrl(process.env.VUE_APP_BASE_URL + '/help?topic=4')
          this.urlErrorMsg = LINK_CUSTOMER
        }else{

          this.registerErrorMessage = e.toString()
        }
      }
      this.registering = false
    },
    close() {
      this.init()
      this.$emit('close')
    },
    init() {
      this.registerError = false
      this.requiredError = false
      this.deviceName = ''
      this.deviceLocationName = ''
      this.isPublic = false
      this.indoorSensor = false
      this.deviceType = ''
      this.idSerialNumber = ''
      this.coords.latitude = ''
      this.coords.longitude = ''
      this.urlError = ''
      this.urlErrorMsg = ''
      // resets the validation state
      if (this.$refs && this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    getDeviceSubscription() {
      if (!this.showSubTierSelect) {
        return subscriptionTypes.WEBSITE
      }

      return this.subscriptionTier || this.$t('activateDevice.subscriptionTierOptions.premium')
    },
  },
}
</script>

<style lang="scss" scoped>
.isSticky {
  position: sticky;
  background: #ffffff;
  bottom: 0;
}

.section-padding {
  padding: 0 16px;
  .v-dialog--fullscreen & {
    padding: 0;
  }
}
.form-section-header {
  opacity: 0.56;
}
.hint-text {
  font-size: 12px;
  opacity: 0.76;
}
.hint-text-button {
  margin-left: 16px;
}
.hint-text.red-font {
  color: red;
}
.text-field::v-deep .v-messages__message {
  overflow-wrap: normal;
  hyphens: none;
}
.v-dialog {
  .v-card {
    padding: 16px;
  }
  .v-card__title {
    font-weight: 300;
  }
  .v-card__subtitle {
    padding: 16px 24px;
  }
  .secondary-indicator {
    display: flex;
    .v-input--selection-controls.v-input {
      width: 100%;
    }
    .device-type-indicator {
      align-self: center;
      height: 36px;
      margin: 0 0 0 16px;
      img {
        height: 36px;
      }
    }
  }
  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
    .trailing-icon-text {
      width: 100%;
      text-indent: 30px;
    }
  }
}

.rtl {
  direction: rtl;
}

.dialog {
  margin: 0px !important;
}

.errorMessage {
  font-size: 12px;
  color: red;
  text-align: left;
  padding-left: 16px;
}

.move-title {
  font-size: 18px;
  padding: 0.8rem;
  padding-bottom: 0.2rem;
  font-weight: 400;
}
.move-content {
  padding: 0 0.8rem 1.2rem 0.8rem;
  opacity: 0.7;
}
.move-actions {
  padding: 0;
  .v-btn {
    margin: 0 !important;
    padding: 0;
    width: 50%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: 3.2rem;
    font-size: 1rem;
  }
  .v-btn.left {
    border-bottom-right-radius: 0;
  }
  .v-btn.right {
    border-bottom-left-radius: 0;
  }
}
</style>

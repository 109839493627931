<template>
  <v-card
    v-if="zeroValue"
    flat
    class="calibration-card"
    data-cy="addUserZeroConfirmationText"
  >
    <v-card-text class="tab-content__title">
      {{ $t('map.deviceSettings.zeroing.confirmation.userZero.title') }}
    </v-card-text>
    <v-card-text class="paragraph-text">
      {{ $t('map.deviceSettings.zeroing.confirmation.userZero.paragraph1') }}
    </v-card-text>
    <v-card-text class="paragraph-text">
      {{ $t('map.deviceSettings.zeroing.confirmation.userZero.paragraph2') }}
    </v-card-text>
  </v-card>
  <v-card
    flat
    class="calibration-card"
    v-else
    data-cy="removeUserZeroConfirmation"
  >
    <v-card-text class="tab-content__title">
      {{ $t('map.deviceSettings.zeroing.confirmation.factoryZero.title') }}
    </v-card-text>
    <v-card-text class="paragraph-text">
      {{ $t('map.deviceSettings.zeroing.confirmation.factoryZero.paragraph1') }}
    </v-card-text>
    <v-card-text class="paragraph-text">
      {{ $t('map.deviceSettings.zeroing.confirmation.factoryZero.paragraph2') }}
    </v-card-text>
    <v-card-text class="paragraph-text">
      {{ $t('map.deviceSettings.zeroing.confirmation.factoryZero.paragraph3') }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DeviceZeroConfirmation',
  props: {
    zeroValue: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.v-card__title.tab-content__title {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  opacity: 0.56;
}
.v-card__text.paragraph-text {
  color: rgba(0, 0, 0, 0.9);
}
</style>

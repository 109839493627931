import {
  getWidgetSelectedDevices,
  getWidgetAvailableMeasurements,
  getSelectedMeasurement,
  getDevicesPermissionSet,
} from '../../helpers/widgets/helper'

export default  {
  data() {
    return {
      selectedParameter: this.passedinParameter || null,
    }
  },
  watch: {
    availableMeasurements: {
      deep: true,
      handler() {
        this.checkSelectedParameter()
      },
    },
    passedinParameter(passedinParameter) {
      this.selectedParameter = passedinParameter
      this.checkSelectedParameter()
    },
  },
  computed: {
    filterMeasurements() {
      if (this.deviceIds?.length) {
        for (const deviceId of this.deviceIds) {
          if (this.isAConnectedDevice(deviceId)) {
            return false
          }
        }
      }

      return true
    },
    availableMeasurements() {
      return getWidgetAvailableMeasurements(
        this.selectedDevices,
        this.filterMeasurements,
        this.filterMeasurementStatistics
      )
    },
    selectedDevices() {
      return getWidgetSelectedDevices(this.deviceIds, this.accountId)
    },
    permissionSet() {
      return getDevicesPermissionSet(this.selectedDevices)
    },
  },
  methods: {
    checkSelectedParameter() {
      if (this.availableMeasurements?.length) {
        this.selectedParameter = getSelectedMeasurement(
          this.selectedParameter,
          this.availableMeasurements,
          this.permissionSet,
          {
            defaultParameter: this.defaultParameter,
            savedParameter: this.settings?.selectedParameter,
          }
        )
      }
    },
    isAConnectedDevice(deviceId) {
      return !!this.$store.getters[
        'devices/getConnectedSmartBridgeSerialByDeviceId'
      ](deviceId)
    },
  },
}

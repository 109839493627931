<template>
  <popover-timestamp
    :timestamp="lastCalibrationTimestamp"
    :label="$t('widget.currentReadings.lastCalibrationDate')"
    customClass="small-subtitle"
    dataCy="lastCalibrationDate"
  />
</template>

<script>
import popoverTimestamp from '../popoverTimestamp.vue'

export default {
  name: 'popoverLastCalibrationTimestamp',
  components: {
    'popover-timestamp': popoverTimestamp,
  },
  props: {
    deviceId: String,
  },
  computed: {
    lastCalibrationTimestamp() {
      return this.$store.getters[
        'devices/getLastCalibrationTimestampByDeviceId'
      ](this.deviceId)
    },
  },
}
</script>

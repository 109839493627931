import { getConfigByKey } from "./device-config"

const userCalSortOrder = {
  pm25_raw: 0,
  pm10_raw: 1,
  temp: 2,
  rh: 3,
  baro_inhg: 4,
  co2: 5,
  tvoc: 6,
  co: 7,
  ch2o: 8,
  so2: 9,
  o3: 10,
  no2: 11,
}

const sortUserCals = (a, b) => {
  return userCalSortOrder[a.name] > userCalSortOrder[b.name] ? 1 : -1
}

export const AUTOCAL_SENSORS = ['co2']
export const DEFAULT_SLOPE = 1
export const DEFAULT_OFFSET = 0
export const DEFAULT_AUTOCAL = true

/**
 * Gets saved user calibrations
 * @param {Object} sensorConfigs Config of device
 * @param {Object} modelCalibrationMeta Calibration metadata
 * @returns Object of device user calibrations
 */
export const getUserCals = (sensorConfigs, modelCalibrationMeta) => {
  return Object.entries(modelCalibrationMeta)
    .map(([fieldName, calMeta]) => {
      switch (fieldName) {
        case 'pm10_raw':
          return getPm10Cal(sensorConfigs, calMeta)
        case 'pm25_raw':
          return getPm25Cal(sensorConfigs, calMeta)
        case 'baro_inhg':
          return getBaroCal(sensorConfigs)
        default:
          return getCal(sensorConfigs, calMeta, fieldName)
      }
    })
    .sort(sortUserCals)
}

const getPm10Cal = (sensorConfigs, calMeta) => {
  const cal = {
    name: 'pm10_raw',
  }
  cal.multiFactor = getConfigByKey(sensorConfigs, 'mcpm10_userslope') ?? DEFAULT_SLOPE
  if (calMeta.offset) {
    cal.offset = getConfigByKey(sensorConfigs, 'mcpm10_useroffset') ?? DEFAULT_OFFSET
  }
  return cal
}

const getPm25Cal = (sensorConfigs, calMeta) => {
  const cal = {
    name: 'pm25_raw',
  }
  cal.multiFactor = getConfigByKey(sensorConfigs, 'mcpm2x5_userslope') ?? DEFAULT_SLOPE
  if (calMeta.offset) {
    cal.offset = getConfigByKey(sensorConfigs, 'mcpm2x5_useroffset') ?? DEFAULT_OFFSET
  }
  return cal
}

const getBaroCal = (sensorConfigs) => {
  const cal = {
    name: 'baro_inhg',
  }
  cal.offset = getConfigByKey(sensorConfigs, 'baro_useroffset') ?? DEFAULT_OFFSET
  return cal
}

const getCal = (sensorConfigs, calMeta, fieldName) => {
  const cal = {
    name: fieldName,
  }
  if (calMeta.offset) {
    cal.offset = getConfigByKey(sensorConfigs, fieldName.concat('_useroffset')) ?? DEFAULT_OFFSET
  }
  if (calMeta.multiplicationFactor) {
    cal.multiFactor = getConfigByKey(sensorConfigs, fieldName.concat('_userslope')) ?? DEFAULT_SLOPE
  }
  if (AUTOCAL_SENSORS.includes(fieldName)) {
      const autoCal = getConfigByKey(
        sensorConfigs,
        fieldName.concat('_asc_cal')
      )
      cal.ascCal = autoCal !== null ? autoCal : DEFAULT_AUTOCAL
  }
  return cal
}

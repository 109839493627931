import { LOG_IN_AS_HEADER_NAMES } from './utils'
import { getLogInAsHeaders } from './helper'

// Widgets
export const getWidgets = async function () {
  return this.GET(
    `/widgets/blueskyDashboard`,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.USER_ID]),
    },
    {},
    process.env.VUE_APP_ACCOUNTS_HOST_V2
  )
}

export const postWidget = async function (widget) {
  widget.layoutName = 'blueskyDashboard'
  const body = JSON.stringify(widget)
  return this.POST(
    `/widgets`,
    body,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.USER_ID]),
    },
    {},
    process.env.VUE_APP_ACCOUNTS_HOST_V2
  )
}

export const patchWidget = async function (widget, id) {
  const body = JSON.stringify(widget)
  return this.PATCH(
    `/widgets/${id}`,
    body,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.USER_ID]),
    },
    {},
    process.env.VUE_APP_ACCOUNTS_HOST_V2
  )
}

export const deleteWidget = async function (id) {
  return this.DELETE(
    `/widgets/${id}`,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.USER_ID]),
    },
    {},
    process.env.VUE_APP_ACCOUNTS_HOST_V2
  )
}

// Layout
export const getLayout = async function () {
  return this.GET(
    `/layouts/blueskyDashboard`,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.USER_ID]),
    },
    {},
    process.env.VUE_APP_ACCOUNTS_HOST_V2
  )
}

export const postLayout = async function (layout) {
  layout.name = 'blueskyDashboard'
  const body = JSON.stringify(layout)
  return this.POST(
    `/layouts`,
    body,
    {
      ...getLogInAsHeaders([LOG_IN_AS_HEADER_NAMES.USER_ID]),
    },
    {},
    process.env.VUE_APP_ACCOUNTS_HOST_V2
  )
}

import { REGISTER_ERRORS, ROW_EXAMPLE } from './utils'

const getDeviceModel = (deviceSerial) => {
  return deviceSerial.substring(0, 4)
}

const convertIsAttributeToBoolean = (isAttribute) => {
  return ['Yes', 'y'].includes(isAttribute)
}

export const formatDeviceData = (deviceData) => {
  return {
    model: getDeviceModel(deviceData['Device Serial Number']),
    serial: deviceData['Device Serial Number'],
    cloud_state_data: {
      isIndoor: convertIsAttributeToBoolean(deviceData['Indoor Sensor?']),
      isPublic: convertIsAttributeToBoolean(deviceData['Make Device Public?']),
      longitude: parseFloat(deviceData.Longitude),
      latitude: parseFloat(deviceData.Latitude),
      name: deviceData['Device Name'],
      locationName: deviceData['Location Name'],
    },
  }
}

export const formatRegisterError = (error, serialNumber) => {
  for (let data of REGISTER_ERRORS) {
    if (data.errorRegex.test(error.toLowerCase())) {
      return data.parsedError.replace('/serialNumber/', serialNumber)
    }
  }

  return `${serialNumber} - ${error}`
}

export const sanitizeRows = (csvRows) => {
  return csvRows.filter((row) => {
    return (
      JSON.stringify(row) !==
      JSON.stringify({ ...ROW_EXAMPLE, RowId: row.RowId })
    )
  })
}

<template>
  <v-card
    :class="{
      'ma-6': !this.$vuetify.breakpoint.xs,
      tableCard: true,
      'is-mobile': this.isMobile,
    }"
    :flat="$vuetify.breakpoint.xs"
  >
    <div>
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        style="width: 200px"
        class="search-field"
        data-cy="searchField"
      />
    </div>
    <v-data-table
      v-if="!apiCallInProgress"
      :search="search"
      :headers="headers"
      :items="tsiLinkAccounts"
      :sort-by.sync="sortedBy"
      :sort-desc.sync="sortDesc"
      :itemsPerPage.sync="itemsPerPage"
      :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
      :class="{ mobile: isMobile }"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-text': isMobile
          ? $t('tables.rows')
          : $t('tables.rowsPerPage'),
        itemsPerPageOptions: [10, 25, 50],
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
      }"
      item-key="accountId"
    >
      <template v-slot:body="{ items }" v-if="!isMobile">
        <account-table-body :items="items" />
      </template>

      <template v-slot:body="{ items }" v-else>
        <account-table-body-mobile :items="items" />
      </template>
    </v-data-table>
    <div v-else class="loading-animation">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </v-card>
</template>

<script>
import AccountTableBody from './accountTableBody.vue'
import AccountTableBodyMobile from './accountTableBodyMobile.vue'

const LOCAL_STORAGE_KEYS = 'tsi.accountTableSettings'

export default {
  name: 'AccountTable',
  data() {
    return {
      search: '',
      apiCallInProgress: true,
      sortedBy: ['username'],
      sortDesc: [false],
      itemsPerPage: 10,
      headers: [
        {
          text: this.$t('accountTable.tableHeaders.userEmail'),
          align: 'left',
          value: 'email',
        },
        {
          text: this.$t('accountTable.tableHeaders.username'),
          value: 'username',
        },
        {
          text: this.$t('accountTable.tableHeaders.accountID'),
          value: 'accountId',
        },
        {
          text: this.$t('accountTable.tableHeaders.userSubscription'),
          value: 'subscription',
        },
        {
          text: this.$t('accountTable.tableHeaders.sapAcctId'),
          value: 'sapAcctId',
        },
        {
          text: this.$t('accountTable.tableHeaders.logins'),
          value: 'lastLogIn',
        },
        {
          text: this.$t('accountTable.tableHeaders.actions'),
          value: 'actions',
          sortable: false,
          align: 'right',
        },
      ],
    }
  },
  watch: {
    sortedBy: {
      deep: true,
      handler() {
        this.setAccountTableSettings()
      },
    },
    sortDesc: {
      deep: true,
      handler() {
        this.setAccountTableSettings()
      },
    },
    itemsPerPage: {
      handler() {
        this.setAccountTableSettings()
      },
    },
  },
  components: {
    'account-table-body': AccountTableBody,
    'account-table-body-mobile': AccountTableBodyMobile,
  },
  computed: {
    tsiLinkAccounts() {
      let accountData = []
      accountData = this.$store.getters['adminpanel/getAccountsTableData']
      return accountData
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
  methods: {
    createSimplifiedSubscriptions(accountsData) {
      const simpleSubs = []
      accountsData.forEach((accountData) => {
        let subscriptions = accountData.subscription
        let webBasic = 0
        let webPremium = 0
        let dataServices = 0
        let subscriptionName = ''
        subscriptions?.forEach((subscription) => {
          if (subscription.includes('Premium')) {
            webPremium++
          } else if (subscription.includes('Data Services')) {
            dataServices++
          } else if (!subscription.includes('Free Tier')) {
            webBasic++
          }
        })
        if (dataServices > 0 && (webBasic > 0 || webPremium > 0)) {
          subscriptionName = this.$t('subscriptionType.dual')
        } else if (dataServices > 0) {
          subscriptionName = this.$t('subscriptionType.dataServices')
        } else if (webPremium > 0) {
          subscriptionName = this.$t('subscriptionType.premium')
        } else if (webBasic > 0) {
          subscriptionName = this.$t('subscriptionType.basic')
        } else {
          subscriptionName = this.$t('subscriptionType.free')
        }
        simpleSubs.push(subscriptionName)
      })
      return simpleSubs
    },
    createSimplifiedLoginDates(accountsData) {
      const simpleLoginDates = []
      accountsData.forEach((account) => {
        simpleLoginDates.push(new Date(account.lastLogin).getTime())
      })
      return simpleLoginDates
    },
    setAccountTableSettings() {
       const save = {
        itemsPerPage: this.itemsPerPage,
        sortBy: this.sortedBy,
        sortDesc: this.sortDesc,
      }
      localStorage.setItem(LOCAL_STORAGE_KEYS, JSON.stringify(save))
    },
    getAccountTableSettings() {
      const settings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS))
      if (settings !== null) {
        this.sortDesc = settings.sortDesc
        this.itemsPerPage = settings.itemsPerPage
        this.sortedBy = settings.sortBy
      }
    },
  },
  async mounted() {
    this.apiCallInProgress = true
    const apiCallResponse = await this.$api.getAccountsForAdminPanel()
    if (apiCallResponse.ok) {
    this.getAccountTableSettings()

      const accountsData = await apiCallResponse.json()
      const simpleSubs = this.createSimplifiedSubscriptions(accountsData)
      const loginDates = this.createSimplifiedLoginDates(accountsData)
      await this.$store.dispatch('adminpanel/setAccountsTableData', {
        accountsData: accountsData,
        simpleSubs: simpleSubs,
        loginDates: loginDates,
      })
    } else {
      const message = {
        text: 'API call failed',
        type: 'warning',
        timeout: -1,
        buttonText: 'Close',
      }
      this.$store.dispatch('notifications/addMessage', message)
    }
    this.apiCallInProgress = false
  },
}
</script>
<style lang="scss">
.tableCard {
  .search-field {
    margin-left: 16px;
    margin-right: 16px;
  }
  .loading-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 240px;
  }
}
.tableCard.is-mobile {
  .v-data-table {
    .v-data-footer {
      .v-data-footer__select {
        padding-left: 56px;
      }
      .v-data-footer__pagination {
        margin-left: 8px;
        margin-right: 10px;
      }
      .v-data-footer__icons-before {
        padding-left: 64px;
      }
      justify-content: flex-end;
    }
  }
}
</style>

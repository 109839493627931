import App from './App.vue'
import { getRouter } from './router/index'
import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps'
import { i18n } from './i18n'
import { ApiPlugin } from './api'
import Components from '@fusion/saas-components'
import '@fusion/saas-components/dist/fusion-saas-components.css'
import AccountSubscriptions from './components/userProfile/accountSubscriptions/AccountSubscriptions.vue'
import Auth0Plugin, { getAuthInstance } from '@fusion/auth'
import VueSanitize from 'vue-sanitize'
import PermissionsPlugin from './permissions'
import VueGtm from '@gtm-support/vue2-gtm'

import options from './plugin-options'

Vue.config.productionTip = false

Vue.use(Vuetify)

Vue.use(VueGoogleMaps, options.maps)

Vue.use(VueSanitize)

Vue.component('account-subscriptions', AccountSubscriptions)

// Any setup that requires Auth0 needs to happen after we await the router
const setup = async () => {
  const router = await getRouter()

  Vue.use(Auth0Plugin, options.getAuth(router))

  Vue.use(Components, {
    ...options.components,
    auth: getAuthInstance,
  })

  Vue.use(ApiPlugin, { auth: getAuthInstance })
  Vue.use(PermissionsPlugin, store)
  Vue.use(VueGtm, options.getGtm(router))

  let vm = new Vue({
    i18n,
    router,
    store,
    vuetify: new Vuetify(options.vuetify),
    render: (h) => h(App),
  })

  vm.$mount('#app')
}

setup()

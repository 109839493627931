import { measurementTypes } from '../../services/device-measurements'

const alertMeasurementsSortOrder = {
  pm25_raw: 0,
  pm10_raw: 1,
  pm25: 2,
  pm10: 3,
  temp: 4,
  rh: 5,
  baro_inhg: 6,
  co: 7,
  co2: 8,
  tvoc: 9,
  ch2o: 10,
  so2: 11,
  o3: 12,
  no2: 13,
}

const sortAlertMeasurements = ([, a], [, b]) => {
  return alertMeasurementsSortOrder[a.shortName] >
    alertMeasurementsSortOrder[b.shortName]
    ? 1
    : -1
}

export default {
  getAllowPublic: (state) => (model) => {
    return !!state.modelsMetaData[model]?.allowPublic
  },
  getShowAQI: (state) => (model) => {
    return !!state.modelsMetaData[model]?.showAQI
  },
  getShowCondition: (state) => (model) => {
    return !!state.modelsMetaData[model]?.showCondition
  },
  getShowConnectivity: (state) => (model) => {
    return !!state.modelsMetaData[model]?.showConnectivity
  },
  getShowLastCommunication: (state) => (model) => {
    return !!state.modelsMetaData[model]?.showLastCommunication
  },
  getShowMapSettings: (state) => (model) => {
    return !!state.modelsMetaData[model]?.showMapSettings
  },
  getShowRegistration: (state) => (model) => {
    return state.modelsMetaData[model]?.showRegistration
  },
  getShowSubscription: (state) => (model) => {
    return !!state.modelsMetaData[model]?.showSubscription
  },
  getTelemetryFieldsByModel: (state) => (model) => {
    return state.modelsMetaData[model]?.telemetryFields
  },
  getModels: (state) => {
    return Object.keys(state.modelsMetaData)
  },
  getShowMapSettingsModels: (state) => {
    const models = Object.keys(state.modelsMetaData)
    if (models?.length) {
      return models.filter((model) => {
        return !!state.modelsMetaData[model]?.showMapSettings
      })
    }

    return []
  },
  getSubmodels: (state) => {
    const submodels = {}
    for (const model in state.modelsMetaData) {
      submodels[model] = state.modelsMetaData[model]?.subModels
    }
    return submodels
  },
  getModelName: (state) => (modelNumber) => {
    return state.modelsMetaData[modelNumber]?.name
  },
  getModelNamesMap: (state) => {
    const modelNamesMap = {}
    Object.entries(state.modelsMetaData).forEach(([modelNumber, model]) => {
      modelNamesMap[modelNumber] = model.name
    })
    return modelNamesMap
  },
  getModelProductFamily: (state) => (modelNumber) => {
    if (modelNumber && state.modelsMetaData[modelNumber]) {
      return state.modelsMetaData[modelNumber].productFamily
    }
    return null
  },
  getModelKey: (state) => (modelNumber) => {
    if (modelNumber && state.modelsMetaData[modelNumber]) {
      return state.modelsMetaData[modelNumber].key
    }
    return null
  },
  getSubmodelKey: (state) => (modelNumber, submodelName) => {
    const model = state.modelsMetaData[modelNumber]
    if (model && model.subModels && submodelName) {
      return state.modelsMetaData[modelNumber].subModels.find(
        (sm) => sm.name === submodelName
      ).key
    }
    return ''
  },
  getSerialLength: (state) => (modelNumber) => {
    return state.modelsMetaData[modelNumber]?.serialLength
  },
  getIndoorOnly: (state) => (modelNumber) => {
    return !!state.modelsMetaData[modelNumber]?.indoorOnly
  },
  getShowCalibration: (state) => (modelNumber) => {
    return !!state.modelsMetaData[modelNumber]?.showCalibration
  },
  getShowIndoorSensor: (state) => (modelNumber) => {
    return !!state.modelsMetaData[modelNumber]?.showIndoorSensor
  },
  getShowIntervalSettings: (state) => (modelNumber) => {
    return !!state.modelsMetaData[modelNumber]?.showIntervalSettings
  },
  getShowLocation: (state) => (modelNumber) => {
    return !!state.modelsMetaData[modelNumber]?.showLocation
  },
  getFaqLink: (state) => (modelNumber) => {
    return state.modelsMetaData[modelNumber]?.faqLink
  },
  getCalibrationLimits: (state) => (modelNumber, submodelName) => {
    const calibrationLimits = {}
    const model = state.modelsMetaData[modelNumber]

    let calFields = Object.entries(model.telemetryFields).filter(
      ([, tf]) => tf.calibration
    )
    if (model.subModels && submodelName) {
      const submodel = model.subModels.find((sm) => sm.name === submodelName)
      calFields = calFields.filter(([, tf]) =>
        tf.subModel.includes(submodel.shortName)
      )
    }
    calFields.forEach(([, tf]) => {
      calibrationLimits[tf.shortName] = tf.calibration
    })

    return calibrationLimits
  },
  getAllowedRangeLimits: (state) => (modelNumber, submodelName) => {
    const allowedRangeLimits = {}
    const model = state.modelsMetaData[modelNumber]

    let fields = Object.entries(model.telemetryFields).filter(
      ([, tf]) => tf.allowedRange
    )
    if (model.subModels && submodelName) {
      const submodel = model.subModels.find((sm) => sm.name === submodelName)
      fields = fields.filter(([, tf]) =>
        tf.subModel.includes(submodel.shortName)
      )
    }
    fields.forEach(([, tf]) => {
      allowedRangeLimits[tf.shortName] = tf.allowedRange
    })

    return allowedRangeLimits
  },
  getSupportedMeasurements:
    (state) => (modelNumber, submodelName, publicOnly) => {
      const model = state.modelsMetaData[modelNumber]

      if (model) {
        let supportedTelemetryFields = []
        if (model.telemetryFields) {
          supportedTelemetryFields = Object.entries(
            model.telemetryFields
          ).filter(([, tf]) => tf.supportedInUI)
        }
        if (publicOnly) {
          supportedTelemetryFields = supportedTelemetryFields.filter(
            ([, tf]) => tf.availableToPublic
          )
        }
        if (model.subModels && submodelName) {
          const submodel = model.subModels.find(
            (sm) => sm.name === submodelName
          )
          supportedTelemetryFields = supportedTelemetryFields.filter(([, tf]) =>
            tf.subModel.includes(submodel.shortName)
          )
        }
        return supportedTelemetryFields.map(([, tf]) => {
          return measurementTypes[tf.shortName]
        }).filter((supportedMeasurement) => {
          return typeof supportedMeasurement === 'number'
        })
      }

      return []
    },
  getAlertableMeasurements: (state) => (modelNumber, submodelName) => {
    const model = state.modelsMetaData[modelNumber]

    let supportedTelemetryFields = Object.entries(model.telemetryFields).filter(
      ([, tf]) => tf.alertable
    )
    if (model.subModels && submodelName) {
      const submodel = model.subModels.find((sm) => sm.name === submodelName)
      supportedTelemetryFields = supportedTelemetryFields.filter(([, tf]) =>
        tf.subModel.includes(submodel?.shortName)
      )
    }

    supportedTelemetryFields.sort(sortAlertMeasurements)
    return supportedTelemetryFields.map(([tfName]) => tfName)
  },
  getZeroableMeasurements: (state) => (modelNumber, submodelName) => {
    const zeroableMeasurements = []
    const model = state.modelsMetaData[modelNumber]

    let supportedTelemetryFields = Object.entries(model.telemetryFields).filter(
      ([, tf]) => tf.zeroable
    )

    if (model.subModels && submodelName) {
      const submodel = model.subModels.find((sm) => sm.name === submodelName)
      supportedTelemetryFields = supportedTelemetryFields.filter(([, tf]) =>
        tf.subModel.includes(submodel?.shortName)
      )
    }
    supportedTelemetryFields.forEach(([, tf]) => {
      zeroableMeasurements.push(tf.shortName)
    })
    return zeroableMeasurements
  },
  modelSupportsZeroing: (state) => (modelNumber) => {
    return state.modelsMetaData[modelNumber]?.zeroable ? true : false
  },
  modelSupportsAutoCal: (state) => (modelNumber) => {
    return state.modelsMetaData[modelNumber]?.supportsAutoCal ? true : false
  },
  getPinPath: (state) => (modelNumber, type) => {
    const imgSettings = state.modelsMetaData[modelNumber]?.imgSettings
    const value = imgSettings?.map?.pins?.[type]
    if (value) {
      return `img/map/pins/${value}`
    }
    const defaultCase =
        type === 'indoor' ? 'pin-blank-indoor.svg' : 'pin-blank-outdoor.svg'
    return `img/map/pins/${defaultCase}`
  },
}

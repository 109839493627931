import { render, staticRenderFns } from "./HistoryMenu.vue?vue&type=template&id=33ce7181&scoped=true&"
import script from "./HistoryMenu.vue?vue&type=script&lang=js&"
export * from "./HistoryMenu.vue?vue&type=script&lang=js&"
import style0 from "./HistoryMenu.vue?vue&type=style&index=0&id=33ce7181&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33ce7181",
  null
  
)

export default component.exports
export const featureFlags = Object.freeze({
  BlueSkyGasesEnabled: 'enable_bluesky_gases',
  AirAssureZeroingEnabled: 'enable_aa_zeroing',
  CurrentReadingsWidgetLimitIncreaseEnabled:
    'enable_increased_widget_limit_current_readings',
  MapWidgetLimitIncreaseEnabled: 'enable_increased_widget_limit_map',
  HistoryWidgetLimitIncreaseEnabled: 'enable_increased_widget_limit_history',
  DashboardVerticalCompactEnabled: 'enable_dashboard_vertical_compact',
  DashboardRestoreOnDragEnabled: 'enable_dashboard_restore_on_drag',
  EnableBulkDeviceRegistration: 'enable_bulk_device_registration',
  ExpandManageSubscriptionsRows: 'enable_expand_manage_subscriptions_rows',
  SmartBridgeEnabled: 'enable_smartbridge_option',
  DeviceLocationNameEnabled: 'enable_device_location_name',
  FileImportCountEnabled: 'enable_file_import_count',
  StudyManagerEnabled: 'enable_study_manager',
  EnableManageDevicesCurrentReadings: 'enable_manage_devices_current_readings',
  EnabledCurrentReadingsForConnectedDevices: 'enable_current_readings_for_connected_devices',
  EnabledHistoryChartForConnectedDevices: 'enable_history_chart_for_connected_devices',
  EnabledSharingForSBAndConnectedDevices: 'enable_sharing_for_connected_devices'
})

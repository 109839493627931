<template>
  <!-- unregister card -->
  <v-card class="unregister-warning" flat>
    <div class="unregister-subtitle">
      {{ $t('subscriptionCancel.cancelCard.warningSubtitle') }}
    </div>
    <div class="unregister-message">
      <template>
        <template>
          <p>
            {{ $t('subscriptionCancel.cancelCard.message.partOne') }}
          </p>
          <p>
            {{ $t('subscriptionCancel.cancelCard.message.partTwo') }}
          </p>
        </template>
      </template>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'SubscriptionCancelCard',
}
</script>

<style lang="scss" scoped>
.v-card {
  padding: 24px;
  min-height: 350px;

  .unregister-warning {
    .unregister-title {
      color: red;
      font-size: 18px;
    }
    .unregister-subtitle {
      color: red;
      font-size: 16px;
      margin-bottom: 16px;
    }
    .unregister-message {
      p {
        font-size: 14px;
        opacity: 0.86;
      }
    }
  }
}
</style>

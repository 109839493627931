<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
          class="menu-dropdown black--text"
          text
          v-on="on"
          data-cy="apiTableActionMenu"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <!-- Data Services Unregister -->
        <v-list-item
          :disabled="removeLock"
          @click.stop="openUnregisterDialog(false)"
          link
          data-cy="apiTableApiUnregisterAction"
        >
          <v-icon>mdi-map-marker-remove</v-icon>
          <v-list-item-title>
            {{ $t('apiServices.table.unregisterDataServices') }}
          </v-list-item-title>
        </v-list-item>
        <!-- TSI Link Unregister -->
        <v-list-item
          v-if="hasWebsiteSubscription"
          :disabled="removeLock"
          @click.stop="openUnregisterDialog(true)"
          link
          data-cy="apiTableWebsiteUnregisterAction"
        >
          <v-icon v-if="!removeLock">mdi-map-marker-remove</v-icon>
          <v-progress-circular
            v-else
            :size="20"
            indeterminate
            color="primary"
            class="action-item-loading"
          ></v-progress-circular>
          <v-list-item-title>
            {{ $t('apiServices.table.unregisterTSILink') }}
          </v-list-item-title>
        </v-list-item>
        <!-- TSI Link Register -->
        <v-tooltip left v-else :disabled="!disableWebsiteRegister()">
          <template v-slot:activator="{ on }">
            <v-list-item
              v-on="on"
              :disabled="removeLock"
              @click.stop="openRegisterDialog()"
              link
              data-cy="apiTableWebsiteRegisterAction"
              :class="{
                restrictedContent: disableWebsiteRegister(),
              }"
            >
              <v-icon>mdi-map-marker-plus</v-icon>
              <v-list-item-title>
                {{ $t('apiServices.table.registerTSILink') }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <span>
            {{ $t('apiServices.tooltipFreeTier') }}
          </span>
        </v-tooltip>
      </v-list>
    </v-menu>
    <!-- Dialogs -->
    <device-unregister-dialog
      :open="dialogs.deviceUnregisterDialog.open"
      :deviceId="dialogs.deviceUnregisterDialog.deviceId"
      :useWebsite="dialogs.deviceUnregisterDialog.useWebsite"
      @unregister="unregisterEvent"
      @close="closeUnregisterDialog"
    />
    <device-register-dialog
      :open="dialogs.registerDeviceDialog.open"
      :deviceName="dialogs.registerDeviceDialog.deviceName"
      :deviceModel="dialogs.registerDeviceDialog.deviceType"
      :deviceSerial="dialogs.registerDeviceDialog.idSerialNumber"
      :deviceId="dialogs.registerDeviceDialog.deviceId"
      @close="dialogs.registerDeviceDialog.open = false"
    />
  </div>
</template>

<script>
import DeviceUnregisterDialog from '../deviceUnregister/DeviceUnregisterDialog.vue'
import DeviceRegisterDialog from '../registerDevice/registerDialog.vue'

export default {
  name: 'ApiActionMenu',
  components: {
    'device-register-dialog': DeviceRegisterDialog,
    'device-unregister-dialog': DeviceUnregisterDialog,
  },
  props: {
    deviceId: String,
    websiteSubscription: String,
    hasFreeTier: Boolean,
    removeLock: Boolean,
  },
  data() {
    return {
      dialogs: {
        registerDeviceDialog: {
          open: false,
          deviceType: '',
          idSerialNumber: '',
        },
        deviceUnregisterDialog: {
          open: false,
          deviceId: '',
          useWebsite: true,
        },
      },
    }
  },
  methods: {
    openUnregisterDialog(useWebsite) {
      this.dialogs.deviceUnregisterDialog.deviceId = this.deviceId
      this.dialogs.deviceUnregisterDialog.useWebsite = useWebsite
      this.dialogs.deviceUnregisterDialog.open = true
    },
    closeUnregisterDialog() {
      this.dialogs.deviceUnregisterDialog.open = false
    },
    unregisterEvent() {
      // for now, nothing to do
    },
    openRegisterDialog() {
      // If user has remaining subscriptions open register modal
      if (!this.disableWebsiteRegister()) {
        this.dialogs.registerDeviceDialog.deviceId = this.deviceId
        this.dialogs.registerDeviceDialog.idSerialNumber = this.$store.getters[
          'devices/getSerialByDeviceId'
        ](this.deviceId)
        const deviceModel = this.$store.getters['devices/getModelByDeviceId'](
          this.deviceId
        )
        this.dialogs.registerDeviceDialog.deviceType =
          this.$store.getters['devicemodels/getModelName'](deviceModel)
        this.dialogs.registerDeviceDialog.open = true
      }
    },
    disableWebsiteRegister() {
      const model = this.$store.getters['devices/getModelByDeviceId'](
        this.deviceId
      )
      const canAddToWebsite =
        this.$store.getters['subscriptions/canAddDeviceToWebsiteByModel'](model)
      // Allow website allowances for devices with corresponding subscription type or 1 free tier device
      return !this.hasQtyRemaining && !canAddToWebsite
    },
  },
  computed: {
    hasWebsiteSubscription() {
      return this.websiteSubscription || this.hasFreeTier
    },
    hasQtyRemaining() {
      return this.$store.getters['subscriptions/hasQtyRemaining']
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-dropdown {
  min-width: 10px !important;
  padding: 0 0.4rem !important;
}

.action-item-loading {
  margin-right: 12px;
  margin-left: 4px;
}

.restrictedContent {
  cursor: not-allowed;
  opacity: 0.36;
}
</style>

import HomePublic from '@/views/home-public.vue'
import HomePrivate from '@/views/home-private.vue'
import Dashboard from '@/views/dashboard.vue'
import AlertHistory from '@/views/alert-history.vue'
import ManageDevices from '@/views/manage-devices.vue'
import ManageSubscriptions from '@/views/manage-subscriptions.vue'
import HelpCenter from '@/views/help-center.vue'
import NonVerified from '@/views/non-verified.vue'
import UserCreate from '@/views/user-create.vue'
import AccountCreation from '@/views/account-creation.vue'
import ReleaseNotes from '@/views/release-notes.vue'
import API from '@/views/api.vue'
import AdministrationPanel from '@/views/admin-panel.vue'
import DeveloperPanel from '@/views/developer-panel.vue'
import StudyManager from '@/views/study-manager.vue'


Vue.component('HomePublic', HomePublic)
Vue.component('Dashboard', Dashboard)
Vue.component('AlertHistory', AlertHistory)
Vue.component('ManageDevices', ManageDevices)
Vue.component('ManageSubscriptions', ManageSubscriptions)
Vue.component('HelpCenter', HelpCenter)
Vue.component('HomePrivate', HomePrivate)
Vue.component('NonVerified', NonVerified)
Vue.component('UserCreate', UserCreate)
Vue.component('AccountCreation', AccountCreation)
Vue.component('ReleaseNotes', ReleaseNotes)
Vue.component('API', API)
Vue.component('AdministrationPanel', AdministrationPanel)
Vue.component('DeveloperPanel', DeveloperPanel)
Vue.component('StudyManager', StudyManager)

import Vue from 'vue'
import axios from 'axios'

const getComponentConstructor = (name) => {
  return Vue?.options?.components[name]
}

// Should only be used once in main.js during initialization
// Access the routes from this.$router?.options?.routes in Vue components
export const getRouter = async () => {
  const applicationId = process.env.VUE_APP_APPLICATION_ID

  const result = await axios.get(
    `${process.env.VUE_APP_APPLICATIONS_HOST}/applications/${applicationId}/navigation`
  )

  const routes = []

  if (result.status >= 200 && result.status <= 299) {
    for (let i = 0; i < result.data.length; i++) {
      const route = result.data[i]
      route.component = getComponentConstructor(route.component)
      // add to routes only if component exists
      if (route.component || route.path === '*') {
        routes.push(route)
      }
    }
  } else {
    // eslint-disable-next-line
    console.error('Failed to setup routes')
  }

  const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_PUBLIC_PATH,
    routes,
  })

  return router
}

<template>
  <v-row justify="center" align="center" height="100%">
    <v-card width="80%">
      <v-card-title class="title">Account Creation</v-card-title>
      <v-card-text>
        Account is being created. Once completed you will be logged out and
        required to login with your new credentials. You will be redirected to
        the login page when the countdown reaches zero.
      </v-card-text>
      <div class="countdown-container">
        <v-subheader>Logging out in</v-subheader>
        <div class="countdown">12</div>
      </div>
    </v-card>
  </v-row>
</template>

<script>
import { isAuthorized, emailVerified, accountExists } from '@fusion/auth'

export default {
  name: 'AccountCreation',
  mixins: [isAuthorized, emailVerified, accountExists],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
div {
  padding: 16px;
}

.v-subheader {
  margin-top: 16px;
  padding-bottom: 8px;
  font-size: 1.1rem;
  opacity: 0.76;
}

.v-card {
  padding-right: 0;
  @media (max-width: 791px) {
    padding: 0;
  }
}

.v-card__text {
  padding: 0 16px 0 16px;
}

.v-application .title {
  font-size: 24px !important;
  font-weight: 400;
}

.countdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown {
  font-size: 2.4rem;
  padding: 0 0 16px 0;
}
</style>

export const patchUpdateUser = function (userId, userData) {
  const body = JSON.stringify(userData)
  return this.$auth.getAccountId().then((accountId) => {
    return this.PATCH(
      `/accounts/${accountId}/users/${userId}`,
      body,
      {},
      {},
      process.env.VUE_APP_ACCOUNTS_HOST
    )
  })
}

export const postResetPassword = async function (passwordData) {
  const body = JSON.stringify(passwordData)
  const accountId = await this.$auth.getAccountId()
  const userId = await this.$auth.getUserId()
  return this.POST(
    `/accounts/${accountId}/users/${userId}/password`,
    body,
    {},
    {},
    process.env.VUE_APP_ACCOUNTS_HOST
  )
}

export const getUser = function (userId) {
  return this.$auth.getAccountId().then((accountId) => {
    return this.GET(
      `/accounts/${accountId}/users/${userId}`,
      {},
      {},
      process.env.VUE_APP_ACCOUNTS_HOST
    )
  })
}

export const patchUserFullName = function (userSettings) {
  const body = JSON.stringify(userSettings)
  return this.$auth.getAccountId().then((accountId) => {
    return this.$auth.getUserId().then((userId) => {
      return this.PATCH(
        `/accounts/${accountId}/users/${userId}`,
        body,
        {},
        {},
        process.env.VUE_APP_ACCOUNTS_HOST
      )
    })
  })
}

export const patchUserSettings = function (userSettings) {
  const body = JSON.stringify(userSettings)
  return this.$auth.getAccountId().then((accountId) => {
    return this.$auth.getUserId().then((userId) => {
      return this.PATCH(
        `/accounts/${accountId}/users/${userId}/settings`,
        body,
        {},
        {},
        process.env.VUE_APP_ACCOUNTS_HOST
      )
    })
  })
}

export const postUserSettings = function (userSettings) {
  const body = JSON.stringify(userSettings)
  return this.$auth.getAccountId().then((accountId) => {
    return this.$auth.getUserId().then((userId) => {
      return this.POST(
        `/accounts/${accountId}/users/${userId}/settings`,
        body,
        {},
        {},
        process.env.VUE_APP_ACCOUNTS_HOST
      )
    })
  })
}

export const getUserSettings = function () {
  return this.$auth.getAccountId().then((accountId) => {
    return this.$auth.getUserId().then((userId) => {
      return this.GET(
        `/accounts/${accountId}/users/${userId}/settings`,
        {},
        {},
        process.env.VUE_APP_ACCOUNTS_HOST
      )
    })
  })
}

<template>
  <v-hover
    v-slot:default="{ hover }"
    :open-delay="0"
    :close-delay="0"
    :value="val"
    v-if="eolStatus"
  >
    <span>
      <v-btn class="popover-status-icon" text @click="val = !val">
        <v-icon color="grey">mdi-alert-decagram</v-icon>
      </v-btn>
      <v-tooltip v-bind:value="hover" bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on"></span>
        </template>
        <span>
          {{ $t('device.warnings.sps.eol') }}
        </span>
      </v-tooltip>
    </span>
  </v-hover>
</template>

<script>
export default {
  props: {
    deviceId: String,
  },
  data() {
    return {
      val: false,
    }
  },
  computed: {
    id: function () {
      return this.deviceId || ''
    },
    eolStatus: function () {
      return this.$store.getters['devices/getSpsEoLByDeviceId'](this.id)
    },
  },
}
</script>

import { isDeviceConnected } from '../device-status'

export function getRSSI(deviceId, state) {
  if (state && state.data && state.data.cloud) {
    return {
      deviceId: deviceId,
      rssi: state.data.cloud.ap_rssi || null,
    }
  }

  return {
    deviceId: deviceId,
    rssi: null,
  }
}

export function getConnectionType(deviceId, state) {
  if (state?.data) {
    return {
      deviceId: deviceId,
      connectionType: state.data.connection_type || null,
    }
  }

  return {
    deviceId: deviceId,
    connectionType: null,
  }
}

export function getEoL(deviceId, state) {
  const eol = state?.data?.['bs-8143']?.spsEoL
  if (eol !== undefined) {
    return {
      deviceId: deviceId,
      spsEoL: eol,
    }
  }

  return {
    deviceId: deviceId,
    spsEoL: null,
  }
}

export function getFirmwareVersion(deviceId, state) {
  if (state?.data) {
    return {
      deviceId: deviceId,
      firmware: state.data.firmware_version || null,
    }
  }

  return {
    deviceId: deviceId,
    firmware: null,
  }
}

export function getConnectedSmartBridgeSerial(deviceId, state) {
  return {
    deviceId: deviceId,
    connectedSmartBridgeSerial: state?.data?.hasOwnProperty('gateway_serial') ? state.data.gateway_serial : null,
  }
}

export function getLastState(deviceId, state) {
  if (state) {
    return {
      deviceId: deviceId,
      timestamp: state.timestamp || null,
    }
  }

  return {
    deviceId: deviceId,
    timestamp: null,
  }
}

export function getTelemTxType(deviceId, state) {
  if (state?.data) {
    return {
      deviceId: deviceId,
      telemTxType: state.data.telem_tx_type || null,
    }
  }

  return {
    deviceId: deviceId,
    telemTxType: null,
  }
}

export function getIsConnected(deviceId, state, lastCommunicationTime) {
  return {
    deviceId: deviceId,
    isConnected: state?.data?.hasOwnProperty('connected') ? state.data.connected : isDeviceConnected(lastCommunicationTime),
  }
}

export function getBatteryCharging(deviceId, state) {
  return {
    deviceId: deviceId,
    batteryCharging: state?.data?.hasOwnProperty('battery_charging')
      ? state.data.battery_charging
      : null,
  }
}

export function getBatteryInstalled(deviceId, state) {
  return {
    deviceId: deviceId,
    batteryInstalled: state?.data?.hasOwnProperty('battery_installed')
      ? state.data.battery_installed
      : null,
  }
}

export function getBatteryLow(deviceId, state) {
  return {
    deviceId: deviceId,
    batteryLow: state?.data?.hasOwnProperty('battery_low')
      ? state.data.battery_low
      : null,
  }
}

export function getBatteryRemaining(deviceId, state) {
  return {
    deviceId: deviceId,
    batteryRemaining: state?.data?.hasOwnProperty('battery_remaining')
      ? state.data.battery_remaining
      : null,
  }
}

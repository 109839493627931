<template>
  <div class="multiple-devices-error-message">
    <div class="error-header">
      <v-icon color="red">
        {{ errorIcon }}
      </v-icon>
      <div class="error-title">
        {{ errorTitle }}
      </div>
    </div>
    <div v-if="errors" :class="`error-description ${errorType}`">
      <error-list v-if="errorType === 'list'" :errors="this.errors" />
      <span v-else>
        {{ formattedErrors[0] }}
        <a v-if="formattedErrors && formattedErrors.length >= 2"
          href="csv/Bulk device registration import template.csv" class="download-link" download>
          {{ formattedErrors[1] }}
        </a>
        <span v-if="formattedErrors && formattedErrors.length >= 3">
          {{ formattedErrors[2] }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import errorList from './content/errorList.vue'

export default {
  name: "MultipleDevicesErrorMessage",
  props: {
    icon: String,
    title: String,
    errors: [ String, Array ],
  },
  components: {
    'error-list': errorList,
  },
  computed: {
    errorTitle() {
      return this.title || this.$t('registerMultipleDevices.uploadFailed')
    },
    errorIcon() {
      return this.icon || 'mdi-alert-circle'
    },
    errorType() {
      return typeof this.errors === 'object' && this.errors.length ? 'list' : 'single'
    },
    formattedErrors() {
      if (this.errors) {
        return this.errors.split('-');
      }
      return []
    }
  }
}
</script>

<style lang="scss" scoped>
.multiple-devices-error-message {
  .error-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.3rem 0;
    height: 40%;

    .v-icon {
      font-size: 7vh;
    }
  }

  .error-title {
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .error-description.single {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1rem;
    padding: 0.5rem;
    height: 45%;
  }

  @media (max-width: 599px),
  (max-height: 770px) {
    .error-title {
      font-size: 1rem !important;
      margin-top: 0.5rem;
    }
  }

  @media (max-width: 599px) {
    .error-description.single {
      font-size: 0.9rem;
    }
  }

  @media (max-height: 469px) {
    .error-header {
      margin: 0.5rem 0;
    }

    .error-title {
      font-size: 0.9rem !important;
      margin: 0;
    }

    .error-description.single {
      font-size: 0.8rem;
      padding: 0 0.5rem;
      height: 35%;
    }

  }

  @media (min-height: 1000px) {
    .error-header {
      .v-icon {
        font-size: 3.5rem !important;
      }
    }
  }
}
</style>

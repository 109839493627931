function getPermissionsManager(store, permissions) {
  if (isLoggedInAs(store)) {
    permissions.isAllowed = logInAsIsAllowedFunction(store)
  }
  return permissions
}

function logInAsIsAllowedFunction(store) {
  return (slugs) => {
    if (!Array.isArray(slugs)) {
      slugs = [slugs]
    }
    const userPermissions = getLoggedInAsUserPermissions(store)
    return slugs.every(slug => userPermissions.includes(slug))
  }
}

function isLoggedInAs(store) {
  return store.getters['loginas/getIsLogInAs']
}

function getLoggedInAsUserPermissions(store) {
  return store.getters['loginas/getImpersonatedUserPermissions']
}

export {
  getPermissionsManager
}

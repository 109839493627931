<template>
  <v-card :class="{ 'ma-6': !$vuetify.breakpoint.xs }">
    <v-card-title class="titles">
      {{ $t('thirdPartyTable.titleThirdParty') }}
    </v-card-title>
    <v-container fluid fill-width>
      <v-row no-gutters fill-height>
        <v-col v-for="(service, index) in services" :key="index" cols="auto">
          <service-component class="service-component" data-cy="serviceComponent"
            :serviceName="service.serviceName"
            :serviceUrl="service.serviceUrl"
          ></service-component>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ServiceComponent from './serviceComponent.vue'

export default {
  name: 'ThirdPartyTable',
  data() {
    return {
      services: [
        {
          serviceName: "Auth0",
          serviceUrl: "https://status.auth0.com/",
        },
        {
          serviceName: "SendGrid",
          serviceUrl: "https://status.sendgrid.com/",
        },
        {
          serviceName: "Twilio",
          serviceUrl: "https://status.twilio.com/",
        },
        {
          serviceName: "Flexera",
          serviceUrl: "https://status.flexera.com/",
        },
        {
          serviceName: "MongoDB",
          serviceUrl: "https://status.mongodb.com/",
        },
        {
          serviceName: "ClearBlade",
          serviceUrl: "https://status.clearblade.com/",
        },
        {
          serviceName: "Apigee",
          serviceUrl: "https://status.apigee.com/",
        },
        {
          serviceName: "Google Maps",
          serviceUrl: "https://status.cloud.google.com/maps-platform/",
        },
        {
          serviceName: "GoDaddy",
          serviceUrl: "https://status.godaddy.com/",
        },
      ]
    }
  },
  components: {
    'service-component': ServiceComponent,
  },
}
</script>

<style lang="scss" scoped>
.row {
  padding: 20px;
}
.titles {
  font-weight: 300;
  margin-bottom: -25px;
}
</style>

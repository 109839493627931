import { getIso8601WithoutMilliseconds } from './device-config'

export const getTodayDateObject = () => {
  return new Date()
}

export const getQueryExpression = (start, end) => {
  let startQuery = getStartQuery(start)
  let endQuery = getEndQuery(end)
  let and = ''
  if (start && end) and = '&'

  return `${startQuery}${and}${endQuery}`
}
const getStartQuery = (start) => {
  const date = getIso8601WithoutMilliseconds(new Date(start))
  return start ? `start=${date}` : ''
}

const getEndQuery = (end) => {
  const date = getIso8601WithoutMilliseconds(new Date(end))
  return end ? `end=${date}` : ''
}

<template>
  <v-card-subtitle class="small-subtitle" data-cy="lastDeviceMeasurementText">
    <span :class="{ inactiveTimestamp: !activeLastHour }">
      <span class="lastMeasurementShort">
        {{ $t('map.popover.timestamp.lastMeasurementShort') }}:
      </span>
      <span class="lastMeasurement">
        {{ $t('map.popover.timestamp.lastMeasurement') }}:
      </span>
      <span class="value">
        {{
          timestamp != $t('map.popover.timestamp.lastMeasurementNever')
            ? new Date(timestamp).toLocaleString()
            : timestamp
        }}
      </span>
    </span>
  </v-card-subtitle>
</template>

<script>
import {
  activeLastHour,
  validateTimestamp,
} from '@/services/device-status'

export default {
  props: {
    deviceId: String,
  },
  computed: {
    lastContact() {
      return this.$store.getters['devices/getLastContactByDeviceId'](
        this.deviceId
      )
    },
    activeLastHour() {
      return activeLastHour(this.lastContact)
    },
    sensorReadings() {
      return this.$store.getters['devices/getSensorReadingsByDeviceId'](
        this.deviceId
      )
    },
    timestamp() {
      const noTS = this.$t('map.popover.timestamp.lastMeasurementNever')
      if (
        !Array.isArray(this.sensorReadings) ||
        this.sensorReadings.length === 0
      ) {
        return noTS
      } else {
        return validateTimestamp(this.sensorReadings[0].timestamp, noTS)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.lastMeasurementShort {
  display: none;
}
.lastMeasurement {
  display: unset;
}
.widget-sm,
.widget-xs,
.widget-xxs {
  .measurement {
    width: 96px;
    max-width: 96px;
  }
}
.widget-xs,
.widget-xxs {
  .lastMeasurementShort {
    display: unset;
  }
  .lastMeasurement {
    display: none;
  }
}

.inactiveTimestamp {
  color: #fa1717;

  .value {
    color: inherit;
    font-weight: inherit;
  }
}
</style>

import { errors } from '../../services/location'

export default {
  currentLocation: (state) => {
    if (state.allowed && !state.loading) {
      return { lat: state.lat, lng: state.lng }
    }
    if (state.allowed === false) {
      return { error: errors.disallowed }
    }

    return { error: errors.unknown }
  },
  loading: (state) => () => {
    return state.user.location.loading
  },
}

<template>
  <div class="in-progress-message">
    <div>
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-if="messages && messages.length">
      <p v-for="(message, index) in messages" :key="index">{{ message }}</p>
    </div>
  </div>

</template>

<script>

export default {
  name: "MultipleDevicesInProgressMessage",
  props: {
    messages: Array
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/global-variables.scss';

.in-progress-message {
  div:nth-child(1) {
    height: 75%;
    position: relative;

    div:nth-child(1) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30% !important;
      height: 50% !important;
    }
  }

  div:nth-child(2) {
    height: 25%;
    text-align: center;

    p {
      margin: 0;
      font-size: 1rem;
    }
  }

  @media (max-height: 469px),
  (max-width: 768px) {
    div:nth-child(1) {
      height: 60%;
    }

    div:nth-child(2) {
      height: 40%;
      padding: 0;

      p {
        font-size: 0.9rem;
      }
    }
  }

  @media (max-height: 469px) {
    div:nth-child(1) {
      height: 45%;

      div:nth-child(1) {
        width: 5% !important;
      }
    }

    div:nth-child(2) {
      height: 55%;
    }
  }
}
</style>

import permissionsOptions from './permissions'

const getAuthOptions = (router) => {
  let authOptions = {
    onRedirectCallback: (appState) => {
      router
        .push(
          appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
        )
        .catch(() => {})
    },
    accountsHost: process.env.VUE_APP_ACCOUNTS_HOST,
    audience: process.env.VUE_APP_API_AUDIENCE,
    clientID: process.env.VUE_APP_API_CLIENT,
    domain: process.env.VUE_APP_API_DOMAIN,
    useRefreshTokens: process.env.VUE_APP_API_USE_REFRESH_TOKENS.toLowerCase() === "true",
    permissionsOptions,
  }

  if (window.Cypress && process.env.VUE_APP_AUTH_CACHE) {
    authOptions.cacheLocation = process.env.VUE_APP_AUTH_CACHE
  }

  return authOptions
}

export default getAuthOptions

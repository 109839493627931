const baseServicesUrl = "/statuses/"

export const getAccountsForAdminPanel = async function () {
  return this.GET(`/admin-panel`, {}, {}, process.env.VUE_APP_ACCOUNTS_HOST)
}

export const healthChecker = async function(service) {
  const res = await this.GET(`${baseServicesUrl}${service}`, {}, {}, process.env.VUE_APP_SERVICES_HOST)
  if (res.ok) {
    const data = await res.json();
    return data.status;
  } else {
    return 'error'
  }
}

<template>
  <base-dialog
    @close="close"
    :open="open"
    :title="$t('map.deviceSettings.title')"
    icon="mdi-delete"
    data-cy="deviceUnregisterDialog"
    :fullscreen="$vuetify.breakpoint.xs"
    :persistentOverlay="true"
  >
    <device-unregister-card :deviceId="deviceId" :useWebsite="useWebsite" />
    <template v-slot:actions>
      <device-unregister-actions
        :deviceId="deviceId"
        :useWebsite="useWebsite"
        @unregister="handleUnregister"
        @close="close"
      />
    </template>
  </base-dialog>
</template>

<script>
import Dialog from '../dialogs/Dialog.vue'
import DeviceUnregisterCard from './DeviceUnregisterCard.vue'
import DeviceUnregisterActions from './DeviceUnregisterActions.vue'

export default {
  name: 'DeviceUnregisterDialog',
  props: {
    open: Boolean,
    deviceId: String,
    useWebsite: { type: Boolean, default: true },
  },
  components: {
    'base-dialog': Dialog,
    'device-unregister-card': DeviceUnregisterCard,
    'device-unregister-actions': DeviceUnregisterActions,
  },
  methods: {
    close() {
      this.$emit('close')
    },
    handleUnregister() {
      this.$emit('unregister')
      this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card__title.tab-content__title {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0 0 16px 0;
    opacity: 0.56;
  }
}
</style>

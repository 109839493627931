export default {
  updateAccountsTableData(state, data) {
    state.accounts = data
  },
  updateAuth0Status(state, status) {
    state.auth0Status = status
  },
  updateSendGridStatus(state, status) {
    state.sendgridStatus = status
  },
  updateTwilioStatus(state, status) {
    state.twilioStatus = status
  },
  updateFlexeraStatus(state, status) {
    state.flexeraStatus = status
  },
  updateMongoDBStatus(state, status) {
    state.mongodbStatus = status
  },
  updateClearBladeStatus(state, status) {
    state.clearbladeStatus = status
  },
  updateApigeeStatus(state, status) {
    state.apigeeStatus = status
  },
  updateGoogleMapsStatus(state, status) {
    state.googlemapsStatus = status
  },
  updateGoDaddyStatus(state, status) {
    state.godaddyStatus = status
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-title',[_vm._t("menu"),_c('div',{staticClass:"titleContainer"},[(_vm.canAccess(_vm.access.SHARED, _vm.accessLevel))?_c('v-tooltip',{attrs:{"bottom":"","max-width":600,"disabled":!_vm.isChildOverflowing(_vm.$refs.deviceName)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({ref:"deviceName",staticClass:"device-name",class:{
            'device-name__public': _vm.isAccess(_vm.access.SHARED, _vm.accessLevel),
            mobile: _vm.$vuetify.breakpoint.xs,
          }},on),[_c('span',[_vm._v(_vm._s(_vm.name))])])]}}],null,false,596499631)},[_c('span',[_vm._v(_vm._s(_vm.name))])]):_c('div',{staticClass:"device-name__public",attrs:{"data-cy":"devicePopoverName"}},[_vm._v(" "+_vm._s(_vm.$t('map.popover.publicDevice'))+" ")]),_c('div',{staticClass:"device-type",class:{
        'device-type__public':
          _vm.isAccess(_vm.access.NONE, _vm.accessLevel) ||
          _vm.isAccess(_vm.access.SHARED, _vm.accessLevel),
      }},[_vm._v(" "+_vm._s(_vm.deviceType)+" ")]),(_vm.serial)?_c('div',{staticClass:"device-serial",class:{'device-serial__shared': _vm.isAccess(_vm.access.SHARED, _vm.accessLevel)},attrs:{"data-cy":"devicePopoverSerial"}},[_vm._v(" "+_vm._s(_vm.serial)+" ")]):_vm._e()],1),_c('v-spacer'),(_vm.canAccess(_vm.access.FULL, _vm.accessLevel))?_c('div',[_vm._t("status")],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <base-icon
    :showIcon="showIcon"
    imgSrc="/img/icons/offline_studies_icon.svg"
    imgAlt="Offline Studies Icon"
    :iconText="iconText"
    tooltipContentClass="offline-studies-status-tooltip"
    dataCy="offlineStudiesStatus"
  />
</template>

<script>
import BaseIcon from './BaseIcon.vue'

export default {
  name: 'OfflineStudiesStatus',
  props: {
    deviceId: { type: String, default: '' },
  },
  components: {
    'base-icon': BaseIcon,
  },
  computed: {
    smartBridgeDeviceId() {
      return this.$store.getters['devices/getConnectedSmartBridgeIdByDeviceId'](
        this.deviceId
      )
    },
    parentDeviceId() {
      return this.smartBridgeDeviceId || this.deviceId
    },
    isAutoUpload() {
      return this.$store.getters['devices/getIsAutoUploadByDeviceId'](
        this.parentDeviceId
      )
    },
    isDeviceConnectedToSmartBridge() {
      return !!this.smartBridgeDeviceId
    },
    isSmartBridgeConnected() {
      return this.$store.getters['devices/getIsConnectedByDeviceId'](
        this.parentDeviceId
      )
    },
    iconText() {
      return this.isDeviceConnectedToSmartBridge
        ? this.getConnectedDeviceIconText(this.deviceId)
        : this.getDeviceIconText()
    },
    showIcon() {
      return !!this.iconText
    },
  },
  methods: {
    getDeviceIconText() {
      const connectedDeviceIds = this.$store.getters[
        'devices/getSmartBridgeConnectedDeviceIdsByDeviceId'
      ](this.deviceId)
      for (const connectedDeviceId of connectedDeviceIds) {
        if (this.getConnectedDeviceIconText(connectedDeviceId)) {
          return this.isAutoUpload
            ? this.$t('device.icons.offlineStudiesStatus.deviceAutoUploadOn')
            : this.$t('device.icons.offlineStudiesStatus.deviceAutoUploadOff')
        }
      }

      return ''
    },
    getConnectedDeviceIconText(deviceId) {
      const isDeviceConnected =
        this.$store.getters['devices/getIsConnectedByDeviceId'](deviceId)
      const isOfflinePresent =
        this.$store.getters['devices/getIsOfflinePresentByDeviceId'](deviceId)
      const telemTxType =
        this.$store.getters['devices/getTelemTxTypeByDeviceId'](deviceId)

      if (
        !this.isSmartBridgeConnected ||
        !isDeviceConnected ||
        !isOfflinePresent
      ) {
        return ''
      }

      if (this.isAutoUpload && telemTxType === 2) {
        return this.$t(
          'device.icons.offlineStudiesStatus.connectedDeviceAutoUploadOn'
        )
      }

      if (
        !this.isAutoUpload &&
        typeof telemTxType === 'number' &&
        telemTxType !== 2
      ) {
        return this.$t(
          'device.icons.offlineStudiesStatus.connectedDeviceAutoUploadOff'
        )
      }

      return ''
    },
  },
}
</script>

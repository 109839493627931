import {
  getDeviceDetails,
  getLatestTelemetry,
  getTelemetryAverages,
  getPercentChanged,
} from '../../services/device-metadata'

import {
  getRSSI,
  getConnectionType,
  getEoL,
  getFirmwareVersion,
  getConnectedSmartBridgeSerial,
  getLastState,
  getAllDevices,
  getUserDevices,
  getTelemTxType,
  getIsConnected,
  getBatteryCharging,
  getBatteryInstalled,
  getBatteryLow,
  getBatteryRemaining
} from '../../services/device-service'

import {
  getMeasurementsFormattedResponse,
  getSensorStatusFormattedResponse,
  getDeviceStatusFormattedResponse,
  getSensorsV1Format
} from '../devices/helper'

export default {
  // setAllDevices updates all devices data in Vuex, including latest telemetry.
  async setAllDevices({ commit, getters }) {
    const data = await getAllDevices()

    commit('setAllDevices', data.devices)
    commit('setDevices', data.devices)
    commit('setSubscriptionMetadata', data.activation)
    commit('setIotCoreData', data.iotCoreData)
    commit('deleteRemovedDevices', data.devices)
    for (const device of data.state) {
      const { deviceId, state } = device

      const rssi = getRSSI(deviceId, state)
      const connectionType = getConnectionType(deviceId, state)
      const eol = getEoL(deviceId, state)
      const firmwareVersion = getFirmwareVersion(deviceId, state)
      const connectedSmartBridgeSerial = getConnectedSmartBridgeSerial(deviceId, state)
      const lastState = getLastState(deviceId, state)
      const telemTxType = getTelemTxType(deviceId, state)
      const isConnected = getIsConnected(deviceId, state, getters.getLastCommunicationTimeByDeviceId(deviceId))
      const batteryCharging = getBatteryCharging(deviceId, state)
      const batteryInstalled = getBatteryInstalled(deviceId, state)
      const batteryLow = getBatteryLow(deviceId, state)
      const batteryRemaining = getBatteryRemaining(deviceId, state)

      commit('updateRSSI', rssi)
      commit('updateConnectionType', connectionType)
      commit('updateEoL', eol)
      commit('updateFirmware', firmwareVersion)
      commit('updateConnectedSmartBridgeSerial', connectedSmartBridgeSerial)
      commit('updateLastState', lastState)
      commit('updateTelemTxType', telemTxType)
      commit('updateIsConnected', isConnected)
      commit('updateBatteryCharging', batteryCharging)
      commit('updateBatteryInstalled', batteryInstalled)
      commit('updateBatteryLow', batteryLow)
      commit('updateBatteryRemaining', batteryRemaining)
    }
  },
  async setAllUserDevices({ commit }) {
    const {devices, activations} = await getUserDevices()
    commit('setAllUserDevices', devices)
    commit('setUserDevices', devices)
    commit('setSubscriptionMetadata', activations)
  },
  setDevice({ commit }, device) {
    commit('setDevice', device)
  },
  updateDeviceCoords({ commit }, data) {
    commit('updateCoords', data)
  },
  updateSensorReadings({ commit }, data) {
    commit('updateSensorReadings', data)
  },
  updateRSSI({ commit }, data) {
    commit('updateRSSI', data)
  },
  updateConnectionType({ commit }, data) {
    commit('updateConnectionType', data)
  },
  updateEoL({ commit }, data) {
    commit('updateEoL', data)
  },
  updateUserCals({ commit }, data) {
    commit('updateUserCals', data)
  },
  updateFirmware({ commit }, data) {
    commit('updateFirmware', data)
  },
  updateAverages({ commit }, data) {
    commit('updateAverages', data)
  },
  updatePercentChanged({ commit }, data) {
    commit('updatePercentChanged', data)
  },
  updateIsPublic({ commit }, data) {
    commit('updatePublic', data)
  },
  updatePrivacyZone({ commit }, data) {
    commit('updatePrivacyZone', data)
  },
  updateLastTelemetry({ commit }, data) {
    commit('updateLastTelemetry', data)
  },
  updateLastState({ commit }, data) {
    commit('updateLastState', data)
  },
  updateLastTelemetryStudyId({ commit }, data) {
    commit('updateLastTelemetryStudyId', data)
  },
  updateDeviceStatus({ commit }, data) {
    commit('updateDeviceStatus', data)
  },
  updateSensorStatus({ commit }, data) {
    commit('updateSensorStatus', data)
  },
  updateMetadata({ commit }, data) {
    commit('updateIndoor', data)
    commit('updateName', data)
    commit('updateLocationName', data)
    commit('updateModel', data)
    commit('updateSubmodel', data)
    commit('updateSerial', data)
    commit('updatePublic', data)
    commit('updateShared', data)
  },
  deleteDeviceById({ commit }, id) {
    commit('deleteDeviceById', id)
  },
  async getTelemetryAveragesAndUpdate({ commit }, deviceId) {
    const averages = await getTelemetryAverages(deviceId)
    commit('updateAverages', { deviceId, averages })
  },
  async getPercentChangedAndUpdate({ commit }, deviceId) {
    const percentChanged = await getPercentChanged(deviceId)
    commit('updatePercentChanged', { deviceId, percentChanged })
  },
  async getLatestTelemetryAndUpdate({ commit }, deviceId) {
    const { readings, timestamp, lastTelemetryStudyId } = await getLatestTelemetry(deviceId)
    if (readings && readings.sensors) {
      let measurements = []
      let sensorStatus = []

      const sensors = getSensorsV1Format(readings.sensors)
      sensors.forEach((sensor) => {
        measurements = measurements.concat(
          getMeasurementsFormattedResponse(sensor.measurements)
        )
        sensorStatus = sensorStatus.concat(
          getSensorStatusFormattedResponse(sensor)
        )
      })

      // fish dev status out and make  new commit,
      // fish sensor statuses out and make  new commit
      commit('updateSensorReadings', { deviceId, measurements })
      commit('updateLastTelemetry', { deviceId, timestamp })
      commit('updateLastTelemetryStudyId', { deviceId, lastTelemetryStudyId })
      commit('updateDeviceStatus', {
        deviceId,
        deviceStatus: getDeviceStatusFormattedResponse(readings),
      })
      commit('updateSensorStatus', { deviceId, sensorStatus })
    }
  },
  async getDeviceDetailsAndUpdate({ commit }, deviceId) {
    const {
      name,
      locationName,
      indoor,
      isPublic,
      rssi,
      connectionType,
      spsEoL,
      firmware,
      timestamp,
      model,
      serial,
      zeroAppliedAt,
      zeroState,
      lastCalibrationTimestamp,
    } = await getDeviceDetails(deviceId)
    commit('updateIndoor', { deviceId, indoor })
    commit('updateName', { deviceId, name })
    commit('updateLocationName', { deviceId, locationName })
    commit('updatePublic', { deviceId, isPublic })

    //state
    commit('updateRSSI', { deviceId, rssi })
    commit('updateConnectionType', { deviceId, connectionType })
    commit('updateEoL', { deviceId, spsEoL })
    commit('updateFirmware', { deviceId, firmware })
    commit('updateLastState', { deviceId, timestamp })
    commit('updateModel', { deviceId, model })
    commit('updateSerial', { deviceId, serial })
    commit('updateZeroAppliedAt', { deviceId, zeroAppliedAt })
    commit('updateZeroState', { deviceId, zeroState })
    commit('updateLastCalibrationTimestamp', { deviceId, lastCalibrationTimestamp })
  },
}

<template>
  <div class="zero-actions-row">
    <v-btn @click="cancel()" outlined text data-cy="zeroCancelButton">{{
      $t('buttons.cancel')
    }}</v-btn>
    <v-spacer></v-spacer>
    <v-btn
      @click="confirm()"
      class="primary white--text"
      text
      data-cy="zeroConfirmButton"
      >{{ $t('buttons.ok') }}</v-btn
    >
  </div>
</template>

<script>
export default {
  name: 'DeviceZeroConfirmationActions',
  methods: {
    confirm() {
      this.$emit('confirm')
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
.zero-actions-row {
  display: flex;
  width: 100%;

  .v-btn {
    min-width: 120px !important;
    margin-left: 8px;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }
}
</style>

<template>
  <v-card flat class="calibration-card">
    <v-card-title class="tab-content__title">
      {{ $t('map.deviceSettings.calibrationFactors.title') }}
    </v-card-title>
    <v-card-subtitle>
      <i18n
        path="map.deviceSettings.calibrationFactors.subtitle"
        tab="div"
        class="hint-text"
        for="map.deviceSettings.calibrationFactors.faq"
      >
        <a @click="navigateFaq">
          {{ $t('map.deviceSettings.calibrationFactors.faq') }}
        </a>
      </i18n>
    </v-card-subtitle>
    <v-row class="calibration-header">
      <v-col :cols="columnWidth"></v-col>
      <v-col :cols="columnWidth" class="multi-factor column-header">
        <label>
          {{ $t('map.deviceSettings.calibrationFactors.multiFactor') }}
        </label>
      </v-col>
      <v-col :cols="columnWidth" class="offset column-header">
        <label>{{ $t('map.deviceSettings.calibrationFactors.offset') }}</label>
      </v-col>
      <v-col v-if="showAutoCal" :cols="columnWidth">
        <div class="auto-cal-tooltip">
          <label>{{ $t('map.deviceSettings.calibrationFactors.autoCal') }}</label>
          <v-tooltip bottom max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <span icon v-bind="attrs" v-on="on">
                <v-icon data-cy="autoCalTooltip">mdi-information-outline</v-icon>
              </span>
            </template>
            <span>
              {{ $t('map.deviceSettings.calibrationFactors.autoCalTooltip') }}
            </span>
          </v-tooltip>
        </div>
      </v-col>
      <v-col v-if="showZeroing" :cols="columnWidth">
        <label class="zero-column-header" data-cy="zeroingColumnHeader">{{
          $t('map.deviceSettings.zeroing.zero')
        }}</label>
      </v-col>
    </v-row>

    <fusion-scroll class="calibration-scroll">
      <v-tooltip :open-on-click="true" bottom :disabled="hasPermission">
        <template v-slot:activator="{ on, attrs }">
          <div class="calibration-body" v-bind="attrs" v-on="on">
            <v-row
              v-for="item in userCals"
              :key="item.name"
              align="center"
              data-cy="userCalRows"
            >
              <v-col :cols="columnWidth">
                <div class="label">
                  <img
                    :src="icons[item.name]"
                    class="measurement-icon"
                    alt="Measurement Icon"
                  />
                  <span>{{ $t(`readings.${item.name}`) }}</span>
                </div>
              </v-col>
              <v-col :cols="columnWidth" class="multi-factor cell">
                <v-text-field
                  v-model="item.multiFactor"
                  name="multiplicationFactor"
                  :disabled="item.multiFactor === undefined || !hasPermission"
                  :rules="multiFactorRules[item.name]"
                  :hide-details="true"
                  @focus="setHelpText(item.name, 'multiplicationFactor')"
                  @blur="clearHelpText"
                ></v-text-field>
              </v-col>
              <v-col :cols="columnWidth" class="offset cell">
                <v-text-field
                  v-model="item.offset"
                  name="offset"
                  :disabled="item.offset === undefined || !hasPermission"
                  :rules="offsetRules[item.name]"
                  :hide-details="true"
                  @focus="setHelpText(item.name, 'offset')"
                  @blur="clearHelpText"
                ></v-text-field>
              </v-col>
              <v-col v-if="showAutoCal" :cols="columnWidth">
                <v-switch v-if="sensorCanAutoCal(item.name)" :disabled="!hasPermission" v-model="item.ascCal" name="ascCal"></v-switch>
              </v-col>
              <v-col :cols="columnWidth" class="zero-cell" v-if="showZeroing">
                <v-tooltip bottom v-if="sensorCanZero(item.name)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="requestZero(item.name, true)"
                      v-bind="attrs"
                      v-on="on"
                      :class="getButtonStyles(item.name, true)"
                      :data-cy="`addUserZeroButton_${item.name}`"
                    >
                      <span class="zero-btn-overlay">0</span>
                      <v-icon>mdi-restore</v-icon>
                    </v-btn>
                  </template>
                  <span data-cy="zeroingTooltipText">
                    {{ getZeroButtonTooltipText(item.name) }}
                  </span>
                </v-tooltip>
                <v-tooltip bottom v-if="showRemoveUserZeroButton(item.name)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="requestZero(item.name, false)"
                      icon
                      :class="`${getButtonStyles(item.name, false)} request-factory-zero-btn`"
                      v-bind="attrs"
                      v-on="on"
                      :data-cy="`removeUserZeroButton_${item.name}`"
                    >
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    $t('map.deviceSettings.zeroing.tooltip.userRemove')
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </div>
        </template>
        <span>{{
          $t('map.deviceSettings.calibrationFactors.upgradeTooltip')
        }}</span>
      </v-tooltip>
    </fusion-scroll>

    <v-row v-if="helpText" :class="`calibration-footer ${calibrationFooterClass}`">
      <v-col cols="12">
        <p class="help-text">{{ helpText }}</p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { measurementIcon } from '@/services/measurement-icon'
import {
  AUTOCAL_SENSORS,
  DEFAULT_SLOPE,
  DEFAULT_OFFSET,
} from '@/services/device-calibration'
import { ZEROING_SENSORS, ZERO_STATES } from '@/services/device-zeroing'
import { SlugsEnum } from '@/permissions/SlugsEnum'
import { featureFlags } from '@/services/feature-flags'

const NUMERIC_REGEXES = {
  0: /^-?[\d]{1,4}?$/,
  1: /^-?[\d]{1,3}(\.[\d])?$/,
  2: /^-?[\d]{1,3}(\.[\d]{1,2})?$/,
  3: /^-?[\d]{1,3}(\.[\d]{1,3})?$/,
}

const DEFAULT_COLUMNS_NUMBER = 3
const DEFAULT_COLUMNS_WIDTH = 4

export default {
  name: 'DeviceCalibration',
  props: ['deviceModel', 'userCals', 'permissions', 'userZeros'],
  data() {
    return {
      rules: {
        required: (v) => v !== '' || this.$t('validation.required'),
      },
      icons: measurementIcon,
      helpTextParams: {
        name: '',
        factor: '',
      },
      defaults: {
        multiplicationFactor: DEFAULT_SLOPE,
        offset: DEFAULT_OFFSET,
      },
    }
  },
  watch: {
    userCals: {
      handler: function (newVal) {
        for (const uc of newVal) {
          const measurement = uc.name
          const mf = uc.multiFactor
          const offset = uc.offset
          const mfRules = this.multiFactorRules[measurement]
          const offsetRules = this.offsetRules[measurement]
          if (mfRules) {
            for (const rule of mfRules) {
              const msg = rule(mf)
              if (
                msg === this.$t('validation.invalidEntry') ||
                msg === this.$t('validation.required')
              ) {
                this.$emit('validated', { valid: false, type: 'calibration' })
                return false
              }
            }
          }
          if (offsetRules) {
            for (const rule of offsetRules) {
              const msg = rule(offset)
              if (
                msg === this.$t('validation.invalidEntry') ||
                msg === this.$t('validation.required')
              ) {
                this.$emit('validated', { valid: false, type: 'calibration' })
                return false
              }
            }
          }
        }
        this.$emit('validated', { valid: true, type: 'calibration' })
        return true
      },
      deep: true,
    },
  },
  computed: {
    showAutoCal() {
      return (
        this.modelSupportsAutoCal
      )
    },
    showZeroing() {
      return (
        (this.modelSupportsZeroing && this.enableZeroing) ||
        this.deviceModel === '8145' // This is temporary, as we do not want 8145 included in the enableZeroing feature flag
      )
    },
    modelSupportsAutoCal() {
      return this.$store.getters['devicemodels/modelSupportsAutoCal'](this.deviceModel)
    },
    modelSupportsZeroing() {
      return this.$store.getters['devicemodels/modelSupportsZeroing'](
        this.deviceModel
      )
    },
    enableZeroing() {
      return this.$store.getters['featureFlags/getFeatureFlagBySlug'](
        featureFlags.AirAssureZeroingEnabled
      )
    },
    numberOfColumns() {
      let columns = DEFAULT_COLUMNS_NUMBER
      this.showZeroing && columns++
      this.showAutoCal && columns++
      return columns
    },
    columnWidth() {
      return DEFAULT_COLUMNS_WIDTH - (this.numberOfColumns - DEFAULT_COLUMNS_NUMBER)
    },
    multiFactorRules() {
      const allRules = {}
      Object.entries(this.calibrationLimits).forEach(([key, cal]) => {
        if (cal.multiplicationFactor) {
          const mfRule = (v) => {
            return (
              (NUMERIC_REGEXES[cal.multiplicationFactor.precision].test(v) &&
                v >= cal.multiplicationFactor.min &&
                v <= cal.multiplicationFactor.max) ||
              this.$t('validation.invalidEntry')
            )
          }
          allRules[key] = [this.rules.required, mfRule]
        } else {
          allRules[key] = []
        }
      })
      return allRules
    },
    offsetRules() {
      const allRules = {}
      Object.entries(this.calibrationLimits).forEach(([key, cal]) => {
        if (cal.offset) {
          const offsetRule = (v) => {
            return (
              (NUMERIC_REGEXES[cal.offset.precision].test(v) &&
                v >= cal.offset.min &&
                v <= cal.offset.max) ||
              this.$t('validation.invalidEntry')
            )
          }
          allRules[key] = [this.rules.required, offsetRule]
        } else {
          allRules[key] = []
        }
      })
      return allRules
    },
    calibrationLimits() {
      return this.$store.getters['devicemodels/getCalibrationLimits'](
        this.deviceModel
      )
    },
    hasPermission: function () {
      return this.permissions.includes(SlugsEnum.CalibrationWrite)
    },
    helpText() {
      let message = ''
      if (this.helpTextParams.name && this.helpTextParams.factor) {
        const { name, factor } = this.helpTextParams
        const limits = this.calibrationLimits[name]
        if (limits[factor]) {
          const measurementName = this.$t(`readings.${name}`)
          const decimalPlacesText = this.$tc(
            'map.deviceSettings.calibrationFactors.decimalPlaces',
            limits[factor].precision
          )
          message = this.$t('map.deviceSettings.calibrationFactors.helpText', {
            measurement: measurementName,
            rangeLow: limits[factor].min,
            rangeHigh: limits[factor].max,
            decimalPlaces: decimalPlacesText,
            default: this.defaults[factor],
          })
          const unitNoteKey = 'map.deviceSettings.calibrationFactors.helpTextOffsetNote.' + name
          if (this.$te(unitNoteKey) && factor === 'offset') {
            message = `${message} ${this.$t(unitNoteKey)}`
          }
        }
      }
      return message
    },
    calibrationFooterClass() {
      return this.userCals?.length > 4 ? 'fixed-position' : ''
    }
  },
  methods: {
    preventCalibrationFactor(event) {
      const keypress = event.keyCode || event.which || event.charCode
      if (keypress > 57) {
        // NOTE: may need to modify this to prevent input like "--4.323" or "0100..43" etc
        event.preventDefault()
      }
    },
    navigateFaq() {
      let faqUrl = this.$store.getters['devicemodels/getFaqLink'](
        this.deviceModel
      )
      if (faqUrl) {
        window.open(faqUrl)
      }
    },
    setHelpText(name, factor) {
      this.helpTextParams = {
        name,
        factor,
      }
    },
    clearHelpText() {
      this.helpTextParams = {
        name: '',
        factor: '',
      }
    },
    requestZero(sensorName, zeroValue) {
      if (
        this.hasPermission &&
        !this.hasUserZeroRequestedState(sensorName) &&
        !this.hasFactoryZeroRequestedState(sensorName)
      ) {
        if (
          !this.hasUnsavedUserZero(sensorName) &&
          !this.hasUnsavedFactoryZero(sensorName)
        ) {
          this.$emit('requestZero', sensorName, zeroValue)
        } else {
          // if the user has already requested a zero, remove the request upon clicking icon again
          this.userZeros[sensorName].userzero = null
        }
      }
    },
    sensorCanZero(sensorName) {
      return ZEROING_SENSORS.includes(sensorName)
    },
    sensorCanAutoCal(sensorName) {
      return AUTOCAL_SENSORS.includes(sensorName)
    },
    hasUnsavedUserZero(sensorName) {
      return (
        this.sensorCanZero(sensorName) &&
        this.userZeros[sensorName]?.userzero === true
      )
    },
    hasUnsavedFactoryZero(sensorName) {
      return (
        this.sensorCanZero(sensorName) &&
        this.userZeros[sensorName]?.userzero === false
      )
    },
    hasUserZeroAppliedState(sensorName) {
      return (
        this.sensorCanZero(sensorName) &&
        this.userZeros[sensorName]?.zero_state === ZERO_STATES.USER_ZERO_APPLIED
      )
    },
    hasUserZeroRequestedState(sensorName) {
      return (
        this.sensorCanZero(sensorName) &&
        this.userZeros[sensorName]?.zero_state ===
          ZERO_STATES.USER_ZERO_REQUESTED
      )
    },
    hasFactoryZeroAppliedState(sensorName) {
      return (
        this.sensorCanZero(sensorName) &&
        this.userZeros[sensorName]?.zero_state ===
          ZERO_STATES.FACTORY_ZERO_APPLIED
      )
    },
    hasFactoryZeroRequestedState(sensorName) {
      return (
        this.sensorCanZero(sensorName) &&
        this.userZeros[sensorName]?.zero_state ===
          ZERO_STATES.FACTORY_ZERO_REQUESTED
      )
    },
    getZeroAppliedAtString(sensorName) {
      if (this.sensorCanZero(sensorName)) {
        return new Date(
          this.userZeros[sensorName]?.zero_applied_at
        ).toLocaleString()
      }
      return new Date('').toLocaleString()
    },
    getZeroRequestedAtString(sensorName) {
      if (this.sensorCanZero(sensorName)) {
        return new Date(
          this.userZeros[sensorName].zero_requested_at
        ).toLocaleString()
      }
      return new Date('').toLocaleString()
    },
    getButtonStyles(sensorName, isUserZeroBtn) {
      if (isUserZeroBtn) {
        return `${this.getUserZeroButtonColor(
          sensorName
        )} ${this.getDisabledZeroButtonColor()} zero-btn`
      } else {
        return `${this.getDisabledZeroButtonColor()} zero-btn`
      }
    },
    getUserZeroButtonColor(sensorName) {
      if (
        this.hasUnsavedFactoryZero(sensorName) ||
        this.hasFactoryZeroRequestedState(sensorName)
      ) {
        return 'warning--text'
      } else if (
        this.hasUnsavedUserZero(sensorName) ||
        this.hasUserZeroRequestedState(sensorName)
      ) {
        return 'primary--text'
      } else if (this.hasUserZeroAppliedState(sensorName)) {
        return 'success--text'
      }
      return ''
    },
    getDisabledZeroButtonColor() {
      if (!this.hasPermission) {
        return 'disabled--color'
      }
      return ''
    },
    getZeroButtonTooltipText(sensorName) {
      if (this.hasUnsavedUserZero(sensorName)) {
        return this.$t('map.deviceSettings.zeroing.tooltip.userUnsaved')
      } else if (this.hasUnsavedFactoryZero(sensorName)) {
        return this.$t('map.deviceSettings.zeroing.tooltip.factoryUnsaved')
      } else if (this.hasUserZeroAppliedState(sensorName)) {
        return this.$t('map.deviceSettings.zeroing.tooltip.userApplied', {
          timestamp: this.getZeroAppliedAtString(sensorName),
        })
      } else if (this.hasUserZeroRequestedState(sensorName)) {
        return this.$t('map.deviceSettings.zeroing.tooltip.userRequested', {
          timestamp: this.getZeroRequestedAtString(sensorName),
        })
      } else if (this.hasFactoryZeroRequestedState(sensorName)) {
        return this.$t('map.deviceSettings.zeroing.tooltip.factoryRequested', {
          timestamp: this.getZeroRequestedAtString(sensorName),
        })
      } else if (this.hasFactoryZeroAppliedState(sensorName)) {
        if (this.userZeros[sensorName].zero_applied_at !== null) {
          return this.$t('map.deviceSettings.zeroing.tooltip.factoryApplied', {
            timestamp: this.getZeroAppliedAtString(sensorName),
          })
        }
        return this.$t('map.deviceSettings.zeroing.tooltip.factoryDefault')
      } else {
        return this.$t('map.deviceSettings.zeroing.tooltip.invalid')
      }
    },
    showRemoveUserZeroButton(sensorName) {
      return (
        this.sensorCanZero(sensorName) &&
        this.hasUserZeroAppliedState(sensorName) &&
        !this.hasUnsavedFactoryZero(sensorName) &&
        !this.hasUnsavedUserZero(sensorName)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/global-mixins.scss';
@import '@/assets/global-variables.scss';

.v-dialog {
  .v-card {
    padding: 24px;

    .v-input {
      padding: 0;
    }

    .v-text-field {
      padding: 0;
      margin-bottom: 4px;
      font-size: 14px;
    }

    .v-item-group .v-card {
      padding: 16px;
    }
  }

  .v-card:not(.mobile) {
    .calibration-card {
      padding-right: 13px !important;
    }
  }

  .v-card.mobile {
    .calibration-card {
      .calibration-header,
      .calibration-body {
        padding: 0px;
      }

      .calibration-body {
        max-height: fit-content;
      }

      .calibration-footer.fixed-position {
        position: fixed;
        bottom: 68px;
        right: 0;
        background: $white-color;
        padding: 16px;
        padding-top: 24px;
        margin: 0;
      }

      .col-2 {
        padding: 0 6px;
        min-width: 52px;

        @media (max-width: 430px) {
          &.multi-factor,
          &.offset {
            max-width: min-content;
          }
        }
      }

      .col-3 {
        .request-factory-zero-btn {
          position: absolute;
          margin-left: -8px;
        }
      }

      .row {
        margin: 12px 0 0 0;
      }

      .request-factory-zero-btn {
        margin-left: -6px;
      }

      .auto-cal-tooltip {
        .v-icon {
          margin-left: 1px;
        }
     }
    }
  }

  .v-card__title.tab-content__title {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0 0 16px 0;
    opacity: 0.56;
  }

  .v-card__subtitle {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    color: rgba(0, 0, 0, 0.9);
  }

  .v-btn {
    min-width: 120px !important;

    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }

  .zero-btn-overlay {
    position: absolute;
    right: 12px;
  }

  .zero-btn {
    min-width: 0px !important;
  }

  .zero-btn.disabled--color {
    opacity: 0.56;
  }
}

.calibration-header {
  position: relative;
  padding-right: 16px;
  margin-top: 0;
  margin-bottom: 8px;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 12px;
    right: 16px;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }

  .v-dialog--fullscreen & {
    padding-right: 12px;
  }

  .col {
    label {
      display: inline-block;
      font-size: 0.75rem;
      line-height: 2;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .auto-cal-tooltip {
    .v-icon {
      position: absolute;
      margin-left: 3px;
    }
  }
}

.calibration-scroll {
  padding-top: 16px;
}

.calibration-body {
  max-height: 300px;
  padding-top: 16px;
  padding-right: 16px;

  .v-dialog--fullscreen & {
    max-height: 200px;
  }

  .row:last-child {
    margin-bottom: 0;
  }

  ::v-deep .v-input--selection-controls__input {
    @include center-absolute-element;
  }
}

.calibration-footer {
  margin-top: 16px;
  margin-bottom: 0;
}

.col {
  padding-top: 0;
  padding-bottom: 0;
}

.col-2 {
  max-width: 100%;
  flex-grow: 1 !important;

  .request-factory-zero-btn {
    position: absolute;
    margin-left: -8px;
  }
}

.label {
  font-size: 13px;
  display: flex;

  img {
    width: 17px;
    height: 17px;
    margin-top: 3px;
    margin-right: 3px;
  }
}

.help-text {
  font-size: 0.8125rem;
  line-height: 1.375;
  height: calc(1.375em * 3);
  margin-top: 0;
  margin-bottom: 0;

  .v-dialog--fullscreen & {
    height: calc(1.375em * 4);
  }
}

.zero-column-header {
  margin-left: 8px;
}

.zero-cell {
  padding-left: 16px;
  padding-right: 16px;
}
</style>

import { Buffer } from 'buffer'

export const getLoggedInAsUserFromUrl = () => {
  if (location?.search) {
    const urlParams = new URLSearchParams(location.search)
    const decodedUserData = urlParams.get("user")
    if (decodedUserData) {
      try {
        const encodedUserData = Buffer.from(decodedUserData, 'base64').toString('ascii')
        const loggedInAsUser = JSON.parse(encodedUserData)
        sessionStorage.setItem('tsi.logInAs',  JSON.stringify(loggedInAsUser))
        return loggedInAsUser
      } catch (e) {
        // It doesn't enable the log in as feature
      }
    }
  }

  return null
}

export const getLoggedInAsUserFromSessionStorage = () => {
  const logInAsData = JSON.parse(sessionStorage.getItem('tsi.logInAs'))
  return logInAsData?.accountId ? logInAsData : null
}

export const getLoggedInAsUser = () => {
  return getLoggedInAsUserFromUrl() || getLoggedInAsUserFromSessionStorage()
}

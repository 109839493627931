<template>
  <v-card
    :class="{
      'ma-6': !this.$vuetify.breakpoint.xs,
      'alert-history-card': true,
      'is-mobile': this.isMobile,
    }"
  >
    <v-card-subtitle>{{ $t('alertHistory.title') }}</v-card-subtitle>
    <v-data-table
      v-if="
        !$auth.loading && alertHistory !== null && alertHistory.length !== 0
      "
      :headers="headers"
      :items="alertHistory"
      :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
      :class="{ mobile: isMobile }"
      :header-props="headerProps"
      :search="customTableFilter"
      @current-items="setItems($event)"
      show-select
      :options="tableOptions"
      :sort-by.sync="sortedBy"
      :sort-desc.sync="sortedDesc"
      :itemsPerPage.sync="itemsPerPage"
      :footer-props="{
        'items-per-page-text': isMobile
          ? $t('tables.rows')
          : $t('tables.rowsPerPage'),
        itemsPerPageOptions: [10, 25, 50],
      }"
    >
      <!-- top slot above the table, renders with it -->
      <template v-slot:top>
        <!-- search -->
        <div class="table-bar" v-if="!selected.length">
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            :style="isMobile ? '' : 'width: 200px'"
            class="search-field"
            data-cy="searchField"
          />
          <v-spacer />
          <span class="export">
            <export-notifications />
          </span>
        </div>
        <!-- global delete -->
        <div
          class="table-bar"
          :class="{ 'delete-mode': !!selected.length }"
          v-else
        >
          <span class="selection-message" data-cy="deleteText">
            {{
              $t('alertHistory.selectionMessage', { value: selected.length })
            }}
          </span>
          <v-spacer />
          <!-- delete confirm -->
          <base-dialog
            :open="deleteOpen"
            @close="deleteClose()"
            :fullscreen="$vuetify.breakpoint.xs"
            :title="$t('alertHistory.deleteDialog.title', { plurality })"
            :persistentOverlay="true"
            icon="mdi-delete"
            padded
          >
            <template v-slot:activator="{ on }">
              <v-btn
                class="alert-delete"
                text
                v-on="on"
                @click.stop="deleteOpen = true"
                data-cy="deleteButton"
              >
                <v-icon>mdi-delete</v-icon>
                <span style="padding-left: 4px">
                  {{ $t('buttons.delete') }}
                </span>
              </v-btn>
            </template>
            {{
              $t('alertHistory.deleteDialog.message', {
                value: selected.length,
                plurality,
              })
            }}
            <template v-slot:actions>
              <v-btn
                @click="deleteClose()"
                outlined
                class="black--text"
                text
                data-cy="deleteCancelButtonModal"
                :disabled="deleteInProgress"
                >{{ $t('buttons.cancel') }}</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                @click="deleteNotifications()"
                class="primary white--text"
                text
                data-cy="deleteButtonModal"
                :loading="deleteInProgress"
                >{{ $t('buttons.delete') }}</v-btn
              >
            </template>
          </base-dialog>
        </div>
      </template>
      <!-- header slot -->
      <template v-slot:[`header.data-table-select`]="{ on, props }">
        <v-checkbox
          class="master-select"
          color="primary"
          v-bind="props"
          v-on="on"
          v-model="selectAll"
          data-cy="checkboxDesktop"
        />
      </template>
      <!-- body slot -->
      <template v-slot:body="{ items }">
        <!-- desktop view -->
        <tbody v-if="!isMobile">
          <tr v-for="item in items" :key="item.id">
            <td>
              <v-checkbox color="primary" v-model="selected" :value="item.id" />
            </td>
            <td
              style="min-width: 80px; max-width: 120px"
              data-cy="titleColumnData"
            >
              {{ item.title }}
            </td>
            <td
              style="min-width: 130px"
              class="text-xs-right"
              data-cy="triggeredOnData"
            >
              {{ item.triggeredAt }}
            </td>
            <td
              style="min-width: 150px; max-width: 200px"
              class="text-xs-right"
              data-cy="deviceColumnData"
            >
              <v-tooltip
                bottom
                :max-width="600"
                :disabled="!isNameOverflowing[item.id]"
              >
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="device-name" :ref="item.id">
                    <span>{{ item.deviceName }}</span>
                  </div>
                </template>
                <span>{{ item.deviceName }}</span>
              </v-tooltip>
            </td>
            <td
              style="min-width: 150px; max-width: 200px"
              class="text-xs-right"
              data-cy="locationColumnData"
            >
              <v-tooltip
                v-if="item.deviceLocationName"
                bottom
                :max-width="600"
                :disabled="!isNameOverflowing[item.id]"
              >
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="device-location" :ref="item.id">
                    <span>{{ item.deviceLocationName }}</span>
                  </div>
                </template>
                <span>{{ item.deviceLocationName }}</span>
              </v-tooltip>
              <span v-else class="not-available-text">
                {{ $t('alertHistory.notAvailable') }}
              </span>
            </td>
            <td
              style="max-width: 160px"
              class="text-xs-right"
              data-cy="modelColumnData"
            >
              {{ item.deviceModel }}
            </td>
            <td
              style="min-width: 110px; max-width: 220px"
              class="text-xs-right"
              data-cy="conditionColumnData"
            >
              {{ item.condition }}
            </td>
            <td
              style="min-width: 110px; max-width: 220px"
              class="text-xs-right"
              data-cy="messageColumnData"
            >
              {{ item.message }}
            </td>
            <td
              style="min-width: 110px"
              class="text-xs-right"
              data-cy="alertLevelColumnData"
            >
              {{ item.severity }}
            </td>
          </tr>
        </tbody>
        <!-- mobile view -->
        <tbody v-else>
          <tr v-for="item in items" :key="item.id">
            <td class="mobile-list">
              <v-row no-gutters>
                <v-col cols="1">
                  <v-checkbox
                    color="primary"
                    v-model="selected"
                    :value="item.id"
                  />
                </v-col>
                <v-col cols="11">
                  <ul class="flex-content">
                    <li class="flex-item item-title">{{ item.title }}</li>
                    <li class="flex-item">
                      <span>
                        {{ $t('alertHistory.tableHeaders.triggeredOn') }}:&nbsp;
                      </span>
                      <span>
                        {{ new Date(item.triggeredAt).toLocaleString() }}
                      </span>
                    </li>
                    <li class="flex-item">
                      <span>
                        {{ $t('alertHistory.tableHeaders.deviceName') }}:&nbsp;
                      </span>
                      <v-tooltip
                        bottom
                        :max-width="600"
                        :disabled="!isNameOverflowing[item.id]"
                      >
                        <template v-slot:activator="{ on }">
                          <span v-on="on" class="device-name" :ref="item.id">
                            <span>{{ item.deviceName }}</span>
                          </span>
                        </template>
                        <span>{{ item.deviceName }}</span>
                      </v-tooltip>
                    </li>
                    <li class="flex-item">
                      <span>
                        {{ $t('alertHistory.tableHeaders.deviceLocationName') }}:&nbsp;
                      </span>
                      <v-tooltip
                        v-if="item.deviceLocationName"
                        bottom
                        :max-width="600"
                        :disabled="!isNameOverflowing[item.id]"
                      >
                        <template v-slot:activator="{ on }">
                          <span v-on="on" class="device-location" :ref="item.id">
                            <span>{{ item.deviceLocationName }}</span>
                          </span>
                        </template>
                        <span>{{ item.deviceLocationName }}</span>
                      </v-tooltip>
                      <span v-else class="not-available-text">
                        {{ $t('alertHistory.notAvailable') }}
                      </span>
                    </li>
                    <li class="flex-item">
                      <span>
                        {{ $t('alertHistory.tableHeaders.deviceModel') }}:&nbsp;
                      </span>
                      <span>{{ item.deviceModel }}</span>
                    </li>
                    <li class="flex-item">
                      <span>
                        {{ $t('alertHistory.tableHeaders.condition') }}:&nbsp;
                      </span>
                      <span>{{ item.condition }}</span>
                    </li>
                    <li class="flex-item">
                      <span>
                        {{ $t('alertHistory.tableHeaders.message') }}:&nbsp;
                      </span>
                      <span>{{ item.message }}</span>
                    </li>
                    <li class="flex-item">
                      <span>
                        {{ $t('alertHistory.tableHeaders.alertLevel') }}:&nbsp;
                      </span>
                      <span>{{ item.severity }}</span>
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <!-- empty message -->
    <div
      v-else-if="
        !$auth.loading && alertHistory !== null && alertHistory.length === 0
      "
      class="text-center emptyMessage"
    >
      {{ $t('alertHistory.emptyMessage') }}
    </div>
    <!-- progress spinner -->
    <div v-else class="text-center emptyMessage">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </v-card>
</template>

<script>
import {
  accountExists,
  AuthEvents,
  emailVerified,
  isAuthorized,
} from '@fusion/auth'
import exportNotifications from '../components/dialogs/ExportAlertsDialog.vue'
import dialog from '../components/dialogs/Dialog.vue'
import { deviceModelDisplay, deviceSubmodelDisplay } from '../services/map'
import { isChildOverflowing } from '../services/html-utilities'
import debounce from 'lodash/debounce'

const LOCAL_STORAGE_KEYS = 'tsi.alertHistoryTableSettings'

export default {
  name: 'AlertHistory',
  mixins: [isAuthorized, accountExists, emailVerified],
  components: {
    'export-notifications': exportNotifications,
    'base-dialog': dialog,
  },
  mounted() {
    if (this.$auth.isAuthenticated) {
      this.getAlertNotifications()
    }

    this.$auth.$on(AuthEvents.AUTH_CHANGED, async (isAuthenticated) => {
      if (isAuthenticated) {
        this.getAlertNotifications()
      }
    })

    this.observe(this.$el)
    this.getAlertHistoryTableSettings()
  },
  updated() {
    this.setIsNameOverflowing()
  },
  destroyed() {
    if (this.observer) this.observer.disconnect()
  },
  data() {
    return {
      sortedBy: ['triggeredAt'],
      sortedDesc: [true],
      itemsPerPage: 10,
      tableOptions: {},
      selected: [],
      currentItems: [],
      deleteOpen: false,
      deleteInProgress: false,
      selectAll: false,
      headerProps: {
        sortByText: this.$t('tables.sortBy'),
      },
      search: '',
      headers: [
        {
          text: this.$t('alertHistory.tableHeaders.title'),
          align: 'left',
          value: 'title',
        },
        {
          text: this.$t('alertHistory.tableHeaders.triggeredOn'),
          value: 'triggeredAt',
          sort: function (a, b) {
            // turn strings into dates, subtract them to get a value that is either negative, positive, or zero
            return new Date(a).getTime() - new Date(b).getTime()
          },
        },
        {
          text: this.$t('alertHistory.tableHeaders.deviceName'),
          value: 'deviceName',
        },
        {
          text: this.$t('alertHistory.tableHeaders.deviceLocationName'),
          value: 'deviceLocationName',
        },
        {
          text: this.$t('alertHistory.tableHeaders.deviceModel'),
          value: 'deviceModel',
        },
        {
          text: this.$t('alertHistory.tableHeaders.condition'),
          value: 'condition',
        },
        {
          text: this.$t('alertHistory.tableHeaders.message'),
          value: 'userMessage',
        },
        {
          text: this.$t('alertHistory.tableHeaders.alertLevel'),
          value: 'severity',
        },
      ],
      alertHistoryData: null,
      isNameOverflowing: {},
    }
  },
  computed: {
    alertHistory() {
      // Submodel is not returned with the alert data
      // and may not be yet available in Vuex at mount.
      // Storing the raw data and returning the modified results
      // in a computed property allows the dataset to be reactive.
      return this.alertHistoryData
        ? this.updateAlertHistory(this.alertHistoryData)
        : null
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    customTableFilter() {
      if (this.search.length >= 2) {
        return this.search
      } else if (this.search.length === 1) {
        return '//no-result//'
      }
      return ''
    },
    plurality() {
      return this.selected.length > 0 ? 's' : ''
    },
  },
  watch: {
    selected() {
      if (this.selected.length == 0) this.selectAll = false
    },
    selectAll() {
      if (this.selectAll) {
        for (let i = 0; i < this.currentItems.length; i++) {
          if (!this.selected.includes(this.currentItems[i].id)) {
            this.selected.push(this.currentItems[i].id)
          }
        }
      } else {
        this.selected = []
      }
    },
    currentItems() {
      this.$forceUpdate()
    },
    sortedBy: {
      deep: true,
      handler() {
        this.setAlertHistoryTableSettings()
      },
    },
    sortedDesc: {
      deep: true,
      handler() {
        this.setAlertHistoryTableSettings()
      },
    },
    itemsPerPage() {
      this.setAlertHistoryTableSettings()
    },
  },
  methods: {
    setItems(e) {
      this.currentItems = e
      const tmp = [...this.selected]
      this.selectAll = false
      setTimeout(() => {
        this.selected = [...tmp]
      }, 5)
    },
    getAlertNotifications() {
      this.$api.getAlertNotifications().then((res) => {
        if (res.ok) {
          res.json().then((body) => {
            this.alertHistoryData = body
          })
        }
      })
    },
    updateAlertHistory(alertHistoryData) {
      const alertHistory = []

      if (alertHistoryData && alertHistoryData.length > 0) {
        // reverse the data set, so that the newest alerts are at the top of the table
        for (let i = alertHistoryData.length - 1; i >= 0; i--) {
          const newAlert = {
            id: alertHistoryData[i].id,
            title: alertHistoryData[i].trigger.title,
            triggeredAt: alertHistoryData[i].triggeredAt,
            deviceName: alertHistoryData[i].device.name,
            deviceLocationName: alertHistoryData[i].device.locationName,
            deviceModel: '',
            userMessage: alertHistoryData[i].trigger.userMessage,
            severity: alertHistoryData[i].trigger.severity,
            message: alertHistoryData[i].message,
            condition: '',
          }

          // translate the severity
          newAlert.severity = this.$t(
            `map.deviceAlerts.fields.levels.${newAlert.severity}`
          )

          newAlert.triggeredAt = new Date(newAlert.triggeredAt).toLocaleString()

          // build device model+submodel string
          const modelKey = this.$store.getters['devicemodels/getModelKey'](
            alertHistoryData[i].device.model
          )
          let deviceModel = deviceModelDisplay(modelKey, this.$t.bind(this))
          const submodel = this.$store.getters['devices/getSubmodelByDeviceId'](
            alertHistoryData[i].device.id
          )
          const submodelKey = this.$store.getters[
            'devicemodels/getSubmodelKey'
          ](alertHistoryData[i].device.model, submodel)
          const deviceSubmodel = deviceSubmodelDisplay(
            submodelKey,
            this.$t.bind(this)
          )
          if (deviceSubmodel) deviceModel += ` (${deviceSubmodel})`
          newAlert.deviceModel = deviceModel

          // build condition string
          const conditionMeasurement = this.$t(
            `map.deviceAlerts.fields.sensors.${alertHistoryData[i].trigger.condition.field}`
          )
          const conditionOperator =
            alertHistoryData[i].trigger.condition.operator
          const conditionValue = alertHistoryData[i].trigger.condition.value
          if (conditionValue != 0 && !conditionValue) {
            newAlert.condition = this.$t(
              'map.deviceAlerts.fields.conditions.none'
            )
          } else {
            newAlert.condition = this.$t(
              `map.deviceAlerts.fields.conditions.${conditionOperator}`,
              { measurement: conditionMeasurement, value: conditionValue }
            )
          }

          alertHistory.push(newAlert)
        }
      }

      return alertHistory
    },
    deleteClose() {
      this.selected = []
      this.deleteOpen = false
    },
    async deleteNotifications() {
      if (!this.deleteInProgress) {
        this.deleteInProgress = true
        const notes = [...this.selected]
        const noteMap = notes.map((note) =>
          this.$api.deleteAlertNotification(note)
        )

        await Promise.all(noteMap)
        this.getAlertNotifications()
        this.deleteClose()
        this.deleteInProgress = false
      }
    },
    async setIsNameOverflowing() {
      await Vue.nextTick()
      this.currentItems.forEach((item) => {
        this.isNameOverflowing[item.id] = isChildOverflowing(
          this.$refs[item.id]
        )
      })
    },
    observe(el) {
      const forceUpdate = debounce(() => {
        this.$forceUpdate()
      }, 200)

      this.observer = new ResizeObserver(forceUpdate)
      if (el instanceof Element) {
        this.observer.observe(el)
      }
    },
    setAlertHistoryTableSettings() {
      const save = {
        itemsPerPage: this.itemsPerPage,
        sortBy: this.sortedBy,
        sortDesc: this.sortedDesc,
      }
      localStorage.setItem(LOCAL_STORAGE_KEYS, JSON.stringify(save))
    },
    getAlertHistoryTableSettings() {
      const settings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS))
      if (settings !== null) {
        this.sortedDesc = settings.sortDesc
        this.itemsPerPage = settings.itemsPerPage
        this.sortedBy = settings.sortBy
      }
    },
  },
}
</script>

<style lang="scss">
// requires non-scoped scss to override the vuetify styles
.alert-history-card {
  .v-data-table {
    .v-data-table-header-mobile__select {
      min-width: unset !important;
    }
    .v-data-footer {
      margin-right: 8px !important;
    }
    .v-data-table-header-mobile__wrapper {
      .v-select {
        max-width: 335px !important;
      }
    }
  }
  .table-bar {
    .v-input__prepend-outer {
      margin-right: 16px !important;
    }
  }
  .v-data-table--mobile {
    .v-data-footer__pagination {
      margin: 0 16px 0 12px !important;
    }
  }
}
.alert-history-card.is-mobile {
  .v-data-table-header__icon {
    opacity: 1 !important;
  }
}
</style>

<style lang="scss" scoped>
.emptyMessage {
  height: 240px;
  line-height: 240px;
  color: rgba(0, 0, 0, 0.56);
}

.master-select {
  padding-right: 8px;
}

.device-name,
.device-location {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

td.mobile-list {
  width: 100vw;
  max-width: 100vw;
  .ma-6 & {
    width: calc(100vw - 48px);
    max-width: calc(100vw - 48px);
  }
  ul {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 12px;
    list-style-type: none;
    .item-title {
      font-weight: 500;
      font-size: 15px;
      margin: 4px 0;
    }
  }
  .flex-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;
    > span:first-child {
      display: inline-block;
      margin-right: 4px;
      white-space: nowrap;
      color: rgba(0, 0, 0, 0.6);
    }
    > span:last-child {
      display: inline-block;
      &.device-name,
      &.device-location {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.v-dialog {
  .v-card {
    // padding: 24px;
    min-height: 350px;
    .v-input {
      padding-top: 24px;
    }
    .form-section-header {
      font-weight: 300;
      font-size: 18px;
      margin-left: -12px;
      opacity: 0.56;
    }
    .message {
      color: rgba(0, 0, 0, 0.56);
    }
  }
  .v-card__subtitle {
    min-height: 30px;
    padding: 16px 12px 8px 12px;
  }
  .v-card__title {
    font-weight: 300;
    padding: 0 0 16px 0;
  }
  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }
}

.table-bar {
  display: flex;
  height: 50px;

  background-color: transparent;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;

  .search-field {
    max-width: 375px;
  }

  .export {
    padding-top: 14px;
  }

  &.delete-mode {
    width: 100%;
    margin-left: 0;
    padding-right: 16px;
    background-color: #00aeef;
    transition: height 0.5s linear;

    border-radius: 2px;
  }

  .selection-message {
    color: white;
    padding: 6px;
    margin: 6px 6px;
  }

  .alert-delete {
    color: white;
    padding-top: 6px;
    padding-bottom: 6px;
    margin: 6px 0;
  }
}
</style>

import { LOG_IN_AS_HEADER_NAMES } from './utils'
import { getLogInAsHeaders } from './helper'

export const getUserPermissions = async function () {
  return this.GET(
    '/subscriptions/user/permissions',
    {
      ...getLogInAsHeaders([
        LOG_IN_AS_HEADER_NAMES.EMAIL,
      ]),
    },
    {},
    process.env.VUE_APP_SUBS_HOST
  )
}

export const getUserSubscriptions = async function () {
  return this.GET(
    '/subscriptions/user/',
    {
      ...getLogInAsHeaders([
        LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
        LOG_IN_AS_HEADER_NAMES.SAP_ID,
      ]),
    },
    {},
    process.env.VUE_APP_SUBS_HOST
  )
}

export const postValidateSubscriptionCode = async function (activationCode) {
  return this.POST(
    `/subscriptions/user/activation/add/${activationCode}`,
    JSON.stringify({}),
    {
      ...getLogInAsHeaders([
        LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
        LOG_IN_AS_HEADER_NAMES.SAP_ID,
      ]),
    },
    {},
    process.env.VUE_APP_SUBS_HOST
  )
}

export const patchCancelSubscription = async function (activationId) {
  return this.POST(
    `/subscriptions/user/activation/cancel/${activationId}`,
    JSON.stringify({}),
    {},
    {},
    process.env.VUE_APP_SUBS_HOST
  )
}

export const postBillingInfoPurchaseOrder = async function (
  activationId,
  purchaseOrderReference
) {
  const body = { reference: purchaseOrderReference }
  return this.POST(
    `/subscriptions/user/billing/po/${activationId}`,
    JSON.stringify(body),
    {},
    {},
    process.env.VUE_APP_SUBS_HOST
  )
}

export const postBillingInfoCreditCard = async function (
  activationId,
  cardType,
  cardToken,
  cardExpiry,
  cardHolderName
) {
  const body = {
    cardType: cardType,
    cardToken: cardToken,
    cardExpiry: cardExpiry,
    cardHolderName: cardHolderName,
  }
  return this.POST(
    `/subscriptions/user/billing/cc/${activationId}`,
    JSON.stringify(body),
    {},
    {},
    process.env.VUE_APP_SUBS_HOST
  )
}

export const getDelegoToken = async function () {
  return this.GET(
    `/subscriptions/user/delego/token`,
    {},
    {},
    process.env.VUE_APP_SUBS_HOST
  )
}
